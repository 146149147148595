import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FileSelectButton from './FileSelectButton';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  snackbarPosition: {
    bottom: "95px"
  },
  success: {
    backgroundColor: "#43a047",
    maxWidth: "100%"
  },
  failure: {
    backgroundColor: "#f3004d",
    maxWidth: "100%"
  }
});

class ModelLoader extends Component {
  constructor() {
    super();
    this.state = {
      showSnackbar: false
    };
  }

  handleFileSelect = (f) => {
    const {
      binary,
      onLoading
    } = this.props;

    this.fileSize = f.size;
    this.fileName = f.name;
    if(binary) {
      this.read = 0;
      this.buffer = "";
    } else {
      this.data = undefined;
      this.buffer = undefined;
      this.offset = 0;
    }

    if(onLoading) {
      onLoading();
    }
  };

  handleFinished = () => {
    const {
      onLoadModel,
      showSnackbarOnLoad,
      maxFileSize
    } = this.props;

    this.stopReadingFile = false;

    const fileTooBig = this.buffer.byteLength > maxFileSize;
    const success =  !maxFileSize || !fileTooBig;

    if(success) {
      onLoadModel(this.buffer, this.fileName);

      if(showSnackbarOnLoad) {
        this.setState({ showSnackbar: true, message: "Loaded " + this.fileName, messageStatus: "success" });
      }
    } else {
      if(showSnackbarOnLoad) {
        if(fileTooBig) {
          this.setState({ showSnackbar: true, message: this.fileName + " is too big. Models must be under 30MB.", messageStatus: "failure" });
        }
      }
    }
  };

  handleRead = (chunk, size, totalSize) => {
    const {
      onProgress,
      onError
    } = this.props;

    if(!this.data) {
      this.buffer = new ArrayBuffer(totalSize);
      this.data = new Uint8Array(this.buffer);
      this.offset = 0;
    }

    try {
      this.data.set(new Uint8Array(chunk), this.offset);
    } catch(err) {
      if(onError) {
        onError(new Error("Error reading data chunk: " + err.message + ", " + this.offset));
      }
    }

    this.offset += size;
    const progress = Math.min(this.offset/this.fileSize*100, 100);

    if(onProgress) {
      onProgress(progress);
    }
  };

  stopReading = () => {
    return this.stopReadingFile;
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ showSnackbar: false });
  }

  render() {
    const {
      classes,
      hideButton,
      iconOnly,
      labelOnly,
      variant,
      size,
      color,
      label = "Model"
    } = this.props;
    const {
      showSnackbar,
      message,
      messageStatus
    } = this.state;
    return (
    <>
    { hideButton ? null :
      <FileSelectButton onFileSelect={this.handleFileSelect}
                        onFinished={this.handleFinished}
                        onRead={this.handleRead}
                        stopReading={this.stopReading}
                        chunkSize={64*1024}
                        binary={true}
                        size={size}
                        color={color}
                        accept=".obj,.stl"
                        variant={variant}>
        { labelOnly ? null : <OpenInBrowserIcon/>} {iconOnly ? null : label}
      </FileSelectButton>
    }
      <Snackbar classes={ { root: classes.snackbarPosition  } } 
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={showSnackbar}
        autoHideDuration={10000}
        onClose={this.handleClose}
      >
        <SnackbarContent className={classes[messageStatus]}
          message={message}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        >
        </SnackbarContent>
      </Snackbar>
    </>);
  }
};

ModelLoader.propTypes = {
  onProgress: PropTypes.func,
  onError: PropTypes.func,
  onLoadModel: PropTypes.func,
  stopReading: PropTypes.func
};

export default withStyles(styles)(ModelLoader);
