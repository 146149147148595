import { connect } from 'react-redux';
import actions from '../actions';

import MyAccount from '../components/MyAccount';

export default connect(
  (state, ownProps) => ({
    open: state.get("alignerState").get("myAccountDialogOpen")
  }),
  (dispatch, ownProps) => ({
    onClose: () => dispatch(actions.alignerState.closeMyAccount()),
    onPurchaseCredits: (credits) => {
      dispatch(actions.alignerState.purchaseCredits(credits));
    }
  })
)(MyAccount);
