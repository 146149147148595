import React from 'react';
import { StripeProvider } from 'react-stripe-elements';

import getStripe from '../util/stripe';

export default class Stripe extends React.Component {
  constructor() {
    super();
    this.state = {
      stripe: null
    };
  }

  componentDidMount() {
    getStripe().then((stripe) => {
      this.setState({ stripe });
    });
  }

  render() {
    const {
      children
    } = this.props;
    const {
      stripe
    } = this.state;

    return (<StripeProvider stripe={stripe}>
    {children}
    </StripeProvider>);
  }
};
