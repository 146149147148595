import { connect } from 'react-redux';

import CameraButtons from '../components/CameraButtons';
import actions from '../actions';

export default connect(
  (state, ownProps) => ({
    hideTitleBar: !state.get("ui").get("titleBar").get("visible")
  }),
  (dispatch, ownProps) => ({
    parentCamera: (parentTo) => dispatch(actions.viewer3D.parentCamera(parentTo)),
    setPerspective: () => dispatch(actions.viewer3D.setPerspective()),
    setOrthographic: () => dispatch(actions.viewer3D.setOrthographic())
  })
)(CameraButtons);
