import { takeEvery } from 'redux-saga/effects';
import actions from '../actions';
import IFrameMessenger from '../iframe';

export function* handleSendRockhopperMessage(action) {
  console.log("in simulator handle rockhopper message", action);
  const iframeMessenger = IFrameMessenger.getInstance();
  console.log("in simulator handle rockhopper message", iframeMessenger.windows);

  iframeMessenger.sendMessage(action);
  yield;
}

export default function* watchForToRockhopperMessages() {
  yield takeEvery(actions.iframe.toRockhopper, handleSendRockhopperMessage);
}
