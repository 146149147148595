import { createActions } from 'redux-actions';

export default createActions({
  RFID: {
    RECEIVED_RFID_SCAN_MESSAGE: undefined,
    RECEIVED_ERROR_MESSAGE: undefined,
    RECEIVED_INCOMPLETE_MESSAGE: undefined,
    CONNECTED_SCANNER: undefined,
    DISCONNECTED_SCANNER: undefined
  }
});
