import { createActions } from 'redux-actions';

const actions = createActions({
  MACHINE_STATE: {
    MOTION: {
      OFF: undefined,
      RAPID: undefined,
      LINEAR: undefined,
      ARC_C_W: undefined,
      ARC_C_C_W: undefined,
      SET_POSITION: undefined,
      NON_MOTION_COMMAND: undefined
    }
  }
});

export default actions;
