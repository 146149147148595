import { connect } from 'react-redux';
import actions from '../actions/interpreter';

import GCodeLoader from '../components/GCodeLoader';

export default connect(
  (state, ownProps) => ({ 
    machineState: state.get("machineState")
  }),
  (dispatch, ownProps) => ({
    onSetFileName: (name) => dispatch(actions.interpreter.setFileName(name)),
    onLoading: () => dispatch(actions.interpreter.startLoading()),
    onFinished: () => dispatch(actions.interpreter.endLoading()),
    onProgress: (p) => dispatch(actions.interpreter.setLoadingProgress(p)),
    onLines: (lines, preview) => dispatch(actions.interpreter.setLines(lines, preview))
  })
)(GCodeLoader);
