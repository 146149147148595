import Immutable from 'immutable';
import actions from '../../actions/machine-state/tool';
import { handleActions } from 'redux-actions';
import { LONG } from '../../constants/machine-state/tool';

// The set tool is the number last set using a T word
// The loaded tool is the last tool that was loaded using M6. M6 loads the last set tool.

const initialTool = Immutable.Map({ Z: -3.0, R: .0625, holder: LONG });

const reducer = handleActions(
  {
    [actions.machineState.tool.set]: (state, action) => state.set('set', action.payload),
    [actions.machineState.tool.change]: (state, action) => state.set('loaded', state.get('set')),
    [actions.machineState.tool.setToolLengthOffset]: (state, action) => {
      const { H } = action.payload;

      let toolIndex = state.get("loaded")-1;
      if( H !== undefined ) {
        toolIndex = H-1;
      }

      const tool = state.get("table").get(toolIndex);

      return state.set("toolLengthOffset", tool);
    },
    [actions.machineState.tool.clearToolLengthOffset]: (state, action) => {
      return state.set("toolLengthOffset", initialTool);
    },
    [actions.machineState.tool.table.setTool]: (state, action) => {
      const { P: toolNumber, X, Y, Z, A, B, C, U, V, W, R, I, J, Q, holder } = action.payload;
      let params = { X, Y, Z, A, B, C, U, V, W, R, I, J, Q, holder };
      for(const key in params) {
        if(params[key] === undefined) {
          delete params[key];
        }
      }
      const table = state.get("table");
      return state.set("table", table.set(toolNumber-1, table.get(toolNumber-1).merge(params)));
    }
  },
  Immutable.Map({
    set: 1,
    loaded: 1,
    toolLengthOffset: initialTool,
    table: Immutable.List([
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
     Immutable.Map({ Z: -3, R: .0625, holder: LONG }),
    ])
  })
);

export default reducer;
