import { createActions } from 'redux-actions';

const actions = createActions({
  MACHINE_STATE: {
    FEED_RATE_MODE: {
      SET_INVERSE_TIME: undefined,
      SET_UNITS_PER_MINUTE: undefined,
      SET_UNITS_PER_REVOLUTION: undefined
    }
  }
});

export default actions;
