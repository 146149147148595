const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

let instance;

export default () => {
  return new Promise((resolve, reject) => {
    if(instance) {
      resolve(instance);
      return;
    }

    if(window.Stripe) {
      instance = window.Stripe(STRIPE_PUBLISHABLE_KEY);
      resolve(instance);
      return;
    } else {
      document.querySelector("#stripe-js").addEventListener('load', ()  => {
        instance = window.Stripe(STRIPE_PUBLISHABLE_KEY);
        resolve(instance);
      });
    }
  });
};
