import { INVERSE_TIME, UNITS_PER_MINUTE, UNITS_PER_REVOLUTION } 
  from '../../constants/machine-state/feed-rate-mode';
import actions from '../../actions/machine-state/feed-rate-mode';
import { handleActions } from 'redux-actions';

const reducer = handleActions(
  {
    [actions.machineState.feedRateMode.setInverseTime]: () => INVERSE_TIME,
    [actions.machineState.feedRateMode.setUnitsPerMinute]: () => UNITS_PER_MINUTE,
    [actions.machineState.feedRateMode.setUnitsPerRevolution]: () => UNITS_PER_REVOLUTION
  },
  UNITS_PER_MINUTE
);

export default reducer;
