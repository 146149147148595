import THREE from './three';
import ViewCubeShader from './view-cube-shader';

export default class ViewCube {
  constructor(canvas, left, top, width, height) {
    this.callbacks = {
      click: [],
      mouseover: []
    };
    this.left = left;
    this.top = top;
    this.width = width;
    this.height = height;
    this.opacity = .25;

    this.scene = new THREE.Scene();
    const invAspect = this.height/this.width;
    this.camera = new THREE.OrthographicCamera(-2.5, 2.5, 2.5*invAspect, -2.5*invAspect, -2.5, 2.5);

    this.scene.add(this.camera);

    this.raycaster = new THREE.Raycaster();
    this.material = ViewCubeShader();

    const objLoader = new THREE.OBJLoader();
    const textureLoader = new THREE.TextureLoader();

    objLoader.load(
      'models/rhombicuboctahedron.obj',
      (object) => {
        this.object = object;
        object.traverse((child) => {
          if(child instanceof THREE.Mesh) {
            child.material = this.material;
          }
        });
        this.scene.add(object);
        this.loadedModel = true;
        this.loaded = this.loadedModel && this.loadedTexture;
      },
      (xhr) => {
        // loading progress
      },
      (error) => {
        console.error(error);
      }
    );

    this.material.uniforms.texture.value = 
    textureLoader.load(
      'textures/viewCube.png',
      (texture) => {
        this.material.uniforms.texture.value = texture;
        this.loadedTexture = true;
        this.loaded = this.loadedModel && this.loadedTexture;
      },
      undefined,
      (error) => {
        console.error(error);
      }
    );

    canvas.addEventListener("click", (e) => {
      const viewCubeX = e.offsetX-(this.left);
      const viewCubeY = e.offsetY;
      const x = (viewCubeX / this.width)*2-1;
      const y = -(viewCubeY / this.height)*2+1;

      if(this.loaded) {
        if(x > -1 && x < 1 && y > -1 && y < 1) {
          this.raycaster.set(new THREE.Vector3(2.5*x, 2.5*y, 10).applyQuaternion(this.camera.quaternion), new THREE.Vector3(0, 0, -1).applyQuaternion(this.camera.quaternion));
          const intersects = this.raycaster.intersectObject(this.object, true);
          if(intersects.length > 0) {
            const hit = intersects[0];

            this.doCallback('click', hit.face.normal);
          }
        }
      }
    });
    canvas.addEventListener("touchend", (e) => {
      const rect = e.target.getBoundingClientRect();
      const viewCubeX = (e.changedTouches[0].pageX-rect.left)-(this.left);
      const viewCubeY = e.changedTouches[0].pageY-rect.top;
      const x = (viewCubeX / this.width)*2-1;
      const y = -(viewCubeY / this.height)*2+1;

      if(this.loaded) {
        if(x > -1 && x < 1 && y > -1 && y < 1) {
          this.raycaster.set(new THREE.Vector3(2.5*x, 2.5*y, 10).applyQuaternion(this.camera.quaternion), new THREE.Vector3(0, 0, -1).applyQuaternion(this.camera.quaternion));
          const intersects = this.raycaster.intersectObject(this.object, true);
          if(intersects.length > 0) {
            const hit = intersects[0];

            this.doCallback('click', hit.face.normal);
          }
        }
      }
    });
    canvas.addEventListener("mousemove", (e) => {
      const viewCubeX = e.offsetX-(this.left);
      const viewCubeY = e.offsetY;
      const x = (viewCubeX / this.width)*2-1;
      const y = -(viewCubeY / this.height)*2+1;

      if(this.loaded) {
        if(x > -1 && x < 1 && y > -1 && y < 1) {
          this.raycaster.set(new THREE.Vector3(2.5*x, 2.5*y, 10).applyQuaternion(this.camera.quaternion), new THREE.Vector3(0, 0, -1).applyQuaternion(this.camera.quaternion));
          const intersects = this.raycaster.intersectObject(this.object, true);
          if(intersects.length > 0) {
            const hit = intersects[0];
            this.doCallback('mouseover', hit.face.normal);
            this.material.uniforms.hitNormal.value.copy(hit.face.normal);
            this.opacity = 1;
          } else {
            this.material.uniforms.hitNormal.value.set(0,0,0);
            this.opacity = .25;
          }
        } else {
          this.material.uniforms.hitNormal.value.set(0,0,0);
          this.opacity = .25;
        }
      }
    });
  }

  doCallback(type, ...args) {
    this.callbacks[type].forEach((callback) => callback(...args));
  }

  addEventListener(type, callback) {
    this.callbacks[type].push(callback);
  }

  removeEventListener(type, callback) {
    this.callbacks[type].splice(this.callbacks[type].indexOf(callback), 1);
  }

  setOrientation(quat) {
    this.camera.quaternion.copy(quat);
  }

  setPosition(left, top) {
    this.left = left;
    this.top = top;
  }

  setDimensions(width, height) {
    this.width = width;
    this.height = height;
  }

  step(dt) {
    this.material.uniforms.opacity.value = this.material.uniforms.opacity.value*.9+this.opacity*.1;
    if(this.material.uniforms.opacity.value < 1) {
      this.material.transparent = true;
    } else {
      this.material.transparent = false;
    }
  }

  render(renderer) {
    // TODO - can we do this without messing with the renderer's state (or at least restore it to what it was before we messed with it)?
    if(this.loaded) {
      renderer.setViewport(this.left, this.top, this.width, this.height);
      renderer.setScissor(this.left, this.top, this.width, this.height);
      renderer.setScissorTest(true);
      renderer.clearDepth();

      renderer.render(this.scene, this.camera);
    }
  }
};
