import { createActions } from 'redux-actions';

const actions = createActions({
  MACHINE_STATE: {
    DISTANCE_MODE: {
      SET_ABSOLUTE: undefined,
      SET_INCREMENTAL: undefined,
      SET_ABSOLUTE_ARC: undefined,
      SET_INCREMENTAL_ARC: undefined
    }
  }
});

export default actions;
