import actions from '../../actions/ui';
import { handleActions } from 'redux-actions';
import Immutable from 'immutable';

const reducer = handleActions(
  {
    [actions.ui.controlsOverlay.setVisible]: (state, action) => state.set("visible", action.payload)
  },
  Immutable.Map({
    visible: false
  })
);

export default reducer;
