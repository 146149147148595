export default class IFrameMessenger {
  static getInstance() {
    if(!IFrameMessenger.instance) {
      IFrameMessenger.instance = new IFrameMessenger();
    }

    return IFrameMessenger.instance;
  }

  constructor() {
    this.windows = [];
  }

  addWindow(w, origin) {
    console.log("added window", origin);
    this.windows.push( { window: w, origin: origin });
    w.postMessage({ type: "IFRAME/INITIALIZE" }, origin);
  }

  sendMessage(msg) {
    this.windows.forEach(({ window, origin }) => {
      console.log("sending message to window with origin", origin);
      window.postMessage(msg, origin);
    });
  }
};
