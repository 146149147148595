import { createSelector } from 'reselect';
import * as types from '../constants/messages';

export const messages = (state) => state.get("interpreter").get("messages")
export const annotations = createSelector( [ messages ], (messages) => {
  return messages.filter((msg) => typeof msg.line !== 'undefined').map((msg) => ({
    row: msg.line,
    column: 0,
    text: msg.message,
    type: msg.severity
  }));
});

export const maxLimitMessage = createSelector( [ messages ], (messages) => {
  const maxLimitMessages = messages.filter((msg) => msg.type === types.MAXIMUM_LIMIT);
  const axes = {};
  maxLimitMessages.forEach((msg) => {
    if(!axes[msg.axis]) {
      axes[msg.axis] = [];
    }

    axes[msg.axis].push(msg)
  });

  const maxLimit = {};

  for(let axis in axes) {
    maxLimit[axis] = axes[axis].reduce((a,b) => b.amount > a.amount ? b : a, { amount: 0 })
  }

  return maxLimit;
});

export const minLimitMessage = createSelector( [ messages ], (messages) => {
  const minLimitMessages = messages.filter((msg) => msg.type === types.MINIMUM_LIMIT);
  const axes = {};
  minLimitMessages.forEach((msg) => {
    if(!axes[msg.axis]) {
      axes[msg.axis] = [];
    }

    axes[msg.axis].push(msg)
  });

  const minLimit = {};

  for(let axis in axes) {
    minLimit[axis] = axes[axis].reduce((a,b) => b.amount > a.amount ? b : a, { amount: 0 })
  }

  return minLimit;
});

export const unimplementedMessages = createSelector( [ messages ], (messages) => {
  return messages.filter((msg) => msg.type === types.UNIMPLEMENTED);
});

export const nonSummarizedErrors = createSelector( [ messages ], (messages) => {
  return messages.filter((msg) => msg.severity === types.ERROR && [ types.MAXIMUM_LIMIT, types.MINIMUM_LIMIT ].indexOf(msg.type) === -1);
});
export const nonSummarizedWarnings = createSelector( [ messages ], (messages) => {
  return messages.filter((msg) => msg.severity === types.WARNING && [ types.UNIMPLEMENTED ].indexOf(msg.type) === -1);
});

export const setToolLengthOffsetMessages = createSelector([ messages ], (messages) => {
  return messages.filter((msg) => msg.type === types.SET_TOOL_LENGTH_OFFSET);
});
