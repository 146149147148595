import { V2 } from '../../constants/machine-state/machine';
import actions from '../../actions/machine-state/machine';
import { handleActions } from 'redux-actions';

const reducer = handleActions(
  {
    [actions.machineState.machine.set]: (state, action) => action.payload,
  },
  V2
);

export default reducer;
