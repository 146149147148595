import { createActions } from 'redux-actions';

const actions = createActions({
  UI: {
    GCODE_PANE: {
      TOGGLE_PANE: undefined,
      SET_WIDTH: undefined,
      SET_SELECTED: undefined,
      SET_LINE_OFFSET: undefined,
      SET_SHOW_LINES: undefined,
      SCROLL: undefined,
      SET_TAB: undefined
    }
  }
});

export default actions;
