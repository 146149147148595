import { showThreads } from './legacy';
import { fetchExponentialBackoff } from './exponential-backoff';
export const RFID_REST_URL = process.env.REACT_APP_REST_SERVICE_URL;
if(!RFID_REST_URL) {
  console.error("No REST service URL specified. Be sure to set REST_SERVICE_URL environment variable when building or developing.");
}

const encodeAlignerCode = (code) => encodeURIComponent(encodeURIComponent(code));

export const purchaseCredits = ({ accessToken, apiKey, credits }) => {
  const restUrl = RFID_REST_URL 
                         + "/purchaseCredits?credits=" + credits;
  return fetchExponentialBackoff(restUrl, {
    method: "POST",
    headers: {
      'Authorization': accessToken,
      'x-api-key': apiKey
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const getPoints = ({ accessToken, apiKey, alignerID }) => {
  const restUrl = RFID_REST_URL 
                         + "/getPoints?alignerID=" + alignerID;
  return fetchExponentialBackoff(restUrl, {
    method: "GET",
    headers: {
      'Authorization': accessToken,
      'x-api-key': apiKey
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const getGCodeZipUrl = ({ accessToken, apiKey, code }) => {
  const restUrl = RFID_REST_URL 
                         + "/getGCodeZipUrl?code=" + encodeAlignerCode(code);
  return fetchExponentialBackoff(restUrl, {
    method: "GET",
    headers: {
      'Authorization': accessToken,
      'x-api-key': apiKey
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const copyPointsToAllAlignersInSet = ({ accessToken, apiKey, alignerID }) => {
  const restUrl = RFID_REST_URL 
                         + "/copyPointsToAllAlignersInSet?alignerID=" + alignerID;
  return fetchExponentialBackoff(restUrl, {
    method: "POST",
    headers: {
      'Authorization': accessToken,
      'x-api-key': apiKey
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const getProcessedModelsZipUrl = ({ accessToken, apiKey, code }) => {
  const restUrl = RFID_REST_URL 
                         + "/getProcessedModelsZipUrl?code=" + encodeAlignerCode(code);
  return fetchExponentialBackoff(restUrl, {
    method: "GET",
    headers: {
      'Authorization': accessToken,
      'x-api-key': apiKey
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const multipleProcessedModelsExist = ({ accessToken, apiKey, aligners }) => {
  const restUrl = RFID_REST_URL 
                         + "/multipleProcessedModelsExist";
  return fetch(restUrl, {
    method: "POST",
    headers: {
      'Authorization': accessToken,
      'x-api-key': apiKey
    },
    body: JSON.stringify({ aligners })
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const createMultipleAligners = ({ accessToken, xform, checksum, mapping, apiKey, snapFit, addBridge, addCylinders, points }) => {
  const restUrl = RFID_REST_URL 
                         + "/createMultipleAligners";
  const subThreads = showThreads;
  const subSnaps = !showThreads;
  return fetchExponentialBackoff(restUrl, {
    method: "POST",
    headers: {
      'Authorization': accessToken,
      'x-api-key': apiKey
    },
    body: JSON.stringify({ xform, checksum, mapping, addBridge, addCylinders, subThreads, subSnaps, points, snapFit })
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const logFrontEndError = (apiKey, error) => {
  const restUrl = RFID_REST_URL 
                         + "/logFrontEndError";
  const version = process.env.REACT_APP_VERSION;
  const commit = process.env.REACT_APP_COMMIT;
  return fetchExponentialBackoff(restUrl, {
    method: "PUT",
    headers: {
      'x-api-key': apiKey
    },
    body: JSON.stringify({ error, version, commit })
  }).then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const checkForPointsOnOtherAligners = ({ accessToken, apiKey, alignerID }) => {
  const restUrl = RFID_REST_URL 
                         + "/checkForPointsOnOtherAligners?alignerID=" + alignerID;
  return fetchExponentialBackoff(restUrl, {
    method: "GET",
    headers: {
      'Authorization': accessToken,
      'x-api-key': apiKey
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const getAlignerWithCodeOrder = ({ accessToken, code, order, apiKey }) => {
  const restUrl = RFID_REST_URL 
                         + "/getAlignerWithCodeOrder?code=" + encodeAlignerCode(code) + "&order=" + encodeAlignerCode(order);
  return fetchExponentialBackoff(restUrl, {
    method: "GET",
    headers: {
      'Authorization': accessToken,
      'x-api-key': apiKey
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const getAlignersWithCode = ({ accessToken, code, apiKey }) => {
  const restUrl = RFID_REST_URL 
                         + "/getAlignersWithCode?code=" + encodeAlignerCode(code);
  return fetchExponentialBackoff(restUrl, {
    method: "GET",
    headers: {
      'Authorization': accessToken,
      'x-api-key': apiKey
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const createAligner = ({ accessToken, code, order, apiKey }) => {
  const restUrl = RFID_REST_URL 
                         + "/createAligner?code=" + encodeAlignerCode(code) + "&order=" + encodeAlignerCode(order);
  return fetchExponentialBackoff(restUrl, {
    method: "POST",
    headers: {
      'Authorization': accessToken,
      'x-api-key': apiKey
    }
  }, 4).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const getUserInfo = (accessToken) => {
  const restUrl = RFID_REST_URL 
                         + "/getUserInfo";
  return fetchExponentialBackoff(restUrl, {
    headers: {
      'Authorization': accessToken
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

// Fetches the data necessary to upload a model to S3 using a presigned
// POST request. Returns a promise that returns an object with one of
// two keys, data or error. data will contain a url and a map of fields
// that must be included in a FormData object. error will contain an
// error if something went wrong.
export const getUploadModelPost = ({ alignerID, size, apiKey, accessToken }) => {
  const restUrl = RFID_REST_URL 
                         + "/getUploadModelPost?size="
                         + size + "&alignerID=" + alignerID;
  return fetchExponentialBackoff(restUrl, {
    headers: {
      'x-api-key': apiKey,
      Authorization: accessToken
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const getDownloadProcessedModelUrl = ({ alignerID, apiKey, accessToken }) => {
  const restUrl = RFID_REST_URL 
                         + "/getDownloadProcessedModelUrl?alignerID=" + alignerID;
  
  return fetchExponentialBackoff(restUrl, {
    method: "GET",
    headers: {
      'x-api-key': apiKey,
      Authorization: accessToken
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const getGCode = ({ alignerID, apiKey, label, accessToken }) => {
  const restUrl = RFID_REST_URL 
                         + "/getGCode?label=" + label + "&alignerID=" + alignerID;
  
  return fetchExponentialBackoff(restUrl, {
    method: "GET",
    headers: {
      'x-api-key': apiKey,
      Authorization: accessToken
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const processedModelExists = ({ alignerID, apiKey, accessToken }) => {
  const restUrl = RFID_REST_URL 
                         + "/processedModelExists?alignerID=" + alignerID;
  
  return fetch(restUrl, {
    method: "GET",
    headers: {
      'x-api-key': apiKey,
      Authorization: accessToken
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const getAlignerStatus = ({ alignerID, apiKey, accessToken }) => {
  const restUrl = RFID_REST_URL 
                         + "/getStatus?alignerID=" + alignerID;
  
  return fetchExponentialBackoff(restUrl, {
    method: "GET",
    headers: {
      'x-api-key': apiKey,
      Authorization: accessToken
    }
  }).then((res) => res.json())
    .then((data) => {
      return ({ data });
    })
    .catch((error) => ({ error }));
};

export const isAligner = ({ alignerID, apiKey, accessToken }) => {
  const restUrl = RFID_REST_URL 
                         + "/isAligner?alignerID=" + alignerID;
  
  return fetchExponentialBackoff(restUrl, {
    method: "GET",
    headers: {
      'x-api-key': apiKey,
      Authorization: accessToken
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const finalize = ({ alignerID, apiKey, snapFit, addBridge, addCylinders, accessToken }) => {
  const subThreads = showThreads;
  const subSnaps = !showThreads;
  const restUrl = RFID_REST_URL 
                         + "/finalize?alignerID=" + alignerID 
                         + (subThreads ? "&subThreads=1" : "")
                         + (subSnaps ? "&subSnaps=1&snapFit=" + snapFit : "")
                         + (addBridge ? "&addBridge=1" : "")
                         + (addCylinders ? "&addCylinders=1" : "");
  
  return fetchExponentialBackoff(restUrl, {
    method: "PUT",
    headers: {
      'x-api-key': apiKey,
      Authorization: accessToken
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const savePoints = ({ alignerID, apiKey, points, accessToken }) => {
  const restUrl = RFID_REST_URL 
                         + "/savePoints?alignerID=" + alignerID;
  
  return fetchExponentialBackoff(restUrl, {
    method: "PUT",
    headers: {
      'x-api-key': apiKey,
      Authorization: accessToken
    },
    body: JSON.stringify(points)
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const importTransformAndPoints = ({ alignerID, apiKey, alignerIDImport, accessToken }) => {
  const restUrl = RFID_REST_URL 
                         + "/importTransformAndPoints?alignerID=" + alignerID + "&alignerIDImport=" + alignerIDImport;
  
  return fetchExponentialBackoff(restUrl, {
    method: "PUT",
    headers: {
      'x-api-key': apiKey,
      Authorization: accessToken
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const getTransformAndPoints = ({ alignerID, apiKey, accessToken }) => {
  const restUrl = RFID_REST_URL 
                         + "/getTransformAndPoints?alignerID=" + alignerID;
  
  return fetchExponentialBackoff(restUrl, {
    method: "GET",
    headers: {
      'x-api-key': apiKey,
      Authorization: accessToken
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const saveTransform = ({ alignerID, apiKey, xform, accessToken }) => {
  const restUrl = RFID_REST_URL 
                         + "/saveTransform?alignerID=" + alignerID;
  
  return fetchExponentialBackoff(restUrl, {
    method: "PUT",
    headers: {
      'x-api-key': apiKey,
      Authorization: accessToken
    },
    body: JSON.stringify(xform)
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};

export const createLabel = ({ alignerID, apiKey, label, accessToken }) => {
  const restUrl = RFID_REST_URL 
                         + "/createLabel?alignerID=" + alignerID + "&label=" + label;
  
  return fetchExponentialBackoff(restUrl, {
    method: "PUT",
    headers: {
      'x-api-key': apiKey,
      Authorization: accessToken
    }
  }).then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }));
};
