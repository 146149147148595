import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  div: {
    position: "fixed",
    zIndex: 1300,
    left: 10,
    top: 84

  }
};

export default withStyles(styles)(function AlignerPanel(props) {
  const {
    classes,
    children
  } = props;

  return (<div className={classes.div}>
  {children}
  </div>);
});
