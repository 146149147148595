import { createActions } from 'redux-actions';

const actions = createActions({
  MACHINE_STATE: {
    FEED_RATE: {
      SET: (arg) => {
        return arg;
      }
    }
  }
});

export default actions;
