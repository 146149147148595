export const ERROR = "error";
export const WARNING = "warning";
export const INFO = "info";
export const UNIMPLEMENTED = "UNIMPLEMENTED";
export const UNRECOGNIZED = "UNRECOGNIZED";
export const MINIMUM_LIMIT = "MINIMUM_LIMIT";
export const MAXIMUM_LIMIT = "MAXIMUM_LIMIT";
export const MULTIPLE_COMMANDS_IN_MODAL_GROUP = "MULTIPLE_COMMANDS_IN_MODAL_GROUP";
export const MULTIPLE_COMMANDS_WITH_POSITIONAL_ARGS = "MULTIPLE_COMMANDS_WITH_POSITIONAL_ARGS";
export const ZERO_FEED_RATE = "ZERO_FEED_RATE";
export const SET_TOOL_LENGTH_OFFSET = "SET_TOOL_LENGTH_OFFSET";
export const TOOL_LENGTH_OFFSET_COMMAND_DIFFERS_FROM_LOADED_TOOL = "TOOL_LENGTH_OFFSET_COMMAND_DIFFERS_FROM_LOADED_TOOL";
