import { connect } from 'react-redux';

import THREE from '../viewer3d/three';
import actions from '../actions';
import Viewer3D from '../components/Viewer3D';
import { LONG } from '../constants/machine-state/tool';
import { V2 } from '../constants/machine-state/machine';
import { TOOL } from '../constants/viewer3d/camera-parent';
import machines from '../machines';
import { computeRotationToOrientDown } from '../viewer3d/math-helpers';
import { POSITION_MODEL, EDIT_PATH, PREVIEW_PATH } from '../constants/aligner-state';
import { DRAW, SINGLE_POINT } from '../constants/aligner-state';
import { showThreads } from '../util/legacy';

const RAYCAST_THRESHOLD = .087; // if angle greater than ~85 degrees, i.e. we're almost parallel with the XZ plane

export default connect(
  (state, ownProps) => {
    const toolOffsetZ = state.get("alignerState").get("toolOffsetZ");
    const toolLength = 6.26+toolOffsetZ;
    const toolDiameter = 0.03937;
    const toolHolder = LONG;
    const spindleAngle = 0;
    const joints = state.get("alignerState").get("joints").toJS();
    const limits = machines[V2].limits.extents;
    const axes = "XYZAB";

    const machine = state.get("alignerState").get("machine");

    let respondToCameraGestures = true;
    const step = state.get("alignerState").get("step");
    const editPathMode = step === EDIT_PATH;
    let rotating = state.get("viewer3D").get("rotating");

    let radius = state.get("viewer3D").get("radius");
    let orthographicZoom = state.get("viewer3D").get("orthographicZoom");
    let phi = state.get("viewer3D").get("phi");
    let theta = state.get("viewer3D").get("theta");
    let showViewCube = true;
    let cameraParent = state.get("viewer3D").get("cameraParent");

    if(editPathMode) {
      respondToCameraGestures = false;
      phi = Math.PI/2;
      theta = 0;
      radius = .001;
      showViewCube = false;
      cameraParent = TOOL;
    }

    const xform = state.get("alignerState").get("xform");

    const modelRX = xform.get("rx")/180*Math.PI;
    const modelRY = xform.get("ry")/180*Math.PI;
    const modelRZ = xform.get("rz")/180*Math.PI;
    const modelTX = xform.get("tx")/25.4;
    const modelTY = xform.get("ty")/25.4;
    const modelTZ = xform.get("tz")/25.4;

    return {
      step,
      machine,
      toolLength,
      toolDiameter,
      toolHolder,
      spindleAngle,
      toolOffsetZ,
      joints,
      limits,
      axes,
      drawMode: state.get("alignerState").get("drawMode"),
      hideTitleBar: !state.get("ui").get("titleBar").get("visible"),
      cameraParent,
      respondToCameraGestures,
      respondToModelGestures: true,
      showModel: state.get("alignerState").get("modelLoaded"),
      cameraMode: state.get("viewer3D").get("cameraMode"),
      radius,
      phi,
      theta,
      rotating,
      orthographicZoom,
      showViewCube,
      smoothJointTransitions: true,
      smoothModelTransition: true,
      showGhostSphere: state.get("alignerState").get("showGhostSphere"),
      ghostSpherePosition: state.get("alignerState").get("ghostSpherePosition").toJS(),
      ghostSphereOpacity: state.get("alignerState").get("ghostSphereOpacity"),
      points: state.get("alignerState").get("points").toJS(),
      closePath: state.get("alignerState").get("closePath"),
      activePoint: state.get("alignerState").get("activePoint"),
      highlightedPoint: state.get("alignerState").get("highlightedPoint"),
      machineRotating: state.get("alignerState").get("machineRotating"),
      hideTool: state.get("alignerState").get("step") === EDIT_PATH,
      highlightModel: state.get("alignerState").get("highlightModel"),
      drawPath: state.get("alignerState").get("drawPath"),
      showDentalCylinders: state.get("alignerState").get("addCylinders"),
      modelRX,
      modelRY,
      modelRZ,
      modelTX,
      modelTY,
      modelTZ
    };
  },
  (dispatch, ownProps) => ({
    onUpdateDimensions: (width, height) => {
      dispatch(actions.viewer3D.setDimensions(width, height));
      dispatch(actions.alignerState.setDimensions(width,height));
    },
    onEscape: (viewer) => {
      if(viewer.props.drawMode === DRAW) {
        dispatch(actions.alignerState.cancelDrawPath());
      }
    },
    onCameraZoom: (deltaY) => dispatch(actions.viewer3D.cameraZoom(deltaY)),
    onCameraStartRotating: (x,y) => dispatch(actions.viewer3D.cameraStartRotating(x,y)),
    onCameraStopRotating: (x,y) => dispatch(actions.viewer3D.cameraStopRotating(x,y)),
    onCameraRotate: (x,y) => dispatch(actions.viewer3D.cameraRotate(x,y)),
    onCameraSetDirection: (x,y,z) => dispatch(actions.viewer3D.cameraSetDirection(x,y,z)),
    onModelComputePlaneWithLargestArea: (plane, viewer) => {
      const quat = computeRotationToOrientDown(plane.normal);

      const euler = new THREE.Euler();
      euler.setFromQuaternion(quat);

//      const rotationBackup = viewer.parts.model.object.rotation.clone();

      viewer.parts.model.object.rotation.x = euler.x;
      viewer.parts.model.object.rotation.y = euler.y;
      viewer.parts.model.object.rotation.z = euler.z;
      viewer.parts.model.object.updateMatrix();

      const boundingBox = new THREE.Box3();
      boundingBox.expandByObject(viewer.parts.model.object);

      const dentalFixtureY = viewer.parts.dentalFixture.boundingBox.max.y;
      const modelBottomY = boundingBox.min.y;
      const snapSocketDepth = 0.25; //inches

      dispatch(actions.alignerState.setModelOrientation(euler.x*180/Math.PI, euler.y*180/Math.PI, euler.z*180/Math.PI));
      dispatch(actions.alignerState.setModelPosition(0, 25.4*(dentalFixtureY-modelBottomY-( showThreads ? 0 : snapSocketDepth)), 0));
      dispatch(actions.alignerState.saveTransform());
    },
    onEditPathModelHover: (hit) => {
      dispatch(actions.alignerState.setGhostSpherePosition(hit.point.x,hit.point.y,hit.point.z));
    },
    onEditPathModelEnter: (hit) => {
      dispatch(actions.alignerState.showGhostSphere());
    },
    onEditPathModelExit: (hit) => {
      dispatch(actions.alignerState.hideGhostSphere());
    },
    onEditPathModelPointerDown: (hit, e, viewer) => {
      dispatch(actions.alignerState.setGhostSphereOpacity(1));

      if(viewer.props.drawMode === DRAW) {
        if(hit) {
          const inverseModelWorld = new THREE.Matrix4(); // initialize to identity, will get the inverse matrix on next line
          inverseModelWorld.getInverse(hit.object.matrixWorld); // now the inverseModelWorld matrix

          const pointInModelSpace = new THREE.Vector3(); // initialize to zero vector, will copy hit point into it in world space, then convert to model space
          pointInModelSpace.copy(hit.point); // hit point in world space now, will convert to model space on next line
          pointInModelSpace.applyMatrix4(inverseModelWorld); // now in model space

          const joints = viewer.props.joints;
          const modelRX = viewer.props.modelRX;
          const modelRY = viewer.props.modelRY;
          const modelRZ = viewer.props.modelRZ;
          const model2DentalEuler = new THREE.Euler(modelRX, modelRY, modelRZ);
          const model2Dental = new THREE.Matrix4();
          model2Dental.makeRotationFromEuler(model2DentalEuler);

          const dental2Model = new THREE.Matrix4();
          dental2Model.getInverse(model2Dental);

          const dental2World = new THREE.Matrix4();
          const dental2WorldEuler = new THREE.Euler(joints[3]*Math.PI/180, joints[4]*Math.PI/180, 0);
          dental2World.makeRotationFromEuler(dental2WorldEuler);

          const world2Dental = new THREE.Matrix4();
          world2Dental.getInverse(dental2World);

          const dir = new THREE.Vector3(0,0,1); // in world space
          dir.applyMatrix4(world2Dental); // now in dental space
          dir.applyMatrix4(dental2Model); // now in model space

          dispatch(actions.alignerState.initializeDrawPath([pointInModelSpace.x, pointInModelSpace.y, pointInModelSpace.z, dir.x, dir.y, dir.z ]));
        }
      }
    },
    onEditPathModelPointerUp: (hit, e, viewer) => {
      dispatch(actions.alignerState.setGhostSphereOpacity(.5));

      if(viewer.props.drawMode === DRAW) {
        dispatch(actions.alignerState.simplifyDrawPath());
        dispatch(actions.alignerState.savePoints());
      } else if(viewer.props.drawMode === SINGLE_POINT) {
        const joints = viewer.props.joints;
        const modelRX = viewer.props.modelRX;
        const modelRY = viewer.props.modelRY;
        const modelRZ = viewer.props.modelRZ;
        const model2DentalEuler = new THREE.Euler(modelRX, modelRY, modelRZ);
        const model2Dental = new THREE.Matrix4();
        model2Dental.makeRotationFromEuler(model2DentalEuler);

        const dental2Model = new THREE.Matrix4();
        dental2Model.getInverse(model2Dental);

        const dental2World = new THREE.Matrix4();
        const dental2WorldEuler = new THREE.Euler(joints[3]*Math.PI/180, joints[4]*Math.PI/180, 0);
        dental2World.makeRotationFromEuler(dental2WorldEuler);

        const world2Dental = new THREE.Matrix4();
        world2Dental.getInverse(dental2World);
        if(hit) {
          // model is assumed to be in mm
          const inverseModelWorld = new THREE.Matrix4(); // initialize to identity, will get the inverse matrix on next line
          inverseModelWorld.getInverse(hit.object.matrixWorld); // now the inverseModelWorld matrix

          const pointInModelSpace = new THREE.Vector3(); // initialize to zero vector, will copy hit point into it in world space, then convert to model space
          pointInModelSpace.copy(hit.point); // hit point in world space now, will convert to model space on next line
          pointInModelSpace.applyMatrix4(inverseModelWorld); // now in model space

          const dir = new THREE.Vector3(0,0,1); // in world space
          dir.applyMatrix4(world2Dental); // now in dental space
          dir.applyMatrix4(dental2Model); // now in model space

          dispatch(actions.alignerState.addPoint(pointInModelSpace.x, pointInModelSpace.y, pointInModelSpace.z, dir.x, dir.y, dir.z));
          dispatch(actions.alignerState.savePoints());
        }
      }
    },
    onEditPathModelDrag: (hit, e, viewer) => {
      if(hit) {
        dispatch(actions.alignerState.setGhostSpherePosition(hit.point.x,hit.point.y,hit.point.z));
      }
      if(viewer.props.drawMode === "DRAW") {
        if(hit) {
          const inverseModelWorld = new THREE.Matrix4(); // initialize to identity, will get the inverse matrix on next line
          inverseModelWorld.getInverse(hit.object.matrixWorld); // now the inverseModelWorld matrix

          const pointInModelSpace = new THREE.Vector3(); // initialize to zero vector, will copy hit point into it in world space, then convert to model space
          pointInModelSpace.copy(hit.point); // hit point in world space now, will convert to model space on next line
          pointInModelSpace.applyMatrix4(inverseModelWorld); // now in model space

          const joints = viewer.props.joints;
          const modelRX = viewer.props.modelRX;
          const modelRY = viewer.props.modelRY;
          const modelRZ = viewer.props.modelRZ;
          const model2DentalEuler = new THREE.Euler(modelRX, modelRY, modelRZ);
          const model2Dental = new THREE.Matrix4();
          model2Dental.makeRotationFromEuler(model2DentalEuler);

          const dental2Model = new THREE.Matrix4();
          dental2Model.getInverse(model2Dental);

          const dental2World = new THREE.Matrix4();
          const dental2WorldEuler = new THREE.Euler(joints[3]*Math.PI/180, joints[4]*Math.PI/180, 0);
          dental2World.makeRotationFromEuler(dental2WorldEuler);

          const world2Dental = new THREE.Matrix4();
          world2Dental.getInverse(dental2World);

          const dir = new THREE.Vector3(0,0,1); // in world space
          dir.applyMatrix4(world2Dental); // now in dental space
          dir.applyMatrix4(dental2Model); // now in model space

          dispatch(actions.alignerState.addPointToDrawPath([ pointInModelSpace.x, pointInModelSpace.y, pointInModelSpace.z, dir.x, dir.y, dir.z]));
        }
      }
    },
    onEditPathSphereEnter: (hit) => {
      dispatch(actions.alignerState.setHighlightedPoint(hit.object.userData.index));
    },
    onEditPathSphereExit: (hit) => {
      dispatch(actions.alignerState.setHighlightedPoint(-1));
    },

    onEditPathSpherePointerDown: (hit) => {
      dispatch(actions.alignerState.setActivePoint(hit.object.userData.index));
    },
    onEditPathSpherePointerUp: (hit) => {
      dispatch(actions.alignerState.savePoints());
    },
    onEditPathSphereDrag: (hit) => {
      if(hit) {
        // model is assumed to be in mm
        const inverseModelWorld = new THREE.Matrix4(); // initialize to identity, will get the inverse matrix on next line
        inverseModelWorld.getInverse(hit.model.matrixWorld); // now the inverseModelWorld matrix

        const pointInModelSpace = new THREE.Vector3(); // initialize to zero vector, will copy hit point into it in world space, then convert to model space
        pointInModelSpace.copy(hit.point); // hit point in world space now, will convert to model space on next line
        pointInModelSpace.applyMatrix4(inverseModelWorld); // now in model space

        dispatch(actions.alignerState.updatePointPosition(pointInModelSpace.x, pointInModelSpace.y, pointInModelSpace.z));
      }
    },

    onEditPathMouseDown: ({x,y}) => {
      dispatch(actions.alignerState.startRotatingMachine(x, y));
    },
    onEditPathMouseMove: ({x,y}) => {
      dispatch(actions.alignerState.rotateMachine(x, y));
    },
    onEditPathMouseUp: () => {
      dispatch(actions.alignerState.stopRotatingMachine());
      dispatch(actions.alignerState.savePoints());
    },

    onEditPathDeleteActiveSphere: () => {
      dispatch(actions.alignerState.deletePoint());
      dispatch(actions.alignerState.savePoints());
    },

    onPositionModelModelPointerDown: (hit, {x,y}, viewer) => {
      const ray = viewer.getCameraRay(x, y);
      const viewDir = viewer.getViewDirection();
      const up = THREE.Object3D.DefaultUp;

      let plane;
      if(Math.abs(viewDir.dot(up)) > RAYCAST_THRESHOLD) { // if angle greater than ~85 degrees, i.e. we're almost parallel with the XZ plane
        plane = new THREE.Plane(up, 0);
      } else {
        plane = new THREE.Plane(viewDir, 0);
      }
      const planePoint = new THREE.Vector3();
      if(ray.intersectPlane(plane, planePoint)) {
        dispatch(actions.alignerState.setDragModelStartPoint(25.4*planePoint.x,25.4*planePoint.z));
      }
    },

    onPositionModelModelPointerUp: (hit, {x,y}, viewer) => {
      dispatch(actions.alignerState.saveTransform());
    },

    onPositionModelModelEnter: (hit, {x,y}, viewer) => {
      dispatch(actions.alignerState.setHighlightModel(true));
    },

    onPositionModelModelExit: (hit, {x,y}) => {
      dispatch(actions.alignerState.setHighlightModel(false));
    },
    onPositionModelModelDrag: (hit, {x,y}, viewer) => {
      const ray = viewer.getCameraRay(x, y);
      const viewDir = viewer.getViewDirection();
      const up = THREE.Object3D.DefaultUp;

      let plane;
      if(Math.abs(viewDir.dot(up)) > RAYCAST_THRESHOLD) { // if angle greater than ~85 degrees, i.e. we're almost parallel with the XZ plane
        plane = new THREE.Plane(up, 0);
      } else {
        plane = new THREE.Plane(viewDir, 0);
      }
      const planePoint = new THREE.Vector3();
      if(ray.intersectPlane(plane, planePoint)) {
        dispatch(actions.alignerState.dragModel(25.4*planePoint.x,25.4*planePoint.z));
      }
    }


  }),
  (stateProps, dispatchProps, ownProps) => {
    const {
      step,
      machineRotating
    } = stateProps;

    const {
      onUpdateDimensions,
      onCameraZoom,
      onCameraStartRotating,
      onCameraStopRotating,
      onCameraRotate,
      onCameraSetDirection,

      // position model interactions
      onPositionModelModelEnter,
      onPositionModelModelExit,
      onPositionModelModelDrag,
      onPositionModelModelPointerDown,
      onPositionModelModelPointerUp,

      // edit path machine orientation
      onEditPathMouseDown,
      onEditPathMouseMove,
      onEditPathMouseUp,

      // edit path model interactions
      onEditPathModelDrag,
      onEditPathModelHover,
      onEditPathModelEnter,
      onEditPathModelExit,
      onEditPathModelPointerDown,
      onEditPathModelPointerUp,

      // edit path sphere interactions
      onEditPathDeleteActiveSphere,
      onEditPathSphereEnter,
      onEditPathSphereExit,
      onEditPathSpherePointerDown,
      onEditPathSpherePointerUp,
      onEditPathSphereDrag,
      onModelComputePlaneWithLargestArea,
      onEscape
    } = dispatchProps;

    let onMouseDown,
        onMouseUp,
        onMouseMove,
        onModelDrag,
        onModelHover,
        onModelEnter,
        onModelExit,
        onModelPointerDown,
        onModelPointerUp,
        onDeleteActiveSphere,
        onSphereEnter,
        onSphereExit,
        onSpherePointerDown,
        onSpherePointerUp,
        onSphereDrag;

    switch(step) {
      case POSITION_MODEL:
        onModelEnter = onPositionModelModelEnter;
        onModelExit = onPositionModelModelExit;
        onModelDrag = onPositionModelModelDrag;
        onModelPointerDown = onPositionModelModelPointerDown;
        onModelPointerUp = onPositionModelModelPointerUp;
        break;
      case EDIT_PATH:
        onMouseDown = onEditPathMouseDown;
        onMouseUp = onEditPathMouseUp;

        if(machineRotating) {
          onMouseMove = onEditPathMouseMove;
        }

        onModelEnter = onEditPathModelEnter;
        onModelExit = onEditPathModelExit;
        onModelPointerDown = onEditPathModelPointerDown;
        onModelPointerUp = onEditPathModelPointerUp;
        onModelDrag = onEditPathModelDrag;
        onModelHover = onEditPathModelHover;
        onDeleteActiveSphere = onEditPathDeleteActiveSphere;
        onSphereEnter = onEditPathSphereEnter;
        onSphereExit = onEditPathSphereExit;
        onSpherePointerDown = onEditPathSpherePointerDown;
        onSpherePointerUp = onEditPathSpherePointerUp;
        onSphereDrag = onEditPathSphereDrag;
        break;
      case PREVIEW_PATH:
        onSphereEnter = onEditPathSphereEnter;
        onSphereExit = onEditPathSphereExit;
        onSpherePointerDown = onEditPathSpherePointerDown;
        onSpherePointerUp = onEditPathSpherePointerUp;
        break;
      default:
        break;
    }

    return {
      ...ownProps,
      ...stateProps,
      onEscape,
      onMouseDown,
      onMouseUp,
      onMouseMove,
      onModelDrag,
      onModelHover,
      onModelEnter,
      onModelExit,
      onModelPointerDown,
      onModelPointerUp,
      onDeleteActiveSphere,
      onSphereEnter,
      onSphereExit,
      onSpherePointerDown,
      onSpherePointerUp,
      onSphereDrag,

      onModelComputePlaneWithLargestArea,

      onUpdateDimensions,
      onCameraZoom,
      onCameraStartRotating,
      onCameraStopRotating,
      onCameraRotate,
      onCameraSetDirection,
    };
  }
)(Viewer3D);
