import { createActions } from 'redux-actions';

export default createActions({
  MACHINE_STATE: {
    KINEMATICS_MODE: {
      SET_FIVE_AXIS: undefined,
      SET_TRIVIAL: undefined
    }
  }
});
