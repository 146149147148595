import Immutable from 'immutable';
import actions from '../../actions/machine-state';
import { combineActions, handleActions } from 'redux-actions';

import { MM } from '../../constants/machine-state/units';
import { ABSOLUTE } from '../../constants/machine-state/distance-mode';

import machines from '../../machines';

export const doInverseKinematics = (machineState) => handleActions(
  {
    // Unlike most other reduces, we have access to the full machineState tree in this reducer.

    // motion reducer has already been executed so we need to calculate the joints
    // based on machine, kinematicsMode and motion.position

    [combineActions( 
      actions.machineState.motion.rapid,
      actions.machineState.motion.linear,
      actions.machineState.motion.arcCW,
      actions.machineState.motion.arcCCW,
      actions.machineState.motion.setPosition)]: (state, action) => {
        const { X, Y, Z, A, B, C, units, G53 } = action.payload;
        const machine = machines[machineState.get("machine")];

        let joints;
        if(G53) {
          // we need to calculate the current position in machine space
          let pos = machine.kinematics.forwardKinematics(machineState, G53);

          let params = { X, Y, Z, A, B, C };
          for(const key in params) {
            if(params[key] === undefined) {
              delete params[key];
            } else if(units === MM && (key === 'X' || key === 'Y' || key === 'Z')) {
              params[key] = params[key]/25.4; // convert parameters to inches
            }
          }

          const distanceMode = machineState.get("motion").get("distanceMode");

          // then modify it based on the provided parameters
          if(distanceMode === ABSOLUTE) {
            pos = pos.merge(params);
          } else {
            // if(distanceMode === INCREMENTAL)
            pos = pos.withMutations((pos) => {
              for(const key in params) {
                pos.set(key, pos.get(key)+params[key]);
              }
            });
          }

          // the do inverse kinematics using that position
          joints = machine.kinematics.inverseKinematics(machineState.set("motion", machineState.get("motion").set("position", pos)), G53);

        } else {
          joints = machine.kinematics.inverseKinematics(machineState);
        }

        return joints;
    },
  },
  Immutable.List([0,0,0,0,0])
);

export default (state, action) => state || Immutable.List([0,1.5,0,0,0]);
