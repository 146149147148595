import machineState from './machine-state';
import interpreter from './interpreter';
import ui from './ui';
import iframe from './iframe';
import viewer3D from './viewer3d';
import alignerState from './aligner-state';
import rfid from './rfid';
import auth from './auth';
import { createActions } from 'redux-actions';

export default {
  ...(createActions({ UNRECOVERABLE_ERROR: undefined })),
  ...auth,
  ...machineState,
  ...interpreter,
  ...ui,
  ...iframe,
  ...viewer3D,
  ...alignerState,
  ...rfid
};
