import { createActions } from 'redux-actions';

const actions = createActions({
  MACHINE_STATE: {
    SPINDLE_CONTROL: {
      OFF: undefined,
      CW: undefined,
      CCW: undefined
    }
  }
});

export default actions;
