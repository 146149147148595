// Old Viewer, hastily implemented to get things working.
// TODO - SOFT-226 We'd like to move toward implementing the viewer using
// react-three-fiber to do it in a more declarative, React way. 
// The components under components/viewer3d use react-three-fiber. 
// Though, it may be a while before everything is implemented enough
// to replace this with them.
import now from 'performance-now';
import getAnalytics from '../util/analytics';
import THREE from './three';
import BackPlot from './backplot';
import BackPlotStrip from './backplot-strip';
import ViewCube from './view-cube';
import CameraTransitions from './camera-transitions';
import { SHORT, LONG } from '../constants/machine-state/tool';
import { V2_50, V2 } from '../constants/machine-state/machine';
import * as exportSTL from 'threejs-export-stl';
import { BASE, A_AXIS, B_AXIS, Y_AXIS, TOOL, DENTAL_FIXTURE } from '../constants/viewer3d/camera-parent';
import { PERSPECTIVE, ORTHOGRAPHIC } from '../constants/viewer3d/camera-mode';
import { EPS } from '../constants';
import { FIXTURE_HEIGHT } from '../constants/aligner-state';

import { showThreads } from '../util/legacy';

const analytics = getAnalytics();

// given a three.js Object3D, find a mesh that is a child of it
// The main purpose is to find the single mesh under a transform hierarchy.
// If there are multiple child meshes under the passed Object3D, the first
// one encountered during a breadth first search will be returned.
// Returns undefined if none was found.
const findMesh = (object3d) => {
  let mesh;
  if(object3d instanceof THREE.Mesh) {
    mesh = object3d;
  } else {
    const queue = [ object3d ];
    while(queue.length > 0) {
      const object = queue.shift();
      for(let i = 0; i < object.children.length; i++) {
        const child = object.children[i];
        if(child instanceof THREE.Mesh) {
          return child;
        } else {
          queue.push(child); 
        }
      }
    }
  }
  return mesh;
};

// Given a mesh, return a plane defined by a normal and distance from origin
// that is coplanar with the largest surface area of triangles on the mesh
// that also doesn't have any points beneath it. This will usually represent
// the surface that we want down.
const computePlaneWithLargestArea = (mesh) => {
  const areas = {};
  const planes = {};
  const epsilon = .001; // fairly large epsilon for bucketing similar planes
  const addTriangle =  (p1x,p1y,p1z,
                        p2x,p2y,p2z,
                        p3x,p3y,p3z) => {
    // vectors for 2 of the sides of the triangles
    const ax = p2x-p1x;
    const ay = p2y-p1y;
    const az = p2z-p1z;

    const bx = p3x-p1x;
    const by = p3y-p1y;
    const bz = p3z-p1z;

    // take the cross product
    const x = ay*bz-az*by;
    const y = az*bx-ax*bz;
    const z = ax*by-ay*bx;

    const length = Math.sqrt(x*x+y*y+z*z);
    const invLength = 1./length;

    // define a plane that the triangle is in
    let nx = invLength*x;
    let ny = invLength*y;
    let nz = invLength*z;

    const dist = -(p1x*nx+p1y*ny+p1z*nz);

    if(nx < epsilon && nx > -epsilon) {
      nx = 0;
    } else if(Math.abs(1-nx) < epsilon) {
      nx = 1;
    } else if(Math.abs(-1-nx) < epsilon) {
      nx = -1;
    }

    if(ny < epsilon && ny > -epsilon) {
      ny = 0;
    } else if(Math.abs(1-ny) < epsilon) {
      ny = 1;
    } else if(Math.abs(-1-ny) < epsilon) {
      ny = -1;
    }

    if(nz < epsilon && nz > -epsilon) {
      nz = 0;
    } else if(Math.abs(1-nz) < epsilon) {
      nz = 1;
    } else if(Math.abs(-1-nz) < epsilon) {
      nz = -1;
    }

    // key of plane for storage in object, so planes that are close
    // add to the same plane
    const kx = Math.round(nx/epsilon);
    const ky = Math.round(ny/epsilon);
    const kz = Math.round(nz/epsilon);
    const d = Math.round(dist/(2*epsilon));
    const k = `${kx},${ky},${kz},${d}`;

    const area = length*.5;

    if(!areas[k]) {
      areas[k] = 0;
      planes[k] = { nx, ny, nz, dist };
    }

    areas[k] += area;
  };

  const positions = mesh.geometry.getAttribute("position");
  //const normals = mesh.geometry.getAttribute("normal");
  const indices = mesh.geometry.getIndex();

  if(indices === null) {
    for(let i = 0; i < positions.itemSize*positions.count; i += 3*positions.itemSize) {
      const p1x = positions.array[i];
      const p1y = positions.array[i+1];
      const p1z = positions.array[i+2];

      const p2x = positions.array[i+3];
      const p2y = positions.array[i+4];
      const p2z = positions.array[i+5];

      const p3x = positions.array[i+6];
      const p3y = positions.array[i+7];
      const p3z = positions.array[i+8];
      
      addTriangle(p1x,p1y,p1z,p2x,p2y,p2z,p3x,p3y,p3z);
    }

    const planeAreas = Object.entries(areas);
    planeAreas.sort((a,b) => b[1]-a[1]);

    let bestPlane = planes[planeAreas[0][0]];
    for(let j = 0; j < planeAreas.length; j++) {
      const {
        nx,
        ny,
        nz,
        dist
      } = planes[planeAreas[j][0]];
      let pointAbovePlane = false;
      for(let i = 0; i < positions.itemSize*positions.count  && !pointAbovePlane; i += positions.itemSize) {
        const px = positions.array[i];
        const py = positions.array[i+1];
        const pz = positions.array[i+2];

        const distToPlane = px*nx+py*ny+pz*nz+dist;
        if(distToPlane > .5) { 
          // if there is a point more than half a millimeter in front of the plane, then it's not a suitable plane
          // this occurs if there are extra triangles internally, such as support structures and/or rafts
          // or if the bottom of the model isn't perfectly flat (we give some wiggle room for this, the .5 is arbitrary so this may need to change)
          pointAbovePlane = true;
        }
      }
      if(!pointAbovePlane) {
        bestPlane = planes[planeAreas[j][0]];
        break;
      }
    }

    return new THREE.Plane(new THREE.Vector3(bestPlane.nx, bestPlane.ny, bestPlane.nz), bestPlane.dist);
  } else {
    // TODO - loop over index array to get triangles rather
    // than use consecutive 3 points to define a triangle
  }
};

export default class Viewer3D {
  static getInstance() {
    return Viewer3D.instance;
  }

  static createInstance(canvas, props) {
    Viewer3D.instance = new Viewer3D(canvas, props);

    return Viewer3D.instance;
  }

  constructor(canvas, props) {
    this.props = props;
    this.canvas = canvas;
    this.startTime = now();

    this.smoothJoints = [ ...props.joints ];
    this.smoothJointsFilter = .6;

    this.modelTransitionFilter = .85;
    this.modelTransitionStartY = 10;

    this.raycaster = new THREE.Raycaster();
    this.hits = [];
    this.mouse = new THREE.Vector2();

    this.backplot = BackPlot.getInstance();
    this.backplotStrip = BackPlotStrip.getInstance();

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xcccccc);

//    this.renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: true });
    this.renderer = new THREE.WebGLRenderer({ canvas: canvas});
    this.renderer.autoClear = false;
    this.renderer.setPixelRatio( window.devicePixelRatio );

    this.width = window.innerWidth;
    this.height = window.innerHeight;

    this.viewCube = new ViewCube(canvas, this.width-150, this.height-100, 100, 100);

    const clickListener = (normal) => {
      const {
        onCameraSetDirection
      } = this.props;

      if(onCameraSetDirection) {
        onCameraSetDirection(normal.x, normal.y, normal.z);
      }

      const max = Math.max(Math.abs(normal.x), Math.abs(normal.y), Math.abs(normal.z));

      // Make the normals have components of length 1.
      // So the direction between the front and top face is (-1, 1, 0) rather
      // than (-.70710678, .70710678, 0).
      const x = normal.x/max;
      const y = normal.y/max;
      const z = normal.z/max;

// if x, y, or z are -0 or a tiny number, then use 0
      analytics.event("ViewCube", "clicked face (" + (x < EPS && x > -EPS ? 0 : x) + ", " 
                                                   + (y < EPS && y > -EPS ? 0 : y) + ", "
                                                   + (z < EPS && z > -EPS ? 0 : z) + ")").send();

    };
    this.viewCube.addEventListener('click', clickListener);

    this.renderer.setSize( this.width, this.height );
    this.renderer.setClearColor(0xffffff, 1.0);

    this.cameraTransitions = new CameraTransitions(this.scene, canvas);

    this.createLights();

    const objLoader = new THREE.OBJLoader();
    this.stlLoader = new THREE.STLLoader();
    this.objLoader = objLoader;
    const textureLoader = new THREE.TextureLoader();
    this.textureLoader = textureLoader;

    var r = "textures/cube/";
    var urls = [ r + "px.jpg", r + "nx.jpg",
        r + "py.jpg", r + "ny.jpg",
        r + "pz.jpg", r + "nz.jpg" ];

    this.envMap = new THREE.CubeTextureLoader().load( urls );
    this.envMap.format = THREE.RGBFormat;
    this.envMap.mapping = THREE.CubeReflectionMapping;

    const material = new THREE.MeshStandardMaterial( {
      color: 0x454545, 
      metalness: 1, 
      roughness: .5,
      envMap: this.envMap,
      envMapIntensity: 1.5
    });
    this.material = material;
    this.toolMaterial = new THREE.MeshStandardMaterial( { 
      color: 0xffffff, 
      metalness: 1, 
      roughness: .3,
      envMap: this.envMap
    });
    const toolMaterial = this.toolMaterial;
    const estopMaterial = new THREE.MeshStandardMaterial( { 
      color: 0x111111, 
      metalness: .5, 
      roughness: .6,
      envMap: this.envMap,
      emissive: 0xdd0000
    });
    const ringMaterial = new THREE.MeshStandardMaterial( { 
      color: 0xffffff, 
      metalness: .2, 
      roughness: .6,
      envMap: this.envMap,
      emissive: 0x444449
    });

    this.sphereGeometry = new THREE.SphereGeometry(.5, 32, 32); // 1 mm diamter
    this.ghostSphereGeometry = new THREE.SphereGeometry(.01968, 32, 32); // 1 mm diameter, in inches
    this.ghostMaterial = new THREE.MeshStandardMaterial({ 
      color: 0x27ebff,
      metalness: .7,
      roughness: .7,
      envMap: this.envMap,
      envMapIntensity: 3,
      opacity: .5,
      transparent: true
    });
    this.sphereMaterial = new THREE.MeshStandardMaterial({
      color: 0x27ebff,
      metalness: .7,
      roughness: .7,
      envMap: this.envMap,
      envMapIntensity: 3
    });
    this.highlightedSphereMaterial = new THREE.MeshStandardMaterial({
      color: 0x27ebff,
      metalness: .7,
      roughness: .7,
      envMap: this.envMap,
      envMapIntensity: 4
    });
    this.activeSphereMaterial = new THREE.MeshStandardMaterial({
      color: 0xccff11,
      metalness: .7,
      roughness: .7,
      envMap: this.envMap,
      envMapIntensity: 3
    });
    this.highlightedActiveSphereMaterial = new THREE.MeshStandardMaterial({
      color: 0xccff11,
      metalness: .7,
      roughness: .7,
      envMap: this.envMap,
      envMapIntensity: 4
    });
    this.ghostSphere = new THREE.Mesh(this.ghostSphereGeometry, this.ghostMaterial);
    this.ghostSphere.visible = this.props.showGhostSphere;
    this.scene.add(this.ghostSphere);

    this.spheres = [];

    this.modelMaterial = new THREE.MeshStandardMaterial({
      color: 0xffffff,
      metalness: .5,
      roughness: .6,
      envMap: this.envMap,
      emissive: 0x222222
    });
    this.transparentMaterial = new THREE.MeshStandardMaterial({
      color: 0xffffff,
      metalness: .5,
      roughness: .6,
      envMap: this.envMap,
      envMapIntensity: 1.5,
      opacity: .6,
      transparent: true,
//      emissive: 0x222244
    });
    this.highlightedModelMaterial = new THREE.MeshStandardMaterial({
      color: 0x27ebff,
      metalness: .5,
      roughness: .6,
      envMap: this.envMap,
      envMapIntensity: 1.5,
      opacity: .75,
      transparent: true,
      emissive: 0x0b464c
//      emissive: 0x222244
    });

    const models = [
      { 
        id: 'aAxis', 
        path: 'models/v2/aAxis.obj',
        map: 'textures/v2/aAxisMap.png'
      },
      { 
        id: 'bAxis', 
        path: 'models/v2/bAxis.obj',
        map: 'textures/v2/bAxisMap.png'
      },
      { 
        id: 'xAxis', 
        path: 'models/v2/xAxis.obj',
        map: 'textures/v2/xAxisMap.png'
      },
      { 
        id: 'yAxis', 
        path: 'models/v2/yAxis.obj',
        map: 'textures/v2/yAxisMap.png'
      },
      { 
        id: 'v250Spindle', 
        path: 'models/v2/zAxisV250.obj',
        map: 'textures/v2/zAxisV250Map.png'
      },
      { 
        id: 'v210Spindle', 
        path: 'models/v2/zAxis.obj',
        map: 'textures/v2/zAxisMap.png'
      },
      { 
        id: 'base', 
        path: 'models/v2/base.obj',
        map: 'textures/v2/baseMap.png'
      },
      { 
        id: 'baseButtons', 
        path: 'models/v2/base_buttons.obj',
        material: toolMaterial
      },
      { 
        id: 'estopRing', 
        path: 'models/v2/estop_ring.obj',
        material: estopMaterial
      },
      { 
        id: 'startStopRing', 
        path: 'models/v2/start_stop_ring.obj',
        material: ringMaterial
      },
      {
        id: 'v250ToolHolder',
        path: 'models/v2/v250ToolHolder.obj',
        map: 'textures/v2/v250ToolHolderAO.png',
        shiny: true
      },
      {
        id: 'toolHolder',
        path: 'models/long_tool_holder.obj',
        material: toolMaterial
      },
      {
        id: 'toolHolderShort',
        path: 'models/short_tool_holder.obj',
        material: toolMaterial
      },
      {
        id: 'toolColletHolder',
        path: 'models/long_tool_collet_holder.obj',
        material: material
      },
      {
        id: 'toolColletHolderShort',
        path: 'models/short_tool_collet_holder.obj',
        material: material
      },
//      {
//        id: 'tool',
//        path: 'models/tool.obj',
//        material: toolMaterial
//      }
    ];

    if(this.props.showDentalFixture) {
      if(showThreads) {
        models.push({
          id: "dentalFixture",
          path: "models/dental_fixture_threads.obj",
          map: "textures/dentalFixtureThreadsMap.png",
          shiny: true
        });
        models.push({
          id: "subtract",
          path: "models/subtract_threads.obj",
          material: this.transparentMaterial
        });
      } else {
        models.push({
          id: "dentalFixture",
          path: "models/dental_fixture_snaps.obj",
          map: "textures/dentalFixtureSnapsMap.png",
          shiny: true
        });
        models.push({
          id: "subtract",
          path: "models/subtract_snaps.obj",
          material: this.transparentMaterial
        });
      }
    }

    this.parts = {};

    models.forEach((model) => {
      objLoader.load(model.path,
        (object) => {
          //console.log("loaded", model.path);
          if(model.material) {
            object.traverse((child) => {
              if(child instanceof THREE.Mesh) {
                child.material = model.material;
              }
            });
          }
          model.object = object;
          analytics.timing("Viewer3D", "Loaded Model " + model.id, Math.round(now()-this.startTime)).send();
        },
        (xhr) => {
          //console.log( (xhr.loaded /xhr.total *100) + '% loaded' );
        },
        (error) => {
          console.error(error);
        }
      );
      if(model.map) {
        textureLoader.load(model.map, 
          (texture) => {
            //console.log("loaded", model.map);
            if(model.shiny) {
              model.material = new THREE.MeshStandardMaterial({
                map: texture,
                metalness: 1,
                roughness: .3,
                envMap: this.envMap
              });
            } else {
              model.material = new THREE.MeshStandardMaterial({
                map: texture,
                metalness: 1,
                roughness: .4,
                envMapIntensity: 1.25,
                envMap: this.envMap
              });
            }
            if(model.object) {
              model.object.traverse((child) => {
                if(child instanceof THREE.Mesh) {
                  child.material = model.material;
                }
              });
            }
            analytics.timing("Viewer3D", "Loaded Texture " + model.id, Math.round(now()-this.startTime)).send();
          },
          undefined,
          (error) => {
            console.error(error);
          }
        );
      } else {
        if(model.object) {
          model.object.traverse((child) => {
            if(child instanceof THREE.Mesh) {
              child.material = model.material;
            }
          });
        }
      }
    });

    const checkLoaded = () => {
      //console.log("checking if we're done loading");
      let loaded = true;
      models.forEach((model) => {
        if(!model.object || !model.material) {
          loaded = false;
        }
      });

      if(loaded) {
        //console.log("all models loaded!");
        models.forEach((model) => {
          this.parts[model.id] = model;
        });

        const toolGeometry = new THREE.CylinderGeometry(.5, .5, 6, 32);
        const ballGeometry = new THREE.SphereGeometry(.5, 32, 32);
        const tool = new THREE.Mesh(toolGeometry, toolMaterial);
        const ball = new THREE.Mesh(ballGeometry, toolMaterial);
        const toolParent = new THREE.Group();

        tool.position.z = 3;
//        tool.position.z = 3+.125*.5;
        tool.rotation.x = Math.PI/2;

        ball.position.z = .0625*.5;

        toolParent.add(tool);
//        toolParent.add(ball);
        this.parts.tool = {
          object: toolParent,
          material: toolMaterial
        };

        this.parts.zAxis = {
          object: new THREE.Group()
        };

        this.parts.zAxis.object.add(this.parts.v250Spindle.object);
        this.parts.zAxis.object.add(this.parts.v210Spindle.object);

        if(this.props.machine === V2_50) {
          this.parts.v210Spindle.object.visible = false;
        } else {
          this.parts.v250Spindle.object.visible = false;
          this.parts.v250ToolHolder.object.visible = false;
        }

        this.parts.zAxis.object.position.z = 3;
        this.parts.aAxis.object.rotation.x = 0;

        this.parts.xAxis.object.add(this.parts.zAxis.object);
        this.parts.yAxis.object.add(this.parts.aAxis.object);
        this.parts.zAxis.object.add(this.parts.tool.object);
        this.parts.zAxis.object.add(this.parts.toolHolder.object);
        this.parts.zAxis.object.add(this.parts.v250ToolHolder.object);
        this.parts.toolHolder.object.add(this.parts.toolColletHolder.object);

        this.parts.toolHolder.object.visible = false;

        this.parts.zAxis.object.add(this.parts.toolHolderShort.object);
        this.parts.toolHolderShort.object.add(this.parts.toolColletHolderShort.object);

        this.parts.aAxis.object.add(this.parts.bAxis.object);

        this.parts.bAxis.object.add(this.backplot.object);
        this.parts.bAxis.object.add(this.backplotStrip.object);
//        this.parts.bAxis.object.add(this.parts.dentalFixture.object);

//        if(this.parts.teeth) {
//          this.parts.bAxis.object.add(this.parts.teeth.object);
//          this.parts.teeth.object.visible = false;
//        }

        this.parts.base.object.add(this.parts.yAxis.object);
        this.parts.base.object.add(this.parts.xAxis.object);
        this.parts.base.object.add(this.parts.baseButtons.object);
        this.parts.base.object.add(this.parts.estopRing.object);
        this.parts.base.object.add(this.parts.startStopRing.object);

        if(this.props.showDentalFixture) {
          this.parts.bAxis.object.add(this.parts.dentalFixture.object);

          const dentalMesh = findMesh(this.parts.dentalFixture.object);
          dentalMesh.geometry.computeBoundingBox();
          this.parts.dentalFixture.boundingBox = dentalMesh.geometry.boundingBox.clone();

          this.parts.dentalFixtureFocusPoint = {
            id: "dentalFixtureFocusPoint",
            object: new THREE.Object3D()
          };
          this.parts.dentalFixtureFocusPoint.object.position.y = (3+FIXTURE_HEIGHT)/25.4;
          this.parts.bAxis.object.add(this.parts.dentalFixtureFocusPoint.object);
          this.parts.bAxis.object.add(this.parts.subtract.object);

          if(!showThreads) {
            const cylinderGeometry = new THREE.CylinderGeometry(6/25.4, 6/25.4, 8/25.4, 32);
            const cylinder1 = new THREE.Mesh(cylinderGeometry, this.modelMaterial)
            const cylinder2 = new THREE.Mesh(cylinderGeometry, this.modelMaterial)
            const cylinder3 = new THREE.Mesh(cylinderGeometry, this.modelMaterial)
            const cylinder4 = new THREE.Mesh(cylinderGeometry, this.modelMaterial)

            const cylindersParent = new THREE.Group();
            cylindersParent.position.y = 36.962994/25.4-.25+8/25.4*.5;

            cylinder1.position.x = -47.5875/25.4*.5;
            cylinder2.position.x = 47.5875/25.4*.5;
            cylinder3.position.x = -44.41250/25.4*.5;
            cylinder4.position.x = 44.41250/25.4*.5;

            cylinder1.position.z = -5.55625/25.4;
            cylinder2.position.z = -5.55625/25.4;
            cylinder3.position.z = 5.55625/25.4;
            cylinder4.position.z = 5.55625/25.4;

            cylindersParent.add(cylinder1);
            cylindersParent.add(cylinder2);
            cylindersParent.add(cylinder3);
            cylindersParent.add(cylinder4);
            this.parts.dentalFixture.object.add(cylindersParent);

            cylindersParent.visible = !!this.props.showDentalCylinders;
            this.parts.cylinders = {
              object: cylindersParent,
              id: "cylinders"
            };
          }
        }

        this.scene.add(this.parts.base.object);

        if(this.props.showDentalFixture) {
          this.cameraTransitions.setParent(this.parts.dentalFixtureFocusPoint.object);
        } else {
          this.cameraTransitions.setParent(this.parts.yAxis.object);
        }

        this.ready = true;
        analytics.timing("Viewer3D", "Ready", Math.round(now()-this.startTime)).send();
      } else {
        window.setTimeout(checkLoaded, 500);
      }
    };

    this.time = 0;

    checkLoaded();

    this.addGestureListeners();
  }

  wheelEventListener = (e) => {
    const {
      onCameraZoom,
      respondToCameraGestures
    } = this.props;
    e.preventDefault();
    e.stopPropagation();

    if(respondToCameraGestures) {
      if(onCameraZoom) {
        onCameraZoom(e.deltaY);
      }
    }
  };

  keyDownListener = (e) => {
    const {
      onDeleteActiveSphere,
      onEscape
    } = this.props;

    if(e.key === "Backspace") {
      if(onDeleteActiveSphere) {
        onDeleteActiveSphere()
      }
    } else if(e.key === "Escape") {
      if(onEscape) {
        onEscape(this);
      }
    }
  };

  getViewDirection = () => {
    const dir = new THREE.Vector3(0, 0, -1);

    return dir.applyMatrix4(this.cameraTransitions.camera.matrixWorld).normalize();
  };

  getCameraRay = (x, y) => {
    this.mouse.x = (x / this.width) * 2 - 1;
    this.mouse.y = -(y / this.height) * 2 + 1;
    this.raycaster.setFromCamera(this.mouse, this.cameraTransitions.camera);

    return this.raycaster.ray.clone();
  };

  mouseDownListener = (e) => {
    const {
      onCameraStartRotating,
      respondToModelGestures,
      respondToCameraGestures,
      onModelPointerDown,
      onSpherePointerDown,
      onMouseDown
    } = this.props;
    e.preventDefault();

    const localX = e.offsetX;
    const localY = e.offsetY;

    if(respondToModelGestures) {
      let responded = false;
      if(this.parts.model) {
        this.mouse.x = ( localX / this.width ) * 2 - 1;
        this.mouse.y = - ( localY / this.height ) * 2 + 1;
        this.hits.length = 0;
        this.raycaster.setFromCamera(this.mouse, this.cameraTransitions.camera);
        this.raycaster.intersectObject(this.parts.model.object, true, this.hits);
        if(this.hits.length > 0) {
          if(this.hits[0].object === this.parts.model.object) {
            this.modelPointerDown = true;
            if(onModelPointerDown) {
              onModelPointerDown(this.hits[0], {x: localX, y: localY}, this);
              responded = true;
            }
          } else {
            if(onSpherePointerDown) {
              this.spherePointerDown = this.hits[0];
              onSpherePointerDown(this.hits[0], e, this);
              responded = true;
            }
          }
          if(responded) {
            return; // skip camera controls check if responding to model gesture
          }
        }
      }
    }

    if(respondToCameraGestures) {
      if(onCameraStartRotating && e.button === THREE.MOUSE.LEFT) {
        this.startRotatingX = e.offsetX;
        this.startRotatingY = e.offsetY;

        onCameraStartRotating();
        return;
      }
    }

    if(onMouseDown) {
      onMouseDown({ x: localX, y: localY }, this);
    }
  };

  mouseUpListener = (e) => {
    e.preventDefault();

    const {
      rotating,
      machineRotating,
      respondToCameraGestures,
      respondToModelGestures,
      onCameraStopRotating,
      onModelPointerUp,
      onSpherePointerUp,
      onSphereExit,
      onMouseUp
    } = this.props;

    const localX = e.offsetX;
    const localY = e.offsetY;

    if(!rotating && !machineRotating) {
      if(respondToModelGestures) {
        let responded = false;
        if(this.parts.model) {
          this.mouse.x = ( localX / this.width ) * 2 - 1;
          this.mouse.y = - ( localY / this.height ) * 2 + 1;
          this.hits.length = 0;
          this.raycaster.setFromCamera(this.mouse, this.cameraTransitions.camera);
          this.raycaster.intersectObject(this.parts.model.object, true, this.hits);
          if(this.hits.length > 0) {
            if(this.hits[0].object === this.parts.model.object) {
              if(this.spherePointerDown && onSpherePointerUp) {
                onSpherePointerUp(undefined, e, this);
                responded = true;
              }
              this.spherePointerDown = false;
              if(onModelPointerUp && this.modelPointerDown) {
                onModelPointerUp(this.hits[0], {x: localX, y: localY}, this);
                responded = true;
              }
              this.modelPointerDown = false;
            } else {
              if(this.modelPointerDown && onModelPointerUp) {
                onModelPointerUp(undefined, {x: localX, y: localY}, this);
                responded = true;
              }
              this.modelPointerDown = false;
              if(this.spherePointerDown) {
                if(this.hits[0].object === this.spherePointerDown.object) {
                  onSpherePointerUp(this.hits[0], e, this);
                  responded = true;
                } else {
                  onSpherePointerUp(undefined, e, this);
                  responded = true;
                }
              }
              this.spherePointerDown = false;
            }
            if(responded) {
              return; // skip camera controls check if responding to model gesture
            }
          } else {
            if(this.modelPointerDown) {
              if(onModelPointerUp) {
                onModelPointerUp(undefined, {x: localX, y: localY}, this);
                responded = true;
              }
              this.modelPointerDown = false;
            }
            if(this.spherePointerDown) {
              if(onSpherePointerUp) {
                onSpherePointerUp(undefined, e, this);
                responded = true;
              }
              if(onSphereExit) {
                onSphereExit(this.spherePointerDown, e, this);
                responded = true;
              }
              this.spherePointerDown = false;
            }
            if(responded) {
              return;
            }
          }
        }
      }
    }

    if(respondToCameraGestures) {
      if(onCameraStopRotating && e.button === THREE.MOUSE.LEFT) {
        onCameraStopRotating();
      }
      return;
    }

    if(onMouseUp) {
      onMouseUp({ x: localX, y: localY }, this);
    }
  };

  mouseMoveListener = (e) => {
    const {
      rotating,
      onCameraRotate,
      respondToModelGestures,
      respondToCameraGestures,
      onModelEnter,
      onModelExit,
      onModelHover,
      onModelDrag,
      onSphereHover,
      onSphereEnter,
      onSphereDrag,
      onSphereExit,
      onMouseMove,
      machineRotating
    } = this.props;

    const localX = e.offsetX;
    const localY = e.offsetY;

    if(!rotating && !machineRotating) {
      if(respondToModelGestures) {
        let responded = false;
        if(this.parts.model) {
          this.mouse.x = ( localX / this.width ) * 2 - 1;
          this.mouse.y = - ( localY / this.height ) * 2 + 1;
          this.hits.length = 0;
          this.raycaster.setFromCamera(this.mouse, this.cameraTransitions.camera);
          this.raycaster.intersectObject(this.parts.model.object, true, this.hits);
          if(this.hits.length > 0) {
            if(this.hits[0].object === this.parts.model.object) {
              if(this.lastHitSphere && !this.spherePointerDown) {
                if(onSphereExit) {
                  onSphereExit(this.lastHitSphere, e, this);
                  this.lastHitSphere = false;
                  responded = true;
                }
              }
              if(!this.lastHitWasModel && !this.spherePointerDown) {
                if(onModelEnter) {
                  onModelEnter(this.hits[0], { x: localX, y: localY }, this);
                  responded = true;
                }
                this.lastHitWasModel = this.hits[0];
              }
              if(this.modelPointerDown) {
                if(onModelDrag) {
                  onModelDrag(this.hits[0], { x: localX, y: localY }, this);
                  responded = true;
                }
              } else if(this.spherePointerDown) {
                if(onSphereDrag) {
                  this.spherePointerDown.point.x = this.hits[0].point.x;
                  this.spherePointerDown.point.y = this.hits[0].point.y;
                  this.spherePointerDown.point.z = this.hits[0].point.z;
                  this.spherePointerDown.model = this.parts.model.object;
                  onSphereDrag(this.spherePointerDown, e, this);
                  responded = true;
                }
              } else {
                if(onModelHover) {
                  onModelHover(this.hits[0], { x: localX, y: localY }, this);
                  responded = true;
                }
              }
            } else {
              // a child of model, currently only spheres
              if(this.lastHitWasModel && !this.modelPointerDown) {
                if(onModelExit) {
                  onModelExit(this.lastHitWasModel, { x: localX, y: localY }, this);
                  responded = true;
                }
              }
              this.lastHitWasModel = false;

              if(!this.lastHitSphere || (this.lastHitSphere && this.hits[0].object !== this.lastHitSphere.object && !this.spherePointerDown)) {
                if(this.lastHitSphere) {
                  if(onSphereExit) {
                    onSphereExit(this.lastHitSphere, e, this);
                    responded = true;
                  }
                }
                if(onSphereEnter) {
                  onSphereEnter(this.hits[0], e, this);
                  responded = true;
                }
                this.lastHitSphere = this.hits[0];
              }
              if(this.lastHitSphere && this.spherePointerDown && this.lastHitSphere.object === this.spherePointerDown.object) {
                if(onSphereDrag) {
                  for(let i = 0; i < this.hits.length; i++) {
                    if(this.parts.model.object === this.hits[i].object) {
                      // we want to know where on the model mesh we hit, not on the sphere
                      // so we're going to copy it into the sphere's hit object
                      this.hits[0].point.x = this.hits[i].point.x;
                      this.hits[0].point.y = this.hits[i].point.y;
                      this.hits[0].point.z = this.hits[i].point.z;
                      this.hits[0].model = this.parts.model.object;
                      onSphereDrag(this.hits[0], e, this);
                      responded = true;
                      break; // we want the closest hit point, so break out after the first one
                    }
                  }
                }
              } else {
                if(onSphereHover) {
                  onSphereHover(this.hits[0], e, this);
                  responded = true;
                }
              }
            }
            if(responded) {
              return; // skip camera controls check if responding to model gesture
            }
          } else {
            if(this.modelPointerDown) {
              if(onModelDrag) {
                onModelDrag(undefined, { x: localX, y: localY }, this);
                responded = true;
              }
            } else {
              if(this.lastHitWasModel) {
                if(onModelExit) {
                  onModelExit(this.lastHitWasModel, { x: localX, y: localY }, this);
                  responded = true;
                }
              }
              this.lastHitWasModel = false;
            }

            if(this.lastHitSphere && !this.spherePointerDown) {
              if(onSphereExit) {
                onSphereExit(this.lastHitSphere, e, this);
                responded = true;
              }
              this.lastHitSphere = false;
            }
            if(responded) {
              return;
            }
          }
        }
      }
    }

    if(respondToCameraGestures) {
      if(onCameraRotate && rotating) {
        onCameraRotate(e.offsetX-this.startRotatingX, e.offsetY-this.startRotatingY);
        this.startRotatingX = e.offsetX;
        this.startRotatingY = e.offsetY;
      }
      return;
    }

    if(onMouseMove) {
      onMouseMove({ x: localX, y: localY }, this);
    }
  };

  touchStartListener = (e) => {
    const {
      onCameraStartRotating,
      onCameraStopRotating,
      respondToModelGestures,
      respondToCameraGestures,
      onModelPointerDown,
      onSpherePointerDown,
      onMouseDown,
      onModelEnter,
      rotating
    } = this.props;
    e.preventDefault();

    const rect = e.target.getBoundingClientRect();
    const localX = e.touches[0].pageX-rect.left;
    const localY = e.touches[0].pageY-rect.top;

    if(respondToModelGestures && e.touches.length === 1) {
      let responded = false;
      if(this.parts.model) {
        this.mouse.x = ( localX / this.width ) * 2 - 1;
        this.mouse.y = - ( localY / this.height ) * 2 + 1;
        this.hits.length = 0;
        this.raycaster.setFromCamera(this.mouse, this.cameraTransitions.camera);
        this.raycaster.intersectObject(this.parts.model.object, true, this.hits);
        if(this.hits.length > 0) {
          if(this.hits[0].object === this.parts.model.object) {
            if(!this.modelPointerDown && onModelEnter) {
              onModelEnter(this.hits[0], { x: localX, y: localY }, this);
              responded = true;
            }
            this.modelPointerDown = true;
            if(onModelPointerDown) {
              onModelPointerDown(this.hits[0], { x: localX, y: localY }, this);
              responded = true;
            }
          } else {
            if(onSpherePointerDown) {
              this.spherePointerDown = this.hits[0];
              onSpherePointerDown(this.hits[0], e, this);
              responded = true;
            }
          }
          if(responded) {
            return; // skip camera controls check if responding to model gesture
          }
        }
      }
    }

    if(!this.modelPointerDown && respondToCameraGestures) {
      switch(e.touches.length) {
        case 1:
          if(onCameraStartRotating) {
            this.startRotateX = e.touches[0].pageX;
            this.startRotateY = e.touches[0].pageY;
            onCameraStartRotating();
          }
          break;
        case 2:
          if(onCameraStopRotating && rotating) {
            onCameraStopRotating();
          }
          const dx = e.touches[0].pageX-e.touches[1].pageX;
          const dy = e.touches[0].pageY-e.touches[1].pageY;
          this.touchZoomStart = Math.sqrt(dx*dx+dy*dy);
          break;
        default:
          break;
      }
    }

    if(onMouseDown) {
      onMouseDown({ x: localX, y: localY } , this);
    }
  };

  touchEndListener = (e) => {
    const {
      rotating,
      machineRotating,
      respondToCameraGestures,
      respondToModelGestures,
      onCameraStartRotating,
      onCameraStopRotating,
      onModelPointerUp,
      onSpherePointerUp,
      onSphereExit,
      onMouseUp,
      onModelExit
    } = this.props;

    const rect = e.target.getBoundingClientRect();
    const localX = e.changedTouches[0].pageX-rect.left;
    const localY = e.changedTouches[0].pageY-rect.top;

    if(!rotating && !machineRotating) {
      if(respondToModelGestures) {
        let responded = false;
        if(this.parts.model) {
          this.mouse.x = ( localX / this.width ) * 2 - 1;
          this.mouse.y = - ( localY / this.height ) * 2 + 1;
          this.hits.length = 0;
          this.raycaster.setFromCamera(this.mouse, this.cameraTransitions.camera);
          this.raycaster.intersectObject(this.parts.model.object, true, this.hits);
          if(this.hits.length > 0) {
            if(this.hits[0].object === this.parts.model.object) {
              if(this.spherePointerDown && onSpherePointerUp) {
                onSpherePointerUp(undefined, e, this);
                responded = true;
              }
              this.spherePointerDown = false;
              if(onModelPointerUp && this.modelPointerDown) {
                onModelPointerUp(this.hits[0], {x: localX, y: localY}, this);
                responded = true;
              }
              if(onModelExit && this.modelPointerDown) {
                onModelExit(this.hits[0], {x: localX, y: localY}, this);
                responded = true;
              }
              this.modelPointerDown = false;
            } else {
              if(this.modelPointerDown && onModelPointerUp) {
                onModelPointerUp(undefined, {x: localX, y: localY}, this);
                responded = true;
              }
              if(this.modelPointerDown && onModelExit) {
                onModelExit(undefined, {x: localX, y: localY}, this);
                responded = true;
              }
              this.modelPointerDown = false;
              if(this.spherePointerDown) {
                if(this.hits[0].object === this.spherePointerDown.object) {
                  onSpherePointerUp(this.hits[0], e, this);
                  responded = true;
                } else {
                  onSpherePointerUp(undefined, e, this);
                  responded = true;
                }
              }
              this.spherePointerDown = false;
            }
            if(responded) {
              return; // skip camera controls check if responding to model gesture
            }
          } else {
            if(this.modelPointerDown) {
              if(onModelPointerUp) {
                onModelPointerUp(undefined, {x: localX, y: localY}, this);
                responded = true;
              }
              if(onModelExit) {
                onModelExit(undefined, {x: localX, y: localY}, this);
                responded = true;
              }
              this.modelPointerDown = false;
            }
            if(this.spherePointerDown) {
              if(onSpherePointerUp) {
                onSpherePointerUp(undefined, e, this);
                responded = true;
              }
              if(onSphereExit) {
                onSphereExit(this.spherePointerDown, e, this);
                responded = true;
              }
              this.spherePointerDown = false;
            }
            if(responded) {
              return;
            }
          }
        }
      }
    }

    if(!this.modelPointerDown && respondToCameraGestures) {
      switch(e.touches.length) {
        case 0:
          if(onCameraStopRotating && rotating) {
            onCameraStopRotating();
          }
          break;
        case 1:
          if(onCameraStartRotating) {
            this.startRotateX = e.touches[0].pageX;
            this.startRotateY = e.touches[0].pageY;
            onCameraStartRotating();
          }
          break;
        case 2:
          if(onCameraStopRotating && rotating) {
            onCameraStopRotating();
          }
          break;
        default:
          break;
      }
    }

    if(onMouseUp) {
      onMouseUp({ x: localX, y: localY }, this);
    }
  };

  touchMoveListener = (e) => {
    e.preventDefault();
//    e.stopPropagation();

    const {
      rotating,
      onCameraRotate,
      onCameraZoom,
      onCameraStopRotating,
      respondToModelGestures,
      respondToCameraGestures,
      onModelEnter,
      onModelExit,
      onModelHover,
      onModelDrag,
      onSphereHover,
      onSphereEnter,
      onSphereDrag,
      onSphereExit,
      onMouseMove,
      machineRotating
    } = this.props;

    const rect = e.target.getBoundingClientRect();
    const localX = e.touches[0].pageX-rect.left;
    const localY = e.touches[0].pageY-rect.top;

    if(!rotating && !machineRotating) {
      if(respondToModelGestures) {
        let responded = false;
        if(this.parts.model) {
          this.mouse.x = ( localX / this.width ) * 2 - 1;
          this.mouse.y = - ( localY / this.height ) * 2 + 1;
          this.hits.length = 0;
          this.raycaster.setFromCamera(this.mouse, this.cameraTransitions.camera);
          this.raycaster.intersectObject(this.parts.model.object, true, this.hits);
          if(this.hits.length > 0) {
            if(this.hits[0].object === this.parts.model.object) {
              if(this.lastHitSphere && !this.spherePointerDown) {
                if(onSphereExit) {
                  onSphereExit(this.lastHitSphere, e, this);
                  this.lastHitSphere = false;
                  responded = true;
                }
              }
              if(!this.lastHitWasModel && !this.spherePointerDown) {
                if(onModelEnter) {
                  onModelEnter(this.hits[0], { x: localX, y: localY }, this);
                  responded = true;
                }
                this.lastHitWasModel = this.hits[0];
              }
              if(this.modelPointerDown) {
                if(onModelDrag) {
                  onModelDrag(this.hits[0], { x: localX, y: localY }, this);
                  responded = true;
                }
              } else if(this.spherePointerDown) {
                if(onSphereDrag) {
                  this.spherePointerDown.point.x = this.hits[0].point.x;
                  this.spherePointerDown.point.y = this.hits[0].point.y;
                  this.spherePointerDown.point.z = this.hits[0].point.z;
                  this.spherePointerDown.model = this.parts.model.object;
                  onSphereDrag(this.spherePointerDown, e, this);
                  responded = true;
                }
              } else {
                if(onModelHover) {
                  onModelHover(this.hits[0], { x: localX, y: localY }, this);
                  responded = true;
                }
              }
            } else {
              // a child of model, currently only spheres
              if(this.lastHitWasModel && !this.modelPointerDown) {
                if(onModelExit) {
                  onModelExit(this.lastHitWasModel, { x: localX, y: localY }, this);
                  responded = true;
                }
              }
              this.lastHitWasModel = false;

              if(!this.lastHitSphere || (this.lastHitSphere && this.hits[0].object !== this.lastHitSphere.object && !this.spherePointerDown)) {
                if(this.lastHitSphere) {
                  if(onSphereExit) {
                    onSphereExit(this.lastHitSphere, e, this);
                    responded = true;
                  }
                }
                if(onSphereEnter) {
                  onSphereEnter(this.hits[0], e, this);
                  responded = true;
                }
                this.lastHitSphere = this.hits[0];
              }
              if(this.lastHitSphere && this.spherePointerDown && this.lastHitSphere.object === this.spherePointerDown.object) {
                if(onSphereDrag) {
                  for(let i = 0; i < this.hits.length; i++) {
                    if(this.parts.model.object === this.hits[i].object) {
                      // we want to know where on the model mesh we hit, not on the sphere
                      // so we're going to copy it into the sphere's hit object
                      this.hits[0].point.x = this.hits[i].point.x;
                      this.hits[0].point.y = this.hits[i].point.y;
                      this.hits[0].point.z = this.hits[i].point.z;
                      this.hits[0].model = this.parts.model.object;
                      onSphereDrag(this.hits[0], e, this);
                      responded = true;
                      break; // we want the closest hit point, so break out after the first one
                    }
                  }
                }
              } else {
                if(onSphereHover) {
                  onSphereHover(this.hits[0], e, this);
                  responded = true;
                }
              }
            }
            if(responded) {
              return; // skip camera controls check if responding to model gesture
            }
          } else {
            if(this.modelPointerDown) {
              if(onModelDrag) {
                onModelDrag(undefined, { x: localX, y: localY }, this);
                responded = true;
              }
            } else {
              if(this.lastHitWasModel) {
                if(onModelExit) {
                  onModelExit(this.lastHitWasModel, { x: localX, y: localY }, this);
                  responded = true;
                }
              }
              this.lastHitWasModel = false;
            }

            if(this.lastHitSphere && !this.spherePointerDown) {
              if(onSphereExit) {
                onSphereExit(this.lastHitSphere, e, this);
                responded = true;
              }
              this.lastHitSphere = false;
            }
            if(responded) {
              return;
            }
          }
        }
      }
    }

    if(respondToCameraGestures) {
      switch(e.touches.length) {
        case 1:
          if(onCameraRotate && rotating) {
            onCameraRotate(e.touches[0].pageX-this.startRotateX, e.touches[0].pageY-this.startRotateY);
            this.startRotateX = e.touches[0].pageX;
            this.startRotateY = e.touches[0].pageY;
          }
          break;
        case 2:
          if(onCameraStopRotating && rotating) {
            onCameraStopRotating();
          }

          if(onCameraZoom) {
            const dx = e.touches[0].pageX-e.touches[1].pageX;
            const dy = e.touches[0].pageY-e.touches[1].pageY;
            const dist = Math.sqrt(dx*dx+dy*dy);

            const delta = dist-this.touchZoomStart;

            onCameraZoom(-delta);

            this.touchZoomStart = dist;
          }


          break;
        default:
          break;
      }
    }

    if(onMouseMove) {
      onMouseMove({ x: localX, y: localY }, this);
    }
  };

  addGestureListeners = () => {
    this.canvas.addEventListener("wheel", this.wheelEventListener, false);
    this.canvas.addEventListener("mousedown", this.mouseDownListener, false);
    document.addEventListener("mouseup", this.mouseUpListener, false);
    this.canvas.addEventListener("mousemove", this.mouseMoveListener);
    this.canvas.addEventListener("touchstart", this.touchStartListener);
    document.addEventListener("touchend", this.touchEndListener);
    this.canvas.addEventListener("touchmove", this.touchMoveListener);
    document.addEventListener("keydown", this.keyDownListener);
  };

  showDentalFixture = () => {
    if(this.parts.dentalFixture) {
      this.parts.dentalFixture.object.visible = true;
    } else if(this.parts.bAxis) {
      const model = {
        id: "dentalFixture",
        path: "models/dental_fixture.obj",
        map: "textures/dentalFixtureMap.png",
        shiny: true
      };
      this.objLoader.load(model.path,
        (object) => {
          object.traverse((child) => {
            if(child instanceof THREE.Mesh) {
              child.material = model.material;
            }
          });
          model.object = object;

          this.parts.dentalFixture = model;

          const dentalMesh = findMesh(this.parts.dentalFixture.object);
          dentalMesh.geometry.computeBoundingBox();
          this.parts.dentalFixture.boundingBox = dentalMesh.geometry.boundingBox.clone();

          this.parts.bAxis.object.add(this.parts.dentalFixture.object);
        },
        (xhr) => {
          //console.log( (xhr.loaded /xhr.total *100) + '% loaded' );
        },
        (error) => {
          console.error(error);
        }
      );
        /*
      {
        const model = {
          id: "subtract",
          path: "models/subtract.obj",
          material: this.modelMaterial
        };
        this.objLoader.load(model.path,
          (object) => {
            object.traverse((child) => {
              if(child instanceof THREE.Mesh) {
                child.material = model.material;
              }
            });
            model.object = object;

            this.parts.subtract = model;
            this.parts.bAxis.object.add(this.parts.subtract.object);
          },
          (xhr) => {
            //console.log( (xhr.loaded /xhr.total *100) + '% loaded' );
          },
          (error) => {
            console.error(error);
          }
        );
      }
        */
    }
  };

  hideDentalFixture = () => {
    if(this.parts.dentalFixture) {
      this.parts.dentalFixture.object.visible = false;
    }
  };

  exportModelToSTL = () => {
    if(this.parts.model) {
      let mesh;
      const parent = this.parts.model.object;
      if(this.parts.model.object instanceof THREE.Mesh) {
        mesh = this.parts.model.object;
      } else {
        this.parts.model.object.traverse((child) => {
          if(child instanceof THREE.Mesh) {
            mesh = child;
          }
        });
      }

      if(mesh) {
        parent.updateMatrix();

        return exportSTL.fromGeometry(mesh.geometry, parent.matrix, true);
      }
    }
  }

  showModel = () => {
    if(this.parts.model) {
      this.parts.model.object.visible = true;
    }
  }

  hideModel = () => {
    if(this.parts.model) {
      this.parts.model.object.visible = false;
    }
  }

  computePlaneWithLargestArea = () => {
    const {
      onModelComputePlaneWithLargestArea
    } = this.props;
    if(onModelComputePlaneWithLargestArea) {
      const plane = computePlaneWithLargestArea(findMesh(this.parts.model.object));

      onModelComputePlaneWithLargestArea(plane, this);
    }
  }

  loadSTLModel = (model) => {
    // TODO - this all needs to be machine specific some how
    //        rather than assuming the model will be parented to
    //        the bAxis.
    const {
      smoothModelTransition
    } = this.props;
    const geometry = this.stlLoader.parse(model);

    const obj = new THREE.Mesh(geometry, this.modelMaterial);

    obj.scale.x = 1./25.4;
    obj.scale.y = 1./25.4;
    obj.scale.z = 1./25.4;

    if(this.parts.model) {
      if(this.parts.smoothModel) {
        // create a local variable so our timeout 
        // creates a closure for it, so it's guaranteed
        // to be cleaned up.
        const leavingModel = this.parts.smoothModel.object;

        // assign leavingModel to this object so it
        // can be animated over time.
        // If multiple objects are loaded too quickly,
        // they would stop animating, but at least they'll 
        // disappear after a second.
        this.leavingModel = leavingModel;
        setTimeout(() => {
          this.parts.bAxis.object.remove(leavingModel);
        }, 1000)
      } else {
        this.parts.bAxis.object.remove(this.parts.model.object);
      }
    } else {
      this.parts.model = {
        id: 'model',
        material: this.modelMaterial
      }
    }

    this.parts.model.object = obj;

    this.parts.model.object.renderOrder = 1;

    obj.traverse((child) => {
      if(child instanceof THREE.Mesh) {
        child.material = this.parts.model.material;
      }
    });


    if(smoothModelTransition) {
      this.parts.smoothModel = {
        id: "smoothModel",
        object: new THREE.Object3D()
      };
      this.parts.smoothModel.object.position.y = this.modelTransitionStartY;
      this.parts.bAxis.object.add(this.parts.smoothModel.object);
      this.parts.smoothModel.object.add(this.parts.model.object);
      this.parts.smoothModel.object.add(this.backplotStrip.object);
    } else {
      this.parts.bAxis.object.add(this.parts.model.object);
    }

/*
      const down = new THREE.Vector3(0,-1,0);
      const angle = Math.acos(plane.normal.dot(down));
      const axis = plane.normal.cross(down).normalize();

      this.parts.model.object.quaternion.setFromAxisAngle(axis, angle);
      this.parts.model.object.updateMatrix();

      this.parts.model.boundingBox = new THREE.Box3();
      this.parts.model.boundingBox.expandByObject(this.parts.model.object);

      const modelBottomY = this.parts.model.boundingBox.min.y;
      this.parts.model.object.position.y = dentalFixtureY-modelBottomY;

      console.log(findMesh(this.parts.model.object));
      console.log(this.parts.dentalFixture.boundingBox);
      console.log(computePlaneWithLargestArea(findMesh(this.parts.model.object)));
      */
//    }

  }

  loadObjModel = (model) => {
    const {
      smoothModelTransition
    } = this.props;
    // TODO - this all needs to be machine specific some how
    //        rather than assuming the model will be parented to
    //        the bAxis.
    const obj = this.objLoader.parse(model);

    obj.scale.x = 1./25.4;
    obj.scale.y = 1./25.4;
    obj.scale.z = 1./25.4;

    if(this.parts.model) {
      if(this.parts.smoothModel) {
        // create a local variable so our timeout 
        // creates a closure for it, so it's guaranteed
        // to be cleaned up.
        const leavingModel = this.parts.smoothModel.object;

        // assign leavingModel to this object so it
        // can be animated over time.
        // If multiple objects are loaded too quickly,
        // they would stop animating, but at least they'll 
        // disappear after a second.
        this.leavingModel = leavingModel;
        setTimeout(() => {
          this.parts.bAxis.object.remove(leavingModel);
        }, 1000)
      } else {
        this.parts.bAxis.object.remove(this.parts.model.object);
      }
    } else {
      this.parts.model = {
        id: 'model',
        material: this.modelMaterial
      }
    }

    this.parts.model.object = obj;

    obj.traverse((child) => {
      if(child instanceof THREE.Mesh) {
        child.material = this.parts.model.material;
      }
    });

    if(smoothModelTransition) {
      this.parts.smoothModel = {
        id: "smoothModel",
        object: new THREE.Object3D()
      };
      this.parts.smoothModel.object.position.y = this.modelTransitionStartY;
      this.parts.bAxis.object.add(this.parts.smoothModel.object);
      this.parts.smoothModel.object.add(this.parts.model.object);
    } else {
      this.parts.bAxis.object.add(this.parts.model.object);
    }
  };

  parentCameraToTool = () => {
    if(this.ready) {
      this.cameraTransitions.setParent(this.parts.tool.object);
    }
  }

  parentCameraToDentalFixture = () => {
    if(this.ready) {
      this.cameraTransitions.setParent(this.parts.dentalFixtureFocusPoint.object);
    }
  }

  parentCameraToB = () => {
    if(this.ready)  {
      this.cameraTransitions.setParent(this.parts.bAxis.object);
    }
  }

  parentCameraToA = () => {
    if(this.ready) {
      this.cameraTransitions.setParent(this.parts.aAxis.object);
    }
  }

  parentCameraToY = () => {
    if(this.ready) {
      this.cameraTransitions.setParent(this.parts.yAxis.object);
    }
  }

  parentCameraToBase = () => {
    if(this.ready) {
      this.cameraTransitions.setParent(this.parts.base.object);
    }
  }

  update = (props) => {
    this.props = props;
    const {
      cameraParent,
      cameraMode,
      showModel,
      showDentalFixture,
      showDentalCylinders,

      rotating,
      phi,
      theta,
      radius,
      orthographicZoom,
      showGhostSphere,
      ghostSpherePosition,
      ghostSphereOpacity,
      points,
      activePoint,
      highlightedPoint,
      hideTool,
      highlightModel,
      modelRX,
      modelRY,
      modelRZ,
      modelTX,
      modelTY,
      modelTZ,
      machine
    } = this.props;

    if(this.ready) {
      switch(machine) {
        case V2_50:
          this.parts.v250ToolHolder.object.visible = true;
          this.parts.toolHolder.object.visible = false;
          this.parts.toolHolderShort.object.visible = false;
          this.parts.v250Spindle.object.visible = true;
          this.parts.v210Spindle.object.visible = false;
          break;
        case V2:
          this.parts.v250ToolHolder.object.visible = false;
          this.parts.v250Spindle.object.visible = false;
          this.parts.v210Spindle.object.visible = true;
          break;
        default:
          // unknown machine
      }
    }

    if(this.parts.model) {
      if(highlightModel) {
        findMesh(this.parts.model.object).material = this.highlightedModelMaterial;
      } else {
        findMesh(this.parts.model.object).material = this.modelMaterial;
      }

      this.backplotStrip.object.scale.x = 1./25.4;
      this.backplotStrip.object.scale.y = 1./25.4;
      this.backplotStrip.object.scale.z = 1./25.4;

      if(typeof(modelTX) !== "undefined") {
        this.parts.model.object.position.x = modelTX;
        this.backplotStrip.object.position.x = modelTX;
      }
      if(typeof(modelTY) !== "undefined") {
        this.parts.model.object.position.y = modelTY;
        this.backplotStrip.object.position.y = modelTY;
      }
      if(typeof(modelTZ) !== "undefined") {
        this.parts.model.object.position.z = modelTZ;
        this.backplotStrip.object.position.z = modelTZ;
      }
      if(typeof(modelRX) !== "undefined") {
        this.parts.model.object.rotation.x = modelRX;
        this.backplotStrip.object.rotation.x = modelRX;
      }
      if(typeof(modelRY) !== "undefined") {
        this.parts.model.object.rotation.y = modelRY;
        this.backplotStrip.object.rotation.y = modelRY;
      }
      if(typeof(modelRZ) !== "undefined") {
        this.parts.model.object.rotation.z = modelRZ;
        this.backplotStrip.object.rotation.z = modelRZ;
      }

      this.parts.model.object.updateMatrix();
      this.parts.model.object.updateMatrixWorld();
      this.backplotStrip.object.updateMatrix();
      this.backplotStrip.object.updateMatrixWorld();
    }

    if(this.parts.tool) {
      if(hideTool) {
        this.parts.tool.object.visible = false;
      } else {
        this.parts.tool.object.visible = true;
      }
    }

    this.ghostSphere.visible = showGhostSphere;

    if(ghostSpherePosition) {
      this.ghostSphere.position.x = ghostSpherePosition.x;
      this.ghostSphere.position.y = ghostSpherePosition.y;
      this.ghostSphere.position.z = ghostSpherePosition.z;
    }

    const opacity = ghostSphereOpacity ? ghostSphereOpacity : 0;
    this.ghostSphere.material.opacity = opacity;
    if(opacity >= 1) {
      this.ghostSphere.material.transparent = false;
    } else {
      this.ghostSphere.material.transparent = true;
    }

    if(points && this.parts.model) {
      if(this.spheres.length < points.length) {
        const needSpheres = points.length-this.spheres.length;
        for(let i = 0; i < needSpheres; i++) {
          const sphere = new THREE.Mesh(this.sphereGeometry, this.sphereMaterial);
          sphere.userData.index = this.spheres.length;
          this.parts.model.object.add(sphere);
          this.spheres.push(sphere);
        }
      }

      for(let i = 0; i < this.spheres.length; i++) {
        if(this.spheres[i].parent !== this.parts.model.object) {
          this.parts.model.object.add(this.spheres[i]);
        }
        if(i === activePoint) {
          if(i === highlightedPoint) {
            this.spheres[i].material = this.highlightedActiveSphereMaterial;
          } else {
            this.spheres[i].material = this.activeSphereMaterial;
          }
        } else {
          if(i === highlightedPoint) {
            this.spheres[i].material = this.highlightedSphereMaterial;
          } else {
            this.spheres[i].material = this.sphereMaterial;
          }
        }
        if(i < points.length) {

          this.spheres[i].visible = true;
          this.spheres[i].position.x = points[i][0];
          this.spheres[i].position.y = points[i][1];
          this.spheres[i].position.z = points[i][2];
        } else {
          this.spheres[i].visible = false;
        }
      }
    }

    this.cameraTransitions.rotating = rotating;
    this.cameraTransitions.spherical.phi = phi;
    this.cameraTransitions.spherical.theta = theta;
    this.cameraTransitions.spherical.radius = radius;
    this.cameraTransitions.orthographicCamera.zoom = orthographicZoom;
    this.cameraTransitions.orthographicCamera.updateProjectionMatrix();

    switch(cameraParent) {
      case BASE:
        this.parentCameraToBase();
        break;
      case Y_AXIS:
        this.parentCameraToY();
        break;
      case B_AXIS:
        this.parentCameraToB();
        break;
      case A_AXIS:
        this.parentCameraToA();
        break;
      case TOOL:
        this.parentCameraToTool();
        break;
      case DENTAL_FIXTURE:
        this.parentCameraToDentalFixture();
        break;
      default:
        break;
    }

    switch(cameraMode) {
      case PERSPECTIVE:
        this.cameraTransitions.setPerspective();
        break;
      case ORTHOGRAPHIC:
        this.cameraTransitions.setOrthographic();
        break;
      default:
        break;
    }

    if(showModel) {
      this.showModel();
    } else {
      this.hideModel();
    }

    if(showDentalFixture) {
      this.showDentalFixture();
    } else {
      this.hideDentalFixture();
    }

    if(!showThreads) {
      if(this.parts.cylinders) {
        this.parts.cylinders.object.visible = !!showDentalCylinders;
      }
    }
  };

  updateDimensions = (width, height) => {
    this.width = width;
    this.height = height;

    this.renderer.setSize( this.width, this.height );

    this.cameraTransitions.updateCanvasDimensions(width, height);

    this.viewCube.setPosition(width-150, this.height-100);
  };

  updateMachine = () => {
    const {
      smoothJointTransitions,
      smoothModelTransition,
      joints
    } = this.props;
    if(this.ready) {
      this.checkLimits();

      this.cameraTransitions.update();

      if(smoothJointTransitions) {
        for(let i = 0; i < this.smoothJoints.length; i++) {
          if(i === 4) {
            let B = joints[i];
            let currentB = this.smoothJoints[i];
            let db = (((B-currentB)%360)+360)%360;
            if(db > 180) {
              db -= 360;
            }
            this.smoothJoints[i] = joints[i]-db;
          }
          this.smoothJoints[i] = this.smoothJoints[i]*this.smoothJointsFilter+joints[i]*(1-this.smoothJointsFilter);
        }
        this.parts.bAxis.object.rotation.y = this.smoothJoints[4]*Math.PI/180;
        this.parts.aAxis.object.rotation.x = this.smoothJoints[3]*Math.PI/180;
        this.parts.yAxis.object.position.y = -this.smoothJoints[1];
        this.parts.zAxis.object.position.z = 6.260+this.smoothJoints[2];
        this.parts.xAxis.object.position.x = this.smoothJoints[0];
      } else {
        this.parts.bAxis.object.rotation.y = joints[4]*Math.PI/180;
        this.parts.aAxis.object.rotation.x = joints[3]*Math.PI/180;
        this.parts.yAxis.object.position.y = -joints[1];
        this.parts.zAxis.object.position.z = 6.260+joints[2];
        this.parts.xAxis.object.position.x = joints[0];
      }

      if(this.parts.model && smoothModelTransition) {
        this.parts.smoothModel.object.position.y = this.parts.smoothModel.object.position.y*this.modelTransitionFilter;
      }
      if(this.leavingModel && smoothModelTransition) {
        this.leavingModel.position.y = this.leavingModel.position.y*this.modelTransitionFilter+
                                       this.modelTransitionStartY*(1-this.modelTransitionFilter);
      }

      if(this.parts.tool) {
        this.parts.tool.object.position.z = -this.props.toolLength;
        this.parts.tool.object.scale.x = this.props.toolDiameter;
        this.parts.tool.object.scale.y = this.props.toolDiameter;

        this.parts.toolHolder.object.rotation.z = this.props.spindleAngle;
        this.parts.toolHolderShort.object.rotation.z = this.props.spindleAngle;

        if(this.props.machine === V2) {
          if(this.props.toolHolder === SHORT) {
            this.parts.toolHolderShort.object.visible = true;
            this.parts.toolHolder.object.visible = false;
          } else if(this.props.toolHolder === LONG) {
            this.parts.toolHolderShort.object.visible = false;
            this.parts.toolHolder.object.visible = true;
          } else {
            this.parts.toolHolderShort.object.visible = false;
            this.parts.toolHolder.object.visible = false;
          }
        }
      }
    }
  };

  render = () => {
    const {
      showViewCube
    } = this.props;

    this.renderer.clear();
    this.renderer.setViewport(0,0,this.width, this.height);
    this.renderer.setScissorTest(false);
    this.renderer.render(this.scene, this.cameraTransitions.camera);

    if(showViewCube) {
      this.viewCube.setOrientation(this.cameraTransitions.localOrientation);
      this.viewCube.render(this.renderer);
    }
  };

  checkLimits = () => {
    const joint2part = {
      0: this.parts.xAxis,
      1: this.parts.yAxis,
      2: this.props.machine === V2 ? this.parts.v210Spindle : this.parts.v250Spindle,
      3: this.parts.aAxis,
      4: this.parts.bAxis
    };
    for(var i = 0; i < this.props.joints.length; i++) {
      if(this.props.joints[i] < this.props.limits.min[i]) {
        if(joint2part[i].material) {
          joint2part[i].material.color = new THREE.Color(1,0,0);
          joint2part[i].material.emissive = new THREE.Color(.3,0,0);
        } else {
        }
//        console.error(this.props.axes[i] + " exceeded minimum limit.");
//        this.props.joints[i] = this.props.limits.min[i];
        this.totalTime = 0;
      } else if(this.props.joints[i] > this.props.limits.max[i]) {
        if(joint2part[i].material) {
          joint2part[i].material.color = new THREE.Color(1,0,0);
          joint2part[i].material.emissive = new THREE.Color(.3,0,0);
        }
//        console.error(this.props.axes[i] + " exceeded maximum limit.");
//        this.props.joints[i] = this.props.limits.max[i];
        this.totalTime = 0;
      } else {
        if(joint2part[i].material) {
          joint2part[i].material.color = new THREE.Color(1,1,1);
          joint2part[i].material.emissive = new THREE.Color(0,0,0);
        }
      }
    }
  };

  createLights = () => {
    const keyLight = new THREE.DirectionalLight(0xffffff, .15);
    keyLight.position.set(-30, 30, 30);
    keyLight.lookAt(0,0,0);

    const keyLight2 = new THREE.DirectionalLight(0xffffff, .15);
    keyLight2.position.set(-30, 30, -30);
    keyLight2.lookAt(0,0,0);

    const fillLight = new THREE.DirectionalLight(0xffffff, .07);
    fillLight.position.set(30, 30, 30);
    fillLight.lookAt(0,0,0);

    const fillLight2 = new THREE.DirectionalLight(0xffffff, .07);
    fillLight2.position.set(30, 30, -30);
    fillLight2.lookAt(0,0,0);

    const rimLight = new THREE.DirectionalLight(0xffffff, .07);
    rimLight.position.set(30, -30, -30);
    rimLight.lookAt(0,0,0);

    this.scene.add(rimLight);
    this.scene.add(keyLight);
    this.scene.add(keyLight2);
    this.scene.add(fillLight);
    this.scene.add(fillLight2);

  }

  findTime = (time) => {
    if(time >= this.times[this.times.length-1]) {
      return this.times.length-1;
    } else {
      let min = 0;
      let max = this.times.length-1;
      let i = Math.floor(this.times.length/2);
      while(!(this.times[i] < time && this.times[i+1] >= time)) {
        if(this.times[i] >= time) {
          max = i;
        } else {
          min = i;
        }
        i = Math.floor((min+max)*.5);
      }

      return i;
    }
  };

  step = (dt) => {
    this.viewCube.step(dt);
  };

};
