import { createActions } from 'redux-actions';

const actions = createActions({
  MACHINE_STATE: {
    UNITS: {
      SET_IN: undefined,
      SET_MM: undefined
    }
  }
});

export default actions;
