import { FIVE_AXIS, TRIVIAL } from '../../constants/machine-state/kinematics-mode';
import actions from '../../actions/machine-state/kinematics-mode';
import { handleActions } from 'redux-actions';

const reducer = handleActions(
  {
    [actions.machineState.kinematicsMode.setFiveAxis]: () => FIVE_AXIS,
    [actions.machineState.kinematicsMode.setTrivial]: () => TRIVIAL
  },
  TRIVIAL
);

export default reducer;
