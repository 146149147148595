// helper for proper initialization of THREE object

import * as three from 'three';
import injectOBJLoader from 'three-obj-loader';
import STLLoader from 'three-stl-loader';

const THREE = three;
injectOBJLoader(THREE);
THREE.STLLoader = STLLoader(THREE);

export default THREE;
