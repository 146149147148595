// These must be in the order they appear in the Stepper
// so if a new step is added, reorder all of them they
// go from 0 to max number of steps - 1
export const LOAD_MODEL = 0;
export const POSITION_MODEL = 1;
export const EDIT_PATH = 2;
export const PREVIEW_PATH = 3;
export const CUT_PATH = 4;

export const FIXTURE_HEIGHT = 28.775; // in mm
export const ROTATE_MODE = "ROTATE_MODE";
export const TRANSLATE_MODE = "TRANSLATE_MODE";

export const SINGLE_POINT = "SINGLE_POINT";
export const DRAW = "DRAW";

export const EXACT = "EXACT";
export const OVERSIZED = "OVERSIZED";
