import { createActions } from 'redux-actions';

const actions = createActions({
  UI: {
    CONTROLS_OVERLAY: {
      SET_VISIBLE: undefined,
      TOGGLE_PANE: undefined,
      PLAY: undefined,
      PREVIOUS: undefined,
      NEXT: undefined,
      HOME: undefined,
      SHUFFLE: undefined
    }
  }
});

export default actions;
