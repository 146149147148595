import THREE from './three';

export default () => {
  return new THREE.ShaderMaterial({
    uniforms: {
      hitNormal: { value: new THREE.Vector3() },
      texture: { type: "t" },
      opacity: { value: .25 }
    },
    vertexColors: THREE.VertexColors,
    vertexShader: `
      varying vec3 vNormal;
      varying vec2 vUV;
      void main() {
        vNormal = normal;
        vUV = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }
    `,

    fragmentShader: `
      uniform sampler2D texture;
      uniform vec3 hitNormal;
      uniform float opacity;

      varying vec3 vNormal;
      varying vec2 vUV;
      void main() {
        vec3 multColor = vec3(1,1,1);
        if(dot(vNormal, hitNormal) > .99) {
          multColor = vec3(.3,.7, .9);
        }
        gl_FragColor = vec4(texture2D(texture, vUV).xyz*multColor, opacity);
      }
    `
  });
};
