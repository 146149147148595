import { connect } from 'react-redux';

import DRO from '../components/DRO';

//import { EDIT_PATH } from '../constants/aligner-state';

export default connect(
  (state, ownProps) => {
    const alignerState = state.get("alignerState");
    const joints = alignerState.get("joints");
//    const step = alignerState.get("step");
//    const toolOffsetZ = -alignerState.get("toolOffsetZ")+(step === EDIT_PATH ? -alignerState.get("editingOffsetZ") : 0);

    let B = joints.get(4);
    if(B < 0) {
      B += 360;
    }

    return {
      A: joints.get(3).toFixed(4).padStart(9, ' '),
      B: B.toFixed(4).padStart(9, ' ')
    };
  },
  (dispatch, ownProps) => ({
  })
)(DRO);
