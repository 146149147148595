import actions from '../../actions';
import { inverseFiveAxis } from '../../machines/v2';
import Immutable from 'immutable';
import { handleActions } from 'redux-actions';
import THREE from '../../viewer3d/three';
import { V2_50 } from '../../constants/machine-state/machine';
import { SINGLE_POINT, OVERSIZED } from '../../constants/aligner-state'

import {  LOAD_MODEL, EDIT_PATH, FIXTURE_HEIGHT, ROTATE_MODE, TRANSLATE_MODE } from '../../constants/aligner-state';

export const calculateJoints = ({ posx, posy, posz, dirx, diry, dirz, toolOffsetZ, offsetZ, xform }) => {
  const v = new THREE.Vector3(posx, posy, posz);
  const d = new THREE.Vector4(dirx, diry, dirz, 0);

  const mat = new THREE.Matrix4();
  mat.makeRotationFromEuler(new THREE.Euler(xform.get("rx")/180*Math.PI, xform.get("ry")/180*Math.PI, xform.get("rz")/180*Math.PI));
  mat.multiplyScalar(xform.get("s"));
  mat.setPosition(new THREE.Vector3(xform.get("tx"), xform.get("ty"), xform.get("tz")));
  v.applyMatrix4(mat);
  d.applyMatrix4(mat);

  const A = Math.asin(d.y);
  const B = Math.atan2(-d.x, d.z);

// TODO - get this from the current machine rather than assume v2
  const joints = inverseFiveAxis(Immutable.Map({
    A: A*180/Math.PI,
    B: B*180/Math.PI,
    X: v.x/25.4,
    Y: v.y/25.4,
    Z: v.z/25.4
  }), toolOffsetZ);

  return joints.set(2, joints.get(2)+offsetZ);
};

const defaults = Immutable.Map({
  warnOnExit: false,
  machine: V2_50,
  width: 500,
  height: 500,
  toolOffsetZ: -3.5,
  editingOffsetZ: 1.5,
  previewingOffsetZ: 0,
  gettingStatus: false,           // Are we fetching aligner status?
  step: LOAD_MODEL,                // What step we are in the stepper
  modelFinalized: false,          // Is the model finalized? This becomes true when verified by the server.
  finalizeModelClicked: false,    // Did the user click the finalize button? This triggers the start of finalizing, but can be cleared if something goes wrong.
  modelLoaded: false,             // Is the model loaded? The model is loaded when the browser has access to the 3D model, so when the user first loads it or when it is successfully downloaded from the server.
  modelUploaded: false,           // Has the model been uploaded? The user needs to upload a model to the server, but can start working with it immediately once it's been loaded in from disk. We need to know when the upload finishes, so we can properly submit a processing job.
  finishedProcessingModel: false, // Did the processing job finish?
  scannersDetected: 0,            // Number of detected scanners connected over WebUSB
  xform: Immutable.Map({          // xform to apply to the model
    s: 1,
    rx: 0,
    ry: 0,
    rz: 0,
    tx: 0,
    ty: 0,
    tz: 0
  }),
  loading: Immutable.Map({}),
  points: Immutable.List([]),
  closePath: false,
  pointsNeedSaving: false,
  xformNeedsSaving: false,
  activePoint: -1,
  highlightedPoint: -1,
  showGhostSphere: false,
  ghostSpherePosition: Immutable.Map({ x: 0, y: 0, z: 0 }),
  ghostSphereOpacity: .5,
  scannerKey: "",
  alignerID: "",
  label: "",
  previousAlignerID: "",
  previousAlignerLabel: "",
  joints: Immutable.List([ 0, (5+FIXTURE_HEIGHT)/25.4, 0, 0, 0 ]),
  machineRotating: false,
  rotateStartX: 0,
  rotateStartY: 0,
  modelHighlighted: false,
  transformMode: TRANSLATE_MODE,
  dragModelStartX: 0,
  dragModelStartZ: 0,
  paused: true,
  time: 0,
  timeMultiplier: 1,
  times: Immutable.List([0]),
  drawPath: Immutable.List([]),
  drawMode: localStorage.getItem('drawMode') || SINGLE_POINT,
  addBridge: localStorage.getItem('addBridge') === "true", // see aligner saga for side effect that persists to localStorage
  addCylinders: localStorage.getItem('addCylinders') === "true",
  snapFit: localStorage.getItem('snapFit') || OVERSIZED,
  quickLookUpAligners: Immutable.List([]),
  user: false,
  myAccountDialogOpen: false,
  createAlignerDialog: Immutable.Map({
    points: null,
    checksum: null,
    firstModelChecksum: null,
    multi: true,
    code: "",
    open: false,
    xform: undefined,
    addBridge: undefined,
    addCylinders: undefined,
    snapFit: undefined,
    positioningModel: false,
    files: Immutable.List([]),
    mapping: Immutable.Map({}),
    error: null,
    showDownloadAllProcessedModels: false,
    showDownloadAllGCode: false
  })
});

const resetState = defaults.withMutations((defaults) => {
  defaults.delete("scannersDetected");
  defaults.delete("width");
  defaults.delete("height");
  defaults.delete("user");
  defaults.delete("createAlignerDialog");
  defaults.delete("loading");

  defaults.set("drawMode", localStorage.getItem('drawMode') || SINGLE_POINT);
  defaults.set("addBridge", localStorage.getItem('addBridge') === "true");
  defaults.set("addCylinders", localStorage.getItem('addCylinders') === "true");
  defaults.set("snapFit", localStorage.getItem('snapFit') || OVERSIZED);
});

const reducer = handleActions({
  [actions.alignerState.openMyAccount]: (state,action) => {
    return state.set("myAccountDialogOpen", true);
  },
  [actions.alignerState.closeMyAccount]: (state,action) => {
    return state.set("myAccountDialogOpen", false);
  },
  [actions.alignerState.createMultipleAligners]: (state,action) => {
    return state.set("showDownloadAllProcessedModels", false)
                .set("showCopyPoints", false)
                .set("showDownloadAllGCode", false);
  },
  [actions.alignerState.setWarnOnExit]: (state,action) => state.set("warnOnExit", action.payload),
  [actions.alignerState.createAlignerDialog.setFirstModelChecksum]: (state,action) => state.set("createAlignerDialog", state.get("createAlignerDialog").set("firstModelChecksum", action.payload)),
  [actions.alignerState.createAlignerDialog.setMulti]: (state,action) => state.set("createAlignerDialog", state.get("createAlignerDialog").set("multi", action.payload)),
  [actions.alignerState.createAlignerDialog.setError]: (state,action) => state.set("createAlignerDialog", state.get("createAlignerDialog").set("error", action.payload)),
  [actions.alignerState.createAlignerDialog.setCode]: (state,action) => state.set("createAlignerDialog", state.get("createAlignerDialog").set("code", action.payload.replace(/_/g, "").replace(/-/g, "").toUpperCase())),
  [actions.alignerState.createAlignerDialog.setChecksum]: (state,action) => state.set("createAlignerDialog", state.get("createAlignerDialog").set("checksum", action.payload)),
  [actions.alignerState.createAlignerDialog.open]: (state,action) => state.set("createAlignerDialog", state.get("createAlignerDialog").merge(Immutable.Map({
    open: true,
    checksum: null,
    code: "",
    error: null,
    xform: undefined,
    positioningModel: false,
    files: Immutable.List([]),
    mapping: Immutable.Map({}),
    points: undefined
  }))),
  [actions.alignerState.createAlignerDialog.close]: (state,action) => state.set("createAlignerDialog", state.get("createAlignerDialog").set("open", false)),
  [actions.alignerState.createAlignerDialog.setPositioningModel]: (state,action) => state.set("createAlignerDialog", state.get("createAlignerDialog").set("positioningModel", action.payload)),
  [actions.alignerState.createAlignerDialog.setFiles]: (state,action) => state.set("createAlignerDialog", state.get("createAlignerDialog").set("files", Immutable.List(action.payload.files)).set("mapping", Immutable.Map(action.payload.mapping))),
  [actions.alignerState.createAlignerDialog.saveXform]: (state,action) => {
    return state.set("createAlignerDialog", 
                  state.get("createAlignerDialog").withMutations((dialog) => {
                    dialog.set("xform", state.get("xform"));
                    dialog.set("addBridge", state.get("addBridge"));
                    dialog.set("addCylinders", state.get("addCylinders"));
                    dialog.set("snapFit", state.get("snapFit"));
                    if(state.get("closePath")) {
                      dialog.set("points", Immutable.Map({
                        "points": state.get("points"),
                        "closed": state.get("closePath")
                      }));
                    }
                  }));
  },
  [actions.alignerState.createAlignerDialog.setAlignerNumber]: (state,action) => {
    const {
      file,
      value
    } = action.payload;
    const createAlignerDialog = state.get("createAlignerDialog");
    const mapping = createAlignerDialog.get("mapping");
    
    return state.set("createAlignerDialog", createAlignerDialog.set("mapping", mapping.merge(Immutable.Map({ [file]: value.replace(/_/g, "").replace(/-/g, "").toUpperCase() }))));
  },
  [actions.alignerState.setDrawMode]: (state, action) => state.set("drawMode", action.payload),
  [actions.auth.userAuthenticated]: (state, action) => state.set("user", Immutable.Map(action.payload)),
  [actions.alignerState.cancelDrawPath]: (state, action) => state.set("drawPath", Immutable.List([ ])),
  [actions.alignerState.initializeDrawPath]: (state,action) => state.set("drawPath", Immutable.List([ Immutable.List(action.payload) ])),
  [actions.alignerState.addPointToDrawPath]: (state,action) => state.set("drawPath", state.get("drawPath").push(Immutable.List(action.payload))),
  [actions.alignerState.simplifyDrawPath]: (state, action) => {
    const drawPath = state.get("drawPath").toJS();

    if(drawPath.length > 0) {
      const threshold = .5;

      const distanceToSegment = (first, last, current) => {
        // line segment vector
        const ldx = last[0]-first[0];
        const ldy = last[1]-first[1];
        const ldz = last[2]-first[2];
        const segmentLengthSq = (ldx*ldx+ldy*ldy+ldz*ldz);

        if(segmentLengthSq < .000001) {
          // if the line segment has a length of 0, simply calculate the distance from last to current point
          const dx = last[0]-current[0];
          const dy = last[1]-current[1];
          const dz = last[2]-current[2];
          return Math.sqrt(dx*dx+dy*dy+dz*dz);
        }

        // calculate the projection of vector from first point to current point
        // onto the segment vector
        // t is a value from 0 to 1 that can be used to calculate the closest point on the segment
        const t = Math.max(0, Math.min(1, ((current[0]-first[0])*(last[0]-first[0])+
                                           (current[1]-first[1])*(last[1]-first[1])+
                                           (current[2]-first[2])*(last[2]-first[2]))/segmentLengthSq));

        // calculate point on segment using t
        const lx = first[0]+t*ldx;
        const ly = first[1]+t*ldy;
        const lz = first[2]+t*ldz;
        const dx = current[0]-lx;
        const dy = current[1]-ly;
        const dz = current[2]-lz;

        // return the distance to that point
        return Math.sqrt(dx*dx+dy*dy+dz*dz);
      };

      const simplified = drawPath.map(() => false);

      let first = 0;
      let last = drawPath.length-1;

      simplified[first] = true;
      simplified[last] = true;

      const firstList = [];
      const lastList = [];
      while(last !== null) {
        let maxDist = 0;
        let index;
        for(let i = first+1; i < last; i++) {
          const dist = distanceToSegment(drawPath[first], drawPath[last], drawPath[i]);
          if(dist > maxDist) {
            index = i;
            maxDist = dist;
          }
        }
        if(maxDist > threshold) {
          simplified[index] = true;

          firstList.push(first);
          lastList.push(index);

          firstList.push(index);
          lastList.push(last);
        }

        if(firstList.length === 0) {
          first = null;
        } else {
          first = firstList.pop();
        }

        if(lastList.length === 0) {
          last = null;
        } else {
          last = lastList.pop();
        }
      }

      const simplifiedDrawPath = drawPath.filter((pt, index) => simplified[index]);

      const activePoint = state.get("activePoint");
      const step = state.get("step");
      const toolOffsetZ = state.get("toolOffsetZ");
      const offsetZ = step === EDIT_PATH ? state.get("editingOffsetZ") : state.get("previewingOffsetZ");
      const xform = state.get("xform");
      const lastposx = simplifiedDrawPath[simplifiedDrawPath.length-1][0];
      const lastposy = simplifiedDrawPath[simplifiedDrawPath.length-1][1];
      const lastposz = simplifiedDrawPath[simplifiedDrawPath.length-1][2];
      const lastdirx = simplifiedDrawPath[simplifiedDrawPath.length-1][3];
      const lastdiry = simplifiedDrawPath[simplifiedDrawPath.length-1][4];
      const lastdirz = simplifiedDrawPath[simplifiedDrawPath.length-1][5];
      const joints = calculateJoints({ posx: lastposx, posy: lastposy, posz: lastposz, dirx: lastdirx, diry: lastdiry, dirz: lastdirz, toolOffsetZ, offsetZ, xform });

      const insertPoints = simplifiedDrawPath.map((pt) => Immutable.List(pt));
      const newPoints = state.get("points").splice(activePoint+1,0, ...insertPoints);

      return state.set("points", newPoints).set("activePoint", activePoint+simplifiedDrawPath.length).set("joints", joints).set("pointsNeedSaving", true).set("drawPath", Immutable.List([]));
  //    return state.set("drawPath", Immutable.List(simplifiedDrawPath));
    } else {
      return state;
    }
  },
  [actions.alignerState.setUserInfo]: (state, action) => state.set("user", state.get("user").merge(action.payload)),
  [actions.alignerState.setRotateMode]: (state, action) => state.set("transformMode", ROTATE_MODE),
  [actions.alignerState.setTranslateMode]: (state, action) => state.set("transformMode", TRANSLATE_MODE),
  [actions.alignerState.setSnapFit]: (state, action) => state.set("snapFit", action.payload),
  [actions.alignerState.setAddBridge]: (state, action) => state.set("addBridge", action.payload ? true : false),
  [actions.alignerState.setAddCylinders]: (state, action) => state.set("addCylinders", action.payload ? true : false),
  [actions.alignerState.setToolOffsetZ]: (state, action) => state.set("toolOffsetZ", action.payload),
  [actions.alignerState.setTimes]: (state, action) => state.set("times", Immutable.List(action.payload)).set("time", action.payload[action.payload.length-1]),
  [actions.alignerState.setMachine]: (state,action) => state.set("machine", action.payload),
  [actions.alignerState.play]: (state, action) => {
    const totalTime = state.get("times").last();
    if(state.get("time") >= totalTime) {
      state = state.set("time", 0);
    }
    return state.set("paused", false);
  },
  [actions.alignerState.pause]: (state, action) => state.set("paused", true),
  [actions.alignerState.incrementTime]: (state,action) => {
    const currentTime = state.get("time");
    const timeMultiplier = state.get("timeMultiplier");
    const times = state.get("times");
    const points = state.get("points");
    const dt = action.payload*timeMultiplier;
    const totalTime = times.get(times.size-1);
    const time = Math.min(currentTime+dt, totalTime);
    const closed = state.get("closePath");

    let percentage = 0;

    let min;
    let max;
    if(time >= totalTime) {
      percentage = 1;
    } else if(time === 0) {
      percentage = 0;
    } else {
      min = 0;
      max = times.size-1;
      let mid = Math.floor(times.size/2);
      while( min+1 < max ) {
        if(times.get(mid) > time) {
          max = mid;
        } else {
          min = mid;
        }
        mid = Math.floor((min+max)*.5);
      }
      const tt = (time-times.get(min))/(times.get(max)-times.get(min));
      percentage = (min+tt)/(times.size-1);
    }

    let numSegments;
    let activePoint;
    let tt;
    if(closed) {
      numSegments = points.size;
      activePoint = Math.floor(numSegments*percentage);
      tt = numSegments*percentage-activePoint;
      if(activePoint >= points.size) {
        activePoint = 0;
      }
    } else {
      numSegments = points.size-1;
      activePoint = Math.floor(numSegments*percentage);
      tt = numSegments*percentage-activePoint;
    }

    //console.log(percentage, activePoint, currentTime, totalTime, min, max);

    let posx,posy,posz;
    let dirx,diry,dirz;
    {
      let i0;
      let i1;
      let i2;
      let i3;

      if(closed) {
        // closed loop
        i1 = activePoint;
        i2 = (i1+1)%numSegments;
        i3 = (i2+1)%numSegments;
        i0 = ((i1+numSegments)-1)%numSegments;
      } else {
        // not closed loop
        i1 = activePoint;
        if(i1 >= numSegments) {
          i1 = numSegments;
        }
        i2 = i1+1;
        if(i2 >= numSegments) {
          i2 = numSegments;
        }
        i3 = i2+1;
        if(i3 >= numSegments) {
          i3 = numSegments;
        }
        i0 = i1-1;
        if(i0 < 0) {
          i0 = 0;
        }
      }

      const n0 = new THREE.Vector3(points.get(i0).get(0), points.get(i0).get(1), points.get(i0).get(2));
      const n1 = new THREE.Vector3(points.get(i1).get(0), points.get(i1).get(1), points.get(i1).get(2));
      const n2 = new THREE.Vector3(points.get(i2).get(0), points.get(i2).get(1), points.get(i2).get(2));
      const n3 = new THREE.Vector3(points.get(i3).get(0), points.get(i3).get(1), points.get(i3).get(2));

      const p0 = new THREE.Vector3();
      const p1 = new THREE.Vector3();
      const m0 = new THREE.Vector3();
      const m1 = new THREE.Vector3();

      p0.copy(n1);
      p1.copy(n2);
      const tension = .5;
      m0.copy(n2);
      m0.sub(n0);
      m0.multiplyScalar(tension);

      m1.copy(n3);
      m1.sub(n1);
      m1.multiplyScalar(tension);

      const x = (1+2*tt)*(1-tt)*(1-tt)*p0.x+tt*(1-tt)*(1-tt)*m0.x+tt*tt*(3-2*tt)*p1.x+tt*tt*(tt-1)*m1.x;
      const y = (1+2*tt)*(1-tt)*(1-tt)*p0.y+tt*(1-tt)*(1-tt)*m0.y+tt*tt*(3-2*tt)*p1.y+tt*tt*(tt-1)*m1.y;
      const z = (1+2*tt)*(1-tt)*(1-tt)*p0.z+tt*(1-tt)*(1-tt)*m0.z+tt*tt*(3-2*tt)*p1.z+tt*tt*(tt-1)*m1.z;

      const d1 = new THREE.Vector3(points.get(i1).get(3), points.get(i1).get(4), points.get(i1).get(5));
      const d2 = new THREE.Vector3(points.get(i2).get(3), points.get(i2).get(4), points.get(i2).get(5));

      const angle = d1.angleTo(d2);

      const axis = new THREE.Vector3();
      axis.crossVectors(d1,d2);
      axis.normalize();

      const dir = new THREE.Vector3();
      dir.copy(d1);
      dir.applyAxisAngle(axis, angle*tt);

      posx = x;
      posy = y;
      posz = z;

      dirx = dir.x;
      diry = dir.y;
      dirz = dir.z;
    }

    const xform = state.get("xform");
    const step = state.get("step");
    const toolOffsetZ = state.get("toolOffsetZ");
    const offsetZ = step === EDIT_PATH ? state.get("editingOffsetZ") : state.get("previewingOffsetZ");
    const joints = calculateJoints({ posx, 
                                     posy, 
                                     posz, 
                                     dirx, 
                                     diry, 
                                     dirz, 
                                     toolOffsetZ,
                                     offsetZ,
                                     xform });

    return state.withMutations((state) => {
      if(time >= totalTime) {
        state.set("paused", true);
      }
      state.set("time", time);
      state.set("joints", joints);
      state.set("activePoint", activePoint);
    });
  },
  [actions.alignerState.setTimeWithPercentage]: (state,action) => {
    const points = state.get("points");
    const times = state.get("times");
    const totalTime = times.last();
    const currentTime = Math.min(action.payload/100*totalTime, totalTime);
    const closed = state.get("closePath");

    let percentage;
    let min;
    let max;
    if(currentTime >= totalTime) {
      percentage = 1;
    } else if(currentTime === 0) {
      percentage = 0;
    } else {
      min = 0;
      max = times.size-1;
      let mid = Math.floor(times.size/2);
      while( min+1 < max ) {
        if(times.get(mid) > currentTime) {
          max = mid;
        } else {
          min = mid;
        }
        mid = Math.floor((min+max)*.5);
      }
      const tt = (currentTime-times.get(min))/(times.get(max)-times.get(min));
      percentage = (min+tt)/(times.size-1);
    }

    let numSegments;
    let activePoint;
    let tt;
    if(closed) {
      numSegments = points.size;
      activePoint = Math.floor(numSegments*percentage);
      tt = numSegments*percentage-activePoint;
      if(activePoint >= points.size) {
        activePoint = 0;
      }
    } else {
      numSegments = points.size-1;
      activePoint = Math.floor(numSegments*percentage);
      tt = numSegments*percentage-activePoint;
    }


    let posx,posy,posz;
    let dirx,diry,dirz;
    {
      let i0;
      let i1;
      let i2;
      let i3;

      if(closed) {
        // closed loop
        i1 = activePoint;
        i2 = (i1+1)%numSegments;
        i3 = (i2+1)%numSegments;
        i0 = ((i1+numSegments)-1)%numSegments;
      } else {
        // not closed loop
        i1 = activePoint;
        if(i1 >= numSegments) {
          i1 = numSegments;
        }
        i2 = i1+1;
        if(i2 >= numSegments) {
          i2 = numSegments;
        }
        i3 = i2+1;
        if(i3 >= numSegments) {
          i3 = numSegments;
        }
        i0 = i1-1;
        if(i0 < 0) {
          i0 = 0;
        }
      }

      const n0 = new THREE.Vector3(points.get(i0).get(0), points.get(i0).get(1), points.get(i0).get(2));
      const n1 = new THREE.Vector3(points.get(i1).get(0), points.get(i1).get(1), points.get(i1).get(2));
      const n2 = new THREE.Vector3(points.get(i2).get(0), points.get(i2).get(1), points.get(i2).get(2));
      const n3 = new THREE.Vector3(points.get(i3).get(0), points.get(i3).get(1), points.get(i3).get(2));

      const p0 = new THREE.Vector3();
      const p1 = new THREE.Vector3();
      const m0 = new THREE.Vector3();
      const m1 = new THREE.Vector3();

      p0.copy(n1);
      p1.copy(n2);
      const tension = .5;
      m0.copy(n2);
      m0.sub(n0);
      m0.multiplyScalar(tension);

      m1.copy(n3);
      m1.sub(n1);
      m1.multiplyScalar(tension);

      const x = (1+2*tt)*(1-tt)*(1-tt)*p0.x+tt*(1-tt)*(1-tt)*m0.x+tt*tt*(3-2*tt)*p1.x+tt*tt*(tt-1)*m1.x;
      const y = (1+2*tt)*(1-tt)*(1-tt)*p0.y+tt*(1-tt)*(1-tt)*m0.y+tt*tt*(3-2*tt)*p1.y+tt*tt*(tt-1)*m1.y;
      const z = (1+2*tt)*(1-tt)*(1-tt)*p0.z+tt*(1-tt)*(1-tt)*m0.z+tt*tt*(3-2*tt)*p1.z+tt*tt*(tt-1)*m1.z;

      const d1 = new THREE.Vector3(points.get(i1).get(3), points.get(i1).get(4), points.get(i1).get(5));
      const d2 = new THREE.Vector3(points.get(i2).get(3), points.get(i2).get(4), points.get(i2).get(5));

      const angle = d1.angleTo(d2);

      const axis = new THREE.Vector3();
      axis.crossVectors(d1,d2);
      axis.normalize();

      const dir = new THREE.Vector3();
      dir.copy(d1);
      dir.applyAxisAngle(axis, angle*tt);

      posx = x;
      posy = y;
      posz = z;

      dirx = dir.x;
      diry = dir.y;
      dirz = dir.z;
    }

    const xform = state.get("xform");
    const step = state.get("step");
    const toolOffsetZ = state.get("toolOffsetZ");
    const offsetZ = step === EDIT_PATH ? state.get("editingOffsetZ") : state.get("previewingOffsetZ");
    const joints = calculateJoints({ posx, 
                                     posy, 
                                     posz, 
                                     dirx, 
                                     diry, 
                                     dirz, 
                                     toolOffsetZ,
                                     offsetZ,
                                     xform });

    return state.withMutations((state) => {
      if(currentTime >= totalTime) {
        state.set("paused", true);
      }
      state.set("time", currentTime);
      state.set("joints", joints);
      state.set("activePoint", activePoint);
    });
  },
  [actions.alignerState.setTimeMultiplier]: (state, action) => {
    return state.set("timeMultiplier", action.payload);
  },
  [actions.alignerState.closePath]: (state, action) => {
    return state.set("closePath", true).set("pointsNeedSaving", true);
  },
  [actions.alignerState.setDragModelStartPoint]: (state, action) => {
    const { x, z } = action.payload;

    return state.withMutations((state) => {
      state.set("dragModelStartX", x);
      state.set("dragModelStartZ", z);
    });
  },
  [actions.alignerState.setModelPosition]: (state,action) => {
    const { x, y, z } = action.payload;

    const xform = state.get("xform").withMutations((xform) => {
        xform.set("tx", x);
        xform.set("ty", y);
        xform.set("tz", z);
    });
    return state.set("xform", xform).set("xformNeedsSaving", true);
  },
  [actions.alignerState.setModelOrientation]: (state,action) => {
    const { x, y, z } = action.payload;

    const xform = state.get("xform");

    return state.set("xform", xform.withMutations((xform) => {
        xform.set("rx", x);
        xform.set("ry", y);
        xform.set("rz", z);
    })).set("xformNeedsSaving", true);
  },
  [actions.alignerState.dragModel]: (state, action) => {
    const { x, z } = action.payload;

    const startX = state.get("dragModelStartX");
    const startZ = state.get("dragModelStartZ");

    const xform = state.get("xform");
    const mode = state.get("transformMode");

    if(mode === TRANSLATE_MODE) { // if translate mode
      const dx = (x-startX);
      const dz = (z-startZ);

      return state.withMutations((state) => {
        state.set("xform", xform.withMutations((xform) => {
          xform.set("tx", xform.get("tx")+dx);
          xform.set("tz", xform.get("tz")+dz);
        }));
        state.set("dragModelStartX", x);
        state.set("dragModelStartZ", z);
        state.set("xformNeedsSaving", true);
      });
    } else { // if rotate mode
      let angleXZ = Math.atan2(z, x);
      let angleStartXZ = Math.atan2(startZ, startX);

      if(angleXZ < 0) {
        angleXZ += 2*Math.PI;
      }
      if(angleStartXZ < 0) {
        angleStartXZ += 2*Math.PI;
      }
      const angle = angleStartXZ-angleXZ;

      const mat = new THREE.Matrix4();
      mat.makeRotationFromEuler(new THREE.Euler(xform.get("rx")/180*Math.PI, xform.get("ry")/180*Math.PI, xform.get("rz")/180*Math.PI));
      mat.setPosition(new THREE.Vector3(xform.get("tx"), xform.get("ty"), xform.get("tz")));

      const rotMat = new THREE.Matrix4();
      rotMat.makeRotationY(angle);
      mat.premultiply(rotMat);

      const euler = new THREE.Euler();
      euler.setFromRotationMatrix(mat);

      const pos = new THREE.Vector3();
      pos.setFromMatrixPosition(mat);


      return state.withMutations((state) => {
        state.set("xform", Immutable.Map({
          s: xform.get("s"),
          rx: euler.x*180/Math.PI,
          ry: euler.y*180/Math.PI,
          rz: euler.z*180/Math.PI,
          tx: pos.x,
          ty: pos.y,
          tz: pos.z
        }))
        state.set("dragModelStartX", x);
        state.set("dragModelStartZ", z);
        state.set("xformNeedsSaving", true);
      });
    }
  },
  [actions.alignerState.setHighlightModel]: (state, action) => state.set("highlightModel", action.payload ? true : false),
  [actions.alignerState.resetState]: (state,action) => {
    return state.merge(resetState);
  },
  [actions.alignerState.setDimensions]: (state, action) => {
    const { width, height } = action.payload;
    return state.set("width", width).set("height", height);
  },
  [actions.alignerState.startRotatingMachine]: (state, action) => {
    const { x, y } = action.payload;

    return state.set("rotateStartX", x).set("rotateStartY", y).set("machineRotating", true);
  },
  [actions.alignerState.stopRotatingMachine]: (state, action) => {
    return state.set("machineRotating", false);
  },
  [actions.alignerState.rotateMachine]: (state, action) => {
    const { x, y } = action.payload;
    const rotating = state.get("machineRotating");
    if(rotating) {
      const startX = state.get("rotateStartX");
      const startY = state.get("rotateStartY");

      const width = state.get("width");
      const height = state.get("height");

      const dx = (x/width*2-1)-(startX/width*2-1);
      const dy = (y/height*2-1)-(startY/height*2-1);
      
      const A = state.get("joints").get(3)+dy*300;
      const B = state.get("joints").get(4)+dx*300;

      const points = state.get("points");
      let newPoints = points;
      let pointsNeedSaving = false;

      let joints;
      if(points.size > 0) {
        const activePoint = state.get("activePoint");
        const point = points.get(activePoint);
        const step = state.get("step");
        const toolOffsetZ = state.get("toolOffsetZ");
        const offsetZ = step === EDIT_PATH ? state.get("editingOffsetZ") : state.get("previewingOffsetZ");
        const xform = state.get("xform");

        const v = new THREE.Vector3(point.get(0), point.get(1), point.get(2)); // in model space
        const model2Dental = new THREE.Matrix4();
        model2Dental.makeRotationFromEuler(new THREE.Euler(xform.get("rx")/180*Math.PI, xform.get("ry")/180*Math.PI, xform.get("rz")/180*Math.PI));
        model2Dental.multiplyScalar(xform.get("s"));
        model2Dental.setPosition(new THREE.Vector3(xform.get("tx"), xform.get("ty"), xform.get("tz")));
        v.applyMatrix4(model2Dental); // in dental space, mm

        joints = inverseFiveAxis(Immutable.Map({
          A,
          B,
          X: v.x/25.4,
          Y: v.y/25.4,
          Z: v.z/25.4
        }), toolOffsetZ);
        joints = joints.set(2, joints.get(2)+offsetZ);

        // set active point orientation
        const dental2Model = new THREE.Matrix4();
        dental2Model.getInverse(model2Dental); 

        // direction vector in local space (local to dental fixture space)
        // We still need to convert to the loaded model's space
//        const d = new THREE.Vector4(-Math.sin(B*Math.PI/180), Math.sin(A*Math.PI/180), Math.cos(B*Math.PI/180), 0);
        const dental2World = new THREE.Matrix4();
        const dental2WorldEuler = new THREE.Euler(A*Math.PI/180, B*Math.PI/180, 0);
        dental2World.makeRotationFromEuler(dental2WorldEuler);
        const world2Dental = new THREE.Matrix4();
        world2Dental.getInverse(dental2World);

        const d = new THREE.Vector4(0,0,1,0);
        d.applyMatrix4(world2Dental);
        d.applyMatrix4(dental2Model);

        // apply inverse of xform to put d into model's space
//        d.applyMatrix4(invMat);
        d.normalize();

        newPoints = points.set(activePoint, point.set(3, d.x).set(4, d.y).set(5, d.z));
        pointsNeedSaving = true;
      } else {
        joints = state.get("joints").set(3, A).set(4, B);
      }


      return state.set("joints", joints).set("rotateStartX", x).set("rotateStartY", y).set("points", newPoints).set("pointsNeedSaving", pointsNeedSaving);
    } else {
      return state;
    }
  },
  [actions.alignerState.pointsSaved]: (state,action) => {
    return state.set("pointsNeedSaving", false);
  },
  [actions.alignerState.transformSaved]: (state,action) => {
    return state.set("xformNeedsSaving", false);
  },
  [actions.alignerState.nextPoint]: (state,action) => {
    const points = state.get("points");
    const times = state.get("times");
    const activePoint = (state.get("activePoint")+1)%points.size;
    const point = points.get(activePoint);
    const step = state.get("step");
    const toolOffsetZ =  state.get("toolOffsetZ");
    const offsetZ = step === EDIT_PATH ? state.get("editingOffsetZ") : state.get("previewingOffsetZ");
    const xform = state.get("xform");
    const joints = calculateJoints({ posx: point.get(0), 
                                     posy: point.get(1), 
                                     posz: point.get(2), 
                                     dirx: point.get(3), 
                                     diry: point.get(4), 
                                     dirz: point.get(5), 
                                     toolOffsetZ,
                                     offsetZ,
                                     xform });
    return state.set("activePoint", activePoint).set("joints", joints).set("time", times.get(activePoint));
  },
  [actions.alignerState.prevPoint]: (state,action) => {
    const times = state.get("times");
    const points = state.get("points");
    const closed = state.get("closePath");
    const currentTime = state.get("time");
    let activePoint = state.get("activePoint");
    const activePointTime = times.get(activePoint);

    if(activePointTime === currentTime) {
      if(closed) {
        activePoint = (activePoint-1+points.size)%points.size;
      } else {
        activePoint = Math.max(activePoint-1, 0);
      }
    }
    const time = times.get(activePoint);

    const point = points.get(activePoint);
    const step = state.get("step");
    const toolOffsetZ =  state.get("toolOffsetZ");
    const offsetZ = step === EDIT_PATH ? state.get("editingOffsetZ") : state.get("previewingOffsetZ");
    const xform = state.get("xform");
    const joints = calculateJoints({ posx: point.get(0), 
                                     posy: point.get(1), 
                                     posz: point.get(2), 
                                     dirx: point.get(3), 
                                     diry: point.get(4), 
                                     dirz: point.get(5), 
                                     toolOffsetZ,
                                     offsetZ,
                                     xform });
    return state.set("activePoint", activePoint).set("joints", joints).set("time", time);
  },
  [actions.alignerState.setActivePoint]: (state,action) => {
    const activePoint = action.payload;
    const points = state.get("points");
    const times = state.get("times");
    const totalTime = times.get(times.size-1);
    const point = points.get(activePoint);
    const closed = state.get("closePath");
    const step = state.get("step");
    const toolOffsetZ =  state.get("toolOffsetZ");
    const offsetZ = step === EDIT_PATH ? state.get("editingOffsetZ") : state.get("previewingOffsetZ");
    const xform = state.get("xform");
    const joints = calculateJoints({ posx: point.get(0), 
                                     posy: point.get(1), 
                                     posz: point.get(2), 
                                     dirx: point.get(3), 
                                     diry: point.get(4), 
                                     dirz: point.get(5), 
                                     toolOffsetZ,
                                     offsetZ,
                                     xform });
    return state.set("activePoint", activePoint).set("joints", joints).set("time", totalTime*activePoint/(closed ? points.size : points.size-1));
  },
  [actions.alignerState.setHighlightedPoint]: (state,action) => state.set("highlightedPoint", action.payload),
  [actions.alignerState.updatePointPosition]: (state, action) => {
    const { posx, posy, posz } = action.payload;
    const points = state.get("points");
    const activePoint = state.get("activePoint");
    const point = points.get(activePoint);

    return state.set("points", points.set(activePoint, point.withMutations((point) => {
      point.set(0, posx);
      point.set(1, posy);
      point.set(2, posz);
    }))).set("pointsNeedSaving", true);
  },
  [actions.alignerState.deletePoint]: (state,action) => {
    const activePoint = state.get("activePoint");
    const points = state.get("points");

    if(points.size > 0) {
      const closed = state.get("closePath");
      const newState = state.set("points", points.delete(activePoint)).set("activePoint", points.size === 1 ? -1 : (activePoint === 0 ? (closed ? Math.max(points.size-2, 0) : 0) : activePoint-1)).set("pointsNeedSaving", true);
      const newActivePoint = newState.get("activePoint");
      if(newActivePoint > -1) {
        const point = newState.get("points").get(newActivePoint);
        const step = state.get("step");
        const toolOffsetZ = state.get("toolOffsetZ");
        const offsetZ = step === EDIT_PATH ? state.get("editingOffsetZ") : state.get("previewingOffsetZ");
        const xform = state.get("xform");
        const joints = calculateJoints({ posx: point.get(0), 
                                         posy: point.get(1), 
                                         posz: point.get(2), 
                                         dirx: point.get(3), 
                                         diry: point.get(4), 
                                         dirz: point.get(5), 
                                         toolOffsetZ,
                                         xform,
                                         offsetZ});
        return newState.set("joints", joints);
      } else {
        return newState.set("joints", defaults.get("joints"));
      }

    } else {
      return state;
    }
  },
  [actions.alignerState.addPoint]: (state,action) => {
    const { posx, posy, posz, dirx, diry, dirz } = action.payload;
    const step = state.get("step");
    const toolOffsetZ = state.get("toolOffsetZ");
    const offsetZ = step === EDIT_PATH ? state.get("editingOffsetZ") : state.get("previewingOffsetZ");
    const xform = state.get("xform");
    const joints = calculateJoints({ ...action.payload, toolOffsetZ, offsetZ, xform });

    const activePoint = state.get("activePoint");
    const points = state.get("points");
    return state.set("points", 
      points.insert(activePoint+1, Immutable.List([ posx, posy, posz, dirx, diry, dirz ]))
    ).set("activePoint", activePoint+1).set("joints", joints).set("pointsNeedSaving", true);
  },
  [actions.alignerState.showGhostSphere]: (state, action) => state.set("showGhostSphere", true),
  [actions.alignerState.hideGhostSphere]: (state, action) => state.set("showGhostSphere", false),
  [actions.alignerState.setGhostSpherePosition]: (state, action) => state.set("ghostSpherePosition", state.get("ghostSpherePosition").withMutations((g) => g.set("x", action.payload.x).set("y", action.payload.y).set("z", action.payload.z))),
  [actions.alignerState.setGhostSphereOpacity]: (state, action) => state.set("ghostSphereOpacity", action.payload),
  [actions.alignerState.setJoints]: (state, action) => state.set("joints", Immutable.List(action.payload)),
  [actions.alignerState.setStep]: (state, action) => {
    const step = action.payload;

    let newState = state.set("step", step);
    const points = state.get("points");
    if(points.size > 0) {
      const activePoint = state.get("activePoint");
      const point = state.get("points").get(activePoint);
      const toolOffsetZ =  state.get("toolOffsetZ");
      const offsetZ = step === EDIT_PATH ? state.get("editingOffsetZ") : state.get("previewingOffsetZ");
      const xform = state.get("xform");
      const joints = calculateJoints({ posx: point.get(0), 
                                       posy: point.get(1), 
                                       posz: point.get(2), 
                                       dirx: point.get(3), 
                                       diry: point.get(4), 
                                       dirz: point.get(5), 
                                       xform,
                                       toolOffsetZ,
                                       offsetZ });
      newState = newState.set("joints", joints);
    } else {
      newState = newState.set("joints", defaults.get("joints"));
    }

    return newState;
  },
  [actions.alignerState.modelLoaded]: (state,action) => state.set("modelLoaded", true),
  [actions.alignerState.modelUploaded]: (state,action) => state.set("modelUploaded", true),
  [actions.alignerState.finalizeModel]: (state,action) => state.set("modelFinalized", true),
  [actions.alignerState.finalizeModelClick]: (state,action) => state.set("finalizeModelClicked", true),
  [actions.alignerState.clearFinalizeModelClick]: (state,action) => state.set("finalizeModelClicked", false),
  [actions.alignerState.finishedProcessing]: (state,action) => state.set("finishedProcessingModel", true),
  [actions.alignerState.importedTransformAndPoints]: (state, action) => {
    return state.withMutations((state) => {
      state.set("points", Immutable.List(action.payload.points.points.map((pt) => Immutable.List(pt))));
      if(action.payload.points.points.length > 0) {
        state.set("activePoint", 0);
      }
      state.set("closePath", action.payload.points.closed);
      state.set("xform", Immutable.Map(action.payload.xform));
    });
  },
  [actions.alignerState.importTransformAndPoints]: (state, action) => {
    return state.set("loading", state.get("loading").set("waitingForScannerToScanRFIDToImport", Immutable.Map({ label: "Use Quick Lookup to choose an aligner to import...", variant: "indeterminate" })));
  },
  [actions.alignerState.cancelImportTransformAndPoints]: (state, action) => {
    return state.set("loading", state.get("loading").delete("waitingForScannerToScanRFIDToImport"));
  },
  [actions.alignerState.loading]: (state, action) => {
    const {
      id,
      label,
      variant,
      progress
    } = action.payload;

    return state.set("loading", state.get("loading").set(id, Immutable.Map({ label, variant, progress })));
  },
  [actions.alignerState.loadingProgress]: (state,action) => {
    const {
      id,
      progress
    } = action.payload;

    const loadingState = state.get("loading");
    const spinnerState = loadingState.get(id);

    return state.set("loading", loadingState.set(id, spinnerState.set("progress", progress)));
  },
  [actions.alignerState.stopLoading]: (state,action) => {
    const {
      id
    } = action.payload;

    const loadingState = state.get("loading");

    return state.set("loading", loadingState.delete(id));
  },
  [actions.alignerState.rotateCW]: (state, action) => {
    const xform = state.get("xform");

    const mat = new THREE.Matrix4();
    mat.makeRotationFromEuler(new THREE.Euler(xform.get("rx")/180*Math.PI, xform.get("ry")/180*Math.PI, xform.get("rz")/180*Math.PI));
    mat.setPosition(new THREE.Vector3(xform.get("tx"), xform.get("ty"), xform.get("tz")));

    const rotMat = new THREE.Matrix4();
    rotMat.makeRotationY(-Math.PI/2);
    mat.premultiply(rotMat);

    const euler = new THREE.Euler();
    euler.setFromRotationMatrix(mat);

    const pos = new THREE.Vector3();
    pos.setFromMatrixPosition(mat);

    return state.set("xform", Immutable.Map({
      s: xform.get("s"),
      rx: euler.x*180/Math.PI,
      ry: euler.y*180/Math.PI,
      rz: euler.z*180/Math.PI,
      tx: pos.x,
      ty: pos.y,
      tz: pos.z
    })).set("xformNeedsSaving", true);
  },
  [actions.alignerState.rotateCCW]: (state, action) => {
    const xform = state.get("xform");

    const mat = new THREE.Matrix4();
    mat.makeRotationFromEuler(new THREE.Euler(xform.get("rx")/180*Math.PI, xform.get("ry")/180*Math.PI, xform.get("rz")/180*Math.PI));
    mat.setPosition(new THREE.Vector3(xform.get("tx"), xform.get("ty"), xform.get("tz")));

    const rotMat = new THREE.Matrix4();
    rotMat.makeRotationY(Math.PI/2);
    mat.premultiply(rotMat);

    const euler = new THREE.Euler();
    euler.setFromRotationMatrix(mat);

    const pos = new THREE.Vector3();
    pos.setFromMatrixPosition(mat);

    return state.set("xform", Immutable.Map({
      s: xform.get("s"),
      rx: euler.x*180/Math.PI,
      ry: euler.y*180/Math.PI,
      rz: euler.z*180/Math.PI,
      tx: pos.x,
      ty: pos.y,
      tz: pos.z
    })).set("xformNeedsSaving", true);

  },
  [actions.alignerState.loadFirstModelOfMultiple]: (state,action) => {
    const {
      alignerID,
      label,
      xform,
      points
    } = action.payload;

    return state.withMutations((state) => {
      state.set("alignerID", alignerID);
      state.set("label", label);
      state.set("modelFinalized", true);
      state.set("finalizeModelClicked", true);
      state.set("modelLoaded", true); // model will be a string with a signed url to fetch
      state.set("modelUploaded", true); // model will be a string with a signed url to fetch
      state.set("finishedProcessingModel", false);
      state.set("xform", Immutable.Map(xform));
      state.set("points", Immutable.List(points ? points.points : []));
      state.set("closePath", points ? points.closed : false);
      state.set("activePoint", -1);
      state.set("highlightedPoint", -1);
    });
  },
  [actions.alignerState.hideCopyPoints]: (state, action) => {
    return state.set("showCopyPoints", false);
  },
  [actions.alignerState.showCopyPoints]: (state, action) => {
    return state.set("showCopyPoints", true);
  },
  [actions.alignerState.showDownloadAllGCode]: (state, action) => {
    return state.set("showDownloadAllGCode", true);
  },
  [actions.alignerState.showDownloadAllProcessedModels]: (state, action) => {
    return state.set("showDownloadAllProcessedModels", true);
  },
  [actions.alignerState.alignerStatusResponse]: (state, action) => {
    const {
//        aligner, // is Pocket NC aligner?
//        label,   // is the label.stl file uploaded?
      model,
      xform,
      finalized,
      processedModel,
      points,
      alignerSet
    } = action.payload;

    return state.withMutations((state) => {
      state.set("modelFinalized", finalized);
      state.set("finalizeModelClicked", finalized);
      state.set("modelLoaded", false); // model will be a string with a signed url to fetch
      state.set("modelUploaded", model ? true : false); // model will be a string with a signed url to fetch
      state.set("finishedProcessingModel", processedModel);
      if(xform) {
        state.set("xform", Immutable.Map(xform));
      } else {
        state.set("xform", defaults.get("xform"));
      }
      if(points) {
        const pts = Immutable.List(points.points.map((point) => Immutable.List(point)))
        state.set("closePath", points.closed);
        state.set("points", pts);
        if(pts.size > 0) {
          state.set("activePoint", pts.size-1);
          const point = pts.get(0);
          const toolOffsetZ =  state.get("toolOffsetZ");
          const offsetZ = state.get("previewingOffsetZ");
          const xform = state.get("xform");
          const joints = calculateJoints({ posx: point.get(0), 
                                           posy: point.get(1), 
                                           posz: point.get(2), 
                                           dirx: point.get(3), 
                                           diry: point.get(4), 
                                           dirz: point.get(5), 
                                           toolOffsetZ,
                                           xform,
                                           offsetZ });
          state.set("joints", joints);
        } else {
          state.set("activePoint", -1);
        }
        state.set("highlightedPoint", -1);
      } else {
        state.set("points", Immutable.List([]));
        state.set("closePath", false);
        state.set("activePoint", -1);
        state.set("highlightedPoint", -1);
      }

      if(alignerSet) {
        const withFinalizedAndProcessedModels = alignerSet.filter((aligner) => aligner.finalized && aligner.processedModel);
        const withFinishedPoints = alignerSet.filter((aligner) => aligner.processedModel && aligner.points);
        const withPoints = alignerSet.filter((aligner) => aligner.points);

        if(withFinishedPoints.length === alignerSet.length) {
          state.set("showDownloadAllGCode", true);
        } else {
          state.set("showDownloadAllGCode", false);
        }

        if(withPoints.length === alignerSet.length || !state.get("closePath")) {
          state.set("showCopyPoints", false);
        } else {
          state.set("showCopyPoints", true);
        }

        if(withFinalizedAndProcessedModels.length === alignerSet.length) {
          state.set("showDownloadAllProcessedModels", true);
        } else {
          state.set("showDownloadAllProcessedModels", false);
        }

        const sortedAligners = [ ...alignerSet ].sort((a,b) => a.label.localeCompare(b.label, undefined, {numeric: true, sensitivity: 'base'}));
        const label = state.get("label");
        const alignerIndex = sortedAligners.findIndex((aligner) => aligner.label === label);
        if(alignerIndex <= 0) {
          state.set("previousAlignerID", "");
          state.set("previousAlignerLabel", "");
        } else {
          state.set("previousAlignerID", sortedAligners[alignerIndex-1].alignerID);
          state.set("previousAlignerLabel", sortedAligners[alignerIndex-1].label);
        }
      } else {
        state.set("showDownloadAllProcessedModels", false);
        state.set("showDownloadAllGCode", false);
        state.set("showCopyPoints", false);
        state.set("previousAlignerID", "");
      }

    });
  },
  [actions.alignerState.receivedPreviousPoints]: (state,action) => {
    const {
      points,
      closed
    } = action.payload;
    return state.set("points", Immutable.List(points.map((point) => Immutable.List(point)))).set("closePath", closed).set("pointsNeedSaving", true);
  },
  [actions.rfid.connectedScanner]: (state,action) => state.set("scannersDetected", state.get("scannersDetected")+1),
  [actions.rfid.disconnectedScanner]: (state,action) => state.set("scannersDetected", state.get("scannersDetected")-1),
  [actions.rfid.receivedRfidScanMessage]: (state,action) => {
    const {
      rfid,
      label,
      scannerKey
    } = action.payload;
    if(state.get("loading").get("waitingForScannerToScanRFIDToImport")) {
      return state;
    } else {
      return state.withMutations((s) => {
        s.set("alignerID", rfid);
        s.set("label", label);
        s.set("scannerKey", scannerKey);
      });
    }
  },
  [actions.alignerState.createdAligner]: (state,action) => {
    const {
      alignerID,
      label
    } = action.payload;

    return state.withMutations((s) => {
      s.set("alignerID", alignerID);
      s.set("label", label);
    });
  },
  [actions.alignerState.setQuickLookUpItems]: (state,action) => {
    return state.set("quickLookUpAligners", Immutable.List(action.payload));
  },
  [actions.alignerState.quickLookUp]: (state,action) => {
    if(state.get("loading").get("waitingForScannerToScanRFIDToImport")) {
      return state;
    } else {
      return state.withMutations((s) => {
        s.merge(resetState);
      });
    }
  },
  [actions.alignerState.setAligner]: (state,action) => {
    const {
      alignerID,
      label
    } = action.payload;

    if(state.get("loading").get("waitingForScannerToScanRFIDToImport")) {
      return state;
    } else {
      return state.withMutations((s) => {
        s.set("alignerID", alignerID);
        s.set("label", label);
      });
    }
  },
}, defaults);

export default reducer;
