export const OFF = 'OFF';
export const RAPID = 'RAPID';
export const LINEAR = 'LINEAR';
export const ARC_CW = 'ARC_CW';
export const ARC_CCW = 'ARC_CCW';
export const CUBIC_SPLINE = 'CUBIC_SPLINE';
export const QUADRATIC_SPLINE = 'QUADRATIC_SPLINE';
export const NURBS = 'NURBS';
export const STRAIGHT_PROBE = 'STRAIGHT_PROBE';
export const SPINDLE_SYNCHRONIZED_MOTION = 'SPINDLE_SYNCHRONIZED_MOTION';
export const RIGID_TAPPING = 'RIGID_TAPPING';
