import { connect } from 'react-redux';

import QuickLookUp from '../components/QuickLookUp';
import actions from '../actions';

export default connect(
  (state, ownProps) => ({
    disabled: state.get("alignerState").get("user").get("apiKey") ? false : true,
    aligners: state.get("alignerState").get("quickLookUpAligners").toJS()
  }),
  (dispatch, ownProps) => ({
    onSelect: (aligner) => {
      const {
        alignerID,
        label
      } = aligner;
      dispatch(actions.alignerState.setAligner(alignerID, label));
    },
    onAutocomplete: (input) => {
      dispatch(actions.alignerState.quickLookUpAutocomplete(input));
    }
  })
)(QuickLookUp);
