import now from 'performance-now';
import { connect } from 'react-redux';
import actions from '../actions';
import getAnalytics from '../util/analytics';

import ModelLoader from '../components/ModelLoader';

import Viewer3D from '../viewer3d';

const analytics = getAnalytics();

export default connect(
  (state, ownProps) => ({ 
  }),
  (dispatch, ownProps) => ({
    onLoadModel: (model, filename) => {
      const viewer = Viewer3D.getInstance();

      if(filename.endsWith(".obj")) {
        if(viewer) {
          const t0 = now();
          const decoder = new TextDecoder("utf-8");
          viewer.loadObjModel(decoder.decode(model));
          const t1 = now();

          analytics.timing("ModelLoader", "load obj", Math.round(t1-t0)).send();
          analytics.event("ModelLoader", "OBJ File Size", "OBJ File Size", model.byteLength).send();

        } else {
          console.error("viewer not loaded yet");
        }
      } else if(filename.endsWith(".stl")) {
        if(viewer) {
          const t0 = now();
          viewer.loadSTLModel(model);
          const t1 = now();

          analytics.timing("ModelLoader", "load stl", Math.round(t1-t0)).send();
          analytics.event("ModelLoader", "STL File Size", "STL File Size", model.byteLength).send();
        } else {
          console.error("viewer not loaded yet");
        }
      }
      dispatch(actions.viewer3D.loadModel())
      dispatch(actions.viewer3D.showModel())
    }
  })
)(ModelLoader);
