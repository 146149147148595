import { OFF, CW, CCW } from '../../constants/machine-state/spindle-control';
import actions from '../../actions/machine-state/spindle-control';
import { handleActions } from 'redux-actions';

const reducer = handleActions(
  {
    [actions.machineState.spindleControl.off]: () => OFF,
    [actions.machineState.spindleControl.ccw]: () => CCW,
    [actions.machineState.spindleControl.cw]: () => CW
  },
  OFF
);

export default reducer;
