import { createActions } from 'redux-actions';

export default createActions({
  ALIGNER_STATE: {
    PURCHASE_CREDITS: undefined,
    OPEN_MY_ACCOUNT: undefined,
    CLOSE_MY_ACCOUNT: undefined,
    RECEIVED_PREVIOUS_POINTS: undefined,
    IMPORT_PREVIOUS_POINTS: undefined,
    CHECK_FOR_POINTS_ON_OTHER_ALIGNERS: undefined,
    SHOW_COPY_POINTS: undefined,
    HIDE_COPY_POINTS: undefined,
    SHOW_DOWNLOAD_ALL_G_CODE: undefined,
    SHOW_DOWNLOAD_ALL_PROCESSED_MODELS: undefined,
    COPY_POINTS: undefined,
    DOWNLOAD_ALL_G_CODE: undefined,
    DOWNLOAD_ALL_PROCESSED_MODELS: undefined,
    SET_WARN_ON_EXIT: undefined,
    LOAD_FIRST_MODEL_OF_MULTIPLE: undefined,
    CANCEL_DRAW_PATH: undefined,
    SET_DRAW_MODE: undefined,
    INITIALIZE_DRAW_PATH: undefined,
    ADD_POINT_TO_DRAW_PATH: undefined,
    SIMPLIFY_DRAW_PATH: undefined,
    SET_ROTATE_MODE: undefined,
    SET_TRANSLATE_MODE: undefined,
    SET_STEP: undefined,
    MODEL_LOADED: undefined,
    MODEL_UPLOADED: undefined,
    FINISHED_PROCESSING: undefined,
    FINALIZE_MODEL: undefined,
    FINALIZE_MODEL_CLICK: undefined,
    CLEAR_FINALIZE_MODEL_CLICK: undefined,
    UPLOAD_MODEL: undefined,
    LOADING: (id, label, variant, progress) => ({ id, label, variant, progress }),
    LOADING_PROGRESS: (id, progress) => ({ id, progress }),
    STOP_LOADING: (id) => ({ id }),
    ALIGNER_STATUS_RESPONSE: undefined,
    DOWNLOAD_PROCESSED_MODEL: undefined,
    DOWNLOAD_G_CODE: undefined,
    SET_SNAP_FIT: undefined,
    SET_JOINTS: undefined,
    RESET_TO_DEFAULTS: undefined,
    SHOW_GHOST_SPHERE: undefined,
    HIDE_GHOST_SPHERE: undefined,
    SET_GHOST_SPHERE_OPACITY: undefined,
    SET_GHOST_SPHERE_POSITION: (x, y, z) => ({ x, y, z }),
    ADD_POINT: (posx,posy,posz,dirx,diry,dirz) => ({ posx, posy, posz, dirx, diry, dirz }),
    UPDATE_POINT_POSITION: (posx,posy,posz) => ({ posx, posy, posz }),
    DELETE_POINT: undefined,
    CLOSE_PATH: undefined, 
    SET_ACTIVE_POINT: undefined,
    NEXT_POINT: undefined,
    PREV_POINT: undefined,
    INCREMENT_TIME: undefined,
    PLAY: undefined,
    PAUSE: undefined,
    SET_TIME_MULTIPLIER: undefined,
    SET_TIME_WITH_PERCENTAGE: undefined,
    SET_HIGHLIGHTED_POINT: undefined,
    START_ROTATING_MACHINE: (x, y) => ({ x, y }),
    ROTATE_MACHINE: (x, y) => ({ x, y }),
    STOP_ROTATING_MACHINE: undefined,
    SET_DIMENSIONS: (width, height) => ({ width, height }),
    SAVE_TRANSFORM: undefined,
    TRANSFORM_SAVED: undefined,
    SAVE_POINTS: undefined,
    POINTS_SAVED: undefined,
    RESET_STATE: undefined,
    SET_HIGHLIGHT_MODEL: undefined,
    SET_DRAG_MODEL_START_POINT: (x,z) => ({ x, z }),
    DRAG_MODEL: (x,z) => ({x, z}),
    SET_MODEL_ORIENTATION: (x,y,z) => ({x, y, z}),
    SET_MODEL_POSITION: (x,y,z) => ({x, y, z}),
    ROTATE_C_W: undefined,
    ROTATE_C_C_W: undefined,
    SET_MACHINE: undefined,
    IMPORT_TRANSFORM_AND_POINTS: undefined,
    CANCEL_IMPORT_TRANSFORM_AND_POINTS: undefined,
    IMPORTED_TRANSFORM_AND_POINTS: undefined,
    SET_TIMES: undefined,
    SET_TOOL_OFFSET_Z: undefined,
    SET_ADD_BRIDGE: undefined,
    SET_ADD_CYLINDERS: undefined,
    SET_USER_INFO: undefined,
    QUICK_LOOK_UP: undefined,
    QUICK_LOOK_UP_AUTOCOMPLETE: undefined,
    SET_QUICK_LOOK_UP_ITEMS: undefined,
    SET_ALIGNER: (alignerID,label) => ({ alignerID, label }),
    CREATE_ALIGNER: (code,order) => ({ code, order }),
    CREATED_ALIGNER: (alignerID,label) => ({ alignerID, label }),
    CREATE_MULTIPLE_ALIGNERS: undefined,
    CREATE_ALIGNER_DIALOG: {
      SET_POSITIONING_MODEL: undefined,
      SET_MULTI: undefined,
      SAVE_XFORM: undefined,
      OPEN: undefined,
      CLOSE: undefined,
      SET_FILES: undefined,
      SET_ERROR: undefined,
      SET_ALIGNER_NUMBER: undefined,
      SET_CHECKSUM: undefined,
      SET_CODE: undefined,
      SET_FIRST_MODEL_CHECKSUM: undefined
    }
  }
});
