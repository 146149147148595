import { connect } from 'react-redux';

import DemoWarning from '../components/DemoWarning';

import { LOAD_MODEL } from '../constants/aligner-state';

export default connect(
  (state, ownProps) => {
    return {
      showWarning: !(state.get("alignerState").get("alignerID")) && state.get("alignerState").get("step") !== LOAD_MODEL
    };
  },
  (dispatch, ownProps) => ({
  })
)(DemoWarning);
