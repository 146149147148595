import { FIVE_AXIS } from '../constants/machine-state/kinematics-mode';
import Immutable from 'immutable';
import * as messages from '../util/messages';

export const toolOrientation = (joints) => {
  const A = joints.get(3);
  const B = joints.get(4);

  const Arad = A*Math.PI/180;
  const Brad = B*Math.PI/180;

  const CA = Math.cos(Arad);
  const SA = Math.sin(Arad);
  const CB = Math.cos(Brad);
  const SB = Math.sin(Brad);

  return Immutable.Map({ X: -SB*CA, Y: SA, Z: CB*CA }); 
};

export const workPieceSpace = (joints, toolLengthOffset) => {
//  console.log(joints.toJS(), joints.set(2, joints.get(2)+.01).toJS());
//  return forwardFiveAxis(joints.set(2, joints.get(2)+.004), toolLengthOffset);
  return forwardFiveAxis(joints.set(2, joints.get(2)), toolLengthOffset);
};

export const forwardFiveAxis = (joints, toolLengthOffset) => {
  const A = joints.get(3);
  const B = joints.get(4);

  const Arad = A*Math.PI/180;
  const Brad = B*Math.PI/180;

  const CA = Math.cos(Arad);
  const SA = Math.sin(Arad);
  const CB = Math.cos(Brad);
  const SB = Math.sin(Brad);

  const Lz = toolLengthOffset;

  const Px = joints.get(0);
  const Py = joints.get(1);
  const Pz = joints.get(2);

  const Qx = Px*CB+Py*SB*SA-(Pz-Lz)*SB*CA;
  const Qy = Py*CA+(Pz-Lz)*SA;
  const Qz = Px*SB-Py*CB*SA+(Pz-Lz)*CB*CA;

  return Immutable.Map({ 
    X: Qx, 
    Y: Qy, 
    Z: Qz, 
    A: A, 
    B: B 
  });
};

export const inverseFiveAxis = (position, toolLengthOffset) => {
  const A = position.get("A");
  const B = position.get("B");

  const Arad = A*Math.PI/180;
  const Brad = B*Math.PI/180;

  const CA = Math.cos(Arad);
  const SA = Math.sin(Arad);
  const CB = Math.cos(Brad);
  const SB = Math.sin(Brad);

  const Lz = toolLengthOffset;

  const Qx = position.get("X");
  const Qy = position.get("Y");
  const Qz = position.get("Z");

  const Px = Qx*CB+Qz*SB;
  const Py = Qx*SB*SA+Qy*CA-Qz*CB*SA;
  const Pz = -Qx*SB*CA+Qy*SA+Qz*CB*CA+Lz;

  return Immutable.List([ Px, Py, Pz, A, B ]);
};

export const kinematics = {
  inverseKinematics: (machineState, machineSpace) => {
    const kinematicsMode = machineState.get("kinematicsMode");

    let toolLengthOffset = 0;

    if(!machineSpace) {
      toolLengthOffset = machineState.get("tool").get("toolLengthOffset").get("Z");
    }

    const position = machineState.get("motion").get("position");

    if(kinematicsMode === FIVE_AXIS) {
      return inverseFiveAxis(position, toolLengthOffset);
    } else {
      return Immutable.List([ position.get("X"), position.get("Y"), position.get("Z")+toolLengthOffset, position.get("A"), position.get("B") ]);
    }
  },
  forwardKinematics: (machineState, machineSpace) => {
    const kinematicsMode = machineState.get("kinematicsMode");
    const toolLengthOffset = machineSpace ? 0 : machineState.get("tool").get("toolLengthOffset").get("Z");
    const joints = machineState.get("joints");

    if(kinematicsMode === FIVE_AXIS) {
      return forwardFiveAxis(joints, toolLengthOffset);
    } else {
      return Immutable.Map({ 
        X: joints.get(0), 
        Y: joints.get(1), 
        Z: joints.get(2)-toolLengthOffset, 
        A: joints.get(3), 
        B: joints.get(4) 
      });
    }
  }
};

export const limits = {
  extents: {
    max: [ 2.55, 2.55, 0.1, 135, 9999 ],
    min: [ -2.0, -2.5, -3.45, -25, -9999]
  },
  // x,y,z in inches/second
  // a,b in degrees/second
  velocities: [ 1, 1, 1, 37.5, 37.5 ],
  accelerations: [ 10, 10, 10, 300, 300 ]
};

export const jointLabels = [ "X", "Y", "Z", "A", "B" ];

export const getLimitErrors = (joints) => {
  const errors = [];
  for(var i = 0; i < joints.size; i++) {
    const joint = joints.get(i);
    if(joint < limits.extents.min[i]) {
      // suggestion for z
      // suggestion: "lengthen tool by " + Math.abs(joint-limits.extents.min[i])
      errors.push(messages.MinimumLimitError(jointLabels[i], Math.abs(joint-limits.extents.min[i])));
    } else if(joint > limits.extents.max[i]) {
      // suggestsion for z
      // suggestion: "shorten tool by " + Math.abs(joint-limits.extends.max[i])
      errors.push(messages.MaximumLimitError(jointLabels[i], Math.abs(joint-limits.extents.max[i])));
    }
  }

  return errors.length > 0 ? errors : [];
};
