import React, { useCallback, useRef } from 'react';
import MultiAlignerTable from './MultiAlignerTable';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import STLZipFileLoader from './STLZipFileLoader';

import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  appBar: {
    backgroundColor: "#333333"
  },
  smallButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
    minHeight: 40,
    maxHeight: 40,
    minWidth: 40,
    maxWidth: 40,
    margin: 10,
    padding: 8
  },
  error: {
    color: "red",
    fontWeight: "bold"
  },
  buttonLabel: {
    width: 20,
    height: 20
  }
});

export default withStyles(styles)((props) => {
  const {
    classes,
    isPrimary,
    isVisible,
    code,
    multi,
    onCreateAligner,
    files = [],
    fileMapping = {},
    onChangeAlignerNumber,
    onLoadZip,
    xform,
    checksum,
    firstModelChecksum,
    onSetPosition,
    positioningModel,
    open,
    onOpen,
    onClose,
    onSetMulti,
    onError,
    error,
    onCreateMultipleAligners,
    onCodeChange,
    addBridge,
    addCylinders,
    snapFit,
    points
  } = props;

  const handleTabChange = useCallback((e, v) => onSetMulti(v === 0), [ onSetMulti ]);
  const codeRef = useRef(null);
  const orderRef = useRef(null);

  const handleCreateMultipleAligners = useCallback(() => {
    const mapping = {};
    Object.keys(fileMapping).forEach((file) => {
      mapping[file] = code + "-" + fileMapping[file];
    });
    const firstLabel = mapping[files[0]];
    onCreateMultipleAligners({
      firstLabel,
      mapping,
      xform: xform.toJS(),
      checksum,
      firstModelChecksum,
      addBridge,
      addCylinders,
      snapFit,
      points: points && points.toJS()
    });
    onClose();
  }, [ points, files, firstModelChecksum, addBridge, addCylinders, checksum, xform, fileMapping, code, onCreateMultipleAligners, onClose, snapFit ]);

  const handleChange = useCallback((e) => {
    if(onCodeChange) {
      onCodeChange(e.target.value);
    }
  }, [ onCodeChange ]);

  const fixCode = (code) => code.toUpperCase().replace(/[-_]/g, "");
  const handleSingleChange = useCallback(() => {
    codeRef.current.value = fixCode(codeRef.current.value);
    orderRef.current.value = fixCode(orderRef.current.value);
  }, [ codeRef, orderRef ]);
  const handleCreateAligner = useCallback(() => {
    onCreateAligner(fixCode(codeRef.current.value), fixCode(orderRef.current.value)); 
    onClose(); 
  }, [ onCreateAligner, onClose ]);

  return (
  <>
  { isVisible ?
    <Tooltip title="New Aligner">
    <Button classes={ { sizeSmall: classes.smallButton, label: classes.buttonLabel } } variant="fab" size="small" color={ isPrimary ? "primary" : "default" } onClick={ onOpen }><AddIcon/></Button>
    </Tooltip>
    : null }
    <Dialog onClose={onClose} open={open && !positioningModel} maxWidth="lg">
      <AppBar className={classes.appBar} position="static">
        <Tabs variant="fullWidth"
              indicatorColor="primary"
              onChange={handleTabChange}
              value={multi ? 0 : 1}>
          <Tab label="Multiple Aligners"/> 
          <Tab label="Single Aligner"/>
        </Tabs>
      </AppBar>
      <div style={{display: multi ? "" : "none" }}>
        <DialogContent>
          <DialogContentText>Create multiple aligners from a zip file of STL files.</DialogContentText>
          <TextField label="Aligner Set Code" autoFocus margin="dense" value={code} onChange={handleChange}/>
          <STLZipFileLoader label="Zip File of STL models" onLoadZip={onLoadZip} onError={onError}/>
          <br/>
          { error ? <div className={classes.error}>{error}</div> : null }
          <MultiAlignerTable files={files} mapping={fileMapping} onChangeAlignerNumber={onChangeAlignerNumber}/>
        </DialogContent>
      <DialogActions>
        { xform ?
          ( files.length > 1 ? (<Button color="primary" onClick={handleCreateMultipleAligners}>Create and Finalize {files.length} { files.length !== 1 ? "Aligners" : "Aligner"}</Button>) : null )
          :
          null
        }
        { files.length > 1 ? (<Button color={ xform ? undefined : "primary" } onClick={onSetPosition}>Set Position</Button>) : null }

        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
      </div>
      <div style={{display: !multi ? "" : "none" }}>
        <DialogContent>
          <DialogContentText>Create a single aligner</DialogContentText>
          <TextField label="Aligner Set Code" autoFocus margin="dense" inputRef={codeRef} onChange={handleSingleChange}/>
          <TextField label="Aligner Number" margin="dense" inputRef={orderRef} onChange={handleSingleChange}/>
          <DialogActions>
            <Button onClick={handleCreateAligner} color="primary">Create</Button>
            <Button onClick={onClose}>Cancel</Button>
          </DialogActions>
        </DialogContent>
      </div>
    </Dialog>
  </>
  );
});
