import { createActions } from 'redux-actions';

const actions = createActions({
  MACHINE_STATE: {
    CUTTER_COMPENSATION: {
      OFF: undefined
      // TODO - cutter compensation left/right
      // TODO - dynamic cutter compensation left/right
    }
  }
});

export default actions;
