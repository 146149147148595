import { createActions } from 'redux-actions';

const actions = createActions({
  MACHINE_STATE: {
    SPINDLE_SPEED: {
      SET: undefined
    }
  }
});

export default actions;
