import { createActions } from 'redux-actions';

const actions = createActions({
  IFRAME: {
    INITIALIZE: undefined,
    ROCKHOPPER: undefined,
    TO_ROCKHOPPER: undefined,
    EMBED_GCODE: undefined
  }
});

export default actions;
