import { combineReducers } from 'redux-immutable';
import reduceReducers from 'reduce-reducers';
import machineState, { machineStateWithState } from './machine-state';
import interpreter from './interpreter';
import viewer3D from './viewer3d';
import ui from './ui';
import alignerState from './aligner-state';
import errors from './errors';

const reducer = combineReducers({
  machineState,
  alignerState,
  interpreter,
  ui,
  viewer3D,
  errors
});

export default reduceReducers(reducer,
  (state, action) => state.set("machineState", machineStateWithState(state)(state.get("machineState"), action))
);
