import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FileSelectButton from './FileSelectButton';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import now from 'performance-now';
import getAnalytics from '../util/analytics';
const analytics = getAnalytics();

class GCodeLoader extends Component {
  handleFileSelect = (f) => {
    const {
      onLoading,
      onSetFileName
    } = this.props;


    this.fileSize = f.size;
    this.fileName = f.name;
    this.read = 0;
    this.lines = [];
    this.buffer = "";

    if(onSetFileName) {
      onSetFileName(f.name);
    }

    analytics.event("GCodeLoader", "GCode File Size", "GCode File Size", this.fileSize).send(); 
    this.startTime = now();

    if(onLoading) {
      onLoading();
    }
  };

  handleFinished = () => {
    const {
      onFinished
    } = this.props;

    this.stopReadingFile = false;

    if(onFinished) {
      onFinished();
    }
    analytics.timing("GCodeLoader", "loaded gcode file", Math.round(now()-this.startTime)).send();
  };

  handleRead = (chunk, size) => {
    const {
      onProgress,
      onLines,
      onError
    } = this.props;

    this.read += size;
    const progress = Math.min(this.read/this.fileSize*100, 100);
    const data = this.buffer + chunk;

    const lines = data.split("\n");
    this.buffer = lines.pop();

    this.lines.push(...lines.map((line) => line.replace(/\r/, "")));

    if(onError && this.lines.length > 2000000) {
      this.stopReadingFile = true;
      onLines(this.lines);
      onError(new Error("GCode program exceeds 2 million lines. Program truncated to " + this.lines.length + " lines."));
    }

    if(onProgress) {
      onProgress(progress);
    }
    if(onLines) {
      if(this.read === size) { 
        // set lines on first chunk and final chunk
        onLines(this.lines.slice(), true);
      } else if(this.read >= this.fileSize) {
        onLines(this.lines);
        analytics.event("GCodeLoader", "Lines In GCode Program", "Lines In GCode Program", this.lines.length).send(); 
      }
    }

  };

  stopReading = () => {
    return this.stopReadingFile;
  };

  render() {
    return (
      <FileSelectButton onFileSelect={this.handleFileSelect}
                        onFinished={this.handleFinished}
                        onRead={this.handleRead}
                        stopReading={this.stopReading}
                        chunkSize={64*1024}
                        size="small"
                        accept=".ngc, .nc"
                        color="inherit">
        <OpenInBrowserIcon/> GCode
      </FileSelectButton>
    );
  }
};

GCodeLoader.propTypes = {
  onProgress: PropTypes.func,
  onLines: PropTypes.func,
  onError: PropTypes.func,
  stopReading: PropTypes.func
};

export default GCodeLoader;
