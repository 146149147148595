import { connect } from 'react-redux';

import AlignerLoadingPanel from '../components/AlignerLoadingPanel';

export default connect(
  (state, ownProps) => ({
    loading: state.get("alignerState").get("loading"),
    gettingStatus: state.get("alignerState").get("gettingStatus")
  }),
  (dispatch, ownProps) => ({
  })
)(AlignerLoadingPanel);
