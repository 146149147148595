import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/CameraAlt';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import { BASE, A_AXIS, B_AXIS, Y_AXIS, TOOL, DENTAL_FIXTURE } from '../constants/viewer3d/camera-parent';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import getAnalytics from '../util/analytics';

const analytics = getAnalytics();

const styles = (theme) => ({
  button: {
    width: "30px",
    height: "30px",
    minHeight: "30px",
    opacity: .25,
    transition: "opacity .5s"
  },
  extendedButton: {
    width: "110px",
    height: "30px",
    minHeight: "30px",
    opacity: .25,
    transition: "opacity .5s",
    textTransform: "none"
  },
  marginLeft: {
    marginLeft: "10px"
  },
  active: {
    opacity: 1
  },
  marginTop: {
    marginTop: "5px"
  },
  cameraButtonsNoTitleBar: {
    position: "absolute",
    right: "20px",
    top: "20px",
    textAlign: "right"
  },
  cameraButtons: {
    position: "absolute",
    right: "20px",
    top: "85px",
    textAlign: "right"
  },
});

class CameraButtons extends Component {
  constructor() {
    super();

    this.state = {
      parentMenuOpen: false,
      perspectiveMenuOpen: false,
      active: false,
      parentAnchorEl: null,
      perspectiveAnchorEl: null
    };
  }

  handleClickOutOfMenu = () => {
    this.setState({ parentMenuOpen: false,
                    perspectiveMenuOpen: false });
  }

  handleMouseOver = () => {
    this.setState({ active: true });
  }
  handleMouseOut = () => {
    this.setState({ active: false });
  }
  toggleParentMenu = (e) => {
    this.setState(((anchor) => (prevState) => ({ parentMenuOpen: !prevState.parentMenuOpen, parentAnchorEl: anchor }))(e.currentTarget));
  }
  togglePerspectiveMenu = (e) => {
    this.setState(((anchor) => (prevState) => ({ perspectiveMenuOpen: !prevState.perspectiveMenuOpen, perspectiveAnchorEl: anchor }))(e.currentTarget));
  }
  handlePerspective = () => {
    const {
      setPerspective
    } = this.props;
    this.setState({ perspectiveMenuOpen: false });

    analytics.event("CameraButtons", "set perspective").send();

    setPerspective();
  }
  handleOrthographic = () => {
    const {
      setOrthographic
    } = this.props;
    this.setState({ perspectiveMenuOpen: false });

    analytics.event("CameraButtons", "set orthographic").send();

    setOrthographic();
  }
  handleParentCamera = (parent) => {
    const {
      parentCamera
    } = this.props;

    analytics.event("CameraButtons", "parent camera to " + parent).send();

    parentCamera(parent);
    this.setState({ parentMenuOpen: false });
  }
  render() {
    const {
      classes,
      hideTitleBar,
      showDentalFixture
    } = this.props;
    const {
      parentMenuOpen,
      perspectiveMenuOpen,
      active,
      parentAnchorEl,
      perspectiveAnchorEl
    } = this.state;
    return (<div className={hideTitleBar ? classes.cameraButtonsNoTitleBar : classes.cameraButtons}>
      <Button variant="fab" className={classnames(classes.button, { [classes.active]: active })} 
        onMouseOver={this.handleMouseOver} 
        onMouseOut={this.handleMouseOut}
        onClick={this.toggleParentMenu}>
        <GpsFixedIcon/>
      </Button><br/>
      <Button variant="fab" className={classnames(classes.button, classes.marginTop, { [classes.active]: active })} 
        onMouseOver={this.handleMouseOver} 
        onMouseOut={this.handleMouseOut}
        onClick={this.togglePerspectiveMenu}>
        <CameraIcon/>
      </Button><br/>
      <Menu open={parentMenuOpen} anchorEl={parentAnchorEl} onClose={this.handleClickOutOfMenu}>
        <MenuItem onClick={() => this.handleParentCamera(BASE)}>Base</MenuItem>
        <MenuItem onClick={() => this.handleParentCamera(Y_AXIS)}>Y</MenuItem>
        <MenuItem onClick={() => this.handleParentCamera(A_AXIS)}>A</MenuItem>
        <MenuItem onClick={() => this.handleParentCamera(B_AXIS)}>B</MenuItem>
        <MenuItem onClick={() => this.handleParentCamera(TOOL)}>Tool</MenuItem>
        { showDentalFixture ? 
        <MenuItem onClick={() => this.handleParentCamera(DENTAL_FIXTURE)}>Dental Fixture</MenuItem>
        : null }
      </Menu>
      <Menu open={perspectiveMenuOpen} anchorEl={perspectiveAnchorEl} onClose={this.handleClickOutOfMenu}>
        <MenuItem onClick={() => this.handlePerspective()}>Perspective</MenuItem>
        <MenuItem onClick={() => this.handleOrthographic()}>Orthographic</MenuItem>
      </Menu>
    </div>);
  }
};

export default withStyles(styles)(CameraButtons);
