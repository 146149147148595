import THREE from '../viewer3d/three';
import { EPS } from '../constants';

export const computeRotationToOrientDown = (planeNormal, down) => {
  if(!down) {
    down = new THREE.Vector3(0,-1,0);
  }
  const axis = planeNormal.clone().cross(down);
  const angle = Math.acos(planeNormal.dot(down));

  if(axis.length() < EPS) {
    axis.x = 0;
    axis.y = 0;
    axis.z = 1;
  } else {
    axis.normalize();
  }

  const quat = new THREE.Quaternion();
  quat.setFromAxisAngle(axis, angle);

  return quat;
};
