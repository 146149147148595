import { lookUpCommandDescription } from '../gcode/commands';
import * as types from '../constants/messages';

export function MultipleCommandsInModalGroup(codes, group) {
  const message = "Can't run multiple commands in the same modal group on the same line. Codes " + codes.slice(0,codes.length-1).join(", ") + " and " + codes[codes.length-1] + " are in modal group " + group + ".";
  return {
    message,
    codes,
    group,
    type: types.MULTIPLE_COMMANDS_IN_MODAL_GROUP,
    severity: types.ERROR
  };
};

export function MultipleCommandsWithPositionalArgs(codes) {
  const message = "Can't run multiple commands with positional arguments on the same line. Codes " + codes.slice(0,codes.length-1).join(", ") + " and " + codes[codes.length-1] + " use positional arguments.";
  return {
    message,
    codes,
    type: types.MULTIPLE_COMMANDS_WITH_POSITIONAL_ARGS,
    severity: types.ERROR
  };
};

export function MinimumLimitError(axis, amount) {
  const message = axis + " axis exceeded minimum limit by " + amount.toFixed(4) + ".";
  return {
    message,
    axis,
    amount,
    type: types.MINIMUM_LIMIT,
    severity: types.ERROR
  }
}

export function MaximumLimitError(axis, amount) {
  const message = axis + " axis exceeded maximum limit by " + amount.toFixed(4) + ".";
  return {
    message,
    axis,
    amount,
    type: types.MAXIMUM_LIMIT,
    severity: types.ERROR
  }
}

export function UnimplementedCode(code) {
  const description = lookUpCommandDescription(code);
  const message = "Ignoring " + code + " as it is currently unimplemented in the simulator. Intended usage: " + description;
  return {
    message,
    description,
    severity: types.WARNING,
    type: types.UNIMPLEMENTED,
    code
  };
};

export function UnrecognizedCode(code) {
  const message = "Unrecognized G or M code: " + code;
  return {
    message,
    severity: types.ERROR,
    type: types.UNRECOGNIZED,
    code
  };
};

export function ZeroFeedRate() {
  const message = "Feed rate must be greater than 0 for linear or arc moves."
  return {
    message,
    severity: types.ERROR,
    type: types.ZERO_FEED_RATE
  };
};

// This message occurs when a G43 command is interpretted, indicating a tool change. M6 is actually
// the tool change command, but since G43 is what affects the tool length offset, it's ultimately
// what determines what was actually loaded.  It is good practice, when using LinuxCNC/MachineKit
// to use G43 without an argument to ensure the M6 and G43 agree.
// toolNumber is the number set in GCode i.e. G43 H[toolNumber]
// A G43 without a toolNumber argument will use the toolNumber of the last tool loaded with M6.
export function SetToolLengthOffset(toolNumber, toolLengthOffset, toolDiameter, toolHolder) {
  const message = "Set tool length offset to " + toolLengthOffset.toFixed(4) + " which is what is stored under tool " + toolNumber + ".";
  return {
    message,
    toolNumber,
    toolLengthOffset,
    toolDiameter,
    toolHolder,
    severity: types.INFO,
    type: types.SET_TOOL_LENGTH_OFFSET
  };
}

// M6 is the load tool command, which could accept a different tool than the one set
// in a following G43 command. G43 is what ultimately affects how the machine behaves,
// but the simulator uses what was loaded with M6 to determine which tool is loaded, so
// if they differ, you may get unexpected results.
export function ToolLengthOffsetCommandDiffersFromLoadedTool(g43ToolNum, m6ToolNum) {
  const message = "Tool specified by G43, T" + g43ToolNum + ", differs from last tool loaded with M6, T" + m6ToolNum + ". The simulator uses the last tool loaded with M6 to determine which tool to show, so your tool path may vary from what you would expect.";
  return {
    message,
    g43ToolNum,
    m6ToolNum,
    severity: types.WARNING,
    type: types.TOOL_LENGTH_OFFSET_COMMAND_DIFFERS_FROM_LOADED_TOOL
  };
}

export function AddLineNumber(message, line) {
  return {
    ...message,
    line
  }
}

