import actions from '../../actions/ui';
import { handleActions } from 'redux-actions';
import Immutable from 'immutable';

const reducer = handleActions(
  {
    [actions.ui.gcodePane.setWidth]: (state, action) => state.set("width", action.payload),
    [actions.ui.gcodePane.setSelected]: (state, action) => state.set("selectedLine", action.payload),
    [actions.ui.gcodePane.setLineOffset]: (state, action) => state.set("lineOffset", action.payload),
    [actions.ui.gcodePane.setShowLines]: (state, action) => state.set("showLines", action.payload),
    [actions.ui.gcodePane.scroll]: (state, action) => state.set("lineOffset", state.get("lineOffset")+action.payload),
    [actions.ui.gcodePane.setTab]: (state, action) => state.set("currentTab", action.payload),

    [actions.ui.gcodePane.togglePane]: (state, action) => state.set("visible", !state.get("visible"))
  },
  Immutable.Map({
    width: 320,
    visible: true,
    lineOffset: 0,
    showLines: 50,
    selectedLine: 0,
    currentTab: 0
  })
);

export default reducer;
