import { createActions } from 'redux-actions';

export default createActions({
  INTERPRETER: {
    SET_FILE_NAME: undefined,
    START_LOADING: undefined,
    END_LOADING: undefined,
    SET_LOADING_PROGRESS: undefined,
    SET_LINES: (lines, preview) => ({ lines, preview }),
    START_PROCESSING: undefined,
    END_PROCESSING: undefined,
    SET_PROCESSING_PROGRESS: undefined,
    SET_INITIAL_STATE: undefined,
    APPEND_INDICES: undefined,
    APPEND_TIMES: undefined,
    APPEND_MESSAGES: undefined,

// actions that trigger machineState updates 
// new ones must be added in reducers/machine-state/index.js
    INCREMENT_TIME: undefined,
    SET_CURRENT_LINE_WITH_PERCENTAGE: undefined,
    SET_CURRENT_LINE_WITH_TIME: undefined,
    SET_CURRENT_LINE: undefined,
    NEXT_LINE: undefined,
    PREVIOUS_LINE: undefined,

    PAUSE: undefined,
    UNPAUSE: undefined,
    RUN: undefined,
    STOP: undefined,
    SET_TIME_MULTIPLIER: undefined
  }
});
