import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FileSelectButton from './FileSelectButton';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import { withStyles } from '@material-ui/core/styles';
import jszip from 'jszip';

const styles = (theme) => ({
  zipButton: {
    marginTop: 15
  },
  center: {
    textAlign: "center"
  },
  leftcell: {
    width: "60%"
  },
  rightcell: {
    width: "40%"
  },
  evenline: {
    backgroundColor: "#fafafa"
  },
  oddline: {
    backgroundColor: "white"
  },
  hidden: {
    display: "none"
  },
  filesContainer: {
    maxHeight: 125,
    overflow: "auto"
  }
});

class STLZipFileLoader extends Component {
  handleFileSelect = (f) => {
    const {
      binary,
      onLoading
    } = this.props;

    this.fileSize = f.size;
    this.fileName = f.name;
    if(binary) {
      this.read = 0;
      this.buffer = "";
    } else {
      this.data = undefined;
      this.buffer = undefined;
      this.offset = 0;
    }

    if(onLoading) {
      onLoading();
    }
  };

  handleFinished = () => {
    const {
      onLoadZip,
      maxIndividualSize = 30*1024*1024,
      maxTotalSize = 500*1024*1024,
      onError
    } = this.props;

    this.stopReadingFile = false;

    if(this.buffer.byteLength > maxTotalSize) {
      if(onError) {
        onError("Zip file size is greater than the limit of " + (maxTotalSize/1024/1024) + "MB.");
      }
    } else {
      jszip.loadAsync(this.buffer).then((zip) => {
        const files = [];
        let errors = [];
        zip.forEach((relativePath, file) => {
          if(file.dir || relativePath.startsWith("__MACOSX") || !relativePath.toLowerCase().endsWith(".stl")) {
            // skip
            return;
          }
          if(file._data && file._data.uncompressedSize && file._data.uncompressedSize > maxIndividualSize) {
            errors.push({ message: "Size of \"" + relativePath + "\" exceeds individual file size limit of " + (maxIndividualSize/1024/1024) + "MB." });
          }

          files.push(relativePath);
        });

        if(errors.length > 0) {
          if(onError) {
            onError(errors[0].message);
          }
          return;
        }
        // this is natural sort, so ABC-10 would sort after ABC-2
        files.sort((a,b) => a.localeCompare(b, undefined, {numeric: true, sensitivity: 'base'}));
        const mapping = {};
        const claimedCodes = {};
        files.forEach((file, index) => {
          const matches = file.match(/(\d+)/g);
          const code = matches && ("" + parseInt(matches[matches.length-1]));
          if(code && !claimedCodes[code]) {
            mapping[file] = code;
            claimedCodes[code] = true;
          } else {
            const indexCode = "A" + index;
            mapping[file] = indexCode;
            claimedCodes[indexCode] = true;
          }
        });
        if(onLoadZip) {
          onLoadZip(zip, files, mapping, new Uint8Array(this.buffer));
        }
      });
    }
  };

  handleRead = (chunk, size, totalSize) => {
    const {
      onProgress,
      onError
    } = this.props;

    if(!this.data) {
      this.buffer = new ArrayBuffer(totalSize);
      this.data = new Uint8Array(this.buffer);
      this.offset = 0;
    }

    try {
      this.data.set(new Uint8Array(chunk), this.offset);
    } catch(err) {
      if(onError) {
        onError(new Error("Error reading data chunk: " + err.message + ", " + this.offset));
      }
    }

    this.offset += size;
    const progress = Math.min(this.offset/this.fileSize*100, 100);

    if(onProgress) {
      onProgress(progress);
    }
  };

  stopReading = () => {
    return this.stopReadingFile;
  };

  render() {
    const {
      classes,
      hideButton,
      iconOnly,
      labelOnly,
      variant,
      size,
      color,
      label = "Model"
    } = this.props;
    return (
    <>
    { hideButton ? null :
      <FileSelectButton onFileSelect={this.handleFileSelect}
                        onFinished={this.handleFinished}
                        onRead={this.handleRead}
                        stopReading={this.stopReading}
                        chunkSize={64*1024}
                        binary={true}
                        size={size}
                        color={color}
                        accept=".zip"
                        variant={variant}
                        buttonClasses={ { root: classes.zipButton }}>
        { labelOnly ? null : <OpenInBrowserIcon/>} {iconOnly ? null : label}
      </FileSelectButton>
    }
    </>);
  }
};

STLZipFileLoader.propTypes = {
  onProgress: PropTypes.func,
  onError: PropTypes.func,
  onLoadModel: PropTypes.func,
  stopReading: PropTypes.func
};

export default withStyles(styles)(STLZipFileLoader);
