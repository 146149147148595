import Immutable from 'immutable';
import { OFF, RAPID, LINEAR, ARC_CW, ARC_CCW } from '../../constants/machine-state/motion';
import actions from '../../actions/machine-state';
import { combineActions, handleActions } from 'redux-actions';
import machines from '../../machines';
import { ABSOLUTE, INCREMENTAL, ABSOLUTE_ARC, INCREMENTAL_ARC } from '../../constants/machine-state/distance-mode';
import { MM } from '../../constants/machine-state/units';

const initialState = Immutable.Map({
    mode: OFF,
    distanceMode: ABSOLUTE,
    arcDistanceMode: INCREMENTAL_ARC,
    turns: 0,
    issuedMotionCommand: false,
    position: Immutable.Map({
      X: 0,
      Y: 1.0,
      Z: 0,
      A: 0,
      B: 0,
      C: 0,
      I: 0,
      J: 0,
      K: 0
    })
  });

// TODO - we've duplicated a portion of this in doInverseKinematics
//      - perhaps we should put all of this over there
const nextPosition = (state, action) => {
  const prevPos = state.get("position");
  const { X, Y, Z, A, B, C, I, J, K, units, G53 } = action.payload;
  const distanceMode = state.get("distanceMode");
  const arcDistanceMode = state.get("arcDistanceMode");

  let params = { X, Y, Z, A, B, C };
  for(const key in params) {
    if(params[key] === undefined) {
      delete params[key];
    } else if(units === MM && (key === 'X' || key === 'Y' || key === 'Z')) {
      params[key] = params[key]/25.4; // convert parameters to inches
    }
  }

  let arcParams = { I, J, K };
  for(const key in arcParams) {
    if(arcParams[key] === undefined) {
      arcParams[key] = 0;
    } else if(units === MM) {
      arcParams[key] = arcParams[key]/25.4; // convert parameters to inches
    }
  }

  let pos = state.get("position");
  if(!G53) { // handle G53 in inverseKinematics
    if(distanceMode === ABSOLUTE) {
      pos = state.get("position").merge(params);
    } else {
      // if(distanceMode === INCREMENTAL) 
      pos = pos.withMutations((pos) => {
        for(const key in params) {
          pos.set(key, pos.get(key)+params[key]);
        }
      });
    }
  }

  if(arcDistanceMode === ABSOLUTE_ARC) {
    // TODO check that both I and J are present for XY plane (G17), or both I and K are present for XZ plane (G18) or both J and K for YZ plane (G19)
    // this page only mentions XY and XZ planes for some reason: http://linuxcnc.org/docs/html/gcode/g-code.html#gcode:g90.1-g91.1
    // will need to test with a real machine to see what happens with each plane
    pos = state.get("position").merge(arcParams);
  } else {
    // if(arcDistanceMode === INCREMENTAL_ARC) 
    const mapping = {
      I: "X",
      J: "Y",
      K: "Z"
    };
    pos = pos.withMutations((pos) => {
      for(const key in arcParams) {
        pos.set(key, prevPos.get(mapping[key])+arcParams[key]);
      }
    });
  }

  return pos;
};

// TODO - check that feed rate is > 0 for feed moves
// set an error if its not so we can annotate a line number
const reducer = handleActions(
  {
    [actions.machineState.distanceMode.setAbsolute]: (state, action) => state.set("distanceMode", ABSOLUTE),
    [actions.machineState.distanceMode.setIncremental]: (state, action) => state.set("distanceMode", INCREMENTAL),
    [actions.machineState.motion.nonMotionCommand]: (state, action) => state.set("issuedMotionCommand", false),
    [actions.machineState.motion.off]: (state, action) => state.set("mode", OFF),
    [actions.machineState.motion.rapid]: (state, action) => {
      return state.withMutations((state) => state.set("position", nextPosition(state,action))
                                                 .set("issuedMotionCommand", true)
                                                 .set("mode", RAPID));
    },
    [actions.machineState.motion.linear]: (state, action) => {
      return state.withMutations((state) => state.set("position", nextPosition(state,action))
                                                 .set("issuedMotionCommand", true)
                                                 .set("mode", LINEAR));
    },
    [actions.machineState.motion.arcCW]: (state, action) => {
      const { P } = action.payload;

      return state.withMutations((state) => state.set("position", nextPosition(state,action))
                                                 .set("turns", P)
                                                 .set("issuedMotionCommand", true)
                                                 .set("mode", ARC_CW));
    },
    [actions.machineState.motion.arcCCW]: (state, action) => {
      const { P } = action.payload;
      return state.withMutations((state) => state.set("position", nextPosition(state,action))
                                                 .set("turns", P)
                                                 .set("issuedMotionCommand", true)
                                                 .set("mode", ARC_CCW));
    },
    [actions.machineState.motion.setPosition]: (state, action) => {
      return state.withMutations((state) => state.set("position", nextPosition(state,action))
                                                 .set("issuedMotionCommand", true));
    }
  },
  initialState
);

export const doForwardKinematics = (machineState) => handleActions(
{
    // Unlike most other reduces, we have access to the full machineState tree in this reducer.
    [combineActions(
       actions.machineState.tool.setToolLengthOffset,
       actions.machineState.tool.clearToolLengthOffset,
       actions.machineState.motion.rapid,
       actions.machineState.motion.linear,
       actions.machineState.motion.arcCW,
       actions.machineState.motion.arcCCW,
       actions.machineState.motion.setPosition,
       actions.machineState.kinematicsMode.setFiveAxis,
       actions.machineState.kinematicsMode.setTrivial
    )]: (state, action) => {
      const machine = machines[machineState.get("machine")];

      const pos = state.get("position").merge(machine.kinematics.forwardKinematics(machineState));

      return state.set("position", pos);
    }
},
initialState
);

export default reducer;
