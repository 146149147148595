import { OFF, RAPID, LINEAR, ARC_CW, ARC_CCW } from '../constants/machine-state/motion';
import { XY, ZX, YZ } from '../constants/machine-state/plane-select';
import Immutable from 'immutable';
import { EPS } from '../constants';

const linearMotion = (pos0, pos1, t) => {
  return Immutable.Map({
    X: pos0.get("X")*(1-t)+pos1.get("X")*t,
    Y: pos0.get("Y")*(1-t)+pos1.get("Y")*t,
    Z: pos0.get("Z")*(1-t)+pos1.get("Z")*t,
    A: pos0.get("A")*(1-t)+pos1.get("A")*t,
    B: pos0.get("B")*(1-t)+pos1.get("B")*t,
    C: pos0.get("C")*(1-t)+pos1.get("C")*t
  });
};

export const arcLength = (pos0, pos1, planeSelect, mode, turns) => {
  if(!turns) {
    turns = 1;
  }
  const px0 = pos0.get("X");
  const py0 = pos0.get("Y");
  const pz0 = pos0.get("Z");

  const cx = pos1.get("I");
  const cy = pos1.get("J");
  const cz = pos1.get("K");

  const px1 = pos1.get("X");
  const py1 = pos1.get("Y");
  const pz1 = pos1.get("Z");

  let dx0;
  let dy0;
  let dx1;
  let dy1;
  let dz;

  switch(planeSelect) {
    case XY:
      dx0 = px0-cx;
      dy0 = py0-cy;

      dx1 = px1-cx;
      dy1 = py1-cy;

      dz = pz1-pz0;
      break;
    case ZX:
      dx0 = pz0-cz;
      dy0 = px0-cx;

      dx1 = pz1-cz;
      dy1 = px1-cx;

      dz = py1-py0;
      break;
    case YZ:
      dx0 = -(pz0-cz);
      dy0 = py0-cy;

      dx1 = -(pz1-cz);
      dy1 = py1-cy;

      dz = px1-px0;
      break;
    default:
      // not implemented - only G17, G18 and G19 are implemented
      break;
  }

  const mag0 = Math.sqrt(dx0*dx0+dy0*dy0);

  // TODO error checking
  // mag0 should equal mag1

  let angle0 = Math.atan2(dy0, dx0);
  if(angle0 < 0) {
    angle0 += 2*Math.PI;
  }

  let angle1 = Math.atan2(dy1, dx1);
  if(angle1 < 0) {
    angle1 += 2*Math.PI;
  }

  let theta;
  if(mode === ARC_CW) {
    theta = angle1-angle0;
    if(theta > EPS) {
      theta = -(2*Math.PI-theta);
    } else if(theta < EPS && theta > -EPS) {
      theta -= 2*Math.PI*turns;
    }
  } else {
    theta = angle1-angle0;
    if(theta < -EPS) {
      theta = 2*Math.PI+theta;
    } else if(theta > -EPS && theta < EPS) {
      theta += 2*Math.PI*turns;
    }
  }

  const depth = dz;
  const circumference = theta*mag0;

  return Math.sqrt(depth*depth+circumference*circumference);
};

export const arcMotion = (pos0, pos1, t, planeSelect, mode, turns) => {
  if(!turns) {
    turns = 1;
  }

  const px0 = pos0.get("X");
  const py0 = pos0.get("Y");
  const pz0 = pos0.get("Z");

  const cx = pos1.get("I");
  const cy = pos1.get("J");
  const cz = pos1.get("K");

  const px1 = pos1.get("X");
  const py1 = pos1.get("Y");
  const pz1 = pos1.get("Z");

  let dx0;
  let dy0;
  let dx1;
  let dy1;

  switch(planeSelect) {
    case XY:
      dx0 = px0-cx;
      dy0 = py0-cy;

      dx1 = px1-cx;
      dy1 = py1-cy;
      break;
    case ZX:
      dx0 = pz0-cz;
      dy0 = px0-cx;

      dx1 = pz1-cz;
      dy1 = px1-cx;
      break;
    case YZ:
      dx0 = -(pz0-cz);
      dy0 = py0-cy;

      dx1 = -(pz1-cz);
      dy1 = py1-cy;
      break;
    default:
      // not implemented - only G17, G18 and G19 are implemented
      break;
  }

  // TODO error checking, make sure mag0 equals mag1

  let angle0 = Math.atan2(dy0, dx0);
  if(angle0 < 0) {
    angle0 += 2*Math.PI;
  }

  let angle1 = Math.atan2(dy1, dx1);
  if(angle1 < 0) {
    angle1 += 2*Math.PI;
  }

  let theta;
  if(mode === ARC_CW) {
    theta = angle1-angle0;
    if(theta > EPS) {
      theta = -(2*Math.PI-theta);
    } else if(theta < EPS && theta > -EPS) {
      theta -= 2*Math.PI*turns;
    }
  } else {
    theta = angle1-angle0;
    if(theta < -EPS) {
      theta = 2*Math.PI+theta;
    } else if(theta > -EPS && theta < EPS) {
      theta += 2*Math.PI*turns;
    }
  }
  theta *= t;
  const Ctheta = Math.cos(theta);
  const Stheta = Math.sin(theta);

  let X;
  let Y;
  let Z;

  switch(planeSelect) {
    case XY:
      // rotation about z
      X = cx+(px0-cx)*Ctheta-(py0-cy)*Stheta;
      Y = cy+(px0-cx)*Stheta+(py0-cy)*Ctheta;
      Z = pz0*(1-t)+pz1*t;
      break;
    case ZX:
      // rotation about y
      X = cx+(px0-cx)*Ctheta+(pz0-cz)*Stheta;
      Y = py0*(1-t)+py1*t;
      Z = cz-(px0-cx)*Stheta+(pz0-cz)*Ctheta;
      break;
    case YZ:
      // rotation about x
      X = px0*(1-t)+px1*t;
      Y = cy+(py0-cy)*Ctheta-(pz0-cz)*Stheta;
      Z = cz+(py0-cy)*Stheta+(pz0-cz)*Ctheta;
      break;

    default:
      // not implemented
      break;
  }

  const A = pos0.get("A")*(1-t)+pos1.get("A")*t;
  const B = pos0.get("B")*(1-t)+pos1.get("B")*t;

  const pos = Immutable.Map({ X, Y, Z, A, B });

  return pos;
};

export const calculatePosition = (motionMode, pos0, pos1, t, planeSelect, turns) => {
  //console.log("calculating position", t, pos0.toJS(), pos1.toJS());
  switch(motionMode) {
    case RAPID:
    case LINEAR:
      const p = linearMotion(pos0, pos1, t);
      //console.log("result", p.toJS());
      return p;
    case OFF:
      return pos1
    case ARC_CW:
    case ARC_CCW:
      return arcMotion(pos0, pos1, t, planeSelect, motionMode, turns);
    default:
      console.error(motionMode, " motion mode not implemented, jumping to final position.");
      return pos1
  }
};
