import actions from '../../actions/viewer3d';
import Immutable from 'immutable';
import { handleActions } from 'redux-actions';
import { EPS } from '../../constants';

import { PERSPECTIVE, ORTHOGRAPHIC } from '../../constants/viewer3d/camera-mode';
import { DENTAL_FIXTURE } from '../../constants/viewer3d/camera-parent';

const reducer = handleActions(
  {
    [actions.viewer3D.parentCamera]: (state, action) => state.set("cameraParent", action.payload),
    [actions.viewer3D.setPerspective]: (state, action) => state.set("cameraMode", PERSPECTIVE),
    [actions.viewer3D.setOrthographic]: (state, action) => state.set("cameraMode", ORTHOGRAPHIC),
    [actions.viewer3D.loadModel]: (state,action) => state.set("modelLoaded", true),
    [actions.viewer3D.showModel]: (state,action) => state.set("showModel", true),
    [actions.viewer3D.hideModel]: (state,action) => state.set("showModel", false),
    [actions.viewer3D.setDimensions]: (state,action) => state.set("width", action.payload.width).set("height", action.payload.height),

    [actions.viewer3D.cameraSetRadius]: (state,action) => state.set("radius", action.payload),
    [actions.viewer3D.cameraSetOrthographicZoom]: (state,action) => state.set("orthographicZoom", action.payload),
    [actions.viewer3D.cameraSetPhi]: (state,action) => state.set("phi", action.payload),
    [actions.viewer3D.cameraSetTheta]: (state,action) => state.set("theta", action.payload),

    [actions.viewer3D.cameraZoom]: (state,action) => {
      let radius = state.get("radius");
      let orthographicZoom = state.get("orthographicZoom");
      const deltaY = action.payload;

      if(deltaY < 0) {
        radius *= .95;
        orthographicZoom /= .95;
      } else if(deltaY > 0) {
        radius /= .95;
        orthographicZoom *= .95;
      }

      orthographicZoom = Math.min(50, Math.max(.1, orthographicZoom)); 
      radius = Math.min(50, Math.max(.1, radius));

      return state.set("orthographicZoom", orthographicZoom).set("radius", radius);
    },
    [actions.viewer3D.cameraStartRotating]: (state, action) => {
      return state.set("rotating", true);
    },
    [actions.viewer3D.cameraStopRotating]: (state, action) => {
      return state.set("rotating", false);
    },
    [actions.viewer3D.cameraSetDirection]: (state,action) => {
      const { x, y, z } = action.payload;

      const radius = Math.sqrt( x*x+y*y+z*z);
      let phi = 0;
      let theta = 0;
      if(radius !== 0) {
        theta = Math.atan2(x,z);
        phi = Math.acos(Math.max(Math.min(y/radius, 1), -1));
      }

      if(phi <= EPS || phi >= Math.PI-EPS) {
        theta = state.get("theta");
      }

      return state.withMutations((state) => {
        state.set("phi", phi);
        state.set("theta", theta);
      });
    },
    [actions.viewer3D.cameraRotate]: (state, action) => {
      const rotating = state.get("rotating");

      if(rotating) {
        const width = state.get("width");
        const height = state.get("height");

        const { dx, dy } = action.payload;

        const theta = state.get("theta")-2*Math.PI*dx / width * 2;
        const phi = Math.max(EPS, Math.min(Math.PI-EPS, state.get("phi")-2*Math.PI*dy / height * 2));

        return state.withMutations((state) => {
          state.set("theta", theta);
          state.set("phi", phi);
        });
      } else{
        return state;
      }
    },
  },
  Immutable.Map({
    width: 500,
    height: 500,
    showModel: false,
    modelLoaded: false,

// Camera parameters
// TODO - set default cameraParent without needing window.location
//    cameraParent: window.location.hash !== "#aligner" ? Y_AXIS : DENTAL_FIXTURE,
    cameraParent: DENTAL_FIXTURE,
    cameraMode: PERSPECTIVE,
    rotating: false,
    radius: 10,
    orthographicZoom: 1,
    phi: Math.PI/4,
    theta: -Math.PI/2
  })
);

export default reducer;
