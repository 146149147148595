import { connect } from 'react-redux';

import actions from '../actions';
import CreateAlignerButton from '../components/CreateAlignerButton';
import { LOAD_MODEL, POSITION_MODEL } from '../constants/aligner-state';
import { DENTAL_FIXTURE } from '../constants/viewer3d/camera-parent';
import Viewer3D from '../viewer3d';
import AlignerCache from '../util/aligner-cache';

export default connect(
  (state, ownProps) => ({
    isPrimary: !state.get("alignerState").get("label") && state.get("alignerState").get("step") === LOAD_MODEL,
    isVisible: state.get("alignerState").get("user").get("credits") > 0,
    multi: state.get("alignerState").get("createAlignerDialog").get("multi"),
    checksum: state.get("alignerState").get("createAlignerDialog").get("checksum"),
    firstModelChecksum: state.get("alignerState").get("createAlignerDialog").get("firstModelChecksum"),
    files: state.get("alignerState").get("createAlignerDialog").get("files").toJS(),
    fileMapping: state.get("alignerState").get("createAlignerDialog").get("mapping").toJS(),
    xform: state.get("alignerState").get("createAlignerDialog").get("xform"),
    points: state.get("alignerState").get("createAlignerDialog").get("points"),
    positioningModel: state.get("alignerState").get("createAlignerDialog").get("positioningModel"),
    open: state.get("alignerState").get("createAlignerDialog").get("open"),
    error: state.get("alignerState").get("createAlignerDialog").get("error"),
    code: state.get("alignerState").get("createAlignerDialog").get("code"),
    addBridge: state.get("alignerState").get("createAlignerDialog").get("addBridge"),
    addCylinders: state.get("alignerState").get("createAlignerDialog").get("addCylinders"),
    snapFit: state.get("alignerState").get("createAlignerDialog").get("snapFit")
  }),
  (dispatch, ownProps) => ({
    onCreateAligner: (code, order) => {
      dispatch(actions.alignerState.createAligner(code, order));
    },
    onChangeAlignerNumber: (file, value) => {
      dispatch(actions.alignerState.createAlignerDialog.setAlignerNumber({ file, value }));
    },
    onCodeChange: (value) => {
      dispatch(actions.alignerState.createAlignerDialog.setCode(value));
    },
    onLoadZip: (zip, files, mapping, buffer) => {
      // cache the zip's raw data for upload later
      const cache = AlignerCache.getInstance();
      const checksum = cache.getMD5Checksum(buffer);
      cache.setItem(checksum, buffer);

      dispatch(actions.alignerState.createAlignerDialog.setError(""));
      dispatch(actions.alignerState.createAlignerDialog.setChecksum(checksum));
      dispatch(actions.alignerState.resetState());
      const viewer = Viewer3D.getInstance();
      if(viewer && files.length > 0) {
        zip.file(files[0]).async("arraybuffer").then((model) => {
          const modelChecksum = cache.getMD5Checksum(model);
          cache.setItem(modelChecksum, model);

          viewer.loadSTLModel(model);
          viewer.computePlaneWithLargestArea();
          dispatch(actions.viewer3D.loadModel())
          dispatch(actions.viewer3D.showModel())
          dispatch(actions.alignerState.modelLoaded());
          dispatch(actions.alignerState.createAlignerDialog.setFirstModelChecksum(modelChecksum));
        }).catch(() => {});
      }

      dispatch(actions.alignerState.createAlignerDialog.setFiles({ files, mapping }));
    },
    onSetPosition: () => {
      dispatch(actions.alignerState.setStep(POSITION_MODEL));
      dispatch(actions.alignerState.createAlignerDialog.setPositioningModel(true));
      dispatch(actions.viewer3D.parentCamera(DENTAL_FIXTURE));
    },
    onSetMulti: (m) => {
      dispatch(actions.alignerState.createAlignerDialog.setMulti(m));
    },
    onOpen: () => {
      dispatch(actions.alignerState.createAlignerDialog.open());
    },
    onClose: () => {
      dispatch(actions.alignerState.createAlignerDialog.close());
      dispatch(actions.alignerState.resetState());
    },
    onError: (err) => {
      dispatch(actions.alignerState.createAlignerDialog.setError(err));
    },
    onCreateMultipleAligners: (data) => {
      dispatch(actions.alignerState.createMultipleAligners(data));
    }

  })
)(CreateAlignerButton);
