import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default (props) => {
  const handleRefresh = () => {
    window.location.reload();
  };
  return <Dialog open={true}>
    <DialogTitle>Error Occurred</DialogTitle>
    <DialogContent>
      <DialogContentText>
      An unrecoverable error has occurred. Please refresh your browser to continue.
      If this error continues to occur, please contact <a href="mailto:info@pocketnc.com">info@pocketnc.com</a>.
      </DialogContentText>
      <DialogActions>
        <Button onClick={handleRefresh} color="primary">Refresh</Button>
      </DialogActions>
    </DialogContent>
  </Dialog>;
};
