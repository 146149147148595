import actions from '../../actions/machine-state/spindle-speed';
import { handleActions } from 'redux-actions';

const reducer = handleActions(
  {
    [actions.machineState.spindleSpeed.set]: (state, action) => action.payload,
  },
  0
);

export default reducer;
