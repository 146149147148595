export const modalGroups = {
  // http://linuxcnc.org/docs/html/gcode/overview.html#_modal_groups
  //
  // See also:
  // https://ws680.nist.gov/publication/get_pdf.cfm?pub_id=823374 - page 20 (pdf page 28/121)

  // G-Code Modal Groups

  // non-modal codes
  "G4": 0,
  "G10": 0,
  "G28": 0,
  "G30": 0,
  "G53": 0,
  "G92": 0,
  "G92.1": 0,
  "G92.2": 0,
  "G92.3": 0,

  // motion
  "G0": 1,
  "G1": 1,
  "G2": 1,
  "G3": 1,
  "G33": 1,
  "G38.2": 1,
  "G73": 1,
  "G76": 1,
  "G81": 1,
  "G82": 1,
  "G83": 1,
  "G84": 1,
  "G85": 1,
  "G86": 1,
  "G87": 1,
  "G88": 1,
  "G89": 1,

  // plane selection
  "G17": 2,
  "G18": 2,
  "G19": 2,
  "G17.1": 2,
  "G18.1": 2,
  "G19.1": 2,

  // distance mode
  "G90": 3,
  "G91": 3,

  // arc ijk distance mode
  "G90.1": 4,
  "G91.1": 4,

  // feed rate mode
  "G93": 5,
  "G94": 5,
  "G95": 5,

  // units
  "G20": 6,
  "G21": 6,

  // cutter radius compensation
  "G40": 7,
  "G41": 7,
  "G42": 7,
  "G41.1": 7,
  "G42.1": 7,

  // tool length offset
  "G43": 8,
  "G43.1": 8,
  "G49": 8,

  // canned cycles return mode
  "G98": 10,
  "G99": 10,

  // coordinate system
  "G54": 12,
  "G55": 12,
  "G56": 12,
  "G57": 12,
  "G58": 12,
  "G59": 12,
  "G59.1": 12,
  "G59.2": 12,
  "G59.3": 12,

  // control mode
  "G61": 13,
  "G61.1": 13,
  "G664": 13,

  // spindle speed mode
  "G96": 14,
  "G97": 14,

  // lathe diameter mode
  "G7": 15,
  "G8": 15,

  // M-Code Modal Groups

  // stopping
  "M0": 4,
  "M1": 4,
  "M2": 4,
  "M30": 4,
  "M60": 4,

  // spindle
  "M3": 7,
  "M4": 7,
  "M5": 7,

  // coolant
  "M7": 8, // special case, M7 & M8 can be on
  "M8": 8,
  "M9": 8,

  // override switches
  "M48": 9,
  "M49": 9
};

export const lookUpModalGroup = (code) => {
  return modalGroups[code];
};
