import distanceMode from './distance-mode';
import feedRateMode from './feed-rate-mode';
import feedRate from './feed-rate';
import units from './units';
import kinematicsMode from './kinematics-mode';
import cutterCompensation from './cutter-compensation';
import motion from './motion';
import planeSelect from './plane-select';
import spindleControl from './spindle-control';
import spindleSpeed from './spindle-speed';
import tool from './tool';

export default {
  machineState: {
    ...distanceMode.machineState,
    ...feedRateMode.machineState,
    ...feedRate.machineState,
    ...kinematicsMode.machineState,
    ...planeSelect.machineState,
    ...units.machineState,
    ...cutterCompensation.machineState,
    ...motion.machineState,
    ...spindleControl.machineState,
    ...spindleSpeed.machineState,
    ...tool.machineState
  }
};
