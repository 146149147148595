import { OFF } from '../../constants/machine-state/cutter-compensation';
import actions from '../../actions/machine-state/cutter-compensation';
import { handleActions } from 'redux-actions';

const reducer = handleActions(
  {
    [actions.machineState.cutterCompensation.off]: undefined
  },
  OFF
);

export default reducer;
