import { call, fork, put, all, delay } from 'redux-saga/effects';
import actions from '../actions';

const pollTime = 1000;

const openConnectionWithDevice = (device) => {
  return device.open()
    .then(() => {
      return device.selectConfiguration(1);
    })
    .then(() => {
      return device.claimInterface(2);
    })
    .then(() => {
      return device.selectAlternateInterface(2, 0);
    })
    .then(() => {
      return device.controlTransferOut({
        requestType: 'class',
        recipient: 'interface',
        request: 0x22,
        value: 0x01,
        index: 0x02
      })
    })
    .then(() => {
      return ({
        device
      })
    })
    .catch((error) => ({ error }));
};

const readData = (device) => {
  return device.transferIn(5, 64).then((result) => {
    const decoder = new TextDecoder();
    return {
      data: decoder.decode(result.data)
    };
  }).catch((error) => ({ error }));
};

// TODO - create a way to send data to the device as well
function* establishSerialConnection(d, disconnectDevice) {
  const { device } = yield call(openConnectionWithDevice, d);

  if(device) {
    yield put(actions.rfid.connectedScanner());
    let buffer = "";
    let connected = true;
    while(connected) {
      const { data, error } = yield call(readData, device);

      if(data) {
        buffer += data;
        const index = buffer.indexOf("\r\n");
        if(index > -1) {
          const message = buffer.slice(0,index);
          try {
            const json = JSON.parse(message);

            if(json.error) {
              yield put(actions.rfid.receivedErrorMessage(json));
            } else if(json.rfid) {
              yield put(actions.rfid.receivedRfidScanMessage(json));
            }
          } catch(err) {
            console.error(err);
            yield put(actions.rfid.receivedIncompleteMessage());
          }
          buffer = buffer.slice(index+2);

        }
      } else if(error) {
        yield put(actions.rfid.disconnectedScanner());
        disconnectDevice(device);
        connected = false;
      }
    }
  } else {
    disconnectDevice(device);
  }
};

export default function* pollForScanners() {
  if(navigator.usb) {
    const connectedDevices = [];
    const disconnectDevice = (device) => {
      const index = connectedDevices.indexOf(device);
      if(index > -1) {
        connectedDevices.splice(index,1);
      }
    };
    while(true) {
      const devices = yield navigator.usb.getDevices();
      const newDevices = [];
      devices.forEach((device) => {
        if(!connectedDevices.includes(device)) {
          connectedDevices.push(device);
          newDevices.push(device);
        }
      });

      yield all(newDevices.map((device) => fork(establishSerialConnection, device, disconnectDevice)));
      yield delay(pollTime);
    }
  }
}
