import React, { useCallback } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  buttonContainer: {
    paddingBottom: 10
  }
});

export default withStyles(styles)((props) => {
  const {
    classes,
    open,
    onClose,
    onPurchaseCredits
  } = props;

  const purchase500 = useCallback(() => onPurchaseCredits(500), [ onPurchaseCredits ]);
  const purchase1000 = useCallback(() => onPurchaseCredits(1000), [ onPurchaseCredits ]);
  const purchase5000 = useCallback(() => onPurchaseCredits(5000), [ onPurchaseCredits ]);
  const purchase10000 = useCallback(() => onPurchaseCredits(10000), [ onPurchaseCredits ]);

  return <Dialog open={open} onClose={onClose} maxWidth={false}>
    <DialogTitle>Purchase Credits</DialogTitle>
    <DialogContent classes={{ root: classes.buttonContainer }}>
      <p>Purchased credits expire in 6 months.</p>
      <p><Button color="default" variant="contained" fullWidth={true} onClick={purchase500}>Purchase 500 Credits</Button></p>
      <p><Button color="default" variant="contained" fullWidth={true} onClick={purchase1000}>Purchase 1,000 Credits</Button></p>
      <p><Button color="default" variant="contained" fullWidth={true} onClick={purchase5000}>Purchase 5,000 Credits</Button></p>
      <p><Button color="default" variant="contained" fullWidth={true} onClick={purchase10000}>Purchase 10,000 Credits</Button></p>
      <p>Need higher quantities? Contact <a href="mailto:info@pocketnc.com">info@pocketnc.com</a>.</p>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>;
});
