import THREE from './three';

export default class CameraControls {
  constructor(scene, canvas) {
    this.width = canvas.width;
    this.height = canvas.height;

    this.spherical = new THREE.Spherical(16, Math.PI/4, -Math.PI/2);
    this.spherical.makeSafe();

    this.currentSpherical = new THREE.Spherical(16, Math.PI/4, -Math.PI/2);
    this.currentSpherical.makeSafe();

    this.Y = new THREE.Vector3(0,1,0);
    this.X = new THREE.Vector3(1,0,0);
    this.rotatedX = new THREE.Vector3(1,0,0);
    this.focus = new THREE.Vector3(0, 0, 0);
    this.parent = new THREE.Object3D();

    this.parentPosition = new THREE.Vector3();
    this.parentOrientation = new THREE.Quaternion();

    this.worldPosition = new THREE.Vector3(-16,0,0);
    this.worldOrientation = new THREE.Quaternion();
    this.localOrientation = new THREE.Quaternion();
    this.phiOrientation = new THREE.Quaternion();
    this.thetaOrientation = new THREE.Quaternion();
    this.localOrientation.setFromAxisAngle(new THREE.Vector3(0,1,0), -Math.PI/2);
    this.worldOrientation.copy(this.localOrientation);

    this.startRotate = new THREE.Vector2();
    this.dRotate = new THREE.Vector2();

    this.transitionFilter = .1;
    this.transitionSphericalFilter = .4;

    this.rotateFilter = .4;
    this.rotateSphericalFilter = .8;

    this.lockedFilter = 1;
    this.lockedSphericalFilter = 1;

    this.filter = 1;
    this.sphericalFilter = 1;

    this.scene = scene
    const aspect = this.width/this.height;
    const invAspect = this.height/this.width;
    this.perspectiveCamera = new THREE.PerspectiveCamera(60, aspect, .1, 1000 );
    this.orthographicCamera = new THREE.OrthographicCamera(-10, 10, 10*invAspect, -10*invAspect, -100, 100);
    this.camera = this.perspectiveCamera;
    this.camera.position.x = -3;

    this.scene.add(this.camera);

    this.canvas = canvas;
  }

  updateCanvasDimensions(width, height) {
    this.width = width;
    this.height = height;
    const aspect = this.width/this.height;
    const invAspect = this.height/this.width;
    this.perspectiveCamera.aspect = aspect;
    this.perspectiveCamera.updateProjectionMatrix();
    this.orthographicCamera.top = 10*invAspect;
    this.orthographicCamera.bottom = -10*invAspect;
    this.orthographicCamera.updateProjectionMatrix();
  }

  setPerspective() {
    this.camera = this.perspectiveCamera;
  }
  setOrthographic() {
    this.camera = this.orthographicCamera;
  }

  update() {
    const filter = this.rotating ? this.rotateFilter : this.transitionFilter;
    const sphericalFilter = this.rotating ? this.rotateFilter : this.transitionSphericalFilter;

    this.currentSpherical.radius = (1-sphericalFilter)*this.currentSpherical.radius+sphericalFilter*this.spherical.radius;
    this.currentSpherical.phi = (1-sphericalFilter)*this.currentSpherical.phi+sphericalFilter*this.spherical.phi;
    if(this.currentSpherical.theta-this.spherical.theta > Math.PI) {
      this.currentSpherical.theta -= 2*Math.PI;
    } else if(this.spherical.theta-this.currentSpherical.theta > Math.PI) {
      this.currentSpherical.theta += 2*Math.PI;
    }
    this.currentSpherical.theta = (1-sphericalFilter)*this.currentSpherical.theta+sphericalFilter*this.spherical.theta;
    this.currentSpherical.makeSafe();

//    if(this.camera instanceof THREE.OrthographicCamera) {
//      this.currentSpherical.radius = 10;
//    }
    this.worldPosition.setFromSpherical(this.currentSpherical);

    this.parent.getWorldQuaternion(this.parentOrientation);
    this.parent.getWorldPosition(this.parentPosition);

    this.worldPosition.applyQuaternion(this.parentOrientation);
    this.worldPosition.add(this.parentPosition);

    this.thetaOrientation.setFromAxisAngle(this.Y, this.currentSpherical.theta);
    this.phiOrientation.setFromAxisAngle(this.rotatedX.copy(this.X).applyQuaternion(this.thetaOrientation), -Math.PI/2+this.currentSpherical.phi);

    this.localOrientation.copy(this.thetaOrientation);
    this.localOrientation.premultiply(this.phiOrientation);

    this.worldOrientation.copy(this.localOrientation);
    this.worldOrientation.premultiply(this.parentOrientation);

    this.perspectiveCamera.quaternion.slerp(this.worldOrientation, filter);
    this.perspectiveCamera.position.lerp(this.worldPosition, filter);
    this.orthographicCamera.quaternion.slerp(this.worldOrientation, filter);
    this.orthographicCamera.position.lerp(this.worldPosition, filter);

/*
    if(Math.abs(this.currentSpherical.radius-this.spherical.radius) < EPS &&
       Math.abs(this.currentSpherical.phi-this.spherical.phi) < EPS &&
       Math.abs(this.currentSpherical.theta-this.spherical.theta) < EPS) {
      this.sphericalFilter = this.lockedSphericalFilter;
    }

    if(Math.abs(this.camera.position.x-this.worldPosition.x) < EPS &&
       Math.abs(this.camera.position.y-this.worldPosition.y) < EPS &&
       Math.abs(this.camera.position.z-this.worldPosition.z) < EPS &&
       Math.abs(this.camera.quaternion.x-this.worldOrientation.x) < EPS &&
       Math.abs(this.camera.quaternion.y-this.worldOrientation.y) < EPS &&
       Math.abs(this.camera.quaternion.z-this.worldOrientation.z) < EPS &&
       Math.abs(this.camera.quaternion.w-this.worldOrientation.w) < EPS) {
      this.filter = this.lockedFilter;
    }
    */
  }

  setParent(object) {
    //console.log(object);
    if(object && this.parent !== object) {
      this.parent = object;
      this.filter = this.transitionFilter;
      this.sphericalFilter = this.transitionSphericalFilter;
    }
  }
};
