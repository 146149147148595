import { createActions } from 'redux-actions';

const actions = createActions({
  MACHINE_STATE: {
    TOOL: {
      SET: undefined, // T<number> - <number> goes in the payload
      CHANGE: undefined, // M6 - load the currently set tool
      SET_TOOL_LENGTH_OFFSET: undefined, // G43 <H->
      CLEAR_TOOL_LENGTH_OFFSET: undefined, // G49
      // TODO add actions for other tool length offset commands
      TABLE: {
        SET_TOOL: undefined
      }
    }
  }
});

export default actions;
