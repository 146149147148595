import { IN, MM } from '../../constants/machine-state/units';
import actions from '../../actions/machine-state/units';
import { handleActions } from 'redux-actions';

const reducer = handleActions(
  {
    [actions.machineState.units.setIn]: () => IN,
    [actions.machineState.units.setMm]: () => MM
  },
  IN
);

export default reducer;
