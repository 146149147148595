import { createActions } from 'redux-actions';

export default createActions({
  VIEWER3D: {
    PARENT_CAMERA: undefined,
    SHOW_MODEL: undefined,
    HIDE_MODEL: undefined,
    LOAD_MODEL: undefined,
    SET_PERSPECTIVE: undefined,
    SET_ORTHOGRAPHIC: undefined,

    SET_DIMENSIONS: (width,height) => ({ width, height }),

    CAMERA_ZOOM: undefined,
    CAMERA_START_ROTATING: undefined,
    CAMERA_ROTATE: (dx, dy) => ({ dx, dy }),
    CAMERA_STOP_ROTATING:  undefined,

    CAMERA_SET_DIRECTION: (x,y,z) => ({ x, y, z }),

    CAMERA_SET_RADIUS: undefined,
    CAMERA_SET_ORTHOGRAPHIC_ZOOM: undefined,
    CAMERA_SET_PHI: undefined,
    CAMERA_SET_THETA: undefined
  }
});
