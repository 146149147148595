import { configureAuth, getAuthenticatedUserOnSignIn } from '../util/auth'; 
import { put, all, call } from 'redux-saga/effects';
import actions from '../actions';

export function* initializeUserInfo() {
  configureAuth();

  let user = yield call(getAuthenticatedUserOnSignIn);

  if(user) {
    const {
      sub,
      name
    } = user.signInUserSession.idToken.payload;

    const userInfoState = {
      name, userid: sub
    };

    yield put(actions.auth.userAuthenticated(userInfoState));
  }
}

export default function* authSaga() {
  yield all([ initializeUserInfo() ]);
}
