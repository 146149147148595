import now from 'performance-now';
import { put, call, select, takeLatest } from 'redux-saga/effects';
import actions from '../actions';
import * as feedRateConstants from '../constants/machine-state/feed-rate-mode';
import * as motionConstants from '../constants/machine-state/motion';

import getAnalytics from '../util/analytics';

import { calculatePosition, arcLength } from '../util/motion-math';

import { AddLineNumber, ZeroFeedRate } from '../util/messages';

import machines from '../machines';
import BackPlot, { RAPID_COLOR, FEED_COLOR }from '../viewer3d/backplot';
import { FIVE_AXIS } from '../constants/machine-state/kinematics-mode';

import GCodeInterpreter from '../gcode/interpreter';
import { machineState as machineStateSelector,
         lines as linesSelector,
         linesPerIndex as linesPerIndexSelector,
         approximateTime as approximateTimeSelector,
         loadedTool as loadedToolSelector
         } from '../selectors';
import { unimplementedMessages as unimplementedMessagesSelector } from '../selectors/messages';

import { EPS } from '../constants';
import { MM } from '../constants/machine-state/units';

const analytics = getAnalytics();

const SECONDS_PER_MINUTE = 60;
const NUM_LINEAR_AXES = 3;

// export so we can use it in a test
export function interpretLines(interpreter, lines, curIndex, numLines, linesPerIndex, time) {
  return new Promise((resolve) => setTimeout(() => {
    let nextState;
    const states = [];
    const times = [];
    const points = [];
    const messages = [];
    let currentState =  interpreter.getState();
    for(let i = 0; i < numLines && curIndex < lines.length; i++) {
      nextState = interpreter.interpret(lines[curIndex]);

      if(interpreter.messages.length > 0) {
        interpreter.messages.forEach(((lineNo) => (msg) => {
          messages.push(AddLineNumber(msg, lineNo));
        })(curIndex));
      }

      const machine = machines[nextState.get("machine")];
      const currentJoints = currentState.get("joints");
      const nextJoints = nextState.get("joints");
      const currentPosition = currentState.get("motion").get("position");
      const nextPosition = nextState.get("motion").get("position");

      if(nextState.get("motion").get("issuedMotionCommand")) {
        const turns = nextState.get("motion").get("turns");
        const motionMode = nextState.get("motion").get("mode");
        const planeSelect = nextState.get("planeSelect");
        const units = nextState.get("units");

        let t = 0;

        let feedRate = nextState.get("feedRate");
        const feedRateMode = nextState.get("feedRateMode");
        if(feedRateMode !== feedRateConstants.INVERSE_TIME &&
           units === MM) {
          // convert feedRate to inches
          feedRate /= 25.4;
        }

        // All coordinates will be in inches by this point

        if(motionMode !== motionConstants.RAPID) {
          if(feedRateMode === feedRateConstants.INVERSE_TIME) {
            if(feedRate < EPS) {
              messages.push(AddLineNumber(ZeroFeedRate(), curIndex));
            } else {
              t = 1./feedRate*SECONDS_PER_MINUTE;
            }
          } else if(feedRateMode === feedRateConstants.UNITS_PER_MINUTE) {
            if(motionMode === motionConstants.LINEAR) {
              if(feedRate < EPS) {
                messages.push(AddLineNumber(ZeroFeedRate(), curIndex));
              } else {
                let maxLinearMove = 0;
                for(let j = 0; j < NUM_LINEAR_AXES; j++) {
                  const dj = Math.abs(nextJoints.get(j)-currentJoints.get(j));
                  maxLinearMove = Math.max(maxLinearMove, dj);

                  t = Math.max(t, dj/feedRate*SECONDS_PER_MINUTE);
                }
                if(maxLinearMove > -EPS && maxLinearMove < EPS) {
                  // no linear movement, so units are now in degrees/minute
                  for(let j = 3; j < currentJoints.size; j++) {
                    const dj = Math.abs(nextJoints.get(j)-currentJoints.get(j));
                    t = Math.max(t, dj/feedRate*SECONDS_PER_MINUTE);
                  }
                }
              }
            } else if(motionMode === motionConstants.ARC_CW ||
                      motionMode === motionConstants.ARC_CCW) {
              if(feedRate < EPS) {
                messages.push(AddLineNumber(ZeroFeedRate(), curIndex));
              } else {
                const arcL = arcLength(currentPosition, nextPosition, planeSelect, motionMode, turns);
                t = arcL/feedRate*SECONDS_PER_MINUTE;
              }
            } else if(motionMode === motionConstants.OFF) {
              t = 0;
            } else {
              t = 0;
              // TODO other canned cycles
            }
          } else {
            // TODO Units per revolution
              t = 3;
          }
        }

        // make sure we don't go faster than any of our joints can go
        // doesn't take into account arcs and canned cycles, so make sure
        // to calculate time for those before here
        const maxFeedRates = machine.limits.velocities; // in units/second
        const maxAccelerations = machine.limits.accelerations; // in units/second
        let maxRotaryMove = 0;
        for(let j = 3; j < currentJoints.size; j++) {
          const dj = Math.abs(nextJoints.get(j)-currentJoints.get(j));
          maxRotaryMove = Math.max(dj, maxRotaryMove);
        }
        for(let j = 0; j < currentJoints.size; j++) {
          const accelDist = maxFeedRates[j]*maxFeedRates[j]/(.5*maxAccelerations[j]);
          const dj = Math.abs(nextJoints.get(j)-currentJoints.get(j));
          let jointT = 0;
          if(maxRotaryMove < EPS && maxRotaryMove > -EPS) {
            jointT = dj/maxFeedRates[j]; // MachineKit combines linear moves when possible to limit how much acceleration/deceleration 
                                         // is required so ignoring acceleration seems to better approximate the actual time it takes
                                         // on pure linear moves.
          } else {
            // When coordinating rotary+linear moves, acceleration is often the limiting factor 
            // See http://www.machinekit.io/docs/common/User_Concepts/#sec:trajectory-control
            if(dj >= accelDist) {
              // Move is long enough to reach max velocity, taking max acceleration into account, but it takes
              // a bit of time to accelerate to max velocity, so take that time into account.
              jointT = Math.max(dj-accelDist)/maxFeedRates[j]+2*maxFeedRates[j]/(.5*maxAccelerations[j]);
            } else {
              // Move isn't long enough to reach max velocity, so calculate time to it takes to go half the necessary
              // distance at max acceleration + time it takes to decelerate back to 0 (deceleration time is the same
              // as the acceleration time, so twice the time it takes to accelerate).

              jointT = 2*Math.sqrt(dj/maxAccelerations[j]);
            }
          }
          t = Math.max(t, jointT);
        }

        time += t;

        // Back plot
        const joints0 = currentJoints;
        const joints1 = nextJoints;
        const state1 = nextState;

        const pos0 = machine.kinematics.forwardKinematics(state1.set("joints", joints0));
        const pos1 = state1.get("motion").get("position");
        const kinematicsMode = state1.get("kinematicsMode");

        const da = Math.abs(joints1.get(3)-joints0.get(3));
        const db = Math.abs(joints1.get(4)-joints0.get(4));
        if(((da > EPS ||
            db > EPS) && kinematicsMode !== FIVE_AXIS) ||
            (motionMode !== motionConstants.RAPID &&
             motionMode !== motionConstants.LINEAR)) {
          // rotary or other non-linear moves

          const maxDesiredSegmentLength = .1; // We'd prefer all our segments to be shorter than this when approximating curved paths with linear segments
                                               // TODO - smaller numbers give better visual results (spiral.ngc is a good demonstration), but can dramatically slow down processing
                                               // We need to add a way to let the user change this (SOFT-82). 
          const minSamples = 5; // TODO - The minimum number of samples should probably also be configurable, perhaps between 2-20 (SOFT-82)

          let samples = 40;
          let calculateSegments = true;
          let pathPoints = [];
          if((motionMode === motionConstants.RAPID ||
              motionMode === motionConstants.LINEAR) && (db > -EPS || da > -EPS)) {
            const x = Math.max(joints1.get(0),joints0.get(0));
            const y = Math.max(joints1.get(1),joints0.get(1));
            const z = Math.max(6-joints1.get(2),6-joints0.get(2));
            const XZmag = Math.sqrt(x*x+z*z);
            const YZmag = Math.sqrt(y*y+z*z);
            const arcLengthA = da*Math.PI/180*YZmag;
            const arcLengthB = db*Math.PI/180*XZmag;

            samples = Math.max(Math.ceil(arcLengthB/maxDesiredSegmentLength), Math.ceil(arcLengthA/maxDesiredSegmentLength), minSamples);
          } else if(motionMode === motionConstants.ARC_CW ||
                    motionMode === motionConstants.ARC_CCW) {
            const arcL = arcLength(currentPosition, nextPosition, planeSelect, motionMode, turns);

            samples = Math.max(Math.ceil(arcL/maxDesiredSegmentLength), minSamples);
          }

          while(calculateSegments) {
//            let pathLength = 0;
            let maxSegmentLength = 0;

            let lastPt;
            for(let i = 0; i < samples; i++) {
              const param = i/(samples-1);
              const ptTime = time-t+param*t;
              let dt;
              if(param === 0) {
                dt = .01/t;
              } else {
                dt = -.01/t;
              }
              const turns = nextState.get("motion").get("turns");
              const pos = calculatePosition(motionMode, pos0, pos1, param, planeSelect, turns);
              const pos2 = calculatePosition(motionMode, pos0, pos1, param+dt, planeSelect, turns);
              const joints = machine.kinematics.inverseKinematics(state1.set("motion", state1.get("motion").set("position", pos)));
              const joints2 = machine.kinematics.inverseKinematics(state1.set("motion", state1.get("motion").set("position", pos2)));
              const workPos = machine.workPieceSpace(joints, loadedToolSelector(state1).get("Z"));
              const normal = machine.toolOrientation(joints);
              const workPos2 = machine.workPieceSpace(joints2, loadedToolSelector(state1).get("Z"));
              const pt = [ workPos.get("X"), workPos.get("Y"), workPos.get("Z") ];
              const pt2 = [ workPos2.get("X"), workPos2.get("Y"), workPos2.get("Z") ];
              const n = [ normal.get("X"), normal.get("Y"), normal.get("Z") ];

              let speed;
              if(dt > 0) {
                const dx = pt2[0]-pt[0];
                const dy = pt2[1]-pt[1];
                const dz = pt2[2]-pt[2];
                speed = Math.sqrt(dx*dx+dy*dy+dz*dz)/.01;
              } else {
                const dx = pt[0]-pt2[0];
                const dy = pt[1]-pt2[1];
                const dz = pt[2]-pt2[2];
                speed = Math.sqrt(dx*dx+dy*dy+dz*dz)/.01;
              }
              if(motionMode === motionConstants.RAPID) {
                pathPoints.push({ position: pt, color: RAPID_COLOR, speed, time: ptTime, line: curIndex, normal: n });
              } else {
                pathPoints.push({ position: pt, color: FEED_COLOR, speed, time: ptTime, line: curIndex, normal: n });
              }

              if(i > 0) {
                const dx = pt[0]-lastPt[0];
                const dy = pt[1]-lastPt[1];
                const dz = pt[2]-lastPt[2];
                const segmentLength = Math.sqrt(dx*dx+dy*dy+dz*dz);
                maxSegmentLength = Math.max(maxSegmentLength, segmentLength);
//                pathLength += segmentLength;
              }
              lastPt = pt;
            }

            if(maxSegmentLength > 1.5*maxDesiredSegmentLength) {
//              console.log("recalculating samples", "line number", curIndex+1, "path length:", pathLength, "max segment length:", maxSegmentLength, "num points", pathPoints.length);
              samples = Math.ceil(samples*maxSegmentLength/maxDesiredSegmentLength);
              pathPoints = [];
            } else {
//              console.log("happy with samples", "line number", curIndex+1, "path length:", pathLength, "max segment length:", maxSegmentLength, "num points", pathPoints.length);
              calculateSegments = false;
            }
          }

          // using manual for loop to avoid stack overflow from this:
          //points.push(...pathPoints)
          // See interpreter.test.js's 'extents_test.ngc file interprets without errors' test for more info
          for(let i = 0; i < pathPoints.length; i++) {
            points.push(pathPoints[i]);
          }
        } else {
          const currentLoadedToolLengthOffset = loadedToolSelector(currentState).get("Z")
          const nextLoadedToolLengthOffset = loadedToolSelector(nextState).get("Z")
          const currentWorkPieceSpace = machine.workPieceSpace(currentJoints, currentLoadedToolLengthOffset);
          const nextWorkPieceSpace = machine.workPieceSpace(nextJoints, nextLoadedToolLengthOffset);
          const normal0 = machine.toolOrientation(currentJoints);
          const normal1 = machine.toolOrientation(nextJoints);
          const nx0 = normal0.get("X");
          const ny0 = normal0.get("Y");
          const nz0 = normal0.get("Z");
          const nx1 = normal1.get("X");
          const ny1 = normal1.get("Y");
          const nz1 = normal1.get("Z");
          const x0 = currentWorkPieceSpace.get("X");
          const y0 = currentWorkPieceSpace.get("Y");
          const z0 = currentWorkPieceSpace.get("Z");
          const x1 = nextWorkPieceSpace.get("X");
          const y1 = nextWorkPieceSpace.get("Y");
          const z1 = nextWorkPieceSpace.get("Z");
          const dx = x1-x0;
          const dy = y1-y0;
          const dz = z1-z0;
          const speed = Math.sqrt(dx*dx+dy*dy+dz*dz)/t;
          switch(motionMode) {
            case motionConstants.RAPID:
              points.push({ position: [ x0, y0, z0 ], color: RAPID_COLOR, speed: speed, time: time-t, line: curIndex, normal: [ nx0, ny0, nz0 ] });
              points.push({ position: [ x1, y1, z1 ], color: RAPID_COLOR, speed: speed, time, line: curIndex, normal: [ nx1, ny1, nz1 ] });
              break;
            case motionConstants.LINEAR:
              points.push({ position: [ x0, y0, z0 ], color: FEED_COLOR, speed: speed, time: time-t, line: curIndex, normal: [ nx0, ny0, nz0 ] });
              points.push({ position: [ x1, y1, z1 ], color: FEED_COLOR, speed: speed, time, line: curIndex, normal: [ nx1, ny1, nz1 ] });
              break;
            default:
              console.error("shouldn't get here");
              break;
          }
        }

      }

      times.push(time);

      if(curIndex%linesPerIndex === 0) {
        states.push(nextState);
      }

      currentState = nextState;
      curIndex++;
    }
    resolve({ line: curIndex, states, times, points, messages });
  }));
}

// This saga is called whenever an interpreter.setLines action is dispatched.
// It will parse and interpret those lines in chunks. Since we're doing this on
// the main thread, we dynamically adjust how big the chunks are to try to maintain
// a smooth UI. We could potentially move the processing to a WebWorker so we could
// process more per chunk, but there is also the cost of serializing/deserializing
// messages between the worker and the main thread, so we'll have we test whether 
// there's a real performance benefit from doing it that way. Ideally we could wait until
// create-react-app provides a mechanism for writing workers so we could avoid ejecting.
// See this pull request, https://github.com/facebook/create-react-app/pull/3934, for 
// one implementation of WebWorkers with create-react-app (hopefully it will get merged
// into the main branch). 
export function* handleSetGCodeProgram(action) {
  const startTime = now();

  yield put(actions.interpreter.startProcessing());
  const machineState = yield select(machineStateSelector);
  const lines = yield select(linesSelector);
  const linesPerIndex = yield select(linesPerIndexSelector);

  const interpreter = new GCodeInterpreter({ machineState });
  
  yield put(actions.interpreter.setInitialState(machineState));

  const backplot = BackPlot.getInstance();

  // We'll adjust how many lines we should read in each chunk to stay
  // as responsive as possible. targetTimePerChunk is the number of milliseconds
  // we'd like each chunk to take. We'll record how much time the last chunk
  // took to process, and adjust linesPerYield to compensate in the next chunk.
  // UI hiccups occur during garbage collection and some user interactions, so 
  // this helps to keep things as responsive as possible while we're interpreting.
  let linesPerYield = 10;
  const targetTimePerChunk = 12; // milliseconds
  const targetTimePerChunkNotFocused = 240; // milliseconds
  const minLinesPerChunk = 10;
  const timesPerDispatch = 200;

  backplot.clear();

//  let numStates = 0;

  let time = 0;
  let line = 0;

  let newMessages = [];
  let newTimes = [];
  let newStates = [];
  while(line < lines.length) {
    const t0 = now();
    const chunk = yield call(interpretLines, interpreter, lines, line, linesPerYield, linesPerIndex, time);
    const t1 = now();

    if(document.hasFocus()) {
      linesPerYield = Math.max(Math.floor(linesPerYield*targetTimePerChunk/(t1-t0)), minLinesPerChunk);
    } else {
      linesPerYield = Math.max(Math.floor(linesPerYield*targetTimePerChunkNotFocused/(t1-t0)), minLinesPerChunk);
    }

    newTimes.push(...chunk.times);
    newStates.push(...chunk.states);
    newMessages.push(...chunk.messages);

    time = chunk.times[chunk.times.length-1];
//    numStates += chunk.states.length;
    line = chunk.line;

    backplot.addPoints(chunk.points);

    if(newTimes.length >= timesPerDispatch || line >= lines.length) {
      yield put(actions.interpreter.appendIndices(newStates));
      yield put(actions.interpreter.appendTimes(newTimes));
      yield put(actions.interpreter.appendMessages(newMessages));
      yield put(actions.interpreter.setProcessingProgress(line/lines.length*100));

      newStates = [];
      newTimes = [];
      newMessages = [];
    }
  }
//  console.log("done, numStates: ", numStates, "backplot points: ", backplot.lineObject.numPoints);

  yield put(actions.interpreter.endProcessing());

  if(!action.preview) {
    analytics.timing("interpreter", "parsed gcode", Math.round(now()-startTime));
    const approxRunTime = yield select(approximateTimeSelector);
    analytics.event("interpreter", "calculated approximate run time", "calculated approximate run time", Math.round(approxRunTime*1000));

    const unimplementedMessages = yield select(unimplementedMessagesSelector);
    unimplementedMessages.forEach((m) => {
      analytics.event("interpreter", "unimplemented code " + m.code);
    });
    analytics.send();
  }
}

export default function* watchSetGCodeProgram() {
  yield takeLatest(actions.interpreter.setLines, handleSetGCodeProgram);
}
