//import { put } from 'redux-saga/effects';
//import actions from '../actions';

//const gcode = `%
//G20
//G90 G94 G40 G17
//G53 G0 Z0
//G0 A0 B0
//G0 X0
//M5
//M0
//T4 M6
//G43
//S8500 M3
//F20
//
//G0 A90
//G0 X1 Y1
//G1 Z0
//G1 X-1
//G1 y-1
//G1 X1
//G1 Y1
//
//G49
//G53 G0 Z0
//
//M5
//M30
//%
//`;
//
export default function* loadDefaultGCode() {
  // TODO - do this a different way than using window.location
  // some possible options:
  // - separate the aligner and simulator apps completely using a shared set of
  //   React components like the viewer and avoid needing to check which one 
  //   is active.
  // - integrate some kind of react router and redux bindings to get the location in redux state
  //   where we could select it here.
  // - maybe this is a simple enough case to leave it as is
  // - load gcode into default state and figure out a better way to trigger the inter
  //   the interpreter to process it.
//  if(window.location.hash !== "#aligner") {
//    yield put(actions.interpreter.setLines(gcode.split("\n")));
//  }
}
