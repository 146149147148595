import React, { Component } from 'react';
import Viewer from '../viewer3d';

import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    paddingTop: 64,
    overflow: "hidden",
    position: "fixed"
  }
};

class Viewer3D extends Component {
  updateDimensions = () => {
    const {
      hideTitleBar,
      onUpdateDimensions
    } = this.props;

    const width = window.innerWidth;
    const height = window.innerHeight-( hideTitleBar ? 0 : 64);
    this.viewer.updateDimensions(width, height);
    if(onUpdateDimensions) {
      onUpdateDimensions(width, height);
    }
  };

  componentDidMount() {
    const {
      hideTitleBar,
      onUpdateDimensions
    } = this.props;
    window.addEventListener("resize", this.updateDimensions);

    this.viewer = Viewer.createInstance(this.canvas, this.props);

    const width = window.innerWidth;
    const height = window.innerHeight-( hideTitleBar ? 0 : 64);
    this.viewer.updateDimensions(width, height);
    if(onUpdateDimensions) {
      onUpdateDimensions(width, height);
    }

    const render = () => {
      this.animationRequestID = window.requestAnimationFrame(render);
      this.viewer.step(.01667);
      this.viewer.updateMachine();
      this.viewer.render();
    };

    render();
  }

  componentWillUnmount() {
    if(this.animationRequestID) {
      window.cancelAnimationFrame(this.animationRequestID);
    }
    this.viewer = null;
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidUpdate() {
    this.viewer.update(this.props);
  }

  render() {
    const {
      classes,
      hideTitleBar
    } = this.props;

    return (
      <div className={hideTitleBar ? "" : classes.container}>
        <canvas ref={(canvas) => this.canvas = canvas}/>
      </div>
    );
  }
};

export default withStyles(styles)(Viewer3D);
