import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import pad from '../util/pad';

import getAnalytics from '../util/analytics';

import Slider from './Slider';

//import HomeIcon from '@material-ui/icons/Home';
//import ShuffleIcon from '@material-ui/icons/Shuffle';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import TimerIcon from '@material-ui/icons/AvTimer';

import { withStyles } from '@material-ui/core/styles';

const analytics = getAnalytics();
const enterDelay = 500;

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
  overlay: {
    zIndex: theme.zIndex.drawer + 3,
    position: "absolute",
    left: 0,
    bottom: 80,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    width: "670px",
    maxWidth: "100%",
    textAlign: "center",
    verticalAlign: "middle",
    backgroundColor: "rgba(100,100,100,.75)",
    borderRadius: 5,
    color: "white"
  },
  button: {
    color: "#dddddd"
  },
  left: {
    float: "left",
    width: "33.333%",
    height: "10px",
    textAlign: "left"
  },
  center: {
    float: "left",
    width: "33.333%",
    textAlign: "center"
  },
  right: {
    float: "left",
    width: "33.333%",
    marginBottom: 0,
    textAlign: "right"
  },
  break: {
    lineHeight: 0,
    clear: "both"
  },
  sliderTime: {
    float: "left",
    width: 50,
    marginLeft: 15,
    fontSize: 10,
    lineHeight: "32px",
    verticalAlign: "middle"
  },
  sliderContainer: {
    float: "left",
    width: "calc(100% - 95px)",
    marginLeft: 15,
    marginRight: 15,
  },
  timeSliderContainer: {
    width: "calc(100% - 100px)",
    float: "right",
    marginRight: 15
  },
  timeIconContainer: {
    float: "right",
    lineHeight: "32px",
    cursor: "pointer"
  },
  timeIcon: {
    color: "#dddddd",
    verticalAlign: "middle"
  },
  timeMultiplierLabel: {
    fontSize: "10px",
    marginRight: 5
  }

});

class ControlsOverlay extends Component {
  handleMouseMove = (e) => {
    const {
      visible,
      onShow,
      onHide,
      visibleTimeout
    } = this.props;

    if(!e.buttons || this.clickedInOverlay) {
      if(!visible && onShow) {
        onShow();
      }

      if(onHide && !this.over) {
        if(this.timeout) {
          window.clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          onHide()
        }, visibleTimeout || 2000);
      }
    }
  };

  handleMouseOver = () => {
    this.over = true;
    if(this.timeout) {
      window.clearTimeout(this.timeout);
      this.timeout = null;
    }
  };

  handleMouseOut = () => {
    this.over = false;
    const {
      onHide,
      visibleTimeout
    } = this.props;

    if(onHide) {
      if(this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        onHide()
      }, visibleTimeout || 2000);
    }
  }

  handleMouseDown = () => {
    this.clickedInOverlay = true;
  };

  handleMouseUp = () => {
    this.clickedInOverlay = false;
  };

  componentDidMount() {
    window.addEventListener("touchend", this.handleMouseUp);
    window.addEventListener("touchmove", this.handleMouseMove);
    window.addEventListener("mousemove", this.handleMouseMove);
    window.addEventListener("mouseup", this.handleMouseUp);
  }

  componentWillUnmount() {
    window.removeEventListener("touchend", this.handleMouseUp);
    window.removeEventListener("touchmove", this.handleMouseMove);
    window.removeEventListener("mousemove", this.handleMouseMove);
    window.removeEventListener("mouseup", this.handleMouseUp);
  }

  handlePlay = (e) => {
    const {
      onPlay
    } = this.props;

    analytics.event("ControlsOverlay", "clicked play").send();

    if(onPlay) {
      onPlay(e);
    }
  }

  handlePause = (e) => {
    const {
      onPause
    } = this.props;

    analytics.event("ControlsOverlay", "clicked pause").send();

    if(onPause) {
      onPause(e);
    }
  }
  handleRun = (e) => {
    const {
      onRun
    } = this.props;

    analytics.event("ControlsOverlay", "clicked run").send();

    if(onRun) {
      onRun(e);
    }
  }

  handlePrevious = (e) => {
    const {
      onPrevious
    } = this.props;

    analytics.event("ControlsOverlay", "clicked previous").send();

    if(onPrevious) {
      onPrevious(e);
    }
  }
  handleNext = (e) => {
    const {
      onNext
    } = this.props;

    analytics.event("ControlsOverlay", "clicked next").send();

    if(onNext) {
      onNext(e);
    }
  }
  handleStop = (e) => {
    const {
      onStop
    } = this.props;

    analytics.event("ControlsOverlay", "clicked stop").send();

    if(onStop) {
      onStop(e);
    }
  }

  render() {
    const { 
      classes, 
      visible, 
      paused,
      value,
      timeMultiplierValue,
      currentTime,
      fadeTime, 
      //onHome, 
      //onShuffle, 
      onSlider,
      onTimeSlider
    } = this.props;

    const seconds = Math.floor(currentTime)%60;
    const minutes = Math.floor(currentTime/60)%60;
    const hours = Math.floor(currentTime/60/60);
    const currentTimeString = pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);

    const timePower = 9;
    const timeSliderValue = Math.log(timeMultiplierValue+1)/Math.log(timePower); // make time slider a logarithmic scale

    return (
      <Fade in={visible} timeout={fadeTime || 500}>
        <div className={classes.overlay} 
             onMouseOver={this.handleMouseOver} 
             onMouseOut={this.handleMouseOut}
             onMouseDown={this.handleMouseDown}>
        <div>
          <div className={classes.left}>
          </div>
          <div className={classes.center}>
            <Tooltip title="Previous Point" enterDelay={enterDelay} placement="top" disableFocusListener={true}>
              <IconButton onClick={this.handlePrevious}><SkipPreviousIcon className={classes.button}/></IconButton>
            </Tooltip>
            { 
              paused 
            ? 
              <Tooltip title="Play" enterDelay={enterDelay} placement="top" disableFocusListener={true}>
                <IconButton onClick={this.handlePlay}><PlayIcon className={classes.button}/></IconButton>
              </Tooltip>
            : 
              <Tooltip title="Pause" enterDelay={enterDelay} placement="top" disableFocusListener={true}>
                <IconButton onClick={this.handlePause}><PauseIcon className={classes.button}/></IconButton>
              </Tooltip>
            }
            <Tooltip title="Next Point" enterDelay={enterDelay} placement="top" disableFocusListener={true}>
              <IconButton onClick={this.handleNext}><SkipNextIcon className={classes.button}/></IconButton>
            </Tooltip>
          </div>
          <div className={classes.right}>
            <div className={classes.timeSliderContainer}>
              <Slider min={0} max={1} value={timeSliderValue} trackValue={1} onChange={(e,v) => onTimeSlider(Math.pow(timePower, v)-1)}/>
            </div>
            <div className={classes.timeIconContainer}>
              <Tooltip title="Time Scale" enterDelay={enterDelay} placement="top" disableFocusListener={true}>
                <TimerIcon onClick={(e) => {
                  analytics.event("ControlsOverlay", "clicked reset time scale").send();
                  onTimeSlider(1);
                }} className={classes.timeIcon}/> 
              </Tooltip><span className={classes.timeMultiplierLabel}>{ timeMultiplierValue.toFixed(2) }</span>
            </div>
          </div>
          </div>
          <br className={classes.break}/>
          <div className={classes.sliderControls}>
            <div className={classes.sliderTime}>{ currentTimeString }</div>
            <div className={classes.sliderContainer}>
            <Slider value={value} trackValue={100} onChange={(e, v) => onSlider(v)}/>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

ControlsOverlay.propTypes = {
  visible: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,

  fadeTime: PropTypes.number,
  visibleTime: PropTypes.number,
  onShow: PropTypes.func,
  onHide: PropTypes.func,

  onTogglePane: PropTypes.func,
  onHome: PropTypes.func,
  onShuffle: PropTypes.func,
  onPrevious: PropTypes.func,
  onPlay: PropTypes.func,
  onNext: PropTypes.func
};

export default withStyles(styles)(ControlsOverlay);
