import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  warning: {
    position: "absolute",
    top: 65,
    width: "100%",
    textAlign: "center",
    color: "red",
    fontWeight: "bold",
    fontSize: "15px",
    lineHeight: "30px"
  }
});

export default withStyles(styles)(function(props) {
  const {
    showWarning,
    classes
  } = props;

  if(showWarning) {
    return <div className={classes.warning}>No aligner is active. The standard workflow can be performed as a demonstration, but no data will be saved nor will the final outputs be downloadable.</div>;
  }

  return null;
});
