import React from 'react';
import AlignerViewer3D from '../containers/AlignerViewer3D';
import TitleBar from './TitleBar';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import CameraButtons from '../containers/CameraButtons';
import Checkbox from '@material-ui/core/Checkbox';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import AppBar from '@material-ui/core/AppBar';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import LoopIcon from '@material-ui/icons/Loop';
import MoveIcon from '@material-ui/icons/OpenWith';
import AlignerPanel from './AlignerPanel';
import AlignerLoadingPanel from '../containers/AlignerLoadingPanel';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import AlignerControlsOverlay from '../containers/AlignerControlsOverlay';
import CenterTop from './CenterTop';
import AlignerModelLoader from '../containers/AlignerModelLoader';
import { LOAD_MODEL, POSITION_MODEL, EDIT_PATH, PREVIEW_PATH, ROTATE_MODE, TRANSLATE_MODE } from '../constants/aligner-state';
import { V2, V2_50 } from '../constants/machine-state/machine';
import AlignerDRO from '../containers/AlignerDRO';
import DemoWarning from '../containers/DemoWarning';
import { DRAW, SINGLE_POINT } from '../constants/aligner-state';
import { EXACT, OVERSIZED } from '../constants/aligner-state';
import UnrecoverableError from './UnrecoverableError';
import MyAccount from '../containers/MyAccount';
import StripeAsync from './StripeAsync';
import { showThreads } from '../util/legacy';

const styles = (theme) => ({
  floatingButton: {
    margin: "10px"
  },
  scannerPane: {
    position: "fixed",
    right: 10,
    bottom: 80
  },
  container: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    overflow: "hidden"
  },
  droContainer: {
    position: "absolute",
    zIndex: 10,
    right: "20px",
    top: "180px",
    textAlign: "right"
  },
  topSpacing: {
    marginTop: "10px"
  },
  appBar: {
    top: 'auto',
    bottom: 0
  },
  activeStep: {
    fontWeight: "bold"
  },
  stepper: {
    root: {
      zIndex: 1201
    }
  },
  activeAligner: {
    marginBottom: "10px",
    paddingBottom: "10px"
  },
  connectedScanners: {
    fontSize: "10px"
  },
  label: {
    //fontWeight: "bold",
    fontSize: "20px",
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "5px"
  },
  div: {
    marginTop: "10px",
    marginBottom: "10px"
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiStepLabel: {
      label: {
        '&$active': {
          fontWeight: "900 !important"
        }
      }
    }
  }
});

/*
const onPairScanner = () => {
  if(navigator.usb) {
    navigator.usb.requestDevice({ filters: [
      // Arduino Leonardo boards
      { vendorId: 0x2341, productId: 0x0036 }, 
      { vendorId: 0x2341, productId: 0x8036 },
      { vendorId: 0x2A03, productId: 0x0036 },
      { vendorId: 0x2A03, productId: 0x8036 },

      // Adafruit Trinket M0 boards
      { vendorId: 0x239A, productId: 0x801E }
    ] }).catch((error) => { 
      console.error(error);
    });
  }
};
*/

export default withStyles(styles)((props) => {
  const { 
    classes,
    hideTitleBar,
    machine,

    modelLoaded,
    finalizeModelClicked,
    finishedProcessingModel,
    step,
    label,
    //scannersDetected,

    jumpToLoadModelStep,
    jumpToPositionModelStep,
    jumpToEditPathStep,
    jumpToPreviewPathStep,
    //jumpToCutPathStep,
    onFinalize,
    onDownloadProcessedModel,
    onDownloadGCode,
    onRotateCCW,
    onRotateCW,
    showDownloadGCodeButton,
    closePath,
    onClosePath,
    onChangeMachine,
    onImportTransformAndPoints,
    onCancelImport,
    showImportTransformAndPoints,
    showCancelImportTransformAndPoints,
    addBridge,
    addCylinders,
    snapFit,
    onChangeAddBridge,
    onChangeAddCylinders,
    user,
    onRotateMode,
    onTranslateMode,
    onSetDraw,
    onSetExact,
    onSetOversized,
    drawMode,
    onSetSinglePoint,
    transformMode,
    creatingAligner,
    onSaveCreateAlignerPosition,
    onCancelCreateAlignerPosition,
    positioningModel,
    unrecoverableError,
    onDownloadAllProcessedModels,
    onDownloadAllGCode,
    onCopyPoints,
    showDownloadAllProcessedModels,
    showDownloadAllGCode,
    showCopyPoints,
    previousAlignerID,
    previousAlignerLabel,
    onImportPreviousPoints,
    onMyAccount,
    canMakePurchases
  } = props;

  return (
  <MuiThemeProvider theme={theme}>
    <div className={classes.container}>
      { hideTitleBar ? null : <TitleBar user={user} hideButtons={true} title="Pocket NC Dental Aligners" onMyAccount={onMyAccount} canMakePurchases={canMakePurchases}/> }
      <div className={classes.droContainer}>
        <AlignerDRO/>
      </div>

      <AlignerViewer3D showDentalFixture={true}/>
      { step === PREVIEW_PATH ? <AlignerControlsOverlay/> : null }
      { !creatingAligner ? 
      <>
        <AlignerPanel>
          <div className={classes.activeAligner}><span className={classes.label}>{ label ? label : "No Active Aligner" }</span></div>
          <AlignerModelLoader color={label ? "primary" : "default"} variant="contained" label="Load Model" showSnackbarOnLoad={true} hideButton={step !== LOAD_MODEL} maxFileSize={31457280}/>
          { step === POSITION_MODEL ? 
            <div className={classes.div}>
              <Tooltip title="When checked, a bridge will be added to the model with the aligner code embedded in it. This cannot be changed after the position is finalized." placement="right-end">
                <FormControlLabel control={<Checkbox checked={addBridge} onChange={onChangeAddBridge}/>} label="Add Label Bridge"/>
              </Tooltip><br/>
              <Tooltip title="When checked, cylinders of extra material will be added around the fixture features. This can be helpful if the features don't fit nicely into your model and need some extra material to attach the model to on the fixture. This cannot be changed after the position is finalized." placement="right-end">
                <FormControlLabel control={<Checkbox checked={addCylinders} onChange={onChangeAddCylinders}/>} label={ "Add Cylinders Around " + (showThreads ? "Threads" : "Snaps") }/>
              </Tooltip><br/>

              { !showThreads ?
              (<>
              <Tooltip title="When checked, the snap integrated into the model during processing will have exactly the same diameter as the fixture spheres. Use this option if the default Oversized Fit option is too loose after 3D printing. 3D printers print slightly differently from machine to machine, so this option allows you to dial in the best fit for your process." placement="right-end">
                <FormControlLabel control={<Radio value={ EXACT } color="default" checked={snapFit === EXACT} onClick={onSetExact}/>} label="Exact Fit"/>
              </Tooltip><br/>
              <Tooltip title="When checked, the snap integrated into the model during processing will have a slightly larger diameter than the fixture spheres. This option is the default. 3D printers print slightly differently from machine to machine, so this option allows you to dial in the best fit for your process." placement="right-end">
                <FormControlLabel control={<Radio value={ OVERSIZED } color="default" checked={snapFit === OVERSIZED} onClick={onSetOversized}/>} label="Oversized Fit"/>
              </Tooltip><br/>
              </>)
              : null}

              <Button variant="contained" color="primary" onClick={onFinalize}>Finalize Position</Button>
            </div> : null }
          { showImportTransformAndPoints ? <div className={classes.div}><Button variant="contained" color={ showCancelImportTransformAndPoints ? "secondary" : "default" } onClick={showCancelImportTransformAndPoints ? onCancelImport : onImportTransformAndPoints}>{ showCancelImportTransformAndPoints ? "Cancel Import" : "Import Model Position and Points" }</Button></div> : null }

          { finishedProcessingModel ? <div className={classes.div}><Button variant="contained" color="default" onClick={onDownloadProcessedModel}>Download Model</Button></div> : null }
          { showDownloadGCodeButton ? <div className={classes.div}><Button variant="contained" color="default" onClick={onDownloadGCode}>Download G Code</Button></div> : null }

          { showCopyPoints ? <div className={classes.div}><Button variant="contained" color="default" onClick={onCopyPoints}>Copy Points To Unfinished Aligners In Set</Button></div> : null }

          { showDownloadAllProcessedModels ? <div className={classes.div}><Button variant="contained" color="default" onClick={onDownloadAllProcessedModels}>Download Aligner Set Models</Button></div> : null }
          { showDownloadAllGCode ? <div className={classes.div}><Button variant="contained" color="default" onClick={onDownloadAllGCode}>Download Aligner Set G Code</Button></div> : null }

          { step === EDIT_PATH ? (
       <div>
           <Radio value={ SINGLE_POINT } color="default" checked={drawMode === SINGLE_POINT} onClick={onSetSinglePoint}/> Single Point<br/>
           <Radio value={ DRAW } color="default" checked={drawMode === DRAW} onClick={onSetDraw}/> Draw<br/>
           { previousAlignerID ? <Button variant="contained" onClick={onImportPreviousPoints}>Import Points from {previousAlignerLabel}</Button> : null }
       </div>) : null }
          { (step === EDIT_PATH && !closePath) ? (<div className={classes.div}><Button variant="contained" color="primary" onClick={onClosePath}>Finish Tool Path</Button></div>) : null }
        </AlignerPanel>
        <DemoWarning/>
      </>
      :
      <>
       { positioningModel ?
        <AlignerPanel>
          <div className={classes.div}>
            <Tooltip title="When checked, a bridge be added to the model with the aligner code embedded in it. This cannot be changed after the position is finalized." placement="right-end">
              <FormControlLabel control={<Checkbox checked={addBridge} onChange={onChangeAddBridge}/>} label="Add Label Bridge"/>
            </Tooltip><br/>
            <Tooltip title="When checked, cylinders of extra material will be added around the fixture features. This can be helpful if the features don't fit nicely into your model and need some extra material to attach the model to on the fixture. This cannot be changed after the position is finalized." placement="right-end">
              <FormControlLabel control={<Checkbox checked={addCylinders} onChange={onChangeAddCylinders}/>} label={ "Add Cylinders Around " + (showThreads ? "Threads" : "Snaps") }/>
            </Tooltip><br/>

              { !showThreads ?
              (<>
              <Tooltip title="When checked, the snap integrated into the model during processing will have exactly the same diameter as the fixture spheres. Use this option if the default Oversized Fit option is too loose after 3D printing. 3D printers print slightly differently from machine to machine, so this option allows you to dial in the best fit for your process." placement="right-end">
                <FormControlLabel control={<Radio value={ EXACT } color="default" checked={snapFit === EXACT} onClick={onSetExact}/>} label="Exact Fit"/>
              </Tooltip><br/>
              <Tooltip title="When checked, the snap integrated into the model during processing will have a slightly larger diameter than the fixture spheres. This option is the default. 3D printers print slightly differently from machine to machine, so this option allows you to dial in the best fit for your process." placement="right-end">
                <FormControlLabel control={<Radio value={ OVERSIZED } color="default" checked={snapFit === OVERSIZED} onClick={onSetOversized}/>} label="Oversized Fit"/>
              </Tooltip><br/>
              </>)
              : null}
          </div>
          { showImportTransformAndPoints ? <div className={classes.div}><Button variant="contained" color={ showCancelImportTransformAndPoints ? "secondary" : "default" } onClick={showCancelImportTransformAndPoints ? onCancelImport : onImportTransformAndPoints}>{ showCancelImportTransformAndPoints ? "Cancel Import" : "Import Model Position and Points" }</Button></div> : null }
          <Button variant="contained" color="primary" onClick={onSaveCreateAlignerPosition}>Save Position</Button><br/>
          <Button variant="contained" color="secondary" onClick={onCancelCreateAlignerPosition} classes={ { root: classes.topSpacing } }>Cancel</Button>
        </AlignerPanel>
        : null }
      </>
      }
      { 
        step === POSITION_MODEL 
        ? 
          <CenterTop>
            <Button variant="fab" color="default" className={classes.floatingButton} onClick={onRotateCCW}><RotateLeftIcon/></Button>
            <Button variant="fab" color="default" className={classes.floatingButton} onClick={onRotateCW}><RotateRightIcon/></Button>

            <Button variant="fab" color={ transformMode === ROTATE_MODE ? "secondary" : "default" } className={classes.floatingButton} onClick={onRotateMode}><LoopIcon/></Button>
            <Button variant="fab" color={ transformMode === TRANSLATE_MODE ? "secondary" : "default" } className={classes.floatingButton} onClick={onTranslateMode}><MoveIcon/></Button>
          </CenterTop> 
        : null 
      }
      <AlignerLoadingPanel/>
      { step !== EDIT_PATH ? <CameraButtons showDentalFixture={true}/> : null }

     <div className={classes.scannerPane}>
         <Radio value="V2-10" name="V2-10" color="default" checked={machine === V2} onClick={() => onChangeMachine(V2)}/> V2-10<br/>
         <Radio value="V2-50" name="V2-50" color="default" checked={machine === V2_50} onClick={() => onChangeMachine(V2_50)}/> V2-50<br/>
        {/* scannersDetected > 0 ? <span className={classes.connectedScanners}>{scannersDetected + " Connected Scanner" + (scannersDetected > 1 ? "s" : "") +"." }</span> :
            <div><Button variant="contained" color="primary" onClick={onPairScanner}>Pair Scanner</Button> </div>
          */}

     </div>
     { unrecoverableError ? <UnrecoverableError/> : null }
     <StripeAsync><MyAccount/></StripeAsync>
      <AppBar className={classes.appBar}>
        <Stepper nonLinear activeStep={step} classes={{ root: classes.stepper }}>
          <Step disabled={finalizeModelClicked || !label} active={step === LOAD_MODEL && !!label}>
            <StepButton onClick={jumpToLoadModelStep} completed={modelLoaded}>Load Model</StepButton>
          </Step>
          <Step disabled={!modelLoaded || finalizeModelClicked} active={step === POSITION_MODEL}>
            <StepButton onClick={jumpToPositionModelStep} completed={finalizeModelClicked}>Position Model</StepButton>
          </Step>
          <Step disabled={!finalizeModelClicked} active={step === EDIT_PATH}>
            <StepButton onClick={jumpToEditPathStep} completed={false}>Edit Path</StepButton>
          </Step>
          <Step disabled={!finalizeModelClicked} active={step === PREVIEW_PATH}>
            <StepButton onClick={jumpToPreviewPathStep} completed={false}>Preview Path</StepButton>
          </Step>
{
//          <Step disabled={true} active={step === CUT_PATH}>
//            <StepButton onClick={jumpToCutPathStep} completed={false}>Cut Path</StepButton>
//          </Step>
}
        </Stepper>
      </AppBar>
    </div>
  </MuiThemeProvider>
  );

});
