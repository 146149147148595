import { lookUpModalGroup } from './modal-groups';
import { usesPositionalArguments } from './positional-arguments';
import { lookUpUnitsCommand, lookUpNotImplemented, setPosition, lookUpNonModalCommand, lookUpCommand, lookUpParameterCommand } from './commands';
import machines from '../machines';

import { parseLine } from 'gcode-parser';

import motionActions from '../actions/machine-state/motion';

import { createStore } from 'redux';
import machineReducer from '../reducers/machine-state';
import * as messages from '../util/messages';

class GCodeInterpreter {
  constructor(options) {
    const {
      machineState
    } = options;

    this.store = createStore( machineReducer, machineState );
  }

  getState = () => {
    return this.store.getState();
  };

  interpret = (gcode) => {
    this.messages = [];

    const groups = {};
    const positionalCommands = [];

    // gcode-parser gets us an easily consumable representation of each line
    // but doesn't provide syntax checking or more advanced GCode features such as 
    // expressions. We'll likely need to implement our own parser at some point.
    const line = parseLine(gcode);

    line.words.forEach(([word, number], lineIndex) => {
      const code = word+number;
      const modalGroup = lookUpModalGroup(code);

      if(modalGroup !== undefined) {
        if(groups[modalGroup]) {
          groups[modalGroup].push(code)
        } else {
          groups[modalGroup] = [ code ];
        }
      }

      if(usesPositionalArguments(code)) {
        positionalCommands.push(code);
      }
    });

    if(positionalCommands.length > 1) {
      this.messages.push(messages.MultipleCommandsWithPositionalArgs(positionalCommands));
    }
    for(let group in groups) {
      if(groups[group].length > 1) {
        this.messages.push(messages.MultipleCommandsInModalGroup(groups[group], group));
      }
    }

    const args = {}; // WARNING - args is mutable, so we are creating actions ahead of time with this object as their payload and then dispatching them all at the end 
    const parameterActions = [];
    const unitsActions = [];
    const actions = [];

    let hasSetToolLengthOffset = false;

    line.words.forEach((word, i) => {
      const parameterCommand = lookUpParameterCommand(word[0]); 
      if(parameterCommand) {
        parameterActions.push([ parameterCommand, word[1] ]);
      }

      const code = word[0]+word[1];

      if(code === "G43") {
        // We want to report tool length offset changes
        // to the summary tab, so it can generate
        // a message about used tools and an interface
        // for adjusting the tool table.
        hasSetToolLengthOffset = true;
      }

      const notImplementedCommand = lookUpNotImplemented(code);

      const unitsCommand = lookUpUnitsCommand(code);
      if(unitsCommand) {
        unitsActions.push(unitsCommand());
      }

      const command = lookUpCommand(code);
      if(command) {
        actions.push(command(args))
      }

      const nonModalCommand = lookUpNonModalCommand(code);
      if(nonModalCommand) {
        args[code] = true;
      }

      if(notImplementedCommand) {
        this.messages.push(messages.UnimplementedCode(code));
      }

      if(!unitsCommand && !notImplementedCommand && !nonModalCommand && !command && !parameterCommand) {
        args[word[0]] = word[1];
        
        if(word[0] === "G" || word[0] === "M") {
          this.messages.push(messages.UnrecognizedCode(code))
        }
      }
    });

    let hasMotionCommand = groups[1];

    // if no positional commands are provided, but we have positional arguments, assume the previous motion command by
    // issuing a SET_POSITION action
    if(positionalCommands.length === 0) {

      // this checks if any position arguments exist in the args map
      if(["X", "Y", "Z", "A", "B", "C", "I", "J", "K"].reduce((a, b) => a || args[b] !== undefined, false)) {
        actions.push(setPosition(args));
        hasMotionCommand = true;
      }
    }

    if(!hasMotionCommand) {
      actions.push(motionActions.machineState.motion.nonMotionCommand());
    }

    unitsActions.forEach((action) => {
      this.store.dispatch(action);
    });

    const stateAfterUnits = this.store.getState();
    const units = stateAfterUnits.get("units");

    args.units = units;

    parameterActions.forEach(([ actionCreator, arg ]) => {
      this.store.dispatch(actionCreator(arg));
    });

    actions.forEach((action) => {
      this.store.dispatch(action);
    });

    if(hasMotionCommand) {
      const state = this.store.getState();
      const machine = machines[state.get("machine")];

      this.messages = this.messages.concat(machine.getLimitErrors(state.get("joints")));
    }

    const nextState = this.store.getState();

    if(hasSetToolLengthOffset) {
      const tool = nextState.get("tool");
      const loadedToolNumber = tool.get("loaded");

      let toolNumber = loadedToolNumber;
      if(typeof args.H !== 'undefined') {
        toolNumber = args.H;
      }

      this.messages.push(messages.SetToolLengthOffset(toolNumber, tool.get("toolLengthOffset").get("Z"), tool.get("toolLengthOffset").get("R")*2, tool.get("toolLengthOffset").get("holder")))

      if(loadedToolNumber !== toolNumber) {
        this.messages.push(messages.ToolLengthOffsetCommandDiffersFromLoadedTool(toolNumber, loadedToolNumber));
      }
    }

    return nextState;
  };
}

export default GCodeInterpreter;
