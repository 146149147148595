import actions from '../../actions/interpreter';
import { handleActions } from 'redux-actions';
import Immutable from 'immutable';
import { EPS } from '../../constants';

// An index is a cached machineState associated with a specific line of gcode.
// These variables balance speed of computation with memory usage by adjusting how many indices 
// we keep track of. To look up the machine state for a given line of the gcode program we'll 
// look up the closest previous index as a starting state, then reinterpret up to the desired line.
const MAX_LINES_PER_INDEX = 300;
const TARGET_INDICES = 50000;

const setCurrentLineWithTime = (state, currentTime) => {
  const times = state.get("times");

  let currentIndex = 0;
  if(currentTime >= times.get(times.size-1)) {
    currentIndex = times.size-1;
    currentTime = times.get(currentIndex);
  } else if(currentTime === 0) {
    currentIndex = 0;
  } else {
    let min = 0;
    let max = times.size-1;
    let mid = Math.floor(times.size/2);
    while( min+1 < max ) {
      if(times.get(mid) >= currentTime) {
        max = mid;
      } else {
        min = mid;
      }
      mid = Math.floor((min+max)*.5);
    }
    if(currentTime < times.get(min)) {
      currentIndex = min;
    } else {
      currentIndex = max;
    }
  }

  return state.withMutations((state) => 
           state.set("currentLine", currentIndex)
                .set("currentTime", currentTime)
         );
};

export const defaultState = Immutable.Map({
  fileName: "sim.ngc",
  lines: [], // WARNING, mutable data type. Still should be treated as immutable
             // but for performance reasons, leave as a regular array.
             // It is fed directly to ace editor insertLines. It does still hiccup
             // when loading huge files, so perhaps we can go back to an Immutable
             // type and insert them in chunks into the ace editor, similar to how
             // we parse/interpret the file once loaded in.
  messages: Immutable.List([]),
  states: Immutable.List([]), // indices (cached machineStates). The number of elements will be lines.length/linePerIndex.
  initialState: Immutable.Map({}), // initial state of machine before interpretting starts
  times: Immutable.List([]),  // same size as lines, each element is the total real-world time it takes to finish through the current line (so it's an ascending sorted list of times, and the last element is the total time it takes to run the program)
  linesPerIndex: 1,
  loading: false,
  loadingProgress: 0,
  processing: false,
  processingProgress: 0,
  currentLine: 0,
  totalTime: 0,
  currentTime: 0,
  paused: true,
  running: false,
  timeMultiplier: 1
});

const reducer = handleActions(
  {
    [actions.interpreter.startLoading]: (state, action) => 
      state.withMutations((state) => 
        state.set("lines", [])
             .set("loadingProgress", 0)
             .set("loading", true)
             .set("currentLine", 0)
             .set("currentTime", 0)
             .set("totalTime", 0)
             .set("states", Immutable.List([]))
             .set("times", Immutable.List([]))
             .set("messages", Immutable.List([]))
      ),
    [actions.interpreter.endLoading]: (state, action) => state.set("loading", false),
    [actions.interpreter.setFileName]: (state, action) => state.set("fileName", action.payload),
    [actions.interpreter.setLoadingProgress]: (state, action) => state.set("loadingProgress", action.payload),
    [actions.interpreter.setLines]: (state, action) => 
      state.withMutations((state) =>
        state.set("lines", action.payload.lines)
             .set("currentLine", 0)
             .set("currentTime", 0)
             .set("paused", true)
             .set("running", true)
             .set("linesPerIndex", Math.max(Math.min(Math.ceil(action.payload.lines.length/TARGET_INDICES), MAX_LINES_PER_INDEX), 1))
             .set("states", Immutable.List([]))
             .set("times", Immutable.List([]))
             .set("messages", Immutable.List([]))
      ),
    [actions.interpreter.startProcessing]: (state, action) => 
      state.withMutations((state) => 
        state.set("processingProgress", 0)
             .set("processing", true)
             .set("states", Immutable.List([]))
             .set("times", Immutable.List([]))
             .set("messages", Immutable.List([]))
      ),
    [actions.interpreter.endProcessing]: (state, action) => state.set("processing", false),
    [actions.interpreter.setProcessingProgress]: (state, action) => state.set("processingProgress", action.payload),
    [actions.interpreter.setInitialState]: (state, action) => state.set("initialState", action.payload),
    [actions.interpreter.appendIndices]: (state,action) => state.set("states", state.get("states").push(...action.payload)),
    [actions.interpreter.appendMessages]: (state,action) => state.set("messages", state.get("messages").push(...action.payload)),
    [actions.interpreter.appendTimes]: (state,action) => {
      const times = state.get("times").push(...action.payload);
      const lines = state.get("lines");

      // append times and update totalTime with an approximate time (when fully loaded will be exact)
      return state.withMutations((state) => 
               state.set("times", times)
                    .set("totalTime", times.get(times.size-1)*lines.length/times.size)
             );
    },
    [actions.interpreter.pause]: (state, action) => state.set("paused", true),
    [actions.interpreter.stop]: (state, action) => state.set("running", false),
    [actions.interpreter.unpause]: (state, action) => {
      if(state.get("currentTime") >= state.get("totalTime")-EPS) {
        state = state.set("currentTime", 0).set("currentLine", 0);
      }
      return state.set("paused", false);
    },
    [actions.interpreter.run]: (state, action) => state.set("running", true),
    [actions.interpreter.setTimeMultiplier]: (state, action) => state.set("timeMultiplier", action.payload),
    [actions.interpreter.previousLine]: (state,action) => {
      const prevLine = Math.max(state.get("currentLine")-1, 0);
      const prevTime = state.get("times").get(prevLine);
      return state.set("currentLine", prevLine).set("currentTime", prevTime).set("paused", true);
    },
    [actions.interpreter.nextLine]: (state,action) => {
      const nextLine = Math.min(state.get("currentLine")+1, state.get("lines").length-1);
      const nextTime = state.get("times").get(nextLine);
      return state.set("currentLine", nextLine).set("currentTime", nextTime);
    },
    [actions.interpreter.setCurrentLine]: (state,action) => {
      const currentLine = Math.max(0, Math.min(state.get("lines").length-1, action.payload));
      const currentTime = state.get("times").get(currentLine);

      return state.set("currentLine", currentLine).set("currentTime", currentTime);
    },
    [actions.interpreter.incrementTime]: 
      (state,action) => {
        const currentTime = state.get("currentTime");
        const timeMultiplier = state.get("timeMultiplier");
        const dt = action.payload*timeMultiplier;
        state = setCurrentLineWithTime(state, currentTime+dt);

        if(state.get("currentTime") >= state.get("totalTime")) {
          return state.set("paused", true);
        }
        return state;
      },
    [actions.interpreter.setCurrentLineWithTime]: (state,action) => 
      setCurrentLineWithTime(state, action.payload),
    [actions.interpreter.setCurrentLineWithPercentage]: (state,action) => 
      setCurrentLineWithTime(state, state.get("totalTime")*action.payload/100).set("paused", true)
  },
  defaultState
);

export default reducer;
