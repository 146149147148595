import { connect } from 'react-redux';
import actions from '../actions';

import AlignerControlsOverlay from '../components/AlignerControlsOverlay';

export default connect(
  (state, ownProps) => ({
    visible: state.get("ui").get("controlsOverlay").get("visible"),
    value: state.get("alignerState").get("time")/state.get("alignerState").get("times").last()*100,
    currentTime: state.get("alignerState").get("time"),
    totalTime: state.get("alignerState").get("times").last(),
    paused: state.get("alignerState").get("paused"),
    timeMultiplierValue: state.get("alignerState").get("timeMultiplier")
  }),
  (dispatch, ownProps) => ({
    onShow: () => dispatch(actions.ui.controlsOverlay.setVisible(true)),
    onHide: () => dispatch(actions.ui.controlsOverlay.setVisible(false)),
    onPrevious: () => dispatch(actions.alignerState.prevPoint()),
    onPlay: () => dispatch(actions.alignerState.play()),
    onPause: () => dispatch(actions.alignerState.pause()),
    onNext: () => dispatch(actions.alignerState.nextPoint()),
    onSlider: (v) => dispatch(actions.alignerState.setTimeWithPercentage(v)),
    onTimeSlider: (v) => dispatch(actions.alignerState.setTimeMultiplier(v))
  })
)(AlignerControlsOverlay);
