export const positionalArguments = {
  // non-modal codes
  "G10": true,
  "G92": true,

  // motion
  "G0": true,
  "G1": true,
  "G2": true,
  "G3": true,
  "G33": true,
  "G38.2": true,
  "G73": true,
  "G76": true,
  "G81": true,
  "G82": true,
  "G83": true,
  "G84": true,
  "G85": true,
  "G86": true,
  "G87": true,
  "G88": true,
  "G89": true,

  // tool length offset
  "G43.1": true,
};

export const usesPositionalArguments = (code) => {
  return positionalArguments[code];
};
