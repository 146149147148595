import { combineReducers } from 'redux-immutable';
import reduceReducers from 'reduce-reducers';
import { combineActions, handleActions } from 'redux-actions';

import { calculatePosition } from '../../util/motion-math';

import feedRateMode from './feed-rate-mode';
import feedRate from './feed-rate';
import planeSelect from './plane-select';
import units from './units';
import tool from './tool';
import kinematicsMode from './kinematics-mode';
import cutterCompensation from './cutter-compensation';
import spindleSpeed from './spindle-speed';
import spindleControl from './spindle-control';
import machine from './machine';
import motion, { doForwardKinematics } from './motion';
import joints, { doInverseKinematics } from './joints';

import machines from '../../machines';

import GCodeInterpreter from '../../gcode/interpreter';

import actions from '../../actions';

// fullState is the whole redux state tree after all regular actions have been reduced
export const machineStateWithState = (fullState) => handleActions({
  [combineActions(
    actions.interpreter.previousLine,
    actions.interpreter.nextLine,
    actions.interpreter.incrementTime,
    actions.interpreter.setCurrentLineWithPercentage,
    actions.interpreter.setCurrentLineWithTime,
    actions.interpreter.setCurrentLine
  )]: (state, payload) => {
    const currentLine = fullState.get("interpreter").get("currentLine");
    const prevLine = currentLine-1;

    const linesPerIndex = fullState.get("interpreter").get("linesPerIndex");

    const totalStates = fullState.get("interpreter").get("states").size;

    if(totalStates > 0) {
      const index = Math.min(Math.floor(prevLine/linesPerIndex), totalStates-1);
      const closestPreviousState = index < 0 ? 
        fullState.get("interpreter").get("initialState") :
        fullState.get("interpreter").get("states").get(index);
      const line = Math.max(index*linesPerIndex, -1);

      let prevState = closestPreviousState;

      const lines = fullState.get("interpreter").get("lines");
      let interpreter = null;
      let currentState = null;
      const currentIndex = Math.min(Math.floor(currentLine/linesPerIndex), totalStates-1);
      if(prevLine > line || currentIndex*linesPerIndex !== currentLine) {
        interpreter = new GCodeInterpreter({ machineState: prevState });

        //console.log("interpreting ", prevLine-line, " lines");
        let l = line;
        while(l <= prevLine) {
          prevState = interpreter.interpret(lines[l]);
          l++;
        }
      }

      if(currentIndex*linesPerIndex === currentLine) {
        // if the current line is in our list of indexed states, use it
        currentState = fullState.get("interpreter").get("states").get(currentIndex);
      } else {
        // otherwise, interpret from the previous state
        //console.log("interpreting from previous state");
        currentState = interpreter.interpret(lines[currentLine]);
      }

      //console.log(prevLine, prevState.toJS());
      //console.log(currentLine, currentState.toJS());

      const currentMachine = machines[currentState.get("machine")];

// instead of getting the previous position like so:
//      const prevPos = prevState.get("motion").get("position");
// we must calculate forward kinematics using the current state in case there
// were any state changes in the current line that would result in a change to the work piece
// space such as a G43, G49, G60, etc.
      const prevJoints = prevState.get("joints");
      const prevPos = currentMachine.kinematics.forwardKinematics(currentState.set("joints", prevJoints));
      const currentPos = currentState.get("motion").get("position");

      const times = fullState.get("interpreter").get("times");
      const prevTime = prevLine < 0 ? 0 : times.get(prevLine);;
      const nextTime = times.get(currentLine);
      const currentTime = fullState.get("interpreter").get("currentTime");

      let t = 1;
      if(nextTime-prevTime > 0) {
        t = (currentTime-prevTime)/(nextTime-prevTime);
      }

      const motionMode = currentState.get("motion").get("mode");
      const turns = currentState.get("motion").get("turns");
      const planeSelect = currentState.get("planeSelect");
      const pos = calculatePosition(motionMode, prevPos, currentPos, t, planeSelect, turns);

      let nextState = currentState.set("motion", currentState.get("motion").set("position", pos));
      const joints = currentMachine.kinematics.inverseKinematics(nextState);

      //console.log("setting joints", joints.toJS());

      return nextState.set("joints", joints);
    }

    return state;
  }
}, fullState.get("machineState"));

const reducer = combineReducers({
  machine,
  feedRateMode,
  feedRate,
  units,
  tool,
  planeSelect,
  kinematicsMode,
  cutterCompensation,
  spindleSpeed,
  spindleControl,
  motion,
  joints
});

export default reduceReducers(
  reducer, 
  (state, action) => state.set("joints", doInverseKinematics(state)(state.get("joints"), action)),
  (state, action) => state.set("motion", doForwardKinematics(state)(state.get("motion"), action))
);
