import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  div: {
    position: "fixed",
    marginLeft: "auto",
    marginRight: "auto",
    width: "320px",
    height: "50px",
    zIndex: 1300,
    left: 0,
    right: 0,
    top: 80

  }
};

export default withStyles(styles)(function CenterTop(props) {
  const {
    classes,
    children
  } = props;

  return (<div className={classes.div}>
  {children}
  </div>);
});
