import React from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  droNoTitleBar: {
    borderRadius: 10,
    padding: "10px",
    backgroundColor: "rgba(100,100,100,.75)",
    color: "white",
    marginTop: 10,
//    position: "absolute",
//    right: "150px",
//    top: "20px",
    textAlign: "right"
  },
  dro: {
    borderRadius: 10,
    padding: "10px",
    backgroundColor: "rgba(100,100,100,.75)",
    color: "white",
    marginTop: 10,
//    position: "absolute",
//    right: "150px",
//    top: "85px",
    textAlign: "right"
  },
  pre: {
    padding: 0,
    margin: 0
  }
});

export default withStyles(styles)((props) => {
  const {
    classes,
    hideTitleBar,
    X,Y,Z,A,B
  } = props;

  return (<div className={hideTitleBar ? classes.droNoTitleBar : classes.dro }>
  <pre className={classes.pre}>
  { typeof(X) !== "undefined" ? (<>X: { X }<br/></>) : null }
  { typeof(Y) !== "undefined" ? (<>Y: { Y }<br/></>) : null }
  { typeof(Z) !== "undefined" ? (<>Z: { Z }<br/></>) : null }
  A: { A }<br/>
  B: { B }
  </pre>
  </div>)
});
