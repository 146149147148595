// This constant (QUERY_LIMIT) is defined on the server
// and is the maximum number of aligners 
// returned from a quick lookup. If a query
// returns less than this limit than we know
// we have all the aligners that begin with
// that prefix, so we don't need to fetch a
// more specific query. For example, if
// the quick look of of 'j' returns 20 aligners
// we don't need to perform another query
// for 'ja' because it will only have fewer or
// the same number of items.
// If the query of 'j' returns 100 aligners,
// 'ja' could return aligners that aren't included
// in in the 'j' query.
// QuickLookupCache's purpose is to make those determinations
// and return a cached query if we have one that isn't stale
// or return null which means a query needs to be made to the
// server.
const QUERY_LIMIT = 25;
const MAX_AGE = 600; // max age of a cached query in seconds
                     // it can be fairly large as the cache
                     // is cleared on refresh and is updated
                     // when aligners are created or accessed

export default class QuickLookupCache {
  static instance;

  static getInstance() {
    if(!this.instance) {
      this.instance = new QuickLookupCache();
    }
    return this.instance;
  }

  constructor() {
    this.cache = {};
  }

  clear() {
    this.cache = {};
  }

  getQuery(prefix) {
    prefix = prefix.toUpperCase();
    const now = Date.now()/1000;
    if(this.cache[prefix] && this.cache[prefix].timestamp+MAX_AGE >= now) {
      // if we have results for the exact prefix and it's not stale, return the results
      return this.cache[prefix].results;
    } else {
      // otherwise,
      // search for the longest prefix that we do have that has a number of results 
      // that is under the QUERY_LIMIT and isn't stale and return the results for
      // that prefix
      let longestPrefix = null;
      Object.keys(this.cache).forEach((p) => {
        if(prefix.startsWith(p) && 
           (longestPrefix === null || p.length > longestPrefix.length) && 
           this.cache[p].timestamp+MAX_AGE >= now &&
           this.cache[p].results.length < QUERY_LIMIT) {
          longestPrefix = p;
        }

        // delete prefixes with stale results as we find them
        if(this.cache[p].timestamp+MAX_AGE < now) {
          delete this.cache[p];
        }

      });

      if(this.cache[longestPrefix]) {
        return this.cache[longestPrefix].results;
      }
    }
    // if we still don't have any results, return null to indicate that
    return null;
  }

  setQuery(prefix, results) {
    const now = Date.now()/1000;
    this.cache[prefix.toUpperCase()] = {
      timestamp: now,
      results
    };
  }

  addResult(aligner) {
    const now = Date.now()/1000;
    Object.keys(this.cache).forEach((prefix) => {
      if(aligner.label.startsWith(prefix)) {
        this.cache[prefix].results.unshift(aligner);
      }

      // delete stale prefixes
      if(this.cache[prefix].timestamp+MAX_AGE < now) {
        delete this.cache[prefix];
      }

    });
  }

  updateResult(aligner) {
    const now = Date.now()/1000;
    Object.keys(this.cache).forEach((prefix) => {
      if(aligner.label.startsWith(prefix)) {
        this.cache[prefix].results= this.cache[prefix].results.filter((a) => a.alignerID !== aligner.alignerID); 
        this.cache[prefix].results.unshift(aligner);
      }

      // delete stale prefixes
      if(this.cache[prefix].timestamp+MAX_AGE < now) {
        delete this.cache[prefix];
      }
    });
  }
};
