import { combineReducers } from 'redux-immutable';

import gcodePane from './gcode-pane';
import controlsOverlay from './controls-overlay';
import titleBar from './title-bar';

const reducer = combineReducers({
  gcodePane,
  controlsOverlay,
  titleBar
});

export default reducer;
