import actions from '../actions';
import { handleActions } from 'redux-actions';
import Immutable from 'immutable';

const reducer = handleActions({
  [actions.unrecoverableError]: (state, action) => {
    return state.set("unrecoverableError", true);
  }
}, Immutable.Map({}));
export default reducer;
