import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  div: {
    position: "fixed",
    zIndex: 100,
    left: 20,
    bottom: 80

  },
  label: {
    color: "gray",
    fontSize: "10px"
  }
};

const spinnerSize = 12;
export default withStyles(styles)(function AlignerLoadingPanel(props) {
  const {
    classes,
    loading
  } = props;

  const loadingElements = [];
  loading.forEach((value, key) => {
    loadingElements.push(
    <div key={key}>
      <CircularProgress variant={value.get("variant")} value={value.get("progress")} size={spinnerSize}/> <span className={classes.label}>{value.get("label")}</span>
    </div>
    );
  });

  return (
  <div className={classes.div}>
    {loadingElements}
  </div>);
});
