import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';

import classnames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
  zipButton: {
    marginTop: 15
  },
  center: {
    textAlign: "center"
  },
  leftcell: {
    width: "60%"
  },
  rightcell: {
    width: "40%"
  },
  evenline: {
    backgroundColor: "#fafafa"
  },
  oddline: {
    backgroundColor: "white"
  },
  hidden: {
    display: "none"
  },
  filesContainer: {
    maxHeight: 125,
    overflow: "auto"
  }
});

export default withStyles(styles)((props) => {
  const {
    classes,
    files,
    mapping,
    onChangeAlignerNumber
  } = props;

  const callbacks = useMemo(() => files.map(
    (file) => (e) => {
      if(onChangeAlignerNumber) {
        onChangeAlignerNumber(file, e.target.value);
      }
    }
  ), [ files, onChangeAlignerNumber ]);

  return (<>
    <Table className={ files.length === 0 ? classes.hidden : undefined }>
    <TableHead>
      <TableRow>
        <TableCell className={classes.leftcell}>File</TableCell>
        <TableCell className={classnames(classes.rightcell, classes.center)}>Aligner Number</TableCell>
      </TableRow>
    </TableHead>
    </Table>
    <div className={classes.filesContainer}>
    <Table>
    <TableBody>
    {files.map((f, index) => {
      const lastSlash = f.lastIndexOf("/");
      const file = lastSlash === -1 ? f : f.slice(lastSlash+1);
      const noSuffix = file.replace(/.stl$/i, "");
      const shortened = noSuffix.length > 16 ? noSuffix.slice(0,8) + "..." + noSuffix.slice(-8) : noSuffix;
      return (<TableRow key={file}>
        <TableCell className={classnames(classes.leftcell, index % 2 ? classes.oddline : classes.evenline)}>
          { noSuffix !== shortened ?
          <Tooltip title={noSuffix}><span>{shortened}</span></Tooltip>
          : <span>{shortened}</span> }
        </TableCell>
        <TableCell className={classnames(classes.rightcell, index % 2 ? classes.oddline : classes.evenline)}><TextField onChange={callbacks[index]} value={mapping[f]} inputProps={ { className: classes.center }}/></TableCell>
      </TableRow>);
    })}
    </TableBody>
    </Table>
    </div>
  </>);
});
