import { createActions } from 'redux-actions';

const actions = createActions({
  MACHINE_STATE: {
    PLANE_SELECT: {
      SET_X_Y: undefined,
      SET_Z_X: undefined,
      SET_Y_Z: undefined,
      SET_U_V: undefined,
      SET_W_U: undefined,
      SET_V_W: undefined
    }
  }
});

export default actions;
