import { connect } from 'react-redux';
import Progress from '../components/Progress';

export default connect(
  (state, ownProps) => {
    let value = 0;
    let thickness = 3.6;
    let loading = false;
    if(state.get("interpreter").get("loading") ) {
      value = state.get("interpreter").get("loadingProgress");
      thickness = 1;
      loading = true;
    } else if(state.get("interpreter").get("processing")) {
      value = state.get("interpreter").get("processingProgress");
      thickness = 3.6;
      loading = true;
    }

    return { value, thickness, loading };
  },
  (dispatch, ownProps) => ({
  })
)(Progress);
