import { getAccessToken } from '../util/auth'; 
import { exponentialBackoff } from '../util/exponential-backoff';

import now from 'performance-now';
import { eventChannel, END } from 'redux-saga';
import { delay, all, take, put, call, select, takeLatest, takeEvery, cancelled } from 'redux-saga/effects';
import actions from '../actions';
import { alignerState as alignerStateSelector } from '../selectors';
import axios from 'axios';
import machines from '../machines';
import { V2 } from '../constants/machine-state/machine';
import BackPlotStrip from '../viewer3d/backplot-strip';
import BackPlot from '../viewer3d/backplot';
import Viewer3D from '../viewer3d';
import THREE from '../viewer3d/three';
import { saveAs } from 'file-saver';
import { LOAD_MODEL, EDIT_PATH, PREVIEW_PATH, POSITION_MODEL } from '../constants/aligner-state';
import { TOOL, DENTAL_FIXTURE } from '../constants/viewer3d/camera-parent';
import { EPS } from '../constants';
import { pts2relativeMoves } from '../util/path2gcode';
import AlignerCache from '../util/aligner-cache';
import QuickLookupCache from '../util/quick-lookup-cache';
import getStripe from '../util/stripe';

import * as restAPI from '../util/rfid-rest-api';

export function* postFile(post, fileData, progressActionCreator, apiKey) {
  const formData = new FormData();
  for(let name in post.fields) {
    formData.append(name, post.fields[name]);
  }
  formData.append('file', new File([new Blob([fileData])], "filename"));

  let uploadError;
  const channel = eventChannel((emitter) => {
    let cancel;
    const promiseGenerator = () => axios({
      url: post.url,
      method: "POST", 
      data: formData,
      cancelToken: new axios.CancelToken((c) => cancel = c),
      onUploadProgress: (e) => {
        emitter(progressActionCreator(e.loaded/e.total*100));
      }
    });

    exponentialBackoff(promiseGenerator)
      .then(() => {
        emitter(END)
      })
      .catch((error) => {
        console.error("upload error", error);
        uploadError = error;

        // TODO - emit error action
        cancel();
        return restAPI.logFrontEndError(apiKey, { message: "Error in postFile", error });
      });

    return () => cancel();
  });

  try {
    while(true) {
      const action = yield take(channel);
      yield put(action);
    }
  } finally {
    if(yield cancelled()) {
      channel.close();
      return { error: uploadError || "Cancelled" };
    }
  }
};

export function* getFile(url, progressActionCreator, responseType='arraybuffer', apiKey) {
  let downloadError;
  const channel = eventChannel((emitter) => {
    let cancel;
    const promiseGenerator = () => axios({
      url: url,
      method: "GET", 
      responseType,
      cancelToken: new axios.CancelToken((c) => cancel = c),
      onDownloadProgress: (e) => {
        emitter(progressActionCreator(e.loaded/e.total*100));
      }
    });

    exponentialBackoff(promiseGenerator)
      .then((res) => {
        emitter(res.data);
        emitter(END)
      })
      .catch((error) => {
        console.error("download error", error);
        // TODO - emit error action
        downloadError = error;
        cancel();
        return restAPI.logFrontEndError(apiKey, { message: "Error in getFile", error, url });
      });

    return () => cancel();
  });

  const actionType = progressActionCreator(0).type;
  try {
    while(true) {
      const data = yield take(channel);

      if(data.type === actionType) {
        yield put(data);
      } else {
        return { data };
      }
    }
  } finally {
    if(yield cancelled()) {
      channel.close();
      return { error: downloadError || "Cancelled" };
    }
  }
};

export function* waitForProcessedModel({ alignerID, label, apiKey }) {
  const loadingID = "processingModel" + alignerID;
  yield put(actions.alignerState.loading(loadingID, "Processing model for aligner " + label + "...", "indeterminate"));
  const accessToken = yield call(getAccessToken);
  while(true) {
    const { data, error } = yield call(restAPI.processedModelExists, { alignerID, apiKey, accessToken });
    if(error) {
      // TODO display error
      yield call(restAPI.logFrontEndError, apiKey, { message: "Error checking if processed model exists", error, alignerID });
    }

    if(data && data.exists) {
      break;
    } else {
      yield delay(7000);
    }
  }
  const currentAlignerState = yield select(alignerStateSelector);
  if(currentAlignerState.get("alignerID") === alignerID) {
    yield put(actions.alignerState.finishedProcessing());
  }
  yield put(actions.alignerState.stopLoading(loadingID));
}

export function* uploadModel(action) {
  const modelData = new Uint8Array(action.payload);
  const alignerState = yield select(alignerStateSelector);

  const alignerID = alignerState.get("alignerID");
  const label = alignerState.get("label");
  const size = modelData.byteLength;
  const apiKey = alignerState.get("scannerKey") || (alignerState.get("user") && alignerState.get("user").get("apiKey"));

  if(alignerID && apiKey) {
    const accessToken = yield call(getAccessToken);

    yield put(actions.alignerState.loading("preparingToUploadModel", "Preparing to upload model...", "indeterminate"));
    const { data, error } = yield call(restAPI.getUploadModelPost, { alignerID, size, apiKey, accessToken });
    yield put(actions.alignerState.stopLoading("preparingToUploadModel"));

    if(error || data.error) {
      console.error(error);
      yield call(restAPI.logFrontEndError, apiKey, { message: "Error uploading model", error, alignerID, size });
      // TODO - show error in UI, most likely a size violation or the model is finalized
    } else {
      const uploadModelID = "uploadModel" + alignerID;
      yield put(actions.alignerState.loading(uploadModelID, "Uploading model for aligner " + label + "...", "determinate", 0));

      const cache = AlignerCache.getInstance();
      const checksum = cache.getMD5Checksum(modelData.buffer);
      cache.setItem(checksum, modelData.buffer);

      yield call(postFile, data.post, modelData, (progress) => actions.alignerState.loadingProgress(uploadModelID, progress), apiKey);
      yield put(actions.alignerState.stopLoading(uploadModelID));

      const alignerState = yield select(alignerStateSelector);
      if(alignerState.get("alignerID") === alignerID) {
        yield put(actions.alignerState.modelUploaded());
      }
    }
  }
}

export function* finalizeModel(action) {
  const alignerState = yield select(alignerStateSelector);
  const alignerID = alignerState.get("alignerID");
  const label = alignerState.get("label");
  const apiKey = alignerState.get("scannerKey") || (alignerState.get("user") && alignerState.get("user").get("apiKey"));
  const addBridge = alignerState.get("addBridge");
  const addCylinders = alignerState.get("addCylinders");
  const snapFit = alignerState.get("snapFit");

  if(alignerID && apiKey) {
    while(true) {
      const alignerState = yield select(alignerStateSelector);
      if(alignerState.get("modelUploaded")) {
        break;
      }
      yield delay(1000);
    }

    const finalizingID = "finalizing" + alignerID;
    yield put(actions.alignerState.loading(finalizingID, "Finalizing model for aligner " + label + "...", "indeterminate"));
    const accessToken = yield call(getAccessToken);
    const finalizeData = yield call(restAPI.finalize, { alignerID, apiKey, accessToken, addBridge, addCylinders, snapFit });
    yield put(actions.alignerState.stopLoading(finalizingID));
    if(finalizeData.error) {
      // TODO error, retry?
      yield call(restAPI.logFrontEndError, apiKey, { message: "Error finalizing model", error: finalizeData.error, alignerID, addBridge, addCylinders });
      console.error("Error finalizing model", finalizeData.error);
      yield put(actions.alignerState.clearFinalizeModelClick());
      return;
    } else {
      const currentAlignerState = yield select(alignerStateSelector);
      if(currentAlignerState.get("alignerID") === alignerID) {
        yield put(actions.alignerState.finalizeModel());
      }
    }

    yield call(waitForProcessedModel, { alignerID, label, apiKey });
  }
};

export function* scannedRFID(action) {
  const {
    rfid,
    label,
    scannerKey
  } = action.payload;

  const alignerID = rfid;
  const apiKey = scannerKey;
  const alignerState = yield select(alignerStateSelector);

  if(alignerState.get("loading").get("waitingForScannerToScanRFIDToImport")) {
    // import transform and points from the next aligner scanned
    // into the last scanned aligner
    yield put(actions.alignerState.stopLoading("waitingForScannerToScanRFIDToImport"));

    const importingID = "importingTransformAndPoints" + alignerState.get("alignerID");
    yield put(actions.alignerState.loading(importingID, "Importing model position and points from " + label + " to " + alignerState.get("label") + " ...", "indeterminate"));
    const accessToken = yield call(getAccessToken);
    const { data, error } = yield restAPI.importTransformAndPoints({ alignerID: alignerState.get("alignerID"), apiKey, alignerIDImport: alignerID, accessToken  });
    if(data.error || error) {
      console.error(data.error, error);
      yield call(restAPI.logFrontEndError, apiKey, { message: "Error importing transform and points after scanning an RFID", error, alignerID: alignerState.get("alignerID"), alignerIDImport: alignerID });
      // TODO - report error
    }
    yield put(actions.alignerState.stopLoading(importingID));

    const currentAlignerState = yield select(alignerStateSelector);
    if(currentAlignerState.get("alignerID") === alignerState.get("alignerID") && (!data.error && !error)) {
      yield put(actions.alignerState.importedTransformAndPoints(data));
    }
  } else {
    yield call(loadAligner, { alignerID, apiKey, label });
  }
};

export function* quickLookUpAutocomplete(action) {
  const prefix = action.payload;
  const alignerState = yield select(alignerStateSelector);
  const apiKey = (alignerState.get("user") && alignerState.get("user").get("apiKey"));
  const accessToken = yield call(getAccessToken);

  const cache = QuickLookupCache.getInstance();
  const cachedResults = cache.getQuery(prefix);
  if(cachedResults) {
    yield put(actions.alignerState.setQuickLookUpItems(cachedResults));
  } else {
    yield put(actions.alignerState.loading("quickLookUpAutocomplete", "Searching for aligners that begin with " + prefix + "...", "indeterminate"));
    const { data, error } = yield call(restAPI.getAlignersWithCode, { accessToken, code: prefix, apiKey });
    yield put(actions.alignerState.stopLoading("quickLookUpAutocomplete"));
    if(!error && !data.error) {
      data.sort((a,b) => b.accessed-a.accessed);
      cache.setQuery(prefix, data);
      yield put(actions.alignerState.setQuickLookUpItems(data));
    }
  }

}

export function* setAligner(action) {
  const {
    alignerID,
    label
  } = action.payload;

  const alignerState = yield select(alignerStateSelector);
  const apiKey = (alignerState.get("user") && alignerState.get("user").get("apiKey"));

  if(alignerState.get("loading").get("waitingForScannerToScanRFIDToImport")) {
    // import transform and points from the next aligner scanned
    // into the last scanned aligner
    yield put(actions.alignerState.stopLoading("waitingForScannerToScanRFIDToImport"));
    const loadedAlignerID = alignerState.get("alignerID");
    const importingID = "importingTransformAndPoints" + loadedAlignerID;
    yield put(actions.alignerState.loading(importingID, "Importing model position and points from " + label + (alignerState.get("label") ? " to " + alignerState.get("label") : "" ) + "...", "indeterminate"));
    const accessToken = yield call(getAccessToken);

    let data, error;

    if(loadedAlignerID) {
      const importDataError = yield call(restAPI.importTransformAndPoints, { alignerID: loadedAlignerID, apiKey, alignerIDImport: alignerID, accessToken  });
      data = importDataError.data;
      error = importDataError.error;
    } else {
      const getDataError = yield call(restAPI.getTransformAndPoints, { alignerID, apiKey, accessToken  });
      data = getDataError.data;
      error = getDataError.error;
    }

    if(data.error || error) {
      console.error(data.error, error);
      yield call(restAPI.logFrontEndError, apiKey, { message: "Error importing transform and points after selecting an aligner", error: data.error || error, alignerID: alignerState.get("alignerID"), alignerIDImport: alignerID });
      // TODO - report error
    }
    yield put(actions.alignerState.stopLoading(importingID));

    const currentAlignerState = yield select(alignerStateSelector);
    if(currentAlignerState.get("alignerID") === loadedAlignerID && (!data.error && !error)) {
      yield put(actions.alignerState.importedTransformAndPoints(data));
    }
  } else {
    yield call(loadAligner, { alignerID, apiKey, label });
  }
};

export function* createMultipleAligners(action) {
  const {
    firstModelChecksum,
    firstLabel,
    xform,
    mapping,
    checksum,
    addBridge,
    addCylinders,
    snapFit,
    points
  } = action.payload;

  const alignerState = yield select(alignerStateSelector);

  const apiKey = (alignerState.get("user") && alignerState.get("user").get("apiKey"));
  const accessToken = yield call(getAccessToken);

  const code = Object.values(mapping)[0].split('-')[0];
  const count = Object.values(mapping).length;

  const loadingID = "creatingMultipleAligners" + checksum + code;
  yield put(actions.alignerState.loading(loadingID, "Creating " + count + " aligner" + (count === 1 ? "" : "s") + " for aligner set " + code + "...", "indeterminate"));

  const { data, error } = yield call(restAPI.createMultipleAligners, { accessToken, apiKey, xform, mapping, checksum, addBridge, addCylinders, points, snapFit });
  yield put(actions.alignerState.stopLoading(loadingID));

  if(!error && !data.error) {
    const uploadZipID = "uploadZip" + checksum + code;
    yield put(actions.alignerState.setWarnOnExit(true));
    yield put(actions.alignerState.loading(uploadZipID, "Uploading zip for aligner set " + code + "...", "determinate", 0));

    const cache = AlignerCache.getInstance();
    const buffer = yield cache.getItem(checksum);
    const firstModel = yield cache.getItem(firstModelChecksum);
    const firstAlignerID = data.aligners.find((aligner) => aligner.label === firstLabel).alignerID;

    const viewer = Viewer3D.getInstance();
    viewer.loadSTLModel(firstModel);
    yield put(actions.viewer3D.loadModel());
    yield put(actions.viewer3D.showModel());
    if(points && points.points && points.closed) {
      yield put(actions.alignerState.setStep(PREVIEW_PATH));
    } else {
      yield put(actions.alignerState.setStep(EDIT_PATH));
    }
    yield put(actions.alignerState.loadFirstModelOfMultiple({
      alignerID: firstAlignerID,
      label: firstLabel,
      xform,
      points
    }));

    yield call(postFile, data.post, buffer, (progress) => actions.alignerState.loadingProgress(uploadZipID, progress));
    yield put(actions.alignerState.stopLoading(uploadZipID));
    yield put(actions.alignerState.setWarnOnExit(false));

    const processingID = "checkingForProcessedModels" + checksum + code;
    yield put(actions.alignerState.loading(processingID, "Processing " + count + " model" + (count === 1 ? "" : "s") + " for aligner set " + code + "...", "indeterminate"));

    let processing = true;
    let alignersProcessed;
    while(processing) {
      yield delay(7000); 
      const aligners = data.aligners.map((aligner) => aligner.alignerID);

      const { data: existsData, error } = yield call(restAPI.multipleProcessedModelsExist, { accessToken, apiKey, aligners });

      if(!existsData.error && !error) {
        alignersProcessed = existsData;

        // number of aligners that are still processing
        const count = Object.values(existsData).filter((value) => !value).length;

        processing = count > 0;

        yield put(actions.alignerState.loading(processingID, "Processing " + count + " model" + (count === 1 ? "" : "s") + " for aligner set " + code + "...", "indeterminate"));
      }
    }

    yield put(actions.alignerState.stopLoading(processingID));

    const currentAlignerState = yield select(alignerStateSelector);
    if(alignersProcessed[currentAlignerState.get("alignerID")]) {
      yield put(actions.alignerState.finishedProcessing());
    }

    const currentCode = currentAlignerState.get("label").split("-")[0];
    if(currentCode === code) {
      yield put(actions.alignerState.showDownloadAllProcessedModels());

      yield put(actions.alignerState.checkForPointsOnOtherAligners());
    }
  }
};

export function* createAligner(action) {
  const {
    code,
    order
  } = action.payload;

  const alignerState = yield select(alignerStateSelector);

  const apiKey = (alignerState.get("user") && alignerState.get("user").get("apiKey"));
  const accessToken = yield call(getAccessToken);

  yield put(actions.alignerState.loading("creatingAligner", "Creating aligner...", "indeterminate"));

  const { data, error } = yield call(restAPI.createAligner, { accessToken, code, order, apiKey });

  yield put(actions.alignerState.stopLoading("creatingAligner"));

  if(!error && !data.error) {
    const cache = QuickLookupCache.getInstance();
    cache.addResult(data);
    yield put(actions.alignerState.createdAligner(data.alignerID, data.label));
    yield call(loadAligner, { alignerID: data.alignerID, apiKey, label: code + "-" + order, skipVerify: true });
  }
};

export function* loadAligner({ alignerID, apiKey, label, skipVerify }) {
  // load aligner

  yield put(actions.alignerState.pause());

  const cache = QuickLookupCache.getInstance();
  cache.updateResult({ alignerID, label });

  let data;

  if(skipVerify) {
    data = { aligner: true };
  } else {
    yield put(actions.alignerState.loading("getStatus", "Retrieving aligner " + label + "...", "indeterminate"));

    const accessToken = yield call(getAccessToken);
    const { data: alignerData, error } = yield restAPI.getAlignerStatus({ alignerID, apiKey, accessToken });

    data = alignerData;

    yield put(actions.alignerState.stopLoading("getStatus"));

    if(error) {
      console.error("Error fetching status", error);
      yield put(actions.alignerState.resetState());
      return;
    }
  }

  yield put(actions.alignerState.alignerStatusResponse(data));

  if(data.aligner) {
    if(!data.label) {
      const accessToken = yield call(getAccessToken);
      const labelData = yield call(restAPI.createLabel, { alignerID, apiKey, label, accessToken });
      if(labelData.error || (labelData.data && !labelData.data.success)) {
        // TODO error
        yield call(restAPI.logFrontEndError, apiKey, { message: "Error creating label", error: labelData.error, alignerID, label });
      }
    }

    const viewer = Viewer3D.getInstance();
    if(data.model) {
      if(data.points && data.points.length > 0) {
        yield put(actions.viewer3D.parentCamera(TOOL));
        yield put(actions.alignerState.setStep(PREVIEW_PATH));
      } else {
        yield put(actions.alignerState.setStep(EDIT_PATH));
      }

      yield put(actions.alignerState.loading("downloadModel", "Downloading model...", "determinate", 0));
      const cache = AlignerCache.getInstance();
      const cachedData = yield cache.getItem(data.model.ETag);

      if(cachedData) {
        viewer.loadSTLModel(cachedData);
      } else {
        const { data: fileData, error: fileError } = yield call(getFile, data.model.url, (progress) => actions.alignerState.loadingProgress("downloadModel", progress), 'arraybuffer', apiKey);
        if(!fileError) {
          cache.setItem(data.model.ETag, fileData);
        }

        viewer.loadSTLModel(fileData);
      }

      yield put(actions.viewer3D.loadModel());
      yield put(actions.viewer3D.showModel());
      yield put(actions.alignerState.modelLoaded());
      yield put(actions.alignerState.stopLoading("downloadModel"));

      if(data.finalized) {
        if(!data.processedModel) {
          yield call(waitForProcessedModel, { alignerID, label, apiKey });
        } else {
          if(data.points && data.points.closed) {
            yield put(actions.viewer3D.parentCamera(TOOL));
            yield put(actions.alignerState.setStep(PREVIEW_PATH));
          }
        }
      } else {
        yield put(actions.alignerState.setStep(POSITION_MODEL));
      }
    } else {
      yield put(actions.viewer3D.parentCamera(DENTAL_FIXTURE));
      yield put(actions.alignerState.setStep(LOAD_MODEL));
    }

    if(data.alignerSet) {
      const needsProcessing = data.alignerSet.filter((aligner) => aligner.finalized && !aligner.processedModel);
      if(needsProcessing.length > 0) {
        const code = label.split('-')[0];
        const count = needsProcessing.length;
        //console.log("Looks like models are finalized but are still processing. Starting polling loop...");
        const processingID = "checkingForProcessedModels" + code;
        yield put(actions.alignerState.loading(processingID, "Processing " + count + " model" + (count === 1 ? "" : "s") + " for aligner set " + code + "...", "indeterminate"));

        let processing = true;
        let alignersProcessed;
        while(processing) {
          yield delay(7000); 
          const aligners = needsProcessing.map((aligner) => aligner.alignerID);

          const accessToken = yield call(getAccessToken);
          const { data: existsData, error } = yield call(restAPI.multipleProcessedModelsExist, { accessToken, apiKey, aligners });

          if(!existsData.error && !error) {
            alignersProcessed = existsData;
            const count = Object.values(existsData).filter((value) => !value).length;

            processing = count > 0;

            yield put(actions.alignerState.loading(processingID, "Processing " + count + " model" + (count === 1 ? "" : "s") + " for aligner set " + code + "...", "indeterminate"));
          }
        }

        yield put(actions.alignerState.stopLoading(processingID));

        const currentAlignerState = yield select(alignerStateSelector);
        if(alignersProcessed[currentAlignerState.get("alignerID")]) {
          yield put(actions.alignerState.finishedProcessing());
        }

        const currentCode = currentAlignerState.get("label").split("-")[0];
        if(currentCode === code) {
          yield put(actions.alignerState.showDownloadAllProcessedModels());

          yield put(actions.alignerState.checkForPointsOnOtherAligners());
        }
      }
    }

  } else {
    yield put(actions.alignerState.resetState());
  }
};

export function* downloadProcessedModel(action) {
  const alignerState = yield select(alignerStateSelector);
  const alignerID = alignerState.get("alignerID");
  const label = alignerState.get("label");
  const apiKey = alignerState.get("scannerKey") || (alignerState.get("user") && alignerState.get("user").get("apiKey"));

  const preparingID = "preparingToDownloadProcessedModel" + alignerID;
  yield put(actions.alignerState.loading(preparingID, "Preparing to download processed model for aligner " + label + "...", "indeterminate"));
  const accessToken = yield call(getAccessToken);
  const { data, error } = yield call(restAPI.getDownloadProcessedModelUrl, { alignerID, apiKey, accessToken });
  if(error) {
    // TODO error
    yield call(restAPI.logFrontEndError, apiKey, { message: "Error getting download processed model url", error, alignerID });
  }
  yield put(actions.alignerState.stopLoading(preparingID));

  const downloadingID = "downloadProcessedModel" + alignerID;
  yield put(actions.alignerState.loading(downloadingID, "Downloading processed model for aligner " + label + "...", "determinate", 0));
  const { data: fileData, error: fileError } = yield call(getFile, data.url, (progress) => actions.alignerState.loadingProgress(downloadingID, progress), 'arraybuffer', apiKey);
  yield put(actions.alignerState.stopLoading(downloadingID));

  if(!fileError) {
    yield call(saveAs, new Blob([ new Uint8Array(fileData) ]), label + ".stl");
  }
};

export function* downloadGCode(action) {
  const alignerState = yield select(alignerStateSelector);
  const alignerID = alignerState.get("alignerID");
  const label = alignerState.get("label");
  const apiKey = alignerState.get("scannerKey") || (alignerState.get("user") && alignerState.get("user").get("apiKey"));

  const downloadingID = "downloadGCode" + alignerID;
  yield put(actions.alignerState.loading(downloadingID, "Downloading G code for aligner " + label + "...", "indeterminate"));
  const accessToken = yield call(getAccessToken);
  const { data, error } = yield call(restAPI.getGCode, { alignerID, apiKey, label, accessToken });
  yield put(actions.alignerState.stopLoading(downloadingID));

  if((data && data.error) || error) {
    console.error(data, error);
    return;
  }

  yield call(saveAs, new Blob([ data.gcode ]), label + ".ngc");
};

export function* copyPoints() {
  const alignerState = yield select(alignerStateSelector);
  const alignerID = alignerState.get("alignerID");
  const apiKey = alignerState.get("scannerKey") || (alignerState.get("user") && alignerState.get("user").get("apiKey"));

  const label = alignerState.get("label");

  const loadingID = "copyingPointsToAllAlignersInSet" + alignerID;
  yield put(actions.alignerState.loading(loadingID, "Copying points from " + label + " to all unfinished aligners in set...", "indeterminate"));
  const accessToken = yield call(getAccessToken);
  const { data, error } = yield call(restAPI.copyPointsToAllAlignersInSet, { apiKey, accessToken, alignerID });
  if(data.error || error) {
    // TODO error
    yield call(restAPI.logFrontEndError, apiKey, { message: "Error copying points", error, alignerID });
  }
  yield put(actions.alignerState.stopLoading(loadingID));

  yield put(actions.alignerState.hideCopyPoints());
  yield put(actions.alignerState.checkForPointsOnOtherAligners());
}

export function* downloadAllGCode() {
  const alignerState = yield select(alignerStateSelector);
  const label = alignerState.get("label");
  const code = label.split('-')[0];
  const apiKey = alignerState.get("scannerKey") || (alignerState.get("user") && alignerState.get("user").get("apiKey"));

  const preparingID = "preparingToDownloadGCodeZip" + code;
  yield put(actions.alignerState.loading(preparingID, "Preparing to download G code zip for aligner set " + code + "...", "indeterminate"));
  const accessToken = yield call(getAccessToken);
  const { data, error } = yield call(restAPI.getGCodeZipUrl, { apiKey, accessToken, code });
  if(error) {
    // TODO error
    yield call(restAPI.logFrontEndError, apiKey, { message: "Error getting download G code zip url", error, code });
  }
  yield put(actions.alignerState.stopLoading(preparingID));

  if(data && data.url) {
    const downloadingID = "downloadGCodeZip" + code;
    yield put(actions.alignerState.loading(downloadingID, "Downloading G code zip for aligner set " + code + "...", "determinate", 0));
    const { data: fileData, error: fileError } = yield call(getFile, data.url, (progress) => actions.alignerState.loadingProgress(downloadingID, progress), 'arraybuffer', apiKey);
    yield put(actions.alignerState.stopLoading(downloadingID));

    if(!fileError) {
      yield call(saveAs, new Blob([ new Uint8Array(fileData) ]), code + "-gcode.zip");
    }
  } else {
    yield call(restAPI.logFrontEndError, apiKey, { message: "Didn't get a G code zip url", data, code });
  }
};

export function* downloadAllProcessedModels() {
  const alignerState = yield select(alignerStateSelector);
  const label = alignerState.get("label");
  const code = label.split('-')[0];
  const apiKey = alignerState.get("scannerKey") || (alignerState.get("user") && alignerState.get("user").get("apiKey"));

  const preparingID = "preparingToDownloadProcessedModels" + code;
  yield put(actions.alignerState.loading(preparingID, "Preparing to download models zip for aligner set " + code + "...", "indeterminate"));
  const accessToken = yield call(getAccessToken);
  const { data, error } = yield call(restAPI.getProcessedModelsZipUrl, { apiKey, accessToken, code });
  if(error) {
    // TODO error
    yield call(restAPI.logFrontEndError, apiKey, { message: "Error getting download processed models zip url", error, code });
  }
  yield put(actions.alignerState.stopLoading(preparingID));

  if(data && data.url) {
    const downloadingID = "downloadProcessedModelsZip" + code;
    yield put(actions.alignerState.loading(downloadingID, "Downloading models zip for aligner set " + code + "...", "determinate", 0));
    const { data: fileData, error: fileError } = yield call(getFile, data.url, (progress) => actions.alignerState.loadingProgress(downloadingID, progress), 'arraybuffer', apiKey);
    yield put(actions.alignerState.stopLoading(downloadingID));

    if(!fileError) {
      yield call(saveAs, new Blob([ new Uint8Array(fileData) ]), code + "-models.zip");
    }
  } else {
    // TODO error
    yield call(restAPI.logFrontEndError, apiKey, { message: "Error getting download processed models zip url", error, code });
  }
};

export function* savePoints(action) {
  const alignerState = yield select(alignerStateSelector);
  const alignerID = alignerState.get("alignerID");
  const apiKey = alignerState.get("scannerKey") || (alignerState.get("user") && alignerState.get("user").get("apiKey"));

  if(alignerID && apiKey) {
    const pointsNeedSaving = alignerState.get("pointsNeedSaving");
    if(pointsNeedSaving) {
      const points = {
        points: alignerState.get("points").toJS(),
        closed: alignerState.get("closePath")
      };
      yield put(actions.alignerState.loading("savingPoints", "Saving points...", "indeterminate"));
      const accessToken = yield call(getAccessToken);
      const { data, error } = yield call(restAPI.savePoints, { alignerID, apiKey, points, accessToken });
      yield put(actions.alignerState.stopLoading("savingPoints"));

      if(error || !data.success) {
        console.error("error saving points", error);
        // TODO error, retry?
        yield call(restAPI.logFrontEndError, apiKey, { message: "Error saving points", error, points, alignerID });

      } else {
        yield put(actions.alignerState.pointsSaved());
      }
    }
  }
};

export function* saveTransform(action) {
  const alignerState = yield select(alignerStateSelector);
  const alignerID = alignerState.get("alignerID");
  const apiKey = alignerState.get("scannerKey") || (alignerState.get("user") && alignerState.get("user").get("apiKey"));

  if(alignerID && apiKey) {
    const xformNeedsSaving = alignerState.get("xformNeedsSaving");
    if(xformNeedsSaving) {
      const xform = {
        xform: alignerState.get("xform").toJS()
      };
      yield put(actions.alignerState.loading("savingTransform", "Saving model position...", "indeterminate"));
      const accessToken = yield call(getAccessToken);
      const { data, error } = yield call(restAPI.saveTransform, { alignerID, apiKey, xform, accessToken });
      yield put(actions.alignerState.stopLoading("savingTransform"));

      if(error || !data.success) {
        console.error("error saving transform", error);
        // TODO error, retry?
        yield call(restAPI.logFrontEndError, apiKey, { message: "Error saving transform", error, xform, alignerID });

      } else {
        yield put(actions.alignerState.transformSaved());
      }
    }
  }
};

export function* updateDrawPathPreview(action) {
  const backplot = BackPlot.getInstance();
  backplot.clear();

  const alignerState = yield select(alignerStateSelector);
  const viewer = Viewer3D.getInstance();

  if(viewer.parts.model) {
    const points = alignerState.get("drawPath").toJS(); // points in model space
    const numPts = points.length;

    const p = new THREE.Vector3();
    const d = new THREE.Vector4();
    const invBMatrix = new THREE.Matrix4();
    invBMatrix.getInverse(viewer.parts.bAxis.object.matrixWorld);

    for(let i = 0; i < numPts; i++) {
      const pt = points[i];

      p.x = pt[0];
      p.y = pt[1];
      p.z = pt[2];

      d.x = pt[3];
      d.y = pt[4];
      d.z = pt[5];
      d.w = 0;

      p.applyMatrix4(viewer.parts.model.object.matrixWorld); 
      d.applyMatrix4(viewer.parts.model.object.matrixWorld);
      p.applyMatrix4(invBMatrix);
      d.applyMatrix4(invBMatrix);

      d.normalize();

      backplot.addPoints([{ 
        position: [ p.x, 
                    p.y,
                    p.z ],
        color: [ 1, 1, 1],
        normal: [ d.x, d.y, d.z ],
        line: 0,
        time: 0
      }]);
    }
  }
}

export function* updateAlignerPathPreview(action) {
  const backplotStrip = BackPlotStrip.getInstance();
  const backplot = BackPlot.getInstance();
  backplotStrip.clear();
  backplot.clear();

  let startedProcessing = now();

  const viewer = Viewer3D.getInstance();

  if(action.type === actions.alignerState.alignerStatusResponse().type) {
    yield take(actions.alignerState.modelLoaded);
  }

  const alignerState = yield select(alignerStateSelector);

  if(viewer.parts.model) {
    const p = new THREE.Vector3();
    const d = new THREE.Vector4();
    const points = alignerState.get("points").toJS(); // points in model space
    const numPts = points.length;
    const numSamples = 300;

    if(numPts > 0) {
      const cutDepth = -.002*25.4; // preview strip above the surface of model

      const n0 = new THREE.Vector3();
      const n1 = new THREE.Vector3();
      const n2 = new THREE.Vector3();
      const n3 = new THREE.Vector3();
      const m0 = new THREE.Vector3();
      const m1 = new THREE.Vector3();
      const p0 = new THREE.Vector3();
      const p1 = new THREE.Vector3();

      const d1 = new THREE.Vector3();
      const d2 = new THREE.Vector3();

      const hitPos = new THREE.Vector3();

      const axis = new THREE.Vector3();
      const dir = new THREE.Vector3();

      const resampledPts = [];

      const invBMatrix = new THREE.Matrix4();
      invBMatrix.getInverse(viewer.parts.bAxis.object.matrixWorld);
      for(let i = 0; i < numSamples; i++) {
        const t = i/(numSamples-1);

        let tt;
        let i0;
        let i1;
        let i2;
        let i3;

        if(alignerState.get("closePath")) {
          tt = numPts*t-Math.floor(numPts*t);

          // closed loop
          i1 = Math.floor(numPts*t)%numPts;
          i2 = (i1+1)%numPts;
          i3 = (i2+1)%numPts;
          i0 = ((i1+numPts)-1)%numPts;
        } else {
          // not closed loop
          tt = (numPts-1)*t-Math.floor((numPts-1)*t);
          i1 = Math.floor((numPts-1)*t);
          if(i1 >= numPts) {
            i1 = numPts-1;
          }
          i2 = i1+1;
          if(i2 >= numPts) {
            i2 = numPts-1;
          }
          i3 = i2+1;
          if(i3 >= numPts) {
            i3 = numPts-1;
          }
          i0 = i1-1;
          if(i0 < 0) {
            i0 = 0;
          }
        }

        n0.set(points[i0][0], points[i0][1], points[i0][2]);
        n1.set(points[i1][0], points[i1][1], points[i1][2]);
        n2.set(points[i2][0], points[i2][1], points[i2][2]);
        n3.set(points[i3][0], points[i3][1], points[i3][2]);

        p0.copy(n1);
        p1.copy(n2);
        const tension = .5;
        m0.copy(n2);
        m0.sub(n0);
        m0.multiplyScalar(tension);

        m1.copy(n3);
        m1.sub(n1);
        m1.multiplyScalar(tension);

        const x = (1+2*tt)*(1-tt)*(1-tt)*p0.x+tt*(1-tt)*(1-tt)*m0.x+tt*tt*(3-2*tt)*p1.x+tt*tt*(tt-1)*m1.x;
        const y = (1+2*tt)*(1-tt)*(1-tt)*p0.y+tt*(1-tt)*(1-tt)*m0.y+tt*tt*(3-2*tt)*p1.y+tt*tt*(tt-1)*m1.y;
        const z = (1+2*tt)*(1-tt)*(1-tt)*p0.z+tt*(1-tt)*(1-tt)*m0.z+tt*tt*(3-2*tt)*p1.z+tt*tt*(tt-1)*m1.z;

        d1.set(points[i1][3],points[i1][4], points[i1][5]);
        d2.set(points[i2][3],points[i2][4], points[i2][5]);

        const angle = d1.angleTo(d2);

        axis.crossVectors(d1,d2);
        axis.normalize();

        dir.copy(d1);
        dir.applyAxisAngle(axis, angle*tt);

        p.set(x, y, z);
        d.set(dir.x, dir.y, dir.z, 0);

        hitPos.x = p.x-d.x*cutDepth;
        hitPos.y = p.y-d.y*cutDepth;
        hitPos.z = p.z-d.z*cutDepth;

        resampledPts.push([ hitPos.x, hitPos.y, hitPos.z, d.x, d.y, d.z, i1, tt ]);

        const currentTime = now();
        if(currentTime-startedProcessing > 6) {
          yield delay(0);
          startedProcessing = now();
          // machine could have moved since we yielded back to the main loop
          // so update our inverse matrix
          invBMatrix.getInverse(viewer.parts.bAxis.object.matrixWorld);
        }
      }

      const closed = alignerState.get("closePath");
      let numSegments;
      if(closed) {
        numSegments = numPts;
      } else {
        numSegments = numPts-1;
      }
      const segmentTimes = [ 0 ];
      const maxVelocities = machines[V2].limits.velocities;
      const maxAccelerations = machines[V2].limits.accelerations;
      for(let i = 0; i < numSegments; i++) {
        let i0, i1, i2, i3;

        i1 = i;

        if(closed) {
          i0 = (i1-1+numPts)%numPts;
          i2 = (i1+1)%numPts;
          i3 = (i2+1)%numPts;
        } else {
          i0 = Math.max(i1-1, 0);
          i2 = i1+1;
          i3 = Math.min(i2+1, numPts-1);
        }

        n0.set(points[i0][0], points[i0][1], points[i0][2]);
        n1.set(points[i1][0], points[i1][1], points[i1][2]);
        n2.set(points[i2][0], points[i2][1], points[i2][2]);
        n3.set(points[i3][0], points[i3][1], points[i3][2]);

        p0.copy(n1);
        p1.copy(n2);
        const tension = .5;
        m0.copy(n2);
        m0.sub(n0);
        m0.multiplyScalar(tension);

        m1.copy(n3);
        m1.sub(n1);
        m1.multiplyScalar(tension);

        d1.set(points[i1][3],points[i1][4], points[i1][5]);
        d2.set(points[i2][3],points[i2][4], points[i2][5]);

        const angle = d1.angleTo(d2);

        axis.crossVectors(d1,d2);
        axis.normalize();

        const numSegmentSamples = 5;
        const segmentPts = [];
        for(let j = 0; j < numSegmentSamples; j++) {
          const tt = j/(numSegmentSamples-1);

          const x = (1+2*tt)*(1-tt)*(1-tt)*p0.x+tt*(1-tt)*(1-tt)*m0.x+tt*tt*(3-2*tt)*p1.x+tt*tt*(tt-1)*m1.x;
          const y = (1+2*tt)*(1-tt)*(1-tt)*p0.y+tt*(1-tt)*(1-tt)*m0.y+tt*tt*(3-2*tt)*p1.y+tt*tt*(tt-1)*m1.y;
          const z = (1+2*tt)*(1-tt)*(1-tt)*p0.z+tt*(1-tt)*(1-tt)*m0.z+tt*tt*(3-2*tt)*p1.z+tt*tt*(tt-1)*m1.z;

          dir.copy(d1);
          dir.applyAxisAngle(axis, angle*tt);

          p.set(x, y, z);
          d.set(dir.x, dir.y, dir.z, 0);
          d.normalize();
          segmentPts.push( [ p.x, p.y, p.z, d.x, d.y, d.z ]);
        }
        const relativeMoves = pts2relativeMoves(segmentPts);
        let segmentTime = 0;
        relativeMoves.forEach((move) => {
          const axes = {
            X: 0,
            Y: 1,
            Z: 2,
            A: 3,
            B: 4
          };
          const linearAxes = { 
            X: 0,
            Y: 1,
            Z: 2
          };
          const rotaryAxes = { 
            A: 3,
            B: 4
          };
          let maxRotaryMove = 0;

          for(let axis in rotaryAxes) {
            if(typeof(move[axis]) !== 'undefined') {
              maxRotaryMove = Math.max(maxRotaryMove, Math.abs(move[axis]));
            }
          }

          const x = move.X || 0;
          const y = move.Y || 0;
          const z = move.Z || 0;
          const combinedLinear = Math.sqrt(x*x+y*y+z*z);
          const desiredFeedRate = 30; // inches per minute

          let t = combinedLinear/desiredFeedRate*60;
          if(maxRotaryMove < EPS) {
            for(let axis in linearAxes) {
              if(typeof(move[axis]) !== 'undefined') {
                t = Math.max(t, Math.abs(move[axis])/maxVelocities[linearAxes[axis]]);
              }
            }
          } else {
            for(let axis in axes) {
              if(typeof(move[axis]) !== 'undefined') {
                t = Math.max(t, .7*Math.sqrt(Math.abs(move[axis])/maxAccelerations[axes[axis]]));
              }
            }
          }
          segmentTime += t;
        });
        const lastTime = segmentTimes[segmentTimes.length-1];
        segmentTimes.push(lastTime+segmentTime);
      }

      yield put(actions.alignerState.setTimes(segmentTimes));

      const normal = new THREE.Vector3();
      const stripAxis = new THREE.Vector3();
      const stripWidth = 1.;

      const pt1 = new THREE.Vector3();
      const dir1 = new THREE.Vector4();
      dir1.w = 0;

      const XAxis = new THREE.Vector3(1,0,0);
      const YAxis = new THREE.Vector3(0,1,0);

      for(let i = 0; i < resampledPts.length; i++) {
        const pt = resampledPts[i];

        pt1.x = pt[0];
        pt1.y = pt[1];
        pt1.z = pt[2];

        dir1.x = pt[3];
        dir1.y = pt[4];
        dir1.z = pt[5];

        normal.x = dir1.x;
        normal.y = dir1.y;
        normal.z = dir1.z;

        const A = Math.asin(dir1.y);
        const B = Math.atan2(-dir1.x, dir1.z);

    //    stripAxis.crossVectors(normal, tangent).normalize();
        stripAxis.set(0, 1, 0);
        stripAxis.applyAxisAngle(XAxis, -A).applyAxisAngle(YAxis, -B);

        backplotStrip.addPoints([{ 
          position: [ pt1.x+stripAxis.x*.5*stripWidth, 
                      pt1.y+stripAxis.y*.5*stripWidth,
                      pt1.z+stripAxis.z*.5*stripWidth],
          color: [ 1, 1, 1],
          normal: [ normal.x, normal.y, normal.z ],
          line: 0,
          time: 0
        },
        { 
          position: [ pt1.x-stripAxis.x*.5*stripWidth, 
                      pt1.y-stripAxis.y*.5*stripWidth,
                      pt1.z-stripAxis.z*.5*stripWidth],
          color: [ 1, 1, 1],
          normal: [ normal.x, normal.y, normal.z ],
          line: 0,
          time: 0
        }
        ]);

        const currentTime = now();
        if(currentTime-startedProcessing > 6) {
          yield delay(0);
          startedProcessing = now();
        }
      }
    }
  }

};

function requestAnimationFrame() {
  return new Promise((resolve) => 
    window.requestAnimationFrame(() => {
      resolve()
    })
  );
}

function* handleAnimationLoop(action) {
  let dt = .016;
  while(true) {
    const alignerState = yield select(alignerStateSelector);

    const paused = alignerState.get("paused");

    if(paused) {
      break;
    } else {
      const t0 = performance.now();
      yield call(requestAnimationFrame);
      yield put(actions.alignerState.incrementTime(dt));
      const t1 = performance.now();
      dt = (t1-t0)/1000;
    }
  }
}

export function* playSaga() {
  yield takeLatest([
    actions.alignerState.play,
    actions.alignerState.pause,
    actions.alignerState.setActivePoint,
    actions.alignerState.nextPoint,
    actions.alignerState.prevPoint
  ], handleAnimationLoop);
};

export function* persistToLocalStorage() {
  const alignerState = yield select(alignerStateSelector);

  const addBridge = alignerState.get("addBridge");
  const addCylinders = alignerState.get("addCylinders");
  const drawMode = alignerState.get("drawMode");
  const snapFit = alignerState.get("snapFit");

  localStorage.setItem("addBridge", addBridge);
  localStorage.setItem("addCylinders", addCylinders);
  localStorage.setItem("drawMode", drawMode);
  localStorage.setItem("snapFit", snapFit);
};

export function* getAlignerUserInfo() {
  const accessToken = yield call(getAccessToken);

  yield put(actions.alignerState.loading("fetchingUserInfo", "Fetching user information...", "indeterminate"));
  const { data, error } = yield call(restAPI.getUserInfo, accessToken);
  yield put(actions.alignerState.stopLoading("fetchingUserInfo"));

  if(!error && !data.error) {
    const cache = AlignerCache.getInstance();
    yield cache.setKey(data.encryptionKey);
    yield put(actions.alignerState.setUserInfo({
        credits: data.credits,
        expires: data.expires,
        apiKey: data.apiKey,
        canMakePurchases: data.canMakePurchases
    }));
  }
}

export function* importPreviousPoints() {
  const alignerState = yield select(alignerStateSelector);
  const previousAlignerID = alignerState.get("previousAlignerID");
  const previousAlignerLabel = alignerState.get("previousAlignerLabel");
  const apiKey = alignerState.get("scannerKey") || (alignerState.get("user") && alignerState.get("user").get("apiKey"));
  
  if(previousAlignerID) {
    const accessToken = yield call(getAccessToken);

    yield put(actions.alignerState.loading("fetchingPointsFromPreviousAligner", "Fetching points from " + previousAlignerLabel + "...", "indeterminate"));
    const { data, error } = yield call(restAPI.getPoints, { alignerID: previousAlignerID, apiKey, accessToken });
    yield put(actions.alignerState.stopLoading("fetchingPointsFromPreviousAligner"));

    if(!error && !data.error) {
      const currentAlignerState = yield select(alignerStateSelector);
      if(currentAlignerState.get("alignerID") === alignerState.get("alignerID")) {
        yield put(actions.alignerState.receivedPreviousPoints(data.points));
      }
    }
  } else {
    yield call(restAPI.logFrontEndError, apiKey, { message: "No previousAlignerID when trying to import previous points." });
  }
}

export function* purchaseCredits(action) {
  const alignerState = yield select(alignerStateSelector);
  const apiKey = alignerState.get("scannerKey") || (alignerState.get("user") && alignerState.get("user").get("apiKey"));
  const credits = action.payload;

  const loadingID = "creatingCheckoutSession";
  yield put(actions.alignerState.loading(loadingID, "Preparing purchase...", "indeterminate"));
  const accessToken = yield call(getAccessToken);
  const { data, error } = yield call(restAPI.purchaseCredits, { apiKey, accessToken, credits });
  if(error || data.error) {
    // TODO error
    yield call(restAPI.logFrontEndError, apiKey, { message: "Error purchasing credits", error });
  }
  yield put(actions.alignerState.stopLoading(loadingID));

  if(data && data.sessionId) {
    const {
      sessionId
    } = data;
    const stripe = yield call(getStripe);

    try {
      yield call(stripe.redirectToCheckout, { sessionId });
    } catch(err) {
      // TODO - error
      yield call(restAPI.logFrontEndError, apiKey, { message: "Error redirecting to checkout", err });
    }
  }
}

export function* checkForPointsOnOtherAligners() {
  const alignerState = yield select(alignerStateSelector);
  const alignerID = alignerState.get("alignerID");

  if(alignerID) {
    const label = alignerState.get("label");
    const code = label.split("-")[0];
    const apiKey = alignerState.get("scannerKey") || (alignerState.get("user") && alignerState.get("user").get("apiKey"));

    const loadingID = "checkingForPointsOnOtherAligners" + alignerID;
    yield put(actions.alignerState.loading(loadingID, "Checking for points on other aligners in set " + code + "...", "indeterminate"));
    const accessToken = yield call(getAccessToken);
    const { data, error } = yield call(restAPI.checkForPointsOnOtherAligners, { apiKey, accessToken, alignerID });
    if(error) {
      // TODO error
      yield call(restAPI.logFrontEndError, apiKey, { message: "Error checking for points on other aligners", error, code });
    }
    yield put(actions.alignerState.stopLoading(loadingID));

    const currentAlignerState = yield select(alignerStateSelector);
    if(alignerID === currentAlignerState.get("alignerID")) {
      const aligners = data.aligners;
      const withPointsAndProcessed = aligners.filter((aligner) => aligner.processedModel && aligner.points);
      const withPoints = aligners.filter((aligner) => aligner.points);

      // if there is more than 1 aligner and there are aligners that need points
      if(aligners.length > 1) {
        if(aligners.length === withPointsAndProcessed.length) {
          yield put(actions.alignerState.showDownloadAllGCode());
        } else if(withPoints.length !== aligners.length && currentAlignerState.get("closePath")) {
          yield put(actions.alignerState.showCopyPoints());
        }
      }
    }
  }
}

export default function* alignerSaga() {
  yield all([
    playSaga(),
    takeLatest([ actions.auth.userAuthenticated, actions.alignerState.createdAligner ], getAlignerUserInfo),
    takeLatest(actions.rfid.receivedRfidScanMessage, scannedRFID),
    takeLatest(actions.alignerState.createAligner, createAligner),
    takeLatest(actions.alignerState.setAligner, setAligner),
    takeLatest(actions.alignerState.quickLookUpAutocomplete, quickLookUpAutocomplete),
    takeLatest([
      actions.alignerState.setAddBridge, 
      actions.alignerState.setAddCylinders,
      actions.alignerState.setSnapFit,
      actions.alignerState.setDrawMode
    ], persistToLocalStorage),

    takeEvery(actions.alignerState.createMultipleAligners, createMultipleAligners),
    takeEvery(actions.alignerState.uploadModel, uploadModel),
    takeEvery(actions.alignerState.finalizeModelClick, finalizeModel),
    takeEvery(actions.alignerState.downloadProcessedModel, downloadProcessedModel),
    takeEvery(actions.alignerState.downloadGCode, downloadGCode),
    takeEvery(actions.alignerState.downloadAllProcessedModels, downloadAllProcessedModels),
    takeEvery(actions.alignerState.downloadAllGCode, downloadAllGCode),
    takeEvery(actions.alignerState.copyPoints, copyPoints),

    takeLatest(actions.alignerState.purchaseCredits, purchaseCredits),
    takeLatest(actions.alignerState.importPreviousPoints, importPreviousPoints),
    takeLatest(actions.alignerState.checkForPointsOnOtherAligners, checkForPointsOnOtherAligners),
    takeLatest(actions.alignerState.savePoints, savePoints),
    takeLatest(actions.alignerState.saveTransform, saveTransform),
    takeLatest([ actions.alignerState.initializeDrawPath,
                 actions.alignerState.addPointToDrawPath,
                 actions.alignerState.cancelDrawPath
                 ], updateDrawPathPreview),
    takeLatest([ actions.alignerState.addPoint, 
                actions.alignerState.rotateMachine,
                actions.alignerState.updatePointPosition,
                actions.alignerState.deletePoint,
                actions.alignerState.closePath,
                actions.alignerState.alignerStatusResponse,
                actions.alignerState.importedTransformAndPoints,
                actions.alignerState.simplifyDrawPath,
                actions.alignerState.resetState,
                actions.alignerState.receivedPreviousPoints,
                actions.alignerState.loadFirstModelOfMultiple], updateAlignerPathPreview)
  ]);
};
