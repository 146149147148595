import React, { useCallback, useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Downshift from 'downshift';

const styles = (theme) => ({
  quickLookUp: {
    border: '1px solid #888888',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: "white"
  },
  paper: {
    position: 'absolute',
    zIndex: 100,
    maxHeight: 400,
    width: 200,
    overflow: "scroll"
  }
});

export default withStyles(styles)((props) => {
  const {
    classes,
    disabled,
    //onQuickLookUp,
    aligners,
    onAutocomplete,
    onSelect
  } = props;

  const inputRef = useRef(null);
  const [isOpen, setIsOpen ] = useState(false);
  const [ value, setValue ] = useState("");
  const handleFocus = useCallback((e) => {
    onAutocomplete(e.target.value);
    setIsOpen(true);
  }, [ onAutocomplete ]);
  const handleBlur = useCallback((e) => {
    setIsOpen(false);
  }, []);
  const handleKeyDown = useCallback((e) => {
    if(e.key === 'Enter' || e.key === 'Escape') { 
      e.target.blur();
    } 
  }, []);
  const handleItemClick = useCallback((e) => {
    inputRef.current.blur();
    setIsOpen(false);
  }, [ ]);

  const handleSelect = useCallback((aligner) => {
    if(onSelect && aligner) {
      onSelect(aligner);
    }
    setValue("");
  }, [ onSelect, setValue ])
  return <Downshift onSelect={handleSelect}
                    itemToString={aligner => (aligner ? aligner.label : '')}
                    defaultHighlightedIndex={0}
                    onStateChange={(changes, stateAndHelpers) => {
                      switch(changes.type) {
                        case Downshift.stateChangeTypes.changeInput:
                          setValue(changes.inputValue.toUpperCase());
                          onAutocomplete(changes.inputValue);
                          break;
                        default:
                          break;
                      }
                    }}
                    inputValue={value}
                    isOpen={isOpen}>
           {
             (params) => {
               const {
                 getLabelProps,
                 getInputProps,
                 getItemProps,
                 getMenuProps,
                 isOpen,
                 inputValue,
                 highlightedIndex
               } = params;
               return (<div>
                   <TextField inputRef={inputRef} disabled={disabled} label="Quick Lookup" variant="filled" margin="dense" classes={ {root: classes.quickLookUp }} InputProps={getInputProps({ onKeyDown: handleKeyDown, onBlur: handleBlur, onFocus: handleFocus })} InputLabelProps={getLabelProps()}/>
                   <div {...getMenuProps()}>
                   { isOpen ? 
                     <Paper className={classes.paper} square>
                       { aligners.filter(aligner => aligner.label.startsWith(inputValue.toUpperCase()))
                              .map((aligner, index) => (
                                 <MenuItem {...getItemProps({
                                     key: aligner.label, 
                                     index, 
                                     item: aligner, 
                                     style: { 
                                       backgroundColor: highlightedIndex === index ? 'lightgray' : 'white' 
                                     },
                                     onClick: handleItemClick
                                   })} >
                                   {aligner.label}
                                 </MenuItem>
                               ))
                       }
                     </Paper>
                     : null }
                     </div>
                 </div>);
             }
           }
         </Downshift>;
});
