import { all } from 'redux-saga/effects';
import interpreter from './interpreter';
import play from './play';
import loadDefaultGCode from './load-default-gcode';
import iframe from './iframe';
import scanner from './scanner';
import aligner from './aligner';
import auth from './auth';

export default function* rootSaga() {
//  console.log("in rootSaga");
  yield all([ interpreter(),
              play(),
              loadDefaultGCode(),
              iframe(),
              scanner(),
              aligner(),
              auth()]);
};
