//import actions from '../../actions';
import { handleActions } from 'redux-actions';
import Immutable from 'immutable';

const reducer = handleActions(
  {
//    [actions.iframe.initialize]: (state, action) => state.set("visible", false)
  },
  Immutable.Map({
    visible: true
  })
);

export default reducer;
