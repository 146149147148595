export const fetchExponentialBackoff = (url, args, maxRetries=9, retries, base=50) => {
  const promiseGenerator = () => fetch(url, args);
  return exponentialBackoff(promiseGenerator, maxRetries, retries, base);
};
export const exponentialBackoff = (promiseGenerator, maxRetries=9, retries, base=50) => {
  if(typeof(retries) === "undefined") {
    retries = maxRetries;
  }
  const promise = promiseGenerator();

  return promise.catch((err) => {
    if(retries > 0) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          exponentialBackoff(promiseGenerator, maxRetries, retries-1, base).then(resolve).catch(reject);
        }, Math.pow(2, maxRetries-retries)*base);
      })
    } else {
      throw err;
    }
  });
};
