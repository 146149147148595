import now from 'performance-now';
import { connect } from 'react-redux';
import actions from '../actions';
import getAnalytics from '../util/analytics';
import { POSITION_MODEL } from '../constants/aligner-state';

import ModelLoader from '../components/ModelLoader';

import Viewer3D from '../viewer3d';

const analytics = getAnalytics();

export default connect(
  (state, ownProps) => ({ 
  }),
  (dispatch, ownProps) => ({
    onLoadModel: (model, filename) => {
      const viewer = Viewer3D.getInstance();

      let stl;
      if(filename.endsWith(".obj")) {
        if(viewer) {
          const t0 = now();
          const decoder = new TextDecoder("utf-8");
          viewer.loadObjModel(decoder.decode(model));
          viewer.computePlaneWithLargestArea();
          const t1 = now();

          analytics.timing("ModelLoader", "load obj", Math.round(t1-t0)).send();
          analytics.event("ModelLoader", "OBJ File Size", "OBJ File Size", model.byteLength).send();

          stl = viewer.exportModelToSTL();
        } else {
          console.error("viewer not loaded yet");
        }
      } else if(filename.endsWith(".stl")) {
        stl = model; // TODO - check if binary stl file, right now server assumes binary stl, perhaps we should check on the server and do necessary conversion
                     // The three.js stl loader can handle either, so alternatively
                     // if it's ascii, it could be loaded into three.js and exported
                     // to a binary STL file (the same process we do for other formats, like obj above)
        if(viewer) {
          const t0 = now();
          viewer.loadSTLModel(model);
          viewer.computePlaneWithLargestArea();
          const t1 = now();

          analytics.timing("ModelLoader", "load stl", Math.round(t1-t0)).send();
          analytics.event("ModelLoader", "STL File Size", "STL File Size", model.byteLength).send();
        } else {
          console.error("viewer not loaded yet");
        }
      }

      dispatch(actions.alignerState.uploadModel(stl));
      dispatch(actions.viewer3D.loadModel())
      dispatch(actions.viewer3D.showModel())
      dispatch(actions.alignerState.setStep(POSITION_MODEL));
      dispatch(actions.alignerState.modelLoaded());
    }
  })
)(ModelLoader);
