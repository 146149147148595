import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import GCodeLoader from '../containers/GCodeLoader';
import ModelLoader from '../containers/ModelLoader';
import Progress from '../containers/Progress';
import { withStyles } from '@material-ui/core/styles';
import logo from '../pocketnc.png';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import QuickLookUp from '../containers/QuickLookUp';
import CreateAlignerButton from '../containers/CreateAlignerButton';

import getAnalytics from '../util/analytics';
import { signIn, signOut } from '../util/auth';

const analytics = getAnalytics();

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#333333"
  },
  title: {
    flex: 1,
    color: "white",
    textDecoration: "none"
  },
  logo: {
    height: 32,
    marginRight: 10
  }
});

export default withStyles(styles)((props) => {
  const [ userMenuAnchorEl, setUserMenuAnchorEl ] = React.useState(null);

  const handleUserMenuClick = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setUserMenuAnchorEl(null);
  };

  const { 
    classes,
    hideButtons,
    title,
    user,
    onMyAccount,
    canMakePurchases
  } = props;

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <a href="https://www.pocketnc.com" target="_blank" rel="noopener noreferrer"><img src={logo} alt="Pocket NC" className={classes.logo} onClick={() => analytics.event("TitleBar", "clicked link to pocketnc.com").send()}/></a>
        <Typography className={classes.title}>
        <a href="https://www.pocketnc.com" className={classes.title} target="_blank" rel="noopener noreferrer" onClick={() => analytics.event("TitleBar", "clicked link to pocketnc.com").send()}>{ title ? title : "Pocket NC Aligners" }</a> 
        </Typography>
        <Progress/>
        { hideButtons ? null :
        <>
        <GCodeLoader onError={(e) => console.error(e)}/>
        <ModelLoader size="small" color="inherit" onError={(e) => console.error(e)}/>
        </>
        }
        { !user ?
        <Button onClick={() => signIn()} color="inherit">Sign In</Button> 
        : 
        <>
        <CreateAlignerButton/>
        <QuickLookUp/>
        <Button onClick={handleUserMenuClick} color="inherit">{user.get("name")} <ArrowDropDownIcon/></Button>
        <Menu
          anchorEl={userMenuAnchorEl}
          keepMounted
          open={Boolean(userMenuAnchorEl)}
          onClose={handleCloseUserMenu}>
          <MenuItem disabled={true}>Available Credits: {user.get("credits")}</MenuItem>
          { canMakePurchases ? <MenuItem onClick={() => { handleCloseUserMenu(); onMyAccount() }}>Purchase Credits</MenuItem> : null }
          <hr/>
          <MenuItem onClick={() => { handleCloseUserMenu(); signOut(); }}>Sign Out</MenuItem>
        </Menu>
        </>
        }

      </Toolbar>
    </AppBar>
  );
})
