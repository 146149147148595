import localforage from 'localforage';
import crypto from 'crypto';

export default class AlignerCache {
  static instance;

  static getInstance() {
    if(!this.instance) {
      this.instance = new AlignerCache();
    }
    return this.instance;
  }

  constructor() {
    this.maxCacheSize = 1024*1024*1024; // 1GB
    this.store = localforage.createInstance({
      name: "alignerCache",
      driver: localforage.INDEXEDDB,
      storeName: "alignerCache",
      description: "Stores files locally to avoid unnecessary requests to the cloud."
    });
    this.metadata = localforage.createInstance({
      name: "alignerCacheMetadata",
      driver: localforage.INDEXEDDB,
      storeName: "alignerCacheMetadata",
      description: "Stores meta data about files stored in the cache."
    });
  }

  // setKey is a 64 character hex string representing 256 bits of random data (128 bits for the hmac key and 128 bits for the aes key)
  // Each user should be assigned one and it can be reset to new random bits at any time, in which case all cached items will be invalidated on retrieval.
  async setKey(keyString) {
    if(keyString && keyString.length === 64) {
      const hmacKeyData = new Uint8Array(16);
      const aesKeyData = new Uint8Array(16);
      for(let i = 0; i < 16; i++) {
        hmacKeyData[i] = parseInt(keyString.slice(i,i+2), 16);
      }
      for(let i = 0; i < 16; i++) {
        aesKeyData[i] = parseInt(keyString.slice(i+16,i+18), 16);
      }

      const hmacKeyPromise = window.crypto.subtle.importKey("raw", hmacKeyData, { name: "HMAC", hash: "SHA-512" }, false, ["sign", "verify"]).then((key) => {
        this.hmacKey = key;
      });
      const aesKeyPromise = window.crypto.subtle.importKey("raw", aesKeyData, "AES-GCM", false, ["encrypt", "decrypt"]).then((key) => {
        this.aesKey = key;
      });

      await Promise.all([ hmacKeyPromise, aesKeyPromise ]);
    }
  }

  async clearKey() {
    this.hmacKey = null;
    this.aesKey = null;
  }

  getMD5Checksum(data) {
    return '"' + crypto.createHash('md5').update(Buffer.from(data)).digest('hex') + '"';
  }

  async getTotalSize() {
    let totalSize = 0;
    await this.metadata.iterate((value, key, iterationNumber) => {
      totalSize += value.size;
    }).catch((err) => { console.error(err) });

    return totalSize;
  }

  async setItem(checksum, data) {
    if(this.aesKey && this.hmacKey) {
      const size = data.byteLength || data.length || 0;
      const totalSize = await this.getTotalSize()+size;
      const iv = window.crypto.getRandomValues(new Uint8Array(16));
      const encryptedData = await window.crypto.subtle.encrypt({
        name: "AES-GCM",
        iv
      }, this.aesKey, data);

      const signature = await window.crypto.subtle.sign("HMAC", this.hmacKey, encryptedData);

      this.store.setItem(checksum, encryptedData);

      this.metadata.setItem(checksum, {
        timestamp: Date.now(),
        iv,
        signature,
        size
      });

      if(totalSize > this.maxCacheSize) {
        this.removeOldestData(totalSize-this.maxCacheSize);
      }
    }
  }

  async removeOldestData(reduceByAmount) {
    const allFiles = [];
    await this.metadata.iterate((value, key, iterationNumber) => {
      allFiles.push([ value, key ]);
    }).catch((err) => { console.error(err) });

    allFiles.sort((a,b) => { return a[0].timestamp-b[0].timestamp });

    let i = 0;
    let totalRemoved = 0;
    while(totalRemoved < reduceByAmount && i < allFiles.length) {
      const [ value, key ] = allFiles[i];
      totalRemoved += value.size;
      this.metadata.removeItem(key);
      this.store.removeItem(key);
      i++;
    }
  }

  async getItem(checksum) {
    if(this.aesKey && this.hmacKey) {
      const metadata = await this.metadata.getItem(checksum).then((value) => value).catch(() => null);
      if(metadata) {
        const {
          signature,
          iv
        } = metadata;

        if(signature && iv) {
          return await this.store.getItem(checksum).then(async (value) => {
            const verified = await window.crypto.subtle.verify("HMAC", this.hmacKey, signature, value);
            if(verified) {
              return window.crypto.subtle.decrypt({
                name: "AES-GCM",
                iv
              },
              this.aesKey,
              value);
            }
            return null;
          }).then((data) => {
            return data;
          }).catch(() => null);
        }
      }

    }
    return null;
  }

  async clear() {
    await this.store.dropInstance().then(() => console.log("dropped store"));
    await this.metadata.dropInstance().then(() => console.log("dropped metadata"));
    AlignerCache.instance = null;
  }
};
