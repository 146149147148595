import Amplify, { Hub, Auth } from 'aws-amplify';
import AlignerCache from './aligner-cache';
import QuickLookupCache from './quick-lookup-cache';

export const configureAuth = () => {
  const awsconfig = {
    "aws_cognito_region": process.env.REACT_APP_AWS_COGNITO_REGION,
    "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    "oauth": {
      "domain": process.env.REACT_APP_AWS_OAUTH_DOMAIN,
      "scope": [
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin"
      ],
      "redirectSignIn": process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_IN,
      "redirectSignOut": process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_OUT,
      "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
  }
  Amplify.configure(awsconfig);
};

export const signIn = () => {
  const cache = AlignerCache.getInstance();
  cache.clearKey();
  const quickCache = QuickLookupCache.getInstance();
  quickCache.clear();
  Auth.federatedSignIn();
};

export const signOut = () => {
  const cache = AlignerCache.getInstance();
  cache.clearKey();
  const quickCache = QuickLookupCache.getInstance();
  quickCache.clear();
  Auth.signOut();
};

export const getAccessToken = async () => {
  const accessToken = await Auth.currentAuthenticatedUser()
    .then(user => user.signInUserSession.accessToken.jwtToken)
    .catch(err => null);

  return accessToken;
};

// Returns authenticated user object when user successfully authenticates.
// Calling currentAuthenticatedUser will error when the user hasn't finished being authenticated yet 
// (this occurs after the redirect from Cognito). When this happens, we need to subscribe to auth
// events to know when the user is fully authenticated, so we can get return the authenticated user
// at that time. 
export const getAuthenticatedUserOnSignIn = () => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
        .then((user) => resolve(user))
        .catch((err) => {
          const listener = (data) => {
            if(data.payload.event === "signIn") {
              Hub.remove('auth', listener)
              resolve(data.payload.data);
            }
          };
          Hub.listen('auth', listener);
        });
  });
};

export const getCurrentAuthenticatedUser = () => {
  return Auth.currentAuthenticatedUser().catch((err) => null);
}
