import Immutable from 'immutable';
import * as messagesSelectors from './messages';

export const messages = messagesSelectors;

export const alignerState = (state) => state.get("alignerState");
export const interpreter = (state) => state.get("interpreter");
export const machineState = (state) => state.get("machineState");
export const lines = (state) => state.get("interpreter").get("lines");
export const linesPerIndex = (state) => state.get("interpreter").get("linesPerIndex");
export const approximateTime = (state) => state.get("interpreter").get("times").last();
export const loadedTool = (state) => {
  const toolState = state.get("tool");
  const toolTable = toolState.get("table");
  const loaded = toolState.get("loaded");
  if(loaded > 0) {
    return toolTable.get(loaded-1);
  } else {
    return Immutable.Map({ Z: -6, R: 0 });
  }
};
