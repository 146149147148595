import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './containers/App';
import AlignerApp from './containers/AlignerApp';
import registerServiceWorker from './registerServiceWorker';
import Immutable from 'immutable';
import actions from './actions';
import { HashRouter, Route } from "react-router-dom";

import IFrameMessenger from './iframe';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import getAnalytics from './util/analytics';
import { logFrontEndError } from './util/rfid-rest-api';
import { showThreads } from './util/legacy';

const sagaMiddleware = createSagaMiddleware({
  onError: (error, stack) => {
    const state = store.getState().toJS();
    const apiKey = state.alignerState.scannerKey || state.alignerState.user.apiKey;
    logFrontEndError(apiKey, { message: "Unknown root saga error", state, stack, error });

    store.dispatch(actions.unrecoverableError());
    console.error(error);
  }
});
const store = createStore(
  rootReducer, Immutable.Map(), composeWithDevTools(applyMiddleware(sagaMiddleware))
);

window.addEventListener('beforeunload', (e) => {
  const state = store.getState();

  const warnOnExit = state.get("alignerState").get("warnOnExit");

  if(warnOnExit) {
    e.preventDefault();
    e.returnValue = "";
  }
});

sagaMiddleware.run(rootSaga);

getAnalytics().pageview("/", document.location.href, document.title).send();

console.log("Pocket NC Aligners v" + process.env.REACT_APP_VERSION);
console.log("Commit", process.env.REACT_APP_COMMIT);

if(showThreads) {
  console.log("Showing legacy fixture that uses threads to attach model.");
}

window.addEventListener("message", (e) => {
  if(e.origin !== document.location.origin) {
    //console.log("in simulator on message",  { data: e.data, origin: e.origin });
    const initializeType = actions.iframe.initialize().type;
    const rockhopperType = actions.iframe.rockhopper({}).type;
    const embedGcodeType = actions.iframe.embedGcode().type;
    const iframeMessenger = IFrameMessenger.getInstance();
    switch(e.data.type) {
      case initializeType:
        store.dispatch(actions.iframe.initialize(e.data.payload));
        iframeMessenger.addWindow(e.source, e.origin);
        break;
      case rockhopperType:
// We could potentially simply dispatch the action received from pocketnc-ui.
// This would dispatch several different kinds of actions, though, all bundled
// into a single IFRAME/ROCKHOPPER type. Perhaps, that's ok.
//        store.dispatch(e.data);

// Or we could be more selective about what we dispatch.
// This seems like the redux history would stay a little cleaner as we
// can distiguish which events we actually need to dispatch and create
// specific action types for the ones we want to respond to.
//        console.log(e.data.payload.id, e.data.payload.data);
        if(e.data.payload.id === "file_content") {
//          const lines = e.data.payload.data.split("\n").map((line) => line.replace("\r", ""));
//          store.dispatch(actions.viewer3D.hideDentalFixture());
//          store.dispatch(actions.viewer3D.hideModel());
//          store.dispatch(actions.interpreter.setLines(lines));
        } else if(e.data.payload.id.startsWith("tool_table")) {
          const P = e.data.payload.id.split(":")[1];
          const Z = e.data.payload.data[3];
//          const R = e.data.payload.data[10];

          if(P === "1") {
//            console.log("should have dispatched setToolOffsetZ", actions.alignerState.setToolOffsetZ(Z));
            store.dispatch(actions.alignerState.setToolOffsetZ(Z));
          }
        } else if(e.data.payload.id === "HB") {
//          console.log("heart beat");
        }
        break;
      case embedGcodeType:
        const gcode = e.data.payload.split("\n").map((line) => line.trim());
        store.dispatch(actions.interpreter.setLines(gcode));
        break;
      default:
        break;
    }
  }
});

// pocketnc-ui likes to take focus to ensure keyboard shortcuts for jogging are captured.
// If we don't grab focus here, the backplot looks really laggy until we interact with a
// ui element because we allow a less responsive window when we don't have focus to 
// increase processing speed of the interpreter
window.focus();

ReactDOM.render(
<Provider store={store}>
  <HashRouter hashType="noslash">
    <Route path="/" exact component={AlignerApp}/>
  </HashRouter>
</Provider>, document.getElementById('root'));
registerServiceWorker();
