import { XY, ZX, YZ, UV, WU, VW } from '../../constants/machine-state/plane-select';
import actions from '../../actions/machine-state/plane-select';
import { handleActions } from 'redux-actions';

const reducer = handleActions(
  {
    [actions.machineState.planeSelect.setXY]: () => XY,
    [actions.machineState.planeSelect.setZX]: () => ZX,
    [actions.machineState.planeSelect.setYZ]: () => YZ,
    [actions.machineState.planeSelect.setUV]: () => UV,
    [actions.machineState.planeSelect.setWU]: () => WU,
    [actions.machineState.planeSelect.setVW]: () => VW
  },
  XY
);

export default reducer;
