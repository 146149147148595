import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';

function Progress(props) {
  const {
    value,
    thickness,
    loading
  } = props;

  if(loading) {
    const title = value.toFixed(2) + "%";
    return (
    <Tooltip title={title}>
      <CircularProgress variant="determinate" color="inherit" value={value} thickness={thickness}/>
    </Tooltip>
    );
  }

  return null;
}

Progress.propTypes = {
  value: PropTypes.number.isRequired,
  thickness: PropTypes.number.isRequired
};

export default Progress;
