import machineState from './machine-state';
import viewer3D from './viewer3d';
import * as alignerState from './aligner-state';

export const EPS = .000001; // epsilon, for floating point comparisons

export default {
  machineState,
  viewer3D,
  alignerState
};
