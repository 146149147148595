import { Vector3 } from 'three';

const mm2inches = 1.0/25.4;
const calculateGCodeCoord = ( ptRaw, dirRaw, doInverseKinematics ) => {
  const pt = new Vector3( ptRaw[0], ptRaw[1], ptRaw[2] );
  const dir = new Vector3( dirRaw[0], dirRaw[1], dirRaw[2] );

  pt.multiplyScalar(mm2inches)

  const A = Math.asin(dir.y);
  let B = Math.atan2(-dir.x, dir.z);

  const CA = Math.cos(A);
  const SA = Math.sin(A);
  const CB = Math.cos(B);
  const SB = Math.sin(B);

  const P = new Vector3();

  if(doInverseKinematics) {
    P.x = pt.x*CB+pt.z*SB;
    P.y = pt.x*SB*SA+pt.y*CA-pt.z*CB*SA;
    P.z = -pt.x*SB*CA+pt.y*SA+pt.z*CB*CA;
  } else {
    P.x = pt.x;
    P.y = pt.y;
    P.z = pt.z;
  }

  return { X: P.x, Y: P.y, Z: P.z, A: A*180/Math.PI, B: B*180/Math.PI };
};


const G0 = (coord) => {
  let g0 = "G0";
  for(let param in coord) {
    g0 += " " + param + coord[param].toFixed(4);
  }
  g0 += "\n";

  return g0;
};

const G1 = (coord, feed) => {
  let g1 = "G1";
  for(let param in coord) {
    g1 += " " + param + coord[param].toFixed(4);
  }
  if(feed) {
    g1 += " F" + feed.toFixed(4);
  }
  g1 += "\n";

  return g1;
};

// TODO - use this function in pts2gcode
export function pts2relativeMoves(pts) {
  const original = pts.map((arr) => [ [ arr[0], arr[1], arr[2] ], [ arr[3], arr[4], arr[5] ] ]);
  const vectors = [];

  const pt = new Vector3();
  const dir = new Vector3();
  const pt1 = new Vector3();
  const pt2 = new Vector3();
  const dir1 = new Vector3();
  const dir2 = new Vector3();
  const axis = new Vector3(1,0,0);
  for(let i = 0; i < original.length; i++) {
    pt1.set(original[i][0][0], original[i][0][1], original[i][0][2]);
    dir1.set(original[i][1][0], original[i][1][1], original[i][1][2]);

    if(i === original.length-1) {
      pt2.set(original[0][0][0], original[0][0][1], original[0][0][2]);
      dir2.set(original[0][1][0], original[0][1][1], original[0][1][2]);
    } else {
      pt2.set(original[i+1][0][0], original[i+1][0][1], original[i+1][0][2]);
      dir2.set(original[i+1][1][0], original[i+1][1][1], original[i+1][1][2]);
    }

    dir1.normalize();
    dir2.normalize();

    const angle = dir1.angleTo(dir2);
    if(angle > .00001) {
      axis.crossVectors(dir1, dir2);
      axis.normalize();
    }

    const gcodeCoord1 = calculateGCodeCoord( [pt1.x, pt1.y, pt1.z], [dir1.x, dir1.y, dir1.z], true );
    const gcodeCoord2 = calculateGCodeCoord( [pt2.x, pt2.y, pt2.z], [dir2.x, dir2.y, dir2.z], true );

    let aAngle = Math.abs(gcodeCoord1.A-gcodeCoord2.A);
    let bAngle = Math.abs(gcodeCoord1.B-gcodeCoord2.B);

    if(aAngle > 180) {
      aAngle = 360-aAngle;
    }
    if(bAngle > 180) {
      bAngle = 360-bAngle;
    }

    const oneDegree = Math.PI/180;
    const numPoints = Math.max(Math.ceil(angle/oneDegree), 2, Math.ceil(aAngle), Math.ceil(bAngle));
    for(let j = 0; j < numPoints; j++) {
      const t = j/(numPoints-1);

      pt.lerpVectors(pt1, pt2, t);
      dir.copy(dir1);
      dir.applyAxisAngle(axis, angle*t);

      vectors.push([ [ pt.x, pt.y, pt.z ], [dir.x, dir.y, dir.z ] ]);
    }
  }

  let minZ = 0;

  const startCoord = calculateGCodeCoord( vectors[0][0], vectors[0][1], true );
  const relativeMoves = [];

  let lastCoord = startCoord;
  vectors.forEach(([ ptRaw, dirRaw ]) => {
    const coord = calculateGCodeCoord( ptRaw, dirRaw, true );

    if(coord.Z < minZ) {
      minZ = coord.Z;
    }

    const dx = coord.X-lastCoord.X;
    const dy = coord.Y-lastCoord.Y;
    const dz = coord.Z-lastCoord.Z;
    const da = coord.A-lastCoord.A;
    let db = coord.B-lastCoord.B;

    if(db > 180) {
      db = db-360;
    } else if(db < -180) {
      db = db+360;
    }

    const outCoord = {};

    if(Math.abs(dx) > .00001) {
      outCoord.X = dx;
    }

    if(Math.abs(dy) > .00001) {
      outCoord.Y = dy;
    }

    if(Math.abs(dz) > .00001) {
      outCoord.Z = dz;
    }

    if(Math.abs(da) > .00001) {
      outCoord.A = da;
    }

    if(Math.abs(db) > .00001) {
      outCoord.B = db;
    }

    if(Object.keys(outCoord).length > 0) {
      relativeMoves.push(outCoord);
    }

    lastCoord = coord;
  });

  return relativeMoves;
}

export function pts2gcode(pts) {
  const original = pts.map((arr) => [ [ ...arr[0] ], [ ...arr[1] ] ]);
  const vectors = [];

  const pt = new Vector3();
  const dir = new Vector3();
  const pt1 = new Vector3();
  const pt2 = new Vector3();
  const dir1 = new Vector3();
  const dir2 = new Vector3();
  const axis = new Vector3(1,0,0);
  for(let i = 0; i < original.length; i++) {
    pt1.set(original[i][0][0], original[i][0][1], original[i][0][2]);
    dir1.set(original[i][1][0], original[i][1][1], original[i][1][2]);

    if(i === original.length-1) {
      pt2.set(original[0][0][0], original[0][0][1], original[0][0][2]);
      dir2.set(original[0][1][0], original[0][1][1], original[0][1][2]);
    } else {
      pt2.set(original[i+1][0][0], original[i+1][0][1], original[i+1][0][2]);
      dir2.set(original[i+1][1][0], original[i+1][1][1], original[i+1][1][2]);
    }

    dir1.normalize();
    dir2.normalize();

    const angle = dir1.angleTo(dir2);
    if(angle > .00001) {
      axis.crossVectors(dir1, dir2);
      axis.normalize();
    }

    const gcodeCoord1 = calculateGCodeCoord( [pt1.x, pt1.y, pt1.z], [dir1.x, dir1.y, dir1.z], true );
    const gcodeCoord2 = calculateGCodeCoord( [pt2.x, pt2.y, pt2.z], [dir2.x, dir2.y, dir2.z], true );

    let aAngle = Math.abs(gcodeCoord1.A-gcodeCoord2.A);
    let bAngle = Math.abs(gcodeCoord1.B-gcodeCoord2.B);

    if(aAngle > 180) {
      aAngle = 360-aAngle;
    }
    if(bAngle > 180) {
      bAngle = 360-bAngle;
    }

    const oneDegree = Math.PI/180;
    const numPoints = Math.max(Math.ceil(angle/oneDegree), 2, Math.ceil(aAngle), Math.ceil(bAngle));
    for(let j = 0; j < numPoints; j++) {
      const t = j/(numPoints-1);

      pt.lerpVectors(pt1, pt2, t);
      dir.copy(dir1);
      dir.applyAxisAngle(axis, angle*t);

      vectors.push([ [ pt.x, pt.y, pt.z ], [dir.x, dir.y, dir.z ] ]);
    }
  }

  let gcode = `%
G20
G90 G93 G40 G17
G53 G0 Z0

M5
M0
T2 M6

S50000 M3
G43

`;

  let minZ = 0;

  const startCoord = calculateGCodeCoord( vectors[0][0], vectors[0][1], true );
  gcode += G0( { X: startCoord.X, Y: startCoord.Y, A: startCoord.A, B: startCoord.B });
  gcode += G0({ Z: startCoord.Z+.5 });
  gcode += G1({ Z: startCoord.Z }, 9999);

  gcode += "G91\n";

  let lastCoord = startCoord;
  vectors.forEach(([ ptRaw, dirRaw ]) => {
    const coord = calculateGCodeCoord( ptRaw, dirRaw, true );

    if(coord.Z < minZ) {
      minZ = coord.Z;
    }

    const dx = coord.X-lastCoord.X;
    const dy = coord.Y-lastCoord.Y;
    const dz = coord.Z-lastCoord.Z;
    const da = coord.A-lastCoord.A;
    let db = coord.B-lastCoord.B;

    if(db > 180) {
      db = db-360;
    } else if(db < -180) {
      db = db+360;
    }

    const outCoord = {};

    if(Math.abs(dx) > .00001) {
      outCoord.X = dx;
    }

    if(Math.abs(dy) > .00001) {
      outCoord.Y = dy;
    }

    if(Math.abs(dz) > .00001) {
      outCoord.Z = dz;
    }

    if(Math.abs(da) > .00001) {
      outCoord.A = da;
    }

    if(Math.abs(db) > .00001) {
      outCoord.B = db;
    }

    if(Object.keys(outCoord).length > 0) {
      gcode += G1(outCoord, 9999);
    }

    lastCoord = coord;
  });

  gcode += "G0 Z0.5\n";
  gcode += "M5\n";
  gcode += "G90\n";
  gcode += "G53 G0 Z0\n";
  gcode += "%\n";
  return gcode;
}

export default function(pathcsv) {
  const data = pathcsv.split("\n");

  data.pop();

  const original = data.map((line) => {
    const d = line.split(",").map((a) => parseFloat(a));

    return [ d.slice(0,3), d.slice(3,6) ];
  });

  return pts2gcode(original);
}
