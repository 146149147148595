import { connect } from 'react-redux';

import AlignerApp from '../components/AlignerApp';
import actions from '../actions';
import * as alignerStateConstants from '../constants/aligner-state';
import { TOOL } from '../constants/viewer3d/camera-parent';
import { DRAW, SINGLE_POINT } from '../constants/aligner-state';
import { POSITION_MODEL } from '../constants/aligner-state';
import { EXACT, OVERSIZED } from '../constants/aligner-state';

export default connect(
  (state, ownProps) => ({
    previousAlignerID: state.get("alignerState").get("previousAlignerID"),
    previousAlignerLabel: state.get("alignerState").get("previousAlignerLabel"),
    unrecoverableError: state.get("errors").get("unrecoverableError"),
    drawMode: state.get("alignerState").get("drawMode"),
    user: state.get("alignerState").get("user"),
    snapFit: state.get("alignerState").get("snapFit"),
    addBridge: state.get("alignerState").get("addBridge"),
    addCylinders: state.get("alignerState").get("addCylinders"),
    hideTitleBar: !state.get("ui").get("titleBar").get("visible"),
    modelLoaded: state.get("alignerState").get("modelLoaded"),
    finalizeModelClicked: state.get("alignerState").get("finalizeModelClicked"),
    step: state.get("alignerState").get("step"),
    label: state.get("alignerState").get("label"),
    scannersDetected: state.get("alignerState").get("scannersDetected"),
    finishedProcessingModel: state.get("alignerState").get("finishedProcessingModel"),
    showDownloadGCodeButton: (state.get("alignerState").get("finishedProcessingModel") && 
                              state.get("alignerState").get("closePath")),
    closePath: state.get("alignerState").get("closePath"),
    transformMode: state.get("alignerState").get("transformMode"),
    machine: state.get("alignerState").get("machine"),
    showImportTransformAndPoints: (
      (state.get("alignerState").get("step") === POSITION_MODEL && state.get("alignerState").get("alignerID")) ||
      state.get("alignerState").get("createAlignerDialog").get("positioningModel")
    ) ? true : false,
    showCancelImportTransformAndPoints: state.get("alignerState").get("loading").get("waitingForScannerToScanRFIDToImport") ? true : false,
    creatingAligner: state.get("alignerState").get("createAlignerDialog").get("open"),
    positioningModel: state.get("alignerState").get("createAlignerDialog").get("positioningModel"),
    showDownloadAllGCode: state.get("alignerState").get("showDownloadAllGCode"),
    showDownloadAllProcessedModels: state.get("alignerState").get("showDownloadAllProcessedModels"),
    showCopyPoints: state.get("alignerState").get("showCopyPoints"),
    canMakePurchases: state.get("alignerState").get("user") ? state.get("alignerState").get("user").get("canMakePurchases") : false
  }),
  (dispatch, ownProps) => ({
    onMyAccount: () => {
      dispatch(actions.alignerState.openMyAccount());
    },
    onImportPreviousPoints: () => {
      dispatch(actions.alignerState.importPreviousPoints());
    },
    onCopyPoints: () => {
      dispatch(actions.alignerState.copyPoints());
    },
    onDownloadAllGCode: () => {
      dispatch(actions.alignerState.downloadAllGCode());
    },
    onDownloadAllProcessedModels: () => {
      dispatch(actions.alignerState.downloadAllProcessedModels());
    },
    onChangeAddBridge: (event) => {
      dispatch(actions.alignerState.setAddBridge(event.target.checked));
    },
    onChangeAddCylinders: (event) => {
      dispatch(actions.alignerState.setAddCylinders(event.target.checked));
    },
    onCancelImport: () => {
      dispatch(actions.alignerState.cancelImportTransformAndPoints());
    },
    onImportTransformAndPoints: () => {
      dispatch(actions.alignerState.importTransformAndPoints());
    },
    onChangeMachine: (machine) => {
      dispatch(actions.alignerState.setMachine(machine));
    },
    onClosePath: () => {
      dispatch(actions.alignerState.closePath());
      dispatch(actions.alignerState.savePoints());
      dispatch(actions.viewer3D.parentCamera(TOOL));
      dispatch(actions.alignerState.setStep(alignerStateConstants.PREVIEW_PATH));
      dispatch(actions.alignerState.checkForPointsOnOtherAligners());
    },
    onRotateMode: () => {
      dispatch(actions.alignerState.setRotateMode());
    },
    onTranslateMode: () => {
      dispatch(actions.alignerState.setTranslateMode());
    },
    onRotateCW: () => {
      dispatch(actions.alignerState.rotateCW());
      dispatch(actions.alignerState.saveTransform());
    },
    onRotateCCW: () => {
      dispatch(actions.alignerState.rotateCCW());
      dispatch(actions.alignerState.saveTransform());
    },
    onDownloadProcessedModel: () => {
      dispatch(actions.alignerState.downloadProcessedModel());
    },
    onDownloadGCode: () => {
      dispatch(actions.alignerState.downloadGCode());
    },
    onFinalize: () => {
      dispatch(actions.alignerState.finalizeModelClick());
      dispatch(actions.alignerState.setStep(alignerStateConstants.EDIT_PATH));
    },
    jumpToLoadModelStep: () => {
      dispatch(actions.alignerState.setStep(alignerStateConstants.LOAD_MODEL));
    },
    jumpToPositionModelStep: () => {
      dispatch(actions.alignerState.setStep(alignerStateConstants.POSITION_MODEL));
    },
    jumpToEditPathStep: () => {
      dispatch(actions.alignerState.setStep(alignerStateConstants.EDIT_PATH));
    },
    jumpToPreviewPathStep: () => {
      dispatch(actions.viewer3D.parentCamera(TOOL));
      dispatch(actions.alignerState.setStep(alignerStateConstants.PREVIEW_PATH));
    },
    onSetExact: () => {
      dispatch(actions.alignerState.setSnapFit(EXACT));
    },
    onSetOversized: () => {
      dispatch(actions.alignerState.setSnapFit(OVERSIZED));
    },
    onSetDraw: () => {
      dispatch(actions.alignerState.setDrawMode(DRAW));
    },
    onSetSinglePoint: () => {
      dispatch(actions.alignerState.setDrawMode(SINGLE_POINT));
    },
    jumpToCutPathStep: () => {
      dispatch(actions.alignerState.setStep(alignerStateConstants.CUT_PATH));
    },
    onSaveCreateAlignerPosition: () => {
      dispatch(actions.alignerState.createAlignerDialog.saveXform());
      dispatch(actions.alignerState.createAlignerDialog.setPositioningModel(false));
      dispatch(actions.alignerState.setStep(alignerStateConstants.LOAD_MODEL));
    },
    onCancelCreateAlignerPosition: () => {
      dispatch(actions.alignerState.createAlignerDialog.setPositioningModel(false));
      dispatch(actions.alignerState.setStep(alignerStateConstants.LOAD_MODEL));
    }
  })
)(AlignerApp);
