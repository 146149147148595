import { put, call, select, takeLatest } from 'redux-saga/effects';
import actions from '../actions';

import { interpreter as interpreterSelector } from '../selectors';

function requestAnimationFrame() {
  return new Promise((resolve) => 
    window.requestAnimationFrame(() => {
      resolve()
    })
  );
}

// handles all actions that would cause the program to pause or play
function* handleAnimationLoop(action) {
  let dt = .016;
  while(true) {
    let interpreter = yield select(interpreterSelector);

    const running = interpreter.get("running");
    const paused = interpreter.get("paused");

    if(running && !paused) {
      const t0 = performance.now();
      yield call(requestAnimationFrame);
      yield put(actions.interpreter.incrementTime(dt));
      const t1 = performance.now();
      dt = (t1-t0)/1000;
    } else {
      break;
    }
  }
}

export default function* watchSetGCodeProgram() {
  yield takeLatest([
    actions.interpreter.setLines, 
    actions.interpreter.pause,
    actions.interpreter.unpause,
    actions.interpreter.run,
    actions.interpreter.nextLine,
    actions.interpreter.previousLine,
    actions.interpreter.setCurrentLineWithPercentage
  ], handleAnimationLoop);
}
